import React from 'react'

function CategoryCardEngs(props) {
  return (
    <>
        <div
            className="col-auto portfolio-ens-item filter-Demandes pointer card-category-overflow"
            key={props.props.key}
            onClick={() => props.onClick(props.props.category)}>
            <div className="portfolio-ens-wrap">
            <div className="categorie-service">
                <img
                src={props.props.category.categoriePicture}
                />
            </div>
            <p className="text-futura-14 mt-3">
                {props.props.category.categoriefr}
            </p>
            </div>
        </div>
    </>

  )
}

export default CategoryCardEngs