import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import NavBarConnected from "../../components/NavBarConnected";
import { useHistory } from "react-router";
import NavbarMobile from "../../components/NavbarMobile";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import { useContainerDimensions } from "../ContainerDimensions";
import AnnounceCard from "../../components/cards/AnnounceCard";

function InYourSkilsServ() {
  const history = useHistory();

  const { t } = useTranslation();

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  const [pageSize, setPageSize] = useState(7);
  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  const allData = useSelector((state) => state.HomeReducer.allData);

  useEffect(async () => {
    setPageSize(parseInt(width / 180) * 2);
  });

  useEffect(async () => {
    setTotalPage(allData.otherAnnouncementInYourSkills.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);
  }, [allData, pageSize]);

  const goToAnnouncementDetails = (announce) => {
    history.push({
      pathname: `/annonce/${announce.slug}`,
      state: announce,
      connected: true,
    });
  };

  const handleChange = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <div>
      <NavBarConnected />
      <NavbarMobile />

      <main id="main">
        <div className="container-mmk">
          <section id="Photographe" className="Photographe">
            <p className="text-futura-20 text-align-left py-5">
              {t("inYourSkills")}
            </p>

            <div className="row gap-4" ref={componentRef}>
              {allData &&
              allData.otherAnnouncementInYourSkills.length>0 ?
                allData.otherAnnouncementInYourSkills.map(
                  (announce, key) =>
                    key >= minIndex &&
                    key < maxIndex && (
                      <AnnounceCard props={{announce, key}} onClick={() => goToAnnouncementDetails(announce)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </section>

          <div className="d-flex algin-items-center justify-content-center py-5">
            {allData &&
            allData.otherAnnouncementInYourSkills.length > pageSize ? (
              <div className="pagination">
                <Pagination
                  size="small"
                  showLessItems
                  showSizeChanger={false}
                  current={current}
                  total={allData.otherAnnouncementInYourSkills.length}
                  onChange={handleChange}
                  pageSize={pageSize}
                />
              </div>
            ) : null}
          </div>
        </div>
      </main>
    </div>
  );
}

export default InYourSkilsServ;
