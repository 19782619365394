import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavBarConnected from "../../components/NavBarConnected";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { useHistory } from "react-router";
import NavbarMobile from "../../components/NavbarMobile";
import ArrowToLeft from "../../components/icons/ArrowToLeft";
import Url from "../../constants/Url";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import CategoryCard from "../../components/cards/CategoryCard";

function AllCategoriesSer(props) {
  const [allCategorie, setAllCategorie] = useState(null);
  const history = useHistory();
  const categoryType = useSelector((state) => state.SearchReducer.categoryType);

  const { t } = useTranslation();

  useEffect(() => {
    if (!allCategorie) getCategorieWithType("service");
    else getCategorieWithType(categoryType);
  }, [categoryType]);

  async function getCategorieWithType(type) {
    await Url.Url2.post("getCategorieWithType", {
      categoryType: type,
    }).then((response) => {
      setAllCategorie(response.data);
    });
  }

  const token = localStorage.getItem("token");

  const goToSearch = (category) => {
    history.push({
      pathname: "/recherche",
      categoryId: category.id,
      categoryName: category.categoriefr,
    });
  };

  return (
    <>
     <Helmet>
        <title>Make My Work | Tous les catégories</title>
      </Helmet>
      {token ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <main id="main">
        <div className="container">
          <div className="d-flex flex-row align-items-center gap-3">
            <div className="pointer" onClick={() => history.goBack()}>
              <ArrowToLeft />
            </div>

            <p className="text-futura-20 text-align-left py-5">
              {t("allCategories")}
            </p>
          </div>

          <div className="row gap-4">
            {allCategorie &&
            allCategorie.length>0 ?
              allCategorie.map((category, key) => (
                <CategoryCard props={{category,key}} onClick={(category)=>goToSearch(category)}/>
              )):<span className="no-items">{t("noItems")}</span>}
          </div>
        </div>
      </main>
    </>
  );
}

export default AllCategoriesSer;
