import React, { useEffect, useState } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import * as actionCreator from "../../redux/actions";
import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FullStar from "../../components/icons/FullStar";
import EmptyStar from "../../components/icons/EmptyStar";
import VerifedProfile from "../../components/icons/VerifedProfile";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

export default function MyProfil() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [openModalShowMoreCompetence, setOpenModalShowMoreCompetence] =
    useState(false);
  const [openModalForAvis, setOpenModalForAvis] = useState(false);
  //const [userDetail, setUserDetail] = useState(null);

  const dispatch = useDispatch();

  const announcements = useSelector(
    (state) => state.AnnouncementReducer.otherUserannouncements
  );
  const userDetail = useSelector(
    (state) => state.ProfileReducer.otherProfileUser
  );
  const userAvis = useSelector((state) => state.ProfileReducer.otherUserAvis);
  const error = useSelector((state) => state.ProfileReducer.error);
  const otherUserAvgStarRating = useSelector(
    (state) => state.ProfileReducer.otherUserAvgStarRating
  );
  const serviceNumberForOtherUser = useSelector(
    (state) => state.ProfileReducer.serviceNumberForOtherUser
  );

  useEffect(() => {
    if (id) {
      const data = {
        userId: id,
        me: false,
      };
      dispatch(actionCreator.getAllAnnouncementEnCoursForUser(data));
      dispatch(actionCreator.getUserDetail(data));
      dispatch(actionCreator.getAvisUser(data));
      dispatch(actionCreator.getServiceNumber(data));
    }
  }, [id, error]);

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <>
      <Helmet>
        <title>Make My Work | Profil</title>
      </Helmet>
      <NavBarConnected />
      <NavbarMobile />

      <main id="main">
        <section
          id="sec6"
          className="sec6 d-flex align-items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="container-mmk w-100">
            {userDetail && announcements ? (
              <>
                <div
                  className="first-part-profil"
                  style={{ marginTop: "5rem" }}
                >
                  <div className="d-flex jusify-content-between align-items-center bio-down">
                    <div className="r-pro">
                      {userDetail.profilePicture !== null ? (
                        <img
                          className="rounded-circle image-profile"
                          //style={{ height: "20rem", width: "20rem" }}
                          src={userDetail.profilePicture}
                        />
                      ) : (
                        <img
                          className="rounded-circle image-profile"
                          //style={{ height: "20rem", width: "20rem" }}

                          src={`${require("../../img/avatar.png")}`}
                        />
                      )}
                    </div>
                    <div style={{ paddingLeft: "3rem", width: "100%" }}>
                      <div className="d-flex justify-content-between align-items-center container-max-width fix-bio">
                        <span>
                          <div className="d-flex gap-2">
                            <p className="text-futura-20 m-0">
                              {userDetail.name}
                            </p>
                            {userDetail.isCertified === "2" ? (
                              <VerifedProfile />
                            ) : null}
                          </div>
                          <p className="text-futura-20">{userDetail.surname}</p>
                        </span>
                      </div>
                      <div className="d-flex flex-column w-100 container-max-width">
                        <p
                          className="text-futura-16 text-align-left"
                          style={{ paddingTop: "2rem" }}
                        >
                          {userDetail.bio
                            ? userDetail.bio
                            : "Bonjour, c'est ici que tu peux parler de toi, de tes expertises et de ce que tu aimes ... "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" services-profil d-flex flex-column align-items-end ">
                    <div className="d-flex">
                      <p className="text-futura-14 px-2">
                        {otherUserAvgStarRating
                          ? parseFloat(otherUserAvgStarRating).toFixed(2)
                          : null}
                      </p>
                      <FullStar width={22} height={21} />

                      <div className="avis px-3">
                        <div
                          className="text-futura-16 text-underline pointer"
                          onClick={() => setOpenModalForAvis(true)}
                        >
                          {t("seeReviews")}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap gap-2">
                      <div className="service bg-gray mr-3">
                        <p className="text-futura-16">{t("ServiceRendered")}</p>
                        <p className="text-futura-16 mt-3">
                          {serviceNumberForOtherUser
                            ? serviceNumberForOtherUser.serviceRendu
                            : null}
                        </p>
                      </div>
                      <div className="service bg-pink mr-3">
                        <p className="text-futura-16">{t("ServiceRequests")}</p>
                        <p className="text-futura-16 mt-3">
                          {serviceNumberForOtherUser
                            ? serviceNumberForOtherUser.demandeService
                            : null}
                        </p>
                      </div>
                      <div className="service bg-purple ">
                        <p className="text-futura-16">{t("ReplyDuration")}</p>
                        <p className="text-futura-16 mt-3">1h</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "4rem" }}>
                  <p className="text-futura-20 text-align-left">
                    {t("Skills")}
                  </p>
                  <div className="d-flex  d-flex-responsive justify-content-between mt-3">
                    <div className="skills-profil" style={{ gridGap: "3rem" }}>
                      {userDetail.skill.length>0 ? 
                      userDetail.skill.map((skills, key) =>
                        key < 2 ? (
                          <div
                            key={key}
                            className="skill-profil "
                            style={{ marginRight: "2rem" }}
                          >
                            <div className="skill-img">
                              <img src={skills.skill.picture} />
                            </div>
                            <div className="p-skill">
                              <p className="text-futura-16">
                                {skills.skill.categoryName}
                              </p>
                              <p className="text-futura-16">
                                {"  -  " + skills.announcementNumber}{" "}
                                {t("Service")}
                              </p>
                            </div>
                          </div>
                        ) : null
                      ):<span className="no-items">{t("noItems")}</span>}
                      {userDetail && userDetail.skill.length > 3 ? (
                        <a
                          className="text-futura-16 text-underline text-align-right pointer mt-4"
                          onClick={() => setOpenModalShowMoreCompetence(true)}
                        >
                          {t("linkSeeMore")}
                        </a>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex justify-content-right align-items-center mb-5"
                  style={{ marginTop: "4rem" }}
                >
                  <p className="text-futura-20">{t("theCurrentOffers")}</p>
                </div>

                <div className="cards ">
                  {announcements.length>0?
                   announcements.map((announce, key) =>
                    announce.announcementType === "offre" ? (
                      <div key={key} className="card-pro">
                        <img
                          src={
                            announce.picture[0] || require('../../assets/img/No-image.jpeg')
                          }
                        />
                        <h4 className="text-futura-16 text-align-left">
                          {announce.announcementTitle}
                        </h4>
                        <p className="text-futura-11-grey text-align-left">
                          {t("rate")}: {announce.price}€
                        </p>
                        <p className="text-futura-11-grey text-align-left">
                          {announce.city}
                        </p>
                      </div>
                    ) : null
                  ):<span className="no-items">{t("noItems")}</span>}
                </div>

                <div
                  className="d-flex justify-content-right align-items-center mb-5"
                  style={{ marginTop: "4rem" }}
                >
                  <p className="text-futura-20">{t("theCurrentRequests")}</p>
                </div>
                <div className="cards">
                  {announcements.length>0 ?
                  announcements.map((announce, key) =>
                    announce.announcementType === "demande" ? (
                      <div key={key} className="card-pro">
                        <img
                          src={
                            announce.picture[0] || require('../../assets/img/No-image.jpeg')
                          }
                        />
                        <h4 className="text-futura-16 text-align-left">
                          {announce.announcementTitle}
                        </h4>
                        <p className="text-futura-11-grey text-align-left">
                          {t("rate")} : {announce.price}€
                        </p>
                        <p className="text-futura-11-grey text-align-left">
                          {announce.city}
                        </p>
                      </div>
                    ) : null
                  ):<span className="no-items">{t("noItems")}</span>}
                </div>
              </>
            ) : null}
          </div>
        </section>
      </main>

      <Modal
        open={openModalShowMoreCompetence}
        width={"50rem"}
        onCancel={() => setOpenModalShowMoreCompetence(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("Skills")}</p>

          <div className="content">
            <div className="skills-profil" style={{ gridGap: "3rem" }}>
              {userDetail && userDetail.skill.length>0?
                userDetail.skill.map((skills, key) => (
                  <div
                    key={key}
                    className="skill-profil "
                    style={{ marginRight: "2rem" }}
                  >
                    <div className="skill-img">
                      <img src={skills.skill.picture} />
                    </div>
                    <div className="p-skill">
                      <p className="text-futura-16">
                        {skills.skill.categoryName}
                      </p>
                      <p className="text-futura-16">
                        {"  -  " + skills.announcementNumber} {t("Service")}
                      </p>
                    </div>
                  </div>
                )):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalForAvis}
        width={"62rem"}
        onCancel={() => setOpenModalForAvis(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("Notice")}</p>

          <div className="content w-100">
            <div className="blue-scroll d-flex flex-column align-items-center height-modal-avis">
              {userAvis &&
                userAvis.length > 0 ?
                userAvis.map((announce, key) => (
                  <div className="card-avis mb-4" key={key}>
                    <div className="d-flex w-100">
                    {announce.user?.profilePicture ?
                       <img
                       src={
                         announce.user
                           .profilePicture
                       }
                       className="img-card-avis"
                     />
                      :null}
                      <div className="d-flex flex-column justify-content-start align-items-start px-3 width-78">
                        <p className="text-futura-11-grey">
                          {announce.user?.surname}
                        </p>
                        <div className="d-flex justify-content-end algin-items-center w-100">
                          {generateTempArray(
                            announce.rating
                          ).map((item) => (
                            <FullStar width={22} height={21} />
                          ))}
                          {generateTempArray(
                            5 - announce.rating
                          ).map((item) => (
                            <EmptyStar width={20} height={20} />
                          ))}
                        </div>
                        <p className="text-futura-14 overflow-hidden ellipsis-text-card-avis">
                          {announce.commentaire}
                        </p>
                      </div>
                    </div>
                  </div>
                )):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
