import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../../components/Footer";
import Phone from "../../components/icons/Phone";
import IconMail from "../../components/icons/IconMail";
import Accueil from "../../components/icons/Accueil";
import User from "../../components/icons/User";
import { useTranslation } from "react-i18next";
import Url from "../../constants/Url";
import SpinLoading from "../../components/SpinLoading";
import { notification } from "antd";
import { Helmet } from "react-helmet-async";

function Contact() {
  const user = useContext(AuthContext);
  const history = useHistory();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sentContact = async (e) => {
    e.preventDefault();
    setLoading(true);

    await Url.Url2.post("contact", {
      nom: name,
      prenom: lastName,
      entreprise: company,
      numeroTelephone: phoneNumber,
      adresseEmail: Email,
      message: comment,
    })
      .then((response) => {
        notification.success({

          message: "Contact",
          description: "Contact envoyé",
        });
        setLoading(false);
        history.push("/message-envoye");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
      <title>Make My Work | Contact</title>
      </Helmet>
      {user && user.user ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <section data-aos="fade-up" data-aos-delay="200">
        <div className="container mb-5">
          <div className=" d-flex justify-content-center align-items-center my-5">
            <h1 className="text-futura-30">{t("minContact")}</h1>
          </div>
          <div className="contact-container">
            <div className="card-contact">
              <div className="d-flex flex-column contact-left-side gap-5">
                <p className="text-futura-20 text-align-left">
                  {t("doNotHesitateToContactUs")} !
                </p>
                <p className="text-futura-16 text-align-left">
                  {t("fillOutTheFormP1")} <br />
                  {t("fillOutTheFormP2")}
                </p>
                <div className="contact-card">
                  <Accueil />
                  <p className="text-futura-16 mx-4">
                    {t("addressAvenueP1")}
                    <br />
                    {t("addressAvenueP2")}
                  </p>
                </div>

                <div className="contact-card">
                  <IconMail />
                  <p className="text-futura-16 mx-4">{t("mmkMail")}</p>
                </div>

                <div className="contact-card">
                  <Phone />
                  <p className="text-futura-16 mx-4">{t("numbers")}</p>
                </div>
                <div className="d-flex align-items-center gap-5">
                  <img
                    src={require('../../assets/img/twitter.png')}
                    alt=""
                    height="63px"
                    width="63px"
                  />
                  <img
                    src={require('../../assets/img/linkedin.png')}
                    alt=""
                    height="63px"
                    width="63px"
                  />
                  <img
                    src={require('../../assets/img/youtub.png')}
                    alt=""
                    height="63px"
                    width="63px"
                  />
                </div>
              </div>
              <form onSubmit={(e) => sentContact(e)}>
                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("lableFirstName")}
                  </p>

                  <div className="all-input">
                    <input
                      type="text"
                      className="w-100 fix-input"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <i>
                      <User />
                    </i>
                  </div>
                </div>

                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("lableLastName")}
                  </p>

                  <div className="all-input">
                    <input
                      type="text"
                      className="w-100 fix-input"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    <i>
                      <User />
                    </i>
                  </div>
                </div>

                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("labelCompany")} ({t("optional")})
                  </p>

                  <div className="all-input">
                    <input
                      type="text"
                      className="w-100 fix-input"
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div>

                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("lablePhoneNumber")}
                  </p>

                  <div className="all-input">
                    <input
                      type="text"
                      className="w-100 fix-input"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                    <i>
                      <Phone />
                    </i>
                  </div>
                </div>

                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("lableEmail")}
                  </p>

                  <div className="all-input">
                    <input
                      type="text"
                      className="w-100 fix-input"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <i>
                      <IconMail />
                    </i>
                  </div>
                </div>

                <div className="input-cnx w-100">
                  <p className="text-futura-16 text-align-left">
                    {t("lableComment")}
                  </p>

                  <textarea
                    className="form-creation fix-input height text-futura-16 text-align-left "
                    name="announcementDescription"
                    onChange={(e) => setComment(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex justify-content-center algin-items-center mt-5">
                  <button
                    type="submit"
                    id="submit"
                    className={
                      name && lastName && phoneNumber && Email && comment
                        ? "btn-mmk"
                        : "btn-mmk-grey-opacity"
                    }
                    disabled={loading}
                  >
                    {loading ? <SpinLoading /> : t("btnSent")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
