import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const getMessagesNotificationsWithSpecificUser = (userId) => {
  return async (dispatch) => {
    try {
      await Url.Url3.post("getMessagesNotificationsWithSpecificUser", {
        userId: userId,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_NOTIFICATIONS,
            payload: response.data.result.reverse(),
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};
