import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const getUserDetail = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("getUserDetail", {
        userId: data.userId,
      })
        .then((response) => {
          if (data.me) {
            dispatch({
              type: actionsTypes.GET_USER_DETAIL,
              payload: response.data,
            });
          } else {
            dispatch({
              type: actionsTypes.GET_OTHER_USER_DETAIL,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserData = (token) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("getUserInformations", {
        token: token,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_USER_DATA,
            payload: response.data,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateProfile = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("updateProfile", {
        userId: data.userId,
        name: data.name,
        surname: data.surname,
        phoneNumber: data.phoneNumber,
        bio: data.bio,
        birthdate: data.birthdate,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.UPDATE_PROFILE,
            payload: true,
          });
          notification.success({

            message: "Mettre à jour du profil",
            description: "La mise à jour du profil a réussi",
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const updatePassword = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("updatePwd", {
        userId: data.userId,
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.UPDATE_PASSWORD,
            payload: { updated: true },
          });
          notification.success({

            message: "Mettre à jour du mot de passe",
            description: "La mise à jour du mot de passe a réussi",
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
          dispatch({
            type: actionsTypes.UPDATE_PASSWORD,
            payload: { updated: false, message: error.message },
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const addProfilePicture = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("addProfilePicture", {
        userId: data.userId,
        profilePicture: data.profilePicture,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.ADD_PROFILE_PICTURE,
            payload: { pictureUpdated: true },
          });
          notification.success({

            message: "Mettre à jour du photo de profil",
            description: "La mise à jour du photo de profil a réussi",
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
          dispatch({
            type: actionsTypes.ADD_PROFILE_PICTURE,
            payload: { pictureUpdated: false },
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const createBankAccountByMangoPay = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url4.post("createBankAccountByMangoPay", {
        userId: data.userId,
        IBAN: data.IBAN,
        BIC: data.BIC,
      })
        .then((response) => {
          if (response.status === 200) {
            notification.success({
             
              message: "Compte bancaire",
              description: "Votre IBAN a étés bien ajouté.",
            });
          } else {
            notification.warning({
              message: "Compte bancaire",
              description: "Vérifiez vos données s'il vous plaît !",
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAvisUser = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url4.get(`getAvisUser/${data.userId}`)
        .then((response) => {
          if (response.status === 200) {
            let sumStarRating = 0;

            response.data.map(
              (announce) =>
                (sumStarRating =
                  sumStarRating + announce.rating)
            );

            let avgStarRating =
              sumStarRating / response.data.length;

            const resualt = {
              avis: response.data,
              avgStarRating: avgStarRating,
            };

            if (data.me) {
              dispatch({
                type: actionsTypes.GET_USER_AVIS,
                payload: resualt,
              });
            } else {
              dispatch({
                type: actionsTypes.GET_OTHER_USER_AVIS,
                payload: resualt,
              });
            }
          } else {
            notification.error({

              message: "error",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getServiceNumber = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url6.get(`nombreDeService/${data.userId}`)
        .then((response) => {
          if (response.status === 200) {
            if (data.me) {
              dispatch({
                type: actionsTypes.GET_SERVICE_NUMBER,
                payload: response.data,
              });
            } else {
              dispatch({
                type: actionsTypes.GET_SERVICE_NUMBER_FOR_OTHER_USER,
                payload: response.data,
              });
            }
          } else {
            //NotificationManager.error(response.data.message);
            notification.error({

              message: "error",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUserActivity = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url6.post(`revenuTotalDunUser`, {
        year: data.year,
        month: null,
        userId: data.userId,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: actionsTypes.GET_USER_ACTIVITY,
              payload: response.data,
            });
          } else {
            notification.error({

              message: "error",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateAllowNotif = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url3.post(`updateAllowNotif`, {
        userId: data.userId,
        notifAllow: data.notifAllow,
        notifAllowAnn: data.notifAllowAnn,
      })
        .then((response) => {
          notification.success({

            message: "Notification",
            description: "Autoriser la notification modifiée",
          });
        })
        .catch((error) => {
          //console.log("error :", error);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateNotifAllowAnn = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url3.post(`updateAllowNotif`, {
        userId: data.userId,
      })
        .then((response) => {
          notification.success({

            message: "Notification",
            description: "Autoriser la notification modifiée",
          });
        })
        .catch((error) => {
          //console.log("error :", error);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const blockUserByOtherUSer = (data) =>{
  return async (dispatch) => {
    try {
      await Url.Url1.post("blockUserByOtherUSer",{
        senderId: data.senderId,
        recieverId: data.recieverId
      }).then((response)=>{       
        if(response.data.message==="you have unblocked this user!"){
          notification.success({

            message: "Bloquer",
            description: "Utilisateur debloqué !!",
          });
        }else{
          notification.success({

            message: "Bloquer",
            description: "Utilisateur bloqué !!",
          });
        }
      }).catch((error) => {
        notification.error({

          message: "Bloquer",
          description: "Utilisateur déjà bloqué !!",
        });
      });
    } catch (error) {
      console.log(error)
    }
  }
}

export const reportAbusive = (data) =>{
  return async (dispatch)=>{
    try {
      await Url.Url1.post("reportAbusive",{
        senderId: data.senderId,
        receiverId: data.receiverId,
        reasonOfReport: data.reasonOfReport,
        annonceId: data.annonceId,
        type: data.type
      }).then((response)=> {
        notification.success({

          message: "Reporter",
          description: "Reporter envoyé !!",
        })
      }).catch((error) => {
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }
}
