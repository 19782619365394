import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  articlesCatergories: null,
  articles: null,
  videoUrl: null,
  partners: null,
};

const NewsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_ARTICLE_CATEGORIES:
      return {
        ...state,
        articlesCatergories: action.payload,
      };
    case actionType.GET_ARTICLE:
      return {
        ...state,
        articles: action.payload,
      };
    case actionType.GET_VIEDO:
      return {
        ...state,
        videoUrl: action.payload,
      };
    case actionType.GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
    default:
      return { ...state };
  }
};
export default NewsReducer;
