import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const getArticleCategories = (langue) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getArtcileCateg", {
        langue,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_ARTICLE_CATEGORIES,
            payload: response.data,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getArticle = (langue) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getArticle", {
        langue,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_ARTICLE,
            payload: response.data,
          });
        })
        .catch((error) => {
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getVideo = (langue) => {
  return async (dispatch) => {
    try {
      await Url.Url2.get("getVideo")
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_VIEDO,
            payload: response.data.media[0].urlS3,
          });
        })
        .catch((error) => {
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getPartners = (langue) => {
  return async (dispatch) => {
    try {
      await Url.Url2.get("getLogs")
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_PARTNERS,
            payload: response.data.logo,
          });
        })
        .catch((error) => {
        });
    } catch (error) {
      console.error(error);
    }
  };
};
