import React, { Component } from "react";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import * as actionCreator from "../redux/actions";
import { Link, NavLink, withRouter } from "react-router-dom";
import { destroyToken } from "../routes/shared";
import ArrowDown from "./icons/FlechDown";
import ArrowToLeft from "./icons/ArrowToLeft";
import { Menu, Dropdown } from "antd";
import CircleMessageIcon from "./icons/CircleMessageIcon";
import { AuthContext } from "../context/AuthContext";
import Url from "../constants/Url";

class navBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      path: props.location.pathname,
      search: props.inputSearch,
      categoryType:
        props.location.pathname === "/accueil" ||
        props.reducerCategoryType == "service"
          ? "service"
          : "enseignement",
      navbarmobile: false,
      hasMessage: false,
      query: "",
    };
  }

  componentDidMount() {
    if (window.innerWidth > 991) {
      this.setState({ navbarmobile: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.context.user) {
      await Url.Url3.get("getMessageVu/" + `${this.context.user.id}`)
        .then((response) => {
          if (prevState.hasMessage !== response.data.status) {
            this.setState({ hasMessage: response.data.status });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  handleSearch(e) {
    e.preventDefault();
    if (this.props.location.pathname.includes("messagerie")) {
      this.props.history.push({
        pathname: `/messagerie`,
        search: `?query=${this.state.search}`,
      });
      return;
    }
    this.props.history.push({
      pathname: `/recherche`,
      search: `?value=${this.state.search}`,
      state: true,
    });
  }

  handleInputChange(e) {
    e.preventDefault();

    this.setState({ search: e.target.value });
    if (!this.props.location.pathname.includes("messagerie")) {
      this.props.handleInputSearch(e.target.value);
    } else {
      this.props.history.push({
        pathname: `/messagerie`,
        search: `?query=${e.target.value}`,
      });
    }
  }

  toggleNavbar() {
    this.setState({ navbarmobile: !this.state.navbarmobile });
  }

  logOut() {
    destroyToken();
  }

  handleCategoryTypeChange(type) {
    this.props.handleCategoryType(type);
    this.setState({ categoryType: type });

    if (
      type == "enseignement" &&
      this.state.path != "/categorie-service" &&
      this.state.path != "/categorie-enseignement"
    ) {
      this.props.history.push("/accueil/enseignement");
    } else if (
      type == "service" &&
      this.state.path != "/categorie-service" &&
      this.state.path != "/categorie-enseignement"
    ) {
      this.props.history.push("/accueil");
    }
  }

  handleChangeLanguage(lang) {
    const i18n = this.context.i18n;
    i18n.changeLanguage(lang);
  }

  render() {
    const { path, navbarmobile } = this.state;
    const i18n = this.context.i18n;
    const { t } = this.context.t;
    return (
      <React.Fragment>
        <header id="header" className="fixed-top hide-navbar">
          <nav id="navbar " className="navbar">
            <div
              className="w-100 d-flex align-items-center bottom-shadow"
              style={{
                backgroundImage: ` url(${require("../img/bannerx4.png")})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100%",
                height: "10.7rem",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-center w-100"
                style={{ paddingTop: "15px" }}
              >
                {path == "/pseudo" ||
                //path === "/modifier-mes-competences" ||
                path === "/verifie-ton-profil" ? (
                  <div className="d-flex flex-column align-items-center justify-content-center ">
                    <img
                      src={`${require("../img/MMW-Logo-4.png")}`}
                      className="img-fluid icon-logo-mmk"
                      alt="..."
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column align-items-center justify-content-center w-100">
                    <Link to="/accueil">
                      <img
                        src={`${require("../img/MMW-Logo-4.png")}`}
                        className="img-fluid icon-logo-mmk"
                        alt="..."
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {path == "/pseudo" ? (
              <div className="w-100 container-mmk">
                <div className="d-flex justify-content-between align-items-center">
                  <li className="w-100"></li>
                  <li className="w-100">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <span id="t2">{t("chooseNickname")}</span>
                    </div>
                  </li>

                  <div className="w-100 d-flex justify-content-end ">
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </div>
                </div>
              </div>
            ) : path === "/dis-nous-en-plus" ? (
              <div className="w-100 container-mmk">
                <div className="d-flex justify-content-between align-items-center">
                  <li className="w-100"></li>
                  <li className="w-100">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <span id="t2">{t("tellUsMore")}</span>
                    </div>
                  </li>

                  <div className="w-100 d-flex justify-content-end ">
                  <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </div>
                </div>
              </div>
            ) : path === "/verifie-ton-profil" ? (
              <div className="w-100 container-mmk">
                <div className="d-flex justify-content-between align-items-center">
                  <li className="w-100"></li>
                  <li className="w-100">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <span id="t2">{t("profileCertified")}</span>
                    </div>
                  </li>

                  <div className="w-100 d-flex justify-content-end ">
                  <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </div>
                </div>
              </div>
            ) : path === "/profil-verifier" ? (
              <div className="w-100 container-mmk">
                <div className="d-flex justify-content-between align-items-center">
                  <li className="w-100"></li>
                  <li className="w-100">
                    <div className="w-100 d-flex justify-content-center align-items-center">
                      <span id="t2">{t("profileCertified")}</span>
                    </div>
                  </li>

                  <div className="w-100 d-flex justify-content-end ">
                  <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </div>
                </div>
              </div>
            ) : path === "/annonce-publier" ? (
              <div className="container justify-content-center align-items-center">
                <div className="col-3 align-items-center justify-content-center"></div>
                <div className="col-2"></div>
                <div className="col-5 align-items-center justify-content-center">
                  <ul>
                    <li>
                      <p className="text-futura-30">{t("announce")}</p>
                    </li>
                  </ul>
                </div>

                <div className="col-2  align-items-center justify-content-center">
                  <ul style={{ justifyContent: "right" }}>
                  <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className={
                  path !== "/selectionner-un-type-dannonce" &&
                  path !== "/profil" &&
                  path !== "/creer-une-annonce" &&
                  path !== "/ton-annonce" &&
                  path !== "/modifier-ton-announce"
                    ? "container-mmk w-100 d-flex justify-content-center align-items-center"
                    : "container-mmk w-100 d-flex justify-content-end align-items-center"
                }
              >
                {path !== "/selectionner-un-type-dannonce" &&
                path !== "/profil" &&
                path !== "/creer-une-annonce" &&
                path !== "/ton-annonce" &&
                path !== "/modifier-ton-announce" ? (
                  path.includes("/annonce") ? (
                    <div className="col-3 has-search align-items-center justify-content-start">
                      <div className="d-flex gap-3">
                        <ArrowToLeft />
                        <a
                          onClick={() => this.props.history.goBack()}
                          className="text-futura-16 text-align-left"
                        >
                          {t("backToSearchResualt")}
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="col-4 has-search align-items-center justify-content-start mt-1">
                      <span className="bi bi-search form-control-feedback"></span>
                      <form onSubmit={(e) => this.handleSearch(e)}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("search")}
                          style={{ height: "43px" }}
                          value={this.state.search}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </form>
                    </div>
                  )
                ) : null}
                <div className="col-2">

                  {path !== "/selectionner-un-type-dannonce" &&
                  path !== "/profil" &&
                  path !== "/creer-une-annonce" &&
                  path !== "/ton-annonce" &&
                  path !== "/modifier-ton-announce" ? (
                    path === "/accueil" ||
                    path === "/" ||
                    path === "/recherche" ||
                    path === "/categorie-service" ? (
                      this.state.categoryType === "service" ? (
                        <li
                          className="dropdown px-3"
                          id="dropt"
                          style={{ width: "90%" }}
                        >
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <span
                                    className="text-parte-pink"
                                    onClick={() =>
                                      this.handleCategoryTypeChange(
                                        "enseignement"
                                      )
                                    }
                                  >
                                    {t("teachingPart")}
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              <a id="met-violet">
                                <span
                                  className="text-parte-purple"
                                  onClick={() =>
                                    this.handleCategoryTypeChange("service")
                                  }
                                >
                                  {t("servicesPart")}
                                </span>
                                <i
                                  style={{
                                    marginTop: "3px",
                                    fontSize: " 18px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <ArrowDown />
                                </i>
                              </a>
                            </a>
                          </Dropdown>
                        </li>
                      ) : (
                        <li
                          className="dropdown px-3"
                          id="dropt"
                          style={{ width: "125%" }}
                        >
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <span
                                    className="text-parte-purple"
                                    onClick={() =>
                                      this.handleCategoryTypeChange("service")
                                    }
                                  >
                                    {t("servicesPart")}
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              <a id="met-rose">
                                <span
                                  className="text-parte-pink"
                                  onClick={() =>
                                    this.handleCategoryTypeChange(
                                      "enseignement"
                                    )
                                  }
                                >
                                  {t("teachingPart")}
                                </span>
                                <i
                                  style={{
                                    marginTop: "3px",
                                    fontSize: " 17px",
                                    marginLeft: "8px",
                                  }}
                                  //className="bi bi-chevron-down"
                                >
                                  <ArrowDown />
                                </i>
                              </a>
                            </a>
                          </Dropdown>
                        </li>
                      )
                    ) : path === "/accueil/enseignement" ||
                      path === "/categorie-enseignement" ? (
                      this.state.categoryType === "enseignement" ? (
                        <li
                          className="dropdown px-3"
                          id="dropt"
                          style={{ width: "125%" }}
                        >
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <span
                                    className="text-parte-purple"
                                    onClick={() =>
                                      this.handleCategoryTypeChange("service")
                                    }
                                  >
                                    {t("servicesPart")}
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              <a id="met-rose">
                                <span
                                  className="text-parte-pink"
                                  onClick={() =>
                                    this.handleCategoryTypeChange(
                                      "enseignement"
                                    )
                                  }
                                >
                                  {t("teachingPart")}
                                </span>
                                <i
                                  style={{
                                    marginTop: "3px",
                                    fontSize: " 17px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <ArrowDown />
                                </i>
                              </a>
                            </a>
                          </Dropdown>
                        </li>
                      ) : (
                        <li
                          className="dropdown px-3"
                          id="dropt"
                          style={{ width: "90%" }}
                        >
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item key="1">
                                  <span
                                    className="text-parte-pink"
                                    onClick={() =>
                                      this.handleCategoryTypeChange(
                                        "enseignement"
                                      )
                                    }
                                  >
                                    {t("teachingPart")}
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <a
                              className="ant-dropdown-link"
                              onClick={(e) => e.preventDefault()}
                            >
                              <a id="met-violet">
                                <span
                                  className="text-parte-purple"
                                  onClick={() =>
                                    this.handleCategoryTypeChange("service")
                                  }
                                >
                                  {t("servicesPart")}
                                </span>
                                <i
                                  style={{
                                    marginTop: "3px",
                                    fontSize: " 18px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  <ArrowDown />
                                </i>
                              </a>
                            </a>
                          </Dropdown>
                        </li>
                      )
                    ) : null
                  ) : null}
                </div>

                <div
                  className="col-2 d-flex justify-content-center align-items-center"
                  style={
                    path === "/selectionner-un-type-dannonce" ||
                    path === "/creer-une-annonce"
                      ? { width: "22%" }
                      : null
                  }
                >
                  {path === "/selectionner-un-type-dannonce" ||
                  path === "/creer-une-annonce" ? (
                    <p className="text-futura-30">{t("createAnAnnoucement")}</p>
                  ) : path === "/ton-annonce" ? (
                    <p className="text-futura-30">{t("seeOffer")}</p>
                  ) : path === "/modifier-ton-announce" ? (
                    <p className="text-futura-30">{t("editAnnounce")}</p>
                  ) : path.includes("/annonce") ? (
                    <div className="mt-3">
                      <p className="text-futura-26 width-content announce-tite-overflow">
                        {this.props.location.state.announcementTitle
                          ? this.props.location.state.announcementTitle
                          : null}
                      </p>
                      <p className="text-futura-14 ">
                        {this.props.location.state.user
                          ? this.props.location.state.user[0].name
                          : null}
                      </p>
                    </div>
                  ) : null}
                </div>

                {path === "/selectionner-un-type-dannonce" ||
                path === "/profil" ||
                path === "/creer-une-annonce" ||
                path === "/ton-annonce" ||
                path === "/modifier-ton-announce" ||
                path.includes("/annonce") ? (
                  <div className="col-1"></div>
                ) : null}

                <div className="col-4">
                  <div className="d-flex justify-content-between align-items-center py-4">
                    <NavLink
                      to="/accueil"
                      className="text-futura-20-grey pointer"
                      activeClassName="text-futura-20-pink"
                    >
                      {t("home")}
                    </NavLink>

                    <NavLink
                      to="/gerer"
                      className="text-futura-20-grey pointer"
                      activeClassName="text-futura-20-pink"
                    >
                      {t("manage")}
                    </NavLink>

                    <NavLink
                      to="/selectionner-un-type-dannonce"
                      className="text-futura-20-grey pointer"
                      activeClassName="text-futura-20-pink"
                    >
                      {t("create")}
                    </NavLink>

                    <NavLink
                      to="/messagerie"
                      className="text-futura-20-grey pointer "
                      activeClassName="text-futura-20-pink"
                    >
                      {this.state.hasMessage ? <CircleMessageIcon /> : null}{" "}
                      {t("message")}
                    </NavLink>

                    <NavLink
                      to="/profil"
                      className="text-futura-20-grey pointer"
                      activeClassName="text-futura-20-pink"
                    >
                      {t("profile")}
                    </NavLink>

                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
            <div className="mobile-navbar-container">
              <i
                style={{ margin: "2rem" }}
                onClick={() => this.toggleNavbar()}
                className={
                  navbarmobile === false
                    ? "bi bi-list mobile-nav-toggle"
                    : "bi bi-x mobile-nav-toggle"
                }
              ></i>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleInputSearch: (data) =>
      dispatch(actionCreator.handleInputSearch(data)),
    handleCategoryType: (data) =>
      dispatch(actionCreator.handleCategoryType(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    inputSearch: state.SearchReducer.inputSearch,
    reducerCategoryType: state.SearchReducer.categoryType,
  };
};

navBar.contextType = AuthContext;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(navBar));
