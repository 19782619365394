import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { storeToken, destroyToken } from "../../routes/shared";
import { notification } from "antd";

export const Signup = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("signupUser", {
        name: data.name,
        surname: data.surname,
        email: data.email,
        birthdate: null,
        pwd: data.pwd,
        phoneNumber: data.phoneNumber,
      })
        .then(async (response) => {
              notification.success({

                message: "Compte",
                description: "Compte Créé avec succes, vérifiez votre email",
              });
              window.location.href = "/verification-par-email";
        })
        .catch((error) => {
          //console.log("error :", error.message);
          if (error.message === "Request failed with status code 402") {
            notification.error({

              message: "Mail",
              description: "Mail déjà utilisé",
            });

            dispatch(
              mailInUsed({
                error: "Mail déjà utilisé",
              })
            );
            setTimeout(() => {
              dispatch(
                mailInUsed({
                  error: "",
                })
              );
            }, 5000);
          } else {
            //console.log("error :", error);
            notification.error({

              message: "error",
              description: error.message,
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateAdressInformation = (data) => {
  return async (dispatch) => {
    try {
    await Url.Url1.post("updateAdressInformation", {
      userId: data.userId,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      region: data.region,
      postalCode: data.postalCode,
      country: data.country,
      birthdate : data.birthDate,
      phoneNumber : data.phoneNumber,
    })
      .then((response) => {
        createUserAccountByMangoPay(data.userId);
      })
      .catch((error) => {
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  } catch (error) {
    console.log(error);
  }
  }
}

async function createUserAccountByMangoPay(userId) {
  try {
    await Url.Url4.post("createUserAccountByMangoPay", {
      userId: userId,
    })
      .then((response) => {
        // notification.success({

        //   message: "Compte",
        //   description: "Compte bancaire Créé avec succes.",
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
}

export const SignIn = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("signin", {
        email: data.email,
        pwd: data.pwd,
      })
        .then(async (response) => {
          const data = response.data;
          storeToken(data.expiredIn, data.token);
          let avis = await testIfAllCandidatureHaveAvis(response.data.id);
          localStorage.setItem("avis", JSON.stringify(avis));
          notification.success({

            message: "Connexion",
            description: "Connexion à votre compte",
          });

          if (data.hasPseudo === false) {
            window.location.href = "/pseudo";
          } else if (data.hasSkills === false) {
            window.location.href = "/dis-nous-en-plus";
          } else if (data.isCertified == "0") {
            window.location.href = "/verifie-ton-profil";
          } else {
            window.location.href = "/accueil";
          }
        })
        .catch((error) => {
          //console.log("message", error.response.data.message);
          console.log("rere",error.response.status)
          if (error.response.status === 403) {
            //NotificationManager.error("wrong password !");
            notification.error({

              message: "Mot de passe",
              description: "Mot de passe incorrect",
            });
          } else if (error.response.status === 404) {
            //NotificationManager.error("user not found !");
            notification.error({

              message: "Utilisateur",
              description: "Utilisateur non trouvé",
            });
          } else if (error.response.status === 402) {
            //NotificationManager.error("errer !");
            notification.error({

              message: "Utilisateur",
              description: "Votre profil n'est pas encore vérifié",
            });
          }else if (error.response.status === 401)
          {
            notification.error({

              message: "Bloqué",
              description: "Vous êtes bloqué",
            });
          }else {
            notification.error({

              message: "Utilisateur",
              description: "Vous avez déjà un compte avec google ou facebook",
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
};

async function testIfAllCandidatureHaveAvis(userId) {
  let data;
  try {
    await Url.Url4.post("testIfAllCandidatureHaveAvis", {
      userId: userId,
    })
      .then((response) => {
        data = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
  return data;
}

export const Verify = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("verify", {
        codEmailVerification: data.codeVerification,
      })
        .then((response) => {
          if (response.status === 200) {
            notification.success({

              message: "Compte",
              description: "Votre Compte et valider",
            });
            const data = response.data;

            storeToken(data.expiredIn, data.token);

            dispatch(
              authSuccess({
                email: data.email,
                expiredIn: data.expiredIn,
                id: data.id,
                name: data.name,
                pseudo: data.pseudo,
                surname: data.surname,
                token: data.token,
              })
            );
            window.location.href = "/pseudo";
          } else {
            //NotificationManager.warning("utilisateur non trouvé !");
            notification.warning({
              message: "Utilisateur",
              description: "Utilisateur non trouvé !",
            });
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
          //console.log("error :", error);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const LoginFacebook = (inputData) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("signupFacebook", {
        email: inputData.email,
        idFacebook: inputData.idFacebook,
        name: inputData.name,
        surname: inputData.name,
      })
        .then(async (response) => {
          const data = response.data;
          if (response.status === 200) {
            notification.success({

              message: "Connexion facebook",
              description: "Connexion avec facebook réussie",
            });

            dispatch(
              authSuccess({
                email: data.email,
                expiredIn: data.expiredIn,
                id: data.id,
                name: data.name,
                pseudo: data.pseudo,
                surname: data.surname,
                token: data.token,
              })
            );

            if (inputData.from == "signin") {

              storeToken(data.expiredIn, data.token);
              if (data.hasPseudo === false) {
                window.location.href = "/pseudo";
              } else if (data.hasSkills === false) {
                window.location.href = "/dis-nous-en-plus";
              } else if (data.isCertified == "0") {
                window.location.href = "/verifie-ton-profil";
              } else {
                window.location.href = "/";
              }
            } else if (inputData.from == "inscription") {
              
              notification.success({

                message: "Compte",
                description: "Compte Créé avec succes.",
              });

              window.location.href = "/connexion";
            }
          }
        })
        .catch((error) => {
          //console.log("error :", error);
          if (error.response.data.message === "you are blocked")
          {
            notification.error({

              message: "Bloqué",
              description: "Vous êtes bloqué",
            });
          }else{
            notification.error({

              message: "mail",
              description: "Email déjà utilisée",
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const LoginGoogle = (inputData) => {
  return async (dispatch) => {
    try {
      await Url.Url1.post("signinGoogle", {
        email: inputData.email,
        idGoogle: inputData.idGoogle,
        name: inputData.name,
        surname: inputData.surname ? inputData.surname : inputData.name,
      })
        .then(async (response) => {
          const data = response.data;
          if (response.status === 200) {
            notification.success({

              message: "Connexion google",
              description: "Connexion avec google réussie",
            });

            dispatch(
              authSuccess({
                email: data.email,
                expiredIn: data.expiredIn,
                id: data.id,
                name: data.name,
                pseudo: data.pseudo,
                surname: data.surname,
                token: data.token,
              })
            );
            if (inputData.from == "signin") {


              storeToken(data.expiredIn, data.token);
              if (data.hasPseudo === false) {
                window.location.href = "/pseudo";
              } else if (data.hasSkills === false) {
                window.location.href = "/dis-nous-en-plus";
              } else if (data.isCertified == "0") {
                window.location.href = "/verifie-ton-profil";
              } else {
                window.location.href = "/accueil";
              }
            } else if (inputData.from == "inscription") {
              notification.success({

                message: "Compte",
                description: "Compte Créé avec succes.",
              });

              window.location.href = "/connexion";
            }
          }
        })
        .catch((error) => {

          if (error.response.data.message === "you are blocked")
          {
            notification.error({

              message: "Bloqué",
              description: "Vous êtes bloqué",
            });
          }else{
            notification.error({

              message: "mail",
              description: "Email déjà utilisée",
            });
          }
        });
    } catch (error) {
      //data.loading(false)
      console.error(error);
    }
  };
};

export const authSuccess = (data) => {
  return {
    type: actionsTypes.NATIV_SIGNUP,
    payload: data,
  };
};

export const mailInUsed = (data) => {
  return {
    type: actionsTypes.MAIL_IN_USED,
    payload: data,
  };
};
