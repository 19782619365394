import React, { Component } from "react";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import { AuthContext } from "../../context/AuthContext";

class VerifyProfileConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  goToHome() {
    this.props.history.push({
      pathname: "/accueil",
    });
  }
  render() {
    const { t } = this.context.t;

    return (
      <>
        <Helmet>
          <title>Make My Work | Vérifie ton profil</title>
        </Helmet>
        <NavBarConnected />

        <main id="main" style={{ margin: "3rem 0" }}>
          <section
            id="sec7"
            className="sec7 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="container-mailsent">
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="image-verify-profile"
                    src={require('../../assets/img/certifie/certif.png')}
                    alt="..."
                  />
                </div>
                <div
                  className="row  justify-content-center align-items-center"
                  style={{ marginTop: "3rem" }}
                >
                  <p className="text-futura-16">
                    {t("descProfileCertifiedConfirmed")}
                  </p>
                </div>

                <div className=" d-flex justify-content-center mt-5">
                  <button
                    type="submit"
                    id="submit"
                    className="btn-mmk"
                    onClick={() => this.goToHome()}
                  >
                    {t("btnGoToHome")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
VerifyProfileConfirmation.contextType = AuthContext;
export default withRouter(VerifyProfileConfirmation);
