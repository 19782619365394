import React, { useContext, useEffect } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ContactSent() {
  const user = useContext(AuthContext);
  const history = useHistory();
  const {t} = useTranslation();

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
      {user ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <main id="main" style={{ margin: "3rem 0" }}>
        <section
          id="sec7"
          className="d-flex align-items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="container ">
          <div className=" d-flex justify-content-center align-items-center mb-5">
            <h1 className="text-futura-30">{t('minContact')}</h1>
          </div>

            <div className="container-mailsent ">
              <div className=" d-flex justify-content-center align-items-center mt-5">
                <img
                  className="image-verify-profile"
                  src={require('../../assets/img/contact-sent.png')}
                  alt="..."
                />
              </div>

              <div
                className="row  justify-content-center align-items-center"
                style={{ marginTop: "3rem" }}
              >
                <p className="text-futura-16">
                {t('messageSent')}
                </p>
              </div>

              <div className=" d-flex justify-content-center mt-5">
                <button
                  className="btn-mmk"
                  onClick={() => {user ? history.push("/accueil"):history.push("/accueil-non-connecte")}}
                >
                  {t('btnGoToHome')}
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  );
}

export default ContactSent;
