import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  skills: [],
};

const SkillsReducer = (state = INITIAL_STATE, action) => {
  let data = null;
  switch (action.type) {
    case actionType.GET_ALL_SKILLS:
      data = action.payload;
      return {
        ...state,
        skills: action.payload,
      };
    default:
      return { ...state };
  }
};
export default SkillsReducer;
