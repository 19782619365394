import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import Eye from "../../components/icons/Eye";
import IconMail from "../../components/icons/IconMail";
import HiddenEye from "../../components/icons/HiddenEye";
import NavbarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { AuthContext } from "../../context/AuthContext";
import { notification } from "antd";
import { gapi } from "gapi-script";
import { Helmet } from "react-helmet-async";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.facebookIcon = React.createRef();
    this.state = {
      loading: false,
      email: "",
      password: "",
      errormsg: "",
      showPassword: false,
      passwordType: "password",
    };
  }

  componentDidMount() {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId:
          "771665287344-3sb26ap7bb2rvjgg7pbsgee5dc47rgsq.apps.googleusercontent.com",
      });
    });
  }

  SignIn = async (e) => {
    this.setState({ loading: true });

    e.preventDefault();
    const { email, password } = this.state;

    if (email && password) {
      const { email, password } = this.state;
      const data = {
        email: email,
        pwd: password,
      };
      await this.props.SignIn(data);
      this.setState({ loading: false });
    } else {
      this.setState({ errormsg: "Tous les champs sont obligatoires" });
      this.setState({ loading: false });
    }
  };

  responseFacebook = (response) => {
    if (!response.email) {
      notification.error({
        message: "Erreur de création",
        description:
          "cette compte facebook n'est pas autorisé, essayez avec un autre compte!",
      });
      return;
    }
    const data = {
      email: response.email,
      idFacebook: response.id,
      name: response.name,
      surname: "",
      from: "signin",
    };
    this.props.LoginFacebook(data);
  };

  responseGoogle = (response) => {
    if (response.googleId) {
      const data = {
        email: response.profileObj.email,
        idGoogle: response.googleId,
        name: response.profileObj.name,
        surname: response.profileObj.familyName,
        from: "signin",
      };
      this.props.LoginGoogle(data);
    }
  };

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  showHidepwd() {
    this.setState({
      showPassword: !this.state.showPassword,
      passwordType:
        this.state.passwordType === "password" ? "text" : "password",
    });
  }
  render() {
    const { t } = this.context.t;

    const customStyle = {
      backgroundColor: "white",
      cursor: "pointer",
      width: "45px",
      height: "45px",
      borderRadius: "50px ",
      color: "red",
      fontSize: "40px",
    };

    const { email, password } = this.state;

    return (
      <>
        <Helmet>
          <title>Make my work | Connexion</title>
        </Helmet>
        <NavBarNotConnected />
        <NavbarMobile />

        <main id="main">
          <section
            className="d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-10 justify-content-center align-items-center">
                  <form
                    method="post"
                    id="contact-form"
                    className="contact-form"
                  >
                    <div className="row cnx-row gy-4 justify-content-center align-items-center">
                      <div className="img-conx col-12 d-flex justify-content-center align-items-center">
                        <img
                          className="image-signin"
                          src={require("../../assets/img/connx/connect.png")}
                          alt="..."
                        />
                      </div>
                      <div className="d-flex align-content-center  justify-content-center  mb-1 ">
                        {/* row social-cnx */}
                        {/* <div className="col-6 d-flex justify-content-end align-items-center px-5">
                          <FacebookLogin
                            buttonStyle={{
                              border: "none",
                              background: "#184EAF",
                              borderRadius: "50%",
                              position: "relative",
                              color: "white",
                              fontSize: "5rem",
                              textAlign: "center",
                              width: "57px",
                              height: "57px",
                              textAlign: "center",
                            }}
                            appId="1352722058743100" //APP ID NOT CREATED YET  1159357654886099
                            fields="name,email,picture"
                            callback={this.responseFacebook}
                            cssClass="my-facebook-button-class"
                            icon="fa fa-facebook"
                            textButton={false}
                          />
                        </div> */}
                        <div className="col-6 d-flex justify-content-center align-items-center px-5">
                          <GoogleLogin
                            clientId="771665287344-3sb26ap7bb2rvjgg7pbsgee5dc47rgsq.apps.googleusercontent.com"
                            buttonText={false}
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            render={(renderProps) => (
                              <div
                                onClick={renderProps.onClick}
                                style={customStyle}
                              >
                                <span className="ic-google">
                                  <strong>G</strong>
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </div>

                      {this.state.errormsg ? (
                        <p className="d-flex justify-content-center input-msg-error ">
                          {this.state.errormsg}
                        </p>
                      ) : null}

                      <div className="row input-cnx ">
                        <p className="text-futura-16 text-align-left">
                          {t("lableEmail")}
                        </p>
                        <div className="form-c-sign">
                          <input
                            className="fix-input"
                            type="email"
                            name="email"
                            id="user_email"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                          />
                          <i>
                            <IconMail />
                          </i>
                        </div>
                      </div>

                      <div className="row input-cnx">
                        <p className="text-futura-16 text-align-left">
                          {t("lablePassword")}
                        </p>
                        <div className="form-c-sign">
                          <input
                            className=" fix-input"
                            type={this.state.passwordType}
                            name="password"
                            id="user_pwd"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                          />
                          <i
                            className="pointer"
                            onClick={() => this.showHidepwd()}
                          >
                            {this.state.showPassword ? <Eye /> : <HiddenEye />}
                          </i>
                        </div>
                      </div>

                      <div className=" col col-lg-4 col-xl-4 col-md-6 col-sm-7 text-center mb-5 mt-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <Link
                            to="/renitialiser-mot-de-passe"
                            className="text-futura-14 text-underline"
                          >
                            {t("forgetPassword")}
                          </Link>
                          <button
                            type="submit"
                            disabled={this.state.loading}
                            //id="submit"
                            className={
                              email && password
                                ? "btn-mmk"
                                : "btn-mmk-grey-opacity"
                            }
                            onClick={(e) => this.SignIn(e)}
                          >
                            {this.state.loading ? (
                              <SpinLoading />
                            ) : (
                              t("buttonLogin")
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    SignIn: (data) => dispatch(actionCreator.SignIn(data)),
    LoginFacebook: (data) => dispatch(actionCreator.LoginFacebook(data)),
    LoginGoogle: (data) => dispatch(actionCreator.LoginGoogle(data)),
  };
};

SignIn.contextType = AuthContext;

export default connect(null, mapDispatchToProps)(SignIn);
