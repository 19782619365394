import React from 'react'
import {  Redirect, Route, Switch } from 'react-router-dom'


import ChoicePseudo from "./profileing/ChoosePseudo";
import TellUs from "./profileing/TellUs";
import VerifyProfile from "./profileing/VerifyProfile";

import VerifyProfileConfirmation from "./profileing/VerifyProfileConfirmation";

import HomeService from "./home/HomeService";
import HomeEducation from "./home/HomeEducation";

import TypeAnnouncement from "./announcement/TypeAnnouncement";
import CreateAnnouncement from "./announcement/CreateAnnouncement";
import AnnouncementCreated from "./announcement/AnnouncementCreated";
import MyProfil from "./profile/MyProfil";
import MyAnnouncements from "./profile/MyAnnouncements";

import AnnouncementDetails from "./announcement/AnnouncementDetails";
import Search from "./search/Search";
import AllDemandesSer from "./home/AllDemandesSer";
import AllCategoriesSer from "./home/AllCategoriesSer";
import AllDemandesEns from "./home/AllDemandesEns";
import AllCategories from "./home/AllCategoriesEns";
import InYourSkilsServ from "./home/InYourSkilsServ";
import InYourSkilssEns from "./home/InYourSkilssEns";
import Messenger from "./messenger/Messenger";

//import OfferPrice from "./candidacy/OfferPrice";

import MangeOrderService from "./candidacy/MangeOrderService";
import Dissertation from "./candidacy/Dissertation";
//import YourAnnounce from "./profileing/YourAnnounce";
//import UpdateAnnounce from "./profileing/UpdateAnnounce";
import OtherUserProfil from "./profile/OtherUserProfil";
import Activity from "./profile/Activity";
import MMKToken from "./profile/MMKToken";

import News from "./cms/News";
import NewsDetails from "./cms/NewsDetails";
import Contact from "./contact/Contact";
import ContactSent from "./contact/ContactSent";
import Privacy from "./terms/privacy";

import Terms from "./terms/Terms";
import MentionsLegales from './terms/mentionsLegales';
import MobileApp from '../components/install app/MobileApp';


const PrivateRoutes =()=> {
  return (

   <Switch>

            <Route exact path="/accueil">
              <HomeService />
            </Route>
            
            <Route exact path="/">
              <HomeService />
            </Route>

            <Route exact path="/accueil/enseignement">
              <HomeEducation />
            </Route>

            <Route exact path="/pseudo">
              <ChoicePseudo />
            </Route>

            <Route exact path="/profil-verifier">
              <VerifyProfileConfirmation />
            </Route>

            <Route exact path="/verifie-ton-profil">
              <VerifyProfile />
            </Route>

            <Route exact path="/dis-nous-en-plus">
              <TellUs />
            </Route>

            <Route exact path="/selectionner-un-type-dannonce">
              <TypeAnnouncement />
            </Route>

            <Route exact path="/creer-une-annonce">
              <CreateAnnouncement />
            </Route>

            <Route exact path="/annonce-publier">
              <AnnouncementCreated />
            </Route>

            <Route exact path="/messagerie">
              <Messenger />
            </Route>

            <Route exact path="/mes-annonces">
              <MyAnnouncements />
            </Route>

            <Route exact path="/profil">
              <MyProfil />
            </Route>

            <Route exact path="/annonce/:slug">
                <AnnouncementDetails />
            </Route>

            <Route exact path="/recherche">
              <Search />
            </Route>

            <Route exact path="/autres-demandes-service">
              <AllDemandesSer />
            </Route>

            <Route exact path="/categorie-service">
              <AllCategoriesSer />
            </Route>

            <Route exact path="/autres-demandes-enseignement">
              <AllDemandesEns />
            </Route>
            <Route exact path="/categorie-enseignement">
              <AllCategories />
            </Route>

            <Route exact path="/dans-tes-competences-service">
              <InYourSkilsServ />
            </Route>

            <Route exact path="/dans-tes-competences-enseignement">
              <InYourSkilssEns />
            </Route>

            <Route exact path="/gerer">
              <MangeOrderService />
            </Route>

            <Route exact path="/gerer-vos-services">
              <Dissertation />
            </Route>
{/* 
            <Route exact path="/ton-annonce">
              <YourAnnounce />
            </Route> */}
{/* 
            <Route exact path="/modifier-ton-announce">
              <UpdateAnnounce />
            </Route> */}

            <Route exact path={`/profil/:id`}>
              <OtherUserProfil />
            </Route>

            <Route exact path="/mon-activite">
              <Activity />
            </Route>

            <Route exact path="/token-mmw">
              <MMKToken />
            </Route>

            <Route exact path="/actualites">
                <News />
            </Route>

            <Route exact path="/actualites/:categorie/:title">
              <NewsDetails />
            </Route>

            <Route exact path="/contact">
              <Contact />
            </Route>

            <Route exact path="/message-envoye">
              <ContactSent />
            </Route>

            <Route exact path="/condition-generale-utilisation">
              <Terms />
            </Route>

            <Route exact path="/politique-de-confidentialite">
              <Privacy />
            </Route>

            <Route exact path="/mentions-legales">
              <MentionsLegales />
            </Route>

            <Route exact path="/application-mobile">
              <MobileApp />
            </Route>

            <Redirect from="/" to="/accueil" />
            
          </Switch>

  )
}

export default PrivateRoutes