import axios from "axios";
const Url = {
  Url1: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/userServices/",
  }),

  Url2: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/announcementServices/",
  }),

  Url3: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/messagingServices/",
  }),

  Url4: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/candidature/",
  }),

  Url5: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/litigeServices/",
  }),

  Url6: axios.create({
    baseURL: "https://apiv1.makemywork.fr/api/kpiStatistiqueServices/",
  }),

  serverUrl: "https://apiv1.makemywork.fr",
};

export default Url;

//http://18.222.172.138:8088

//145.239.197.226

//https://apiv1.makemywork.fr
