import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  email: "",
  expiredIn: "",
  id: null,
  name: "",
  pseudo: "",
  surname: "",
  token: "",
  msg: "",
  isAuthenticated: false,

  hasAvis: false,
  avis: null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  let data = null;
  switch (action.type) {
    case actionType.NATIV_SIGNUP:
      data = action.payload;
      return {
        ...state,
      };
    case actionType.VERIFY_USER_EMAIL:
      data = action.payload;
      return {
        ...state,
        email: data.email,
        isAuthenticated: true,
        expiredIn: data.expiredIn,
        id: data.id,
        name: data.name,
        pseudo: data.pseudo,
        surname: data.surname,
        token: data.token,
      };
    case actionType.LOGIN_FACEBOOK:
      data = action.payload;
      return {
        ...state,
        email: data.email,
        expiredIn: data.expiredIn,
        id: data.id,
        isAuthenticated: true,
        name: data.name,
        pseudo: data.pseudo,
        surname: data.surname,
        token: data.token,
      };
    case actionType.LOGIN_GOOGLE:
      data = action.payload;
      return {
        ...state,
        email: data.email,
        isAuthenticated: true,
        expiredIn: data.expiredIn,
        id: data.id,
        name: data.name,
        pseudo: data.pseudo,
        surname: data.surname,
        token: data.token,
      };
    case actionType.NATIV_SIGNIN:
      data = action.payload;
      return {
        ...state,
        email: data.email,
        expiredIn: data.expiredIn,
        isAuthenticated: true,
        id: data.id,
        name: data.name,
        pseudo: data.pseudo,
        surname: data.surname,
        token: data.token,
      };

    case actionType.MAIL_IN_USED:
      data = action.payload;
      return {
        ...state,
        msg: data.error,
      };

    case actionType.HAS_AVIS:
      return {
        ...state,
        hasAvis: true,
        avis: action.payload,
      };

    default:
      return { ...state };
  }
};
export default AuthReducer;
