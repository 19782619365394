import React, { useEffect, useContext, useState, useRef } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import { AuthContext } from "../../context/AuthContext";
import * as actionCreator from "../../redux/actions";
import { Modal, notification } from "antd";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { destroyToken } from "../../routes/shared";
import { useLocation } from "react-router-dom";
import FullStar from "../../components/icons/FullStar";
import User from "../../components/icons/User";
import Phone from "../../components/icons/Phone";
import HiddenEye from "../../components/icons/HiddenEye";
import VerifedProfile from "../../components/icons/VerifedProfile";
import CreditCard from "../../components/icons/CreditCard";
import EmptyStar from "../../components/icons/EmptyStar";
import EditeIcon from "../../components/icons/EditeIcon";
import SpinLoading from "../../components/SpinLoading";
import Url from "../../constants/Url";
import { useTranslation } from "react-i18next";
import Pusher from "pusher-js";
import { Helmet } from "react-helmet-async";
import AnnounceCard from "../../components/cards/AnnounceCard";
import PhoneInput from "react-phone-number-input";

export default function MyProfil({ history2 }) {
  const location = useLocation();
  const myRef = useRef(null);
  const history = useHistory();

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [openModalSetting, setOpenModalSetting] = useState(false);
  const [openModalShowMoreCompetence, setOpenModalShowMoreCompetence] =
    useState(false);
  const [openModalForAvis, setOpenModalForAvis] = useState(false);
  const [userDetailModal, setUserDetailModal] = useState(null);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [userDetail, setUserDetail] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [loadingUpdatePassword, setLoadingUpdatePassword] = useState(false);

  const [skills, setSkills] = useState(null);

  const [notifMessage, setNotifMessage] = useState(false);
  const [notifCompetence, setNotifCompetence] = useState(false);
  const [file, setFile] = useState(null);

  const [iban, setIban] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [bic, setBic] = useState(null);
  const [loadingBankAccount, setLoadingBankAccount] = useState(false);
  const [loadingWalletAddress, setLoadingWalletAddress] = useState(false);
  const [loadingNotification, setLoadingNotification] = useState(false);

  const { user } = useContext(AuthContext);

  const announcementInProgress = useSelector(
    (state) => state.AnnouncementReducer.announcementInProgress
  );
  const detail = useSelector((state) => state.ProfileReducer.userDetail);
  const userAvis = useSelector((state) => state.ProfileReducer.userAvis);
  const avgStarRating = useSelector(
    (state) => state.ProfileReducer.avgStarRating
  );
  const serviceNumber = useSelector(
    (state) => state.ProfileReducer.serviceNumber
  );
  const error = useSelector((state) => state.ProfileReducer.error);
  const successUpdate = useSelector(
    (state) => state.ProfileReducer.successUpdate
  );
  const pictureUpdated = useSelector(
    (state) => state.ProfileReducer.pictureUpdated
  );

  useEffect(() => {
    if (location.search || pictureUpdated === true) {
      if (user) {
        const data = {
          userId: user.id,
          me: true,
        };
        dispatch(actionCreator.getUserDetail(data));
      }
    }
  }, [pictureUpdated]);

  useEffect(() => {
    if (userDetail) {
      if (location.state) {
        if (location.state === "needBankAccount") {
          setUserDetailModal(userDetail);
          setOpenModalSetting(true);
        }
      }
      if (userDetail.notifAllow) {
        setNotifMessage(true);
      }
      if (userDetail.notifAllowAnn) {
        setNotifCompetence(true);
      }
    }
  }, [location.state, userDetail]);

  const handleCheckChange = (e) => {
    if (e.target.name === "message") {
      setNotifMessage(e.target.checked);
    } else {
      setNotifCompetence(e.target.checked);
    }
  };

  useEffect(() => {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var age = currentYear - 16;

    setMaxDate(age + "-12-31");
  }, []);

  useEffect(() => {
    if (user && !detail) {
      const data = {
        userId: user.id,
        me: true,
      };
      dispatch(actionCreator.getAllAnnouncementEnCoursForUser(data));
      dispatch(actionCreator.getUserDetail(data));
      dispatch(actionCreator.getAvisUser(data));
      dispatch(actionCreator.getServiceNumber(data));
    }
    if (detail) {
      let search = "";
      setUserDetail(detail);
      setIban(detail.IBAN);
      setBic(detail.BIC);
      setWalletAddress(detail.walletAddress);
      detail.skill &&
        detail.skill.map((skill) => {
          search = search + "," + skill.skill.id;
          setSkills(search);
        });
    }
  }, [user, detail, error]);

  const logOut = () => {
    const pusher = new Pusher("d69d1373d3c67363441e", {
      cluster: "eu",
    });
    pusher.unsubscribe(user.id.toString());
    pusher.disconnect();

    destroyToken();
  };

  async function updateProfile(e) {
    e.preventDefault();
    setLoadingUpdateProfile(true);
    const data = {
      userId: user.id,
      name: userDetailModal.name,
      surname: userDetailModal.surname,
      phoneNumber: userDetailModal.phoneNumber,
      bio: userDetailModal.bio,
      birthdate: userDetailModal.birthdate,
    };
    await dispatch(actionCreator.updateProfile(data));
    setLoadingUpdateProfile(false);
    const userData = {
      userId: user.id,
      me: true,
    };
    await dispatch(actionCreator.getUserDetail(userData));
    setOpenModalSetting(false);
  }

  async function updatePassword(e) {
    e.preventDefault();
    setLoadingUpdatePassword(true);
    const data = {
      userId: user.id,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    await dispatch(actionCreator.updatePassword(data));
    setLoadingUpdatePassword(false);
    setNewPassword("");
    setOldPassword("");
  }

  function changeProfilePicture(result) {
    const data = {
      userId: user.id,
      profilePicture: result,
    };
    dispatch(actionCreator.addProfilePicture(data));
  }

  const createBankAccountByMangoPay = async () => {
    if (iban && bic) {
      setLoadingBankAccount(true);
      const data = {
        userId: user.id,
        IBAN: iban,
        BIC: bic,
      };
      await dispatch(actionCreator.createBankAccountByMangoPay(data));
      setLoadingBankAccount(false);
    }
  };

  const updateWalletAddres = async (e) => {
    e.preventDefault();
    setLoadingWalletAddress(true);

    await Url.Url1.post("updateWalletAddress", {
      userId: user.id,
      walletAddress: walletAddress,
    }).then((res) => {
      notification.success({
        message: "Porte monnaie",
        description: "Adresse de portefeuille ajoutée",
      });
      setLoadingWalletAddress(false);
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];

    if (file.type.includes("image/")) {
      getBase64(e.target.files[0])
        .then(async (result) => {
          changeProfilePicture(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      notification.error({
        message: "Type d'image",
        description: "Merci de verifier le type d'image SVP!",
      });
    }
  };

  async function getBase64(file) {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  }

  const clickButton = (e) => {
    myRef.current.click();
  };

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  const allowNotification = async () => {
    setLoadingNotification(true);
    const data = {
      userId: user.id,
      notifAllow: notifMessage,
      notifAllowAnn: notifCompetence,
    };

    await dispatch(actionCreator.updateAllowNotif(data));
    setLoadingNotification(false);
  };

  const goToDissertation = (announce) => {
    history.push({
      pathname: "/gerer-vos-services",
      state: { id: announce.candidatureId },
    });
  };

  return (
    <>
      <Helmet>
        <title>Make my work | Profil</title>
      </Helmet>
      <NavBarConnected />
      <NavbarMobile />

      <main id="main">
        <section
          id="sec6"
          className="sec6 d-flex align-items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="container-mmk w-100">
            <div className=" d-flex justify-content-center align-items-center">
              <NavLink
                to="/profil"
                activeClassName="m-3 selected-nav"
                className="m-3 not-selected-nav"
              >
                {t("navMyProfile")}
              </NavLink>

              <NavLink
                to="/mon-activite"
                activeClassName="m-3 selected-nav"
                className="m-3 not-selected-nav"
              >
                {t("navMyActivity")}
              </NavLink>

              <NavLink
                to="/mes-annonces"
                activeClassName="m-3 selected-nav"
                className="m-3 not-selected-nav"
              >
                {t("navMyAnnouncements")}
              </NavLink>

              <NavLink
                to="/token-mmw"
                activeClassName="m-3 selected-nav"
                className="m-3 not-selected-nav"
              >
                {t("navToken")}
              </NavLink>
            </div>

            {userDetail && announcementInProgress ? (
              <>
                <div
                  className="first-part-profil"
                  style={{ marginTop: "5rem" }}
                >
                  <div
                    className="d-flex jusify-content-between align-items-center bio-down"
                    style={{ paddingRight: "20px" }}
                  >
                    <input
                      ref={myRef}
                      type="file"
                      onChange={(e) => handleFileInputChange(e)}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                    <div className="r-pro">
                      <div className="upload-img" onClick={() => clickButton()}>
                        <EditeIcon />
                      </div>
                      {userDetail.profilePicture !== null ? (
                        <img
                          className="rounded-circle image-profile"
                          src={userDetail.profilePicture}
                        />
                      ) : (
                        <img
                          className="rounded-circle image-profile"
                          src={require("../../img/avatar.png")}
                        />
                      )}
                    </div>
                    <div style={{ paddingLeft: "3rem", width: "100%" }}>
                      <div className="d-flex justify-content-between align-items-center container-max-width fix-bio">
                        <span>
                          <div className="d-flex gap-2">
                            <p className="text-futura-20 m-0 text-align-left">
                              {userDetail.name}
                            </p>
                            {userDetail.isCertified === "2" ? (
                              <VerifedProfile />
                            ) : null}
                          </div>

                          <p className="text-futura-20 text-align-left">
                            {userDetail.surname}
                          </p>
                        </span>

                        <span>
                          <button
                            className="btn-mmk"
                            onClick={() => {
                              setOpenModalSetting(true);
                              setUserDetailModal(userDetail);
                            }}
                          >
                            {t("Settings")}
                          </button>
                        </span>
                      </div>
                      <div className="d-flex flex-column w-100 container-max-width">
                        <p
                          className="text-futura-16 text-align-left"
                          style={{ paddingTop: "2rem" }}
                        >
                          {userDetail.bio
                            ? userDetail.bio
                            : "Bonjour, c'est ici que tu peux parler de toi, de tes expertises et de ce que tu aimes ..."}
                        </p>
                        <div
                          className="text-futura-16 text-align-right text-underline pointer"
                          onClick={() => logOut()}
                        >
                          {t("logout")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" services-profil d-flex flex-column align-items-end ">
                    <div className="d-flex">
                      <p className="text-futura-14 px-2">
                        {avgStarRating
                          ? parseFloat(avgStarRating).toFixed(2)
                          : null}
                      </p>
                      <FullStar width={22} height={21} />

                      <div className="avis px-3">
                        <div
                          className="text-futura-16 text-underline pointer"
                          onClick={() => setOpenModalForAvis(true)}
                        >
                          {t("seeReviews")}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap gap-2">
                      <div className="service bg-gray mr-3">
                        <p className="text-futura-16">{t("ServiceRendered")}</p>
                        <p className="text-futura-16 mt-3">
                          {serviceNumber ? serviceNumber.serviceRendu : null}
                        </p>
                      </div>
                      <div className="service bg-pink mr-3">
                        <p className="text-futura-16">{t("ServiceRequests")}</p>
                        <p className="text-futura-16 mt-3">
                          {serviceNumber ? serviceNumber.demandeService : null}
                        </p>
                      </div>
                      <div className="service bg-purple ">
                        <p className="text-futura-16">{t("ReplyDuration")}</p>
                        <p className="text-futura-16 mt-3">1h</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "4rem" }}>
                  <p className="text-futura-20 text-align-left">
                    {t("Skills")}
                  </p>
                  <div className="d-flex  d-flex-responsive justify-content-between mt-3">
                    <div className="skills-profil" style={{ gridGap: "3rem" }}>
                      {userDetail.skill.length > 0 ? (
                        userDetail.skill.map((skills, key) =>
                          key < 2 ? (
                            <div
                              key={key}
                              className="skill-profil "
                              style={{ marginRight: "2rem" }}
                            >
                              <div className="skill-img">
                                <img src={skills.skill.picture} />
                              </div>
                              <div className="p-skill">
                                <p className="text-futura-16">
                                  {skills.skill.categoryName}
                                </p>
                                <p className="text-futura-16">
                                  {"  -  " + skills.announcementNumber}{" "}
                                  {t("Service")}
                                </p>
                              </div>
                            </div>
                          ) : null
                        )
                      ) : (
                        <span className="no-items">{t("noItems")}</span>
                      )}
                      {userDetail && userDetail.skill.length > 2 ? (
                        <a
                          className="text-futura-16 text-underline text-align-right pointer mt-4"
                          onClick={() => setOpenModalShowMoreCompetence(true)}
                        >
                          {t("linkSeeMore")}
                        </a>
                      ) : null}
                    </div>

                    <div className="avis text-underline">
                      <a
                        href={`/dis-nous-en-plus?update=true&userskills=${skills}`}
                        className="text-futura-16"
                      >
                        {t("modifyAddSkills")}
                      </a>
                    </div>
                  </div>
                </div>

                {userDetail.isCertified === "3" ||
                userDetail.isCertified === "0" ? (
                  <div className="certifier mt-5 mb-0">
                    <p className="text-futura-16">
                      {t("youCanCertifyYourProfile")}
                    </p>

                    <Link
                      to="/verifie-ton-profil"
                      className="text-futura-16 text-underline pointer"
                    >
                      {t("certifyMyProfile")}
                    </Link>
                  </div>
                ) : null}

                <div
                  className="d-flex justify-content-right align-items-center mb-5"
                  style={{ marginTop: "4rem" }}
                >
                  <p className="text-futura-20">{t("YourCurrentOffers")}</p>
                </div>

                <div className="cards ">
                  {announcementInProgress.length > 0 &&
                  announcementInProgress.find(
                    (an) => an.announcementType === "offre"
                  ) ? (
                    announcementInProgress.map((announce, key) =>
                      announce.announcementType === "offre" ? (
                        <AnnounceCard
                          props={{ announce, key }}
                          onClick={() => goToDissertation(announce)}
                        />
                      ) : null
                    )
                  ) : (
                    <span className="no-items">{t("noItems")}</span>
                  )}
                </div>

                <div
                  className="d-flex justify-content-right align-items-center mb-5"
                  style={{ marginTop: "4rem" }}
                >
                  <p className="text-futura-20">{t("yourCurrentRequests")}</p>
                </div>
                <div className="cards">
                  {announcementInProgress.length > 0 &&
                  announcementInProgress.find(
                    (an) => an.announcementType === "demande"
                  ) ? (
                    announcementInProgress.map((announce, key) =>
                      announce.announcementType === "demande" ? (
                        <AnnounceCard
                          props={{ announce, key }}
                          onClick={() => goToDissertation(announce)}
                        />
                      ) : null
                    )
                  ) : (
                    <span className="no-items">{t("noItems")}</span>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </section>
      </main>

      <Modal
        open={openModalSetting}
        onCancel={() => setOpenModalSetting(false)}
        footer={false}
        width={"63rem"}
      >
        {userDetailModal ? (
          <form id="contact-form" className="contact-form">
            <div className="row gy-4 justify-content-center align-items-star">
              <div className="d-flex align-items-center justify-content-center">
                <p className="text-futura-30">{t("profileSettings")}</p>
              </div>

              <div
                className="d-flex flex-column gap-3 blue-scroll height-modal-profile w-100"
                style={{ paddingLeft: "2rem" }}
              >
                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("lableFirstName")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="text"
                      className="fix-input"
                      name="name"
                      onChange={(e) =>
                        setUserDetailModal({
                          ...userDetailModal,
                          name: e.target.value,
                        })
                      }
                      value={userDetailModal.name}
                    />
                    <i>
                      <User />
                    </i>
                  </div>
                </div>

                <div className="col-12 px-3 ">
                  <p className="text-futura-16 text-align-left">
                    {t("lableLastName")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="text"
                      className="fix-input"
                      name="surname"
                      onChange={(e) =>
                        setUserDetailModal({
                          ...userDetailModal,
                          surname: e.target.value,
                        })
                      }
                      value={userDetailModal.surname}
                    />
                    <i>
                      <User />
                    </i>
                  </div>
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("lablePhoneNumber")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    {/* <input
                      type="text"
                      className="fix-input"
                      name="phoneNumber"
                      onChange={(e) =>
                        setUserDetailModal({
                          ...userDetailModal,
                          phoneNumber: e.target.value,
                        })
                      }
                      value={userDetailModal.phoneNumber}
                    /> */}
                    <PhoneInput
                      defaultCountry="FR"
                      value={userDetailModal.phoneNumber}
                      onChange={(e) =>
                        e &&
                        setUserDetailModal({
                          ...userDetailModal,
                          phoneNumber: e,
                        })
                      }
                      className="w-100 fix-input m-0"
                      name="phoneNumber"
                      id="user_Tel"
                    />
                    <i>
                      <Phone width={24} height={24} />
                    </i>
                  </div>
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("lableBio")}
                  </p>

                  <textarea
                    type="text"
                    className="input-textArea input-max-width"
                    name="bio"
                    onChange={(e) =>
                      setUserDetailModal({
                        ...userDetailModal,
                        bio: e.target.value,
                      })
                    }
                    value={userDetailModal.bio}
                  />
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("lableBirthDay")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="date"
                      max={maxDate}
                      className="fix-input"
                      name="birthdate"
                      onChange={(e) =>
                        setUserDetailModal({
                          ...userDetailModal,
                          birthdate: e.target.value,
                        })
                      }
                      value={userDetailModal.birthdate}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3 px-3">
                  <button
                    onClick={(e) => updateProfile(e)}
                    className="btn-mmk"
                    disabled={loadingUpdateProfile}
                  >
                    {loadingUpdateProfile ? <SpinLoading /> : t("btnEDIT")}
                  </button>
                </div>

                <div className=" mt-5 px-3">
                  <p className="text-futura-20">{t("changePassword")}</p>
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("oldPassword")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="password"
                      className="fix-input"
                      name="oldPassword"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <i>
                      <HiddenEye />
                    </i>
                  </div>
                  {error ? (
                    <span style={{ color: "red" }}>
                      {t("errChangePassword")} !
                    </span>
                  ) : null}
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("newPassword")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="password"
                      className="fix-input"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <i>
                      <HiddenEye />
                    </i>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center  mt-5 px-3">
                  <button
                    onClick={(e) => updatePassword(e)}
                    className={
                      oldPassword && newPassword
                        ? "btn-mmk"
                        : "btn-mmk-grey-opacity"
                    }
                    disabled={loadingUpdatePassword}
                  >
                    {loadingUpdatePassword ? <SpinLoading /> : t("btnSave")}
                  </button>
                </div>

                <div className=" mt-5 px-3">
                  <p className="text-futura-20">{t("bankDetails")}</p>
                </div>
                {userDetail.isCertified !== "0" ? null : (
                  <div className="d-flex px-3 ">
                    <span>{t("verifyYourProfile")}</span>
                  </div>
                )}

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">{t("IBAN")}</p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="text"
                      className="fix-input"
                      value={iban}
                      onChange={(e) => setIban(e.target.value)}
                      disabled={userDetail.isCertified !== "0" ? false : true}
                    />
                    <i>
                      <CreditCard width={24} height={24} />
                    </i>
                  </div>
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">{t("BIC")}</p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="text"
                      className="fix-input"
                      value={bic}
                      onChange={(e) => setBic(e.target.value)}
                      disabled={userDetail.isCertified !== "0" ? false : true}
                    />
                    <i>
                      <CreditCard width={24} height={24} />
                    </i>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-5 px-3">
                  <button
                    onClick={(e) => createBankAccountByMangoPay(e)}
                    className={iban && bic ? "btn-mmk" : "btn-mmk-grey-opacity"}
                    disabled={loadingBankAccount}
                  >
                    {loadingBankAccount ? <SpinLoading /> : t("btnSave")}
                  </button>
                </div>

                <div className=" mt-5 px-3">
                  <p className="text-futura-20">{t("wallet")}</p>
                </div>

                <div className="col-12 px-3">
                  <p className="text-futura-16 text-align-left">
                    {t("address")}
                  </p>
                  <div className="form-c-sign input-max-width">
                    <input
                      type="text"
                      className="fix-input"
                      value={walletAddress}
                      onChange={(e) => setWalletAddress(e.target.value)}
                    />
                    <i>
                      <CreditCard width={24} height={24} />
                    </i>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-5 px-3">
                  <button
                    onClick={(e) => updateWalletAddres(e)}
                    className={
                      walletAddress ? "btn-mmk" : "btn-mmk-grey-opacity"
                    }
                    disabled={loadingBankAccount}
                  >
                    {loadingWalletAddress ? <SpinLoading /> : t("btnSave")}
                  </button>
                </div>

                <div className="mt-5 px-3">
                  <p className="text-futura-20">{t("notifications")}</p>
                </div>

                <div className="d-flex justify-content-start w-100 px-3">
                  <div className="col-2"></div>
                  <div className="col-8 m-0 p-0">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        style={{ marginRight: "15px", height: "23px" }}
                        className="checkbox-mmk"
                        name="message"
                        onChange={(e) => handleCheckChange(e)}
                        checked={notifMessage}
                      />
                      <p className="text-futura-16">{t("everyMessage")}</p>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>

                <div className="d-flex justify-content-start w-100 px-3">
                  <div className="col-2"></div>
                  <div className="col-8 m-0 p-0">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        style={{ marginRight: "15px", height: "23px" }}
                        className="checkbox-mmk"
                        name="competence"
                        onChange={(e) => handleCheckChange(e)}
                        checked={notifCompetence}
                      />
                      <p className="text-futura-16">
                        {t("newAnnouncementInMySkills")}
                      </p>
                    </div>
                  </div>
                  <div className="col-2"></div>
                </div>

                <div className="d-flex align-items-center justify-content-center  mt-3 px-3">
                  <button
                    onClick={() => allowNotification()}
                    className="btn-mmk"
                    disabled={loadingNotification}
                  >
                    {loadingNotification ? <SpinLoading /> : t("btnEDIT")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}
      </Modal>

      <Modal
        open={openModalShowMoreCompetence}
        width={"50rem"}
        onCancel={() => setOpenModalShowMoreCompetence(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("Skills")}</p>

          <div className="content">
            <div className="skills-profil" style={{ gridGap: "3rem" }}>
              {userDetail && userDetail.skill.length > 0 ? (
                userDetail.skill.map((skills, key) => (
                  <div
                    key={key}
                    className="skill-profil "
                    style={{ marginRight: "2rem" }}
                  >
                    <div className="skill-img">
                      <img src={skills.skill.picture} />
                    </div>
                    <div className="p-skill">
                      <p className="text-futura-16">
                        {skills.skill.categoryName}
                      </p>
                      <p className="text-futura-16">
                        {"  -  " + skills.announcementNumber} {t("Service")}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <span className="no-items">{t("noItems")}</span>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalForAvis}
        width={"62rem"}
        onCancel={() => setOpenModalForAvis(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("Notice")}</p>

          <div className="content w-100">
            <div className="blue-scroll d-flex flex-column align-items-center height-modal-avis">
              {userAvis && userAvis.length > 0 ? (
                userAvis.map((announce, key) => (
                  <div className="card-avis mb-4" key={key}>
                    <div className="d-flex w-100">
                      {announce.user?.profilePicture ? (
                        <img
                          src={announce.user.profilePicture}
                          className="img-card-avis"
                        />
                      ) : null}

                      <div className="d-flex flex-column justify-content-start align-items-start px-3 width-78">
                        <p className="text-futura-11-grey">
                          {announce.user?.surname}
                        </p>
                        <div className="d-flex justify-content-end algin-items-center w-100">
                          {generateTempArray(announce.rating).map((item) => (
                            <FullStar width={22} height={21} />
                          ))}
                          {generateTempArray(5 - announce.rating).map(
                            (item) => (
                              <EmptyStar width={20} height={20} />
                            )
                          )}
                        </div>
                        <p className="text-futura-14 overflow-hidden ellipsis-text-card-avis">
                          {announce.commentaire}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="no-items">{t("noItems")}</span>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
