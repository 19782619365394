
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import AnnouncementDetails from './announcement/AnnouncementDetails';
import Confirmation from './authentification/Confirmation';
import MailSent from './authentification/MailSent';
import NewPassword from './authentification/NewPassword';
import ResetPwd from './authentification/ResetPwd';
import SignIn from './authentification/SignIn';
import SignUp from './authentification/SignUp';
import News from './cms/News';
import NewsDetails from './cms/NewsDetails';
import Contact from './contact/Contact';
import ContactSent from './contact/ContactSent';
import AllCategories from './home/AllCategoriesEns';
import AllCategoriesSer from './home/AllCategoriesSer';
import AllDemandesEns from './home/AllDemandesEns';
import AllDemandesSer from './home/AllDemandesSer';
import HomeNoLoggedEnsg from './home/HomeNoLoggedEnsg';
import HomeNoLoggedSer from './home/HomeNoLoggedSer';
import InYourSkilssEns from './home/InYourSkilssEns';
import InYourSkilsServ from './home/InYourSkilsServ';
import Privacy from './terms/privacy';
import Terms from './terms/Terms';
import MentionsLegales from './terms/mentionsLegales';
import Search from './search/Search';


import MobileApp from '../components/install app/MobileApp';

const PublicRoutes =()=> {
  return (
    <Switch>
      <Route exact path="/accueil-non-connecte">
        <HomeNoLoggedSer />
      </Route>
      <Route exact path="/">
        <HomeNoLoggedSer />
      </Route>
      
      <Route exact path="/condition-generale-utilisation">
        <Terms />
      </Route>

      <Route exact path="/politique-de-confidentialite">
        <Privacy />
      </Route>

      <Route exact path="/mentions-legales">
        <MentionsLegales />
      </Route>

      <Route exact path="/accueil-non-connecte/enseignement">
        <HomeNoLoggedEnsg />
      </Route>

      <Route exact path="/connexion">
        <SignIn />
      </Route>
      <Route exact path="/inscription">
        <SignUp />
      </Route>

      <Route exact path="/verification-par-email">
        <MailSent />
      </Route>

      <Route exact path="/email-confirmer">
        <Confirmation />
      </Route>

      <Route exact path="/mot-de-passe-renitialise">
        <NewPassword />
      </Route>

      <Route exact path="/renitialiser-mot-de-passe">
        <ResetPwd />
      </Route>

      <Route exact path="/annonce/:slug">
          <AnnouncementDetails />
      </Route>

      <Route exact path="/recherche">
        <Search />
      </Route>

      <Route exact path="/autres-demandes-service">
        <AllDemandesSer />
      </Route>
      <Route exact path="/categorie-service">
        <AllCategoriesSer />
      </Route>

      <Route exact path="/autres-demandes-enseignement">
        <AllDemandesEns />
      </Route>
      <Route exact path="/categorie-enseignement">
        <AllCategories />
      </Route>

      <Route exact path="/dans-tes-competences-service">
        <InYourSkilsServ />
      </Route>

      <Route exact path="/dans-tes-competences-enseignement">
        <InYourSkilssEns />
      </Route>

      <Route exact path="/actualites">
          <News />
      </Route>

      <Route exact path="/actualites/:categorie/:title">
        <NewsDetails />
      </Route>

      <Route exact path="/contact">
        <Contact />
      </Route>

      <Route exact path="/message-envoye">
        <ContactSent />
      </Route>

      <Route exact path="/application-mobile">
        <MobileApp />
      </Route>

      <Redirect from="/accueil" to="/accueil-non-connecte" />
      <Redirect from="/" to="/accueil-non-connecte" />
    </Switch>
  )
}

export default PublicRoutes