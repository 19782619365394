import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  userDetail: null,
  userData: null,
  successUpdate: false,
  successUpdatePwd: false,
  error: false,
  pictureUpdated: false,
  userAvis: null,
  otherProfileUser: null,
  otherUserAvis: null,
  avgStarRating: null,
  otherUserAvgStarRating: null,
  serviceNumber: null,
  serviceNumberForOtherUser: null,
  userActivity: null,
};

const ProfileReducer = (state = INITIAL_STATE, action) => {
  let data = null;
  switch (action.type) {
    case actionType.GET_USER_DETAIL:
      data = action.payload;
      return {
        ...state,
        userDetail: data[0],
      };
    case actionType.GET_USER_DATA:
      data = action.payload;
      return {
        ...state,
        userData: data[0],
      };
    case actionType.UPDATE_PROFILE:
      data = action.payload;

      return {
        ...state,
        successUpdate: data,
      };
    case actionType.UPDATE_PASSWORD:
      data = action.payload.updated;

      return {
        ...state,
        successUpdatePwd: data,
        error: action.payload.message ? true : false,
      };
    case actionType.ADD_PROFILE_PICTURE:
      data = action.payload.pictureUpdated;
      return {
        ...state,
        pictureUpdated: data,
      };

    case actionType.GET_USER_AVIS:
      data = action.payload;
      return {
        ...state,
        userAvis: data.avis,
        avgStarRating: data.avgStarRating,
      };

    case actionType.GET_OTHER_USER_AVIS:
      data = action.payload;
      return {
        ...state,
        otherUserAvis: data.avis,
        otherUserAvgStarRating: data.avgStarRating,
      };

    case actionType.GET_OTHER_USER_DETAIL:
      data = action.payload;
      return {
        ...state,
        otherProfileUser: data[0],
      };
    case actionType.GET_SERVICE_NUMBER:
      data = action.payload;
      return {
        ...state,
        serviceNumber: data,
      };

    case actionType.GET_SERVICE_NUMBER_FOR_OTHER_USER:
      data = action.payload;
      return {
        ...state,
        serviceNumberForOtherUser: data,
      };

    case actionType.GET_USER_ACTIVITY:
      data = action.payload;
      return {
        ...state,
        userActivity: data,
      };

    default:
      return { ...state };
  }
};
export default ProfileReducer;
