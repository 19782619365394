import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import * as actionCreator from "../redux/actions";
import ArrowDown from "./icons/FlechDown";
import ArrowToLeft from "./icons/ArrowToLeft";
import { Dropdown, Menu } from "antd";
import { AuthContext } from "../context/AuthContext";

class NavBarNoLoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      path: props.location.pathname,
      search: props.inputSearch,
      navbarmobile: false,
      categoryType:
        props.location.pathname === "/accueil-non-connecte" ||
        props.reducerCategoryType == "service"
          ? "service"
          : "enseignement",
    };
  }

  handleSearch(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: `/recherche`,
      search: `?value=${this.state.search}`,
      state: true,
    });
  }

  handleInputChange(e) {
    this.setState({ search: e.target.value });
    this.props.handleInputSearch(e.target.value);
  }

  toggleNavbar() {
    this.setState({ navbarmobile: !this.state.navbarmobile });
  }

  handleCategoryTypeChange(type) {
    this.props.handleCategoryType(type);
    this.setState({ categoryType: type });

    if (
      type == "enseignement" &&
      this.state.path != "/categorie-service" &&
      this.state.path != "/categorie-enseignement"
    ) {
      this.props.history.push("/accueil-non-connecte/enseignement");
    } else if (
      type == "service" &&
      this.state.path != "/categorie-service" &&
      this.state.path != "/categorie-enseignement"
    ) {
      this.props.history.push("/accueil-non-connecte");
    }
  }

  handleChangeLanguage(lang) {
    const i18n = this.context.i18n;
    i18n.changeLanguage(lang);
  }

  render() {
    const { path, navbarmobile } = this.state;
    const i18n = this.context.i18n;
    const { t } = this.context.t;

    return (
      <React.Fragment>
        <header id="header" className="fixed-top hide-navbar">
          <nav id="navbar" className="navbar">
            <div
              className="container-fluid d-flex align-items-center"
              style={{
                backgroundImage: ` url(${require("../img/bannerx4.png")})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "100%",
                height: "10.7rem",
              }}
            >
              <div className="col-5"></div>
              <div
                className="col-2 d-flex flex-column align-items-center justify-content-center"
                style={{ padding: "15px 0" }}
              >
                <Link to="/accueil">
                  <a href="#">
                    <img
                      src={`${require("../img/MMW-Logo-4.png")}`}
                      className="img-fluid icon-logo-mmk"
                      alt="..."
                    />
                  </a>
                </Link>
              </div>

              <div className="col-5"></div>
            </div>
            {path === "/connexion" ? (
              <div className="w-100 container-mmk">
                <div className="row">
                  <div className="d-flex justify-content-between align-items-center">
                    <li className="w-100"></li>
                    <li className="w-100">
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <span id="t2">{t("connectYourself")}</span>
                      </div>
                    </li>

                    <div className="w-100 d-flex justify-content-end ">
                      <ul>
                        <li>
                          <Link to="/inscription">
                            <a className="getstarted-inscription scrollto">
                              {t("buttonRegister")}
                            </a>
                          </Link>
                        </li>

                        <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : path == "/inscription" ? (
              <div className="w-100 container-mmk">
                <div className="row">
                  <div className="d-flex justify-content-between align-items-center">
                    <li className="w-100"></li>
                    <li className="w-100">
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <span id="t2">{t("registerYourself")}</span>
                      </div>
                    </li>

                    <div className="w-100 d-flex justify-content-end ">
                      <ul>
                        <li>
                          <Link to="/connexion">
                            <a className="getstarted-inscription scrollto">
                              {t("buttonLogin")}
                            </a>
                          </Link>
                        </li>
                        <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : path === "/verification-par-email" ? (
              <div className="w-100 container-mmk">
                <div className="row">
                  <div className="d-flex justify-content-between align-items-center">
                    <li className="w-100"></li>
                    <li className="w-100">
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <span id="t2">{t("validateYourEmail")}</span>
                      </div>
                    </li>

                    <div className="w-100 d-flex justify-content-end ">
                      <ul>
                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : path === "/renitialiser-mot-de-passe" ? (
              <div className="w-100 container-mmk">
                <div className="row">
                  <div className="d-flex justify-content-between align-items-center">
                    <li className="w-100"></li>
                    <li className="w-100">
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <span id="t2">{t("resetPassword")}</span>
                      </div>
                    </li>

                    <div className="w-100 d-flex justify-content-end ">
                      <ul>
                        <li>
                          <Link to="/inscription">
                            <a className="getstarted-inscription scrollto">
                              {t("buttonRegister")}
                            </a>
                          </Link>
                        </li>
                        <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : path === "/email-confirmer" ? (
              <div className="w-100 container-mmk">
                <div className="row">
                  <div className="d-flex justify-content-between align-items-center">
                    <li className="w-100"></li>
                    <li className="w-100">
                      <div className="w-100 d-flex justify-content-center align-items-center">
                        <span id="t2">{t("emailValidated")}</span>
                      </div>
                    </li>

                    <div className="w-100 d-flex justify-content-end ">
                      <ul>
                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex justify-content-center align-items-center">
                {path.includes("/annonce") ? ( //|| path === "/connexion"
                  <div className="col-4  align-items-center justify-content-start">
                    <div className="d-flex gap-3">
                      <ArrowToLeft />
                      <a
                        onClick={() => this.props.history.goBack()}
                        className="text-futura-16 text-align-left"
                      >
                        {t("backToSearchResualt")}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="col-4 align-items-center justify-content-start">
                    <form
                      className="has-search"
                      onSubmit={(e) => this.handleSearch(e)}
                    >
                      <span className="bi bi-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("search")}
                        style={{ height: "45px" }}
                        value={this.state.search}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </form>
                  </div>
                )}

                <div className="col-3">
                  {path == "/accueil-non-connecte" ||
                  path == "/" ||
                  path === "/recherche" ||
                  path === "/categorie-service" ? (
                    this.state.categoryType === "service" ? (
                      <li className="dropdown" id="dropt">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <span
                                  className="text-parte-pink"
                                  onClick={() =>
                                    this.handleCategoryTypeChange(
                                      "enseignement"
                                    )
                                  }
                                >
                                  {t("teachingPart")}
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <a id="met-violet">
                              <span
                                className="text-parte-purple"
                                onClick={() =>
                                  this.handleCategoryTypeChange("service")
                                }
                              >
                                {t("servicesPart")}
                              </span>
                              <i
                                style={{
                                  marginTop: "3px",
                                  fontSize: " 17px",
                                  marginLeft: "8px",
                                }}
                                //className="bi bi-chevron-down"
                              >
                                <ArrowDown />
                              </i>
                            </a>
                          </a>
                        </Dropdown>
                      </li>
                    ) : (
                      <li className="dropdown" id="dropt">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <span
                                  className="text-parte-purple"
                                  onClick={() =>
                                    this.handleCategoryTypeChange("service")
                                  }
                                >
                                  {t("servicesPart")}
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <a id="met-rose">
                              <span
                                className="text-parte-pink"
                                onClick={() =>
                                  this.handleCategoryTypeChange("enseignement")
                                }
                              >
                                {t("teachingPart")}
                              </span>
                              <i
                                style={{
                                  marginTop: "3px",
                                  fontSize: " 17px",
                                  marginLeft: "8px",
                                }}
                                //className="bi bi-chevron-down"
                              >
                                <ArrowDown />
                              </i>
                            </a>
                          </a>
                        </Dropdown>
                      </li>
                    )
                  ) : path == "/accueil-non-connecte/enseignement" ||
                    path === "/categorie-enseignement" ? (
                    this.state.categoryType === "enseignement" ? (
                      <li className="dropdown" id="dropt">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <span
                                  className="text-parte-purple"
                                  onClick={() =>
                                    this.handleCategoryTypeChange("service")
                                  }
                                >
                                  {t("servicesPart")}
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <a id="met-rose">
                              <span
                                className="text-parte-pink"
                                onClick={() =>
                                  this.handleCategoryTypeChange("enseignement")
                                }
                              >
                                {t("teachingPart")}
                              </span>
                              <i
                                style={{
                                  marginTop: "3px",
                                  fontSize: " 17px",
                                  marginLeft: "8px",
                                }}
                                //className="bi bi-chevron-down"
                              >
                                <ArrowDown />
                              </i>
                            </a>
                          </a>
                        </Dropdown>
                      </li>
                    ) : (
                      <li className="dropdown" id="dropt">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <span
                                  className="text-parte-pink"
                                  onClick={() =>
                                    this.handleCategoryTypeChange(
                                      "enseignement"
                                    )
                                  }
                                >
                                  {t("teachingPart")}
                                </span>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <a id="met-violet">
                              <span
                                className="text-parte-purple"
                                onClick={() =>
                                  this.handleCategoryTypeChange("service")
                                }
                              >
                                {t("servicesPart")}
                              </span>
                              <i
                                style={{
                                  marginTop: "3px",
                                  fontSize: " 17px",
                                  marginLeft: "8px",
                                }}
                                //className="bi bi-chevron-down"
                              >
                                <ArrowDown />
                              </i>
                            </a>
                          </a>
                        </Dropdown>
                      </li>
                    )
                  ) : null}

                  {path.includes("/annonce")? (
                    <React.Fragment>
                      <p className="text-futura-30 width-content w-100 announce-tite-overflow">
                        {this.props.location.state.announcementTitle
                          ? this.props.location.state.announcementTitle
                          : null}
                      </p>
                      <p className="text-futura-14 w-100">
                        {this.props.location.state.user.length > 0
                          ? this.props.location.state.user[0].name
                          : null}
                      </p>
                    </React.Fragment>
                  ) : null}
                </div>

                <div className="col-4 ">
                  <div className="d-flex align-items-center justify-content-end">
                    <ul>
                      <li>
                        <Link to="/connexion">
                          <a className="getstarted-btn1 scrollto">
                            {t("buttonLogin")}
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link to="/inscription">
                          <a className="getstarted-inscription scrollto">
                            {t("buttonRegister")}
                          </a>
                        </Link>
                      </li>

                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <div className="mobile-navbar-container">
              <i
                style={{ margin: "2rem" }}
                onClick={() => this.toggleNavbar()}
                className={
                  navbarmobile === false
                    ? "bi bi-list mobile-nav-toggle"
                    : "bi bi-x mobile-nav-toggle"
                }
              ></i>
            </div>

          </nav>
        </header>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleInputSearch: (data) =>
      dispatch(actionCreator.handleInputSearch(data)),
    handleCategoryType: (data) =>
      dispatch(actionCreator.handleCategoryType(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    inputSearch: state.SearchReducer.inputSearch,
    reducerCategoryType: state.SearchReducer.categoryType,
  };
};

NavBarNoLoggedIn.contextType = AuthContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBarNoLoggedIn));
