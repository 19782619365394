import React, { useContext, useEffect, useState } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as actionCreator from "../../redux/actions";
import moment from "moment";
import { Helmet } from "react-helmet-async";

function News() {
  const user = useContext(AuthContext);

  const history = useHistory();
  const dispatch = useDispatch();
  const [tabArticles, setTabArticles] = useState(null);
  const [tabarticlesCatergories, setTabarticlesCatergories] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);

  const { t, i18n } = useTranslation();
  const articles = useSelector((state) => state.NewsReducer.articles);
  const articlesCatergories = useSelector(
    (state) => state.NewsReducer.articlesCatergories
  );

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(
      actionCreator.getArticle(i18n.language.includes("fr") ? "fr" : "en")
    );
    dispatch(
      actionCreator.getArticleCategories(
        i18n.language.includes("fr") ? "fr" : "en"
      )
    );
  }, []);

  useEffect(() => {
    if (articles && articles.length > 0) {
      setTabArticles(articles);
    }
    if (articlesCatergories && articlesCatergories.length > 0) {
      setTabarticlesCatergories(articlesCatergories);
    }
  }, [articles, articlesCatergories]);

  useEffect(() => {
    if (articles && articles.length > 0) {
      if (categorySelected) {
        let values = articles.filter((article) => {
          if (
            categorySelected &&
            categorySelected[article.articleCategName] &&
            categorySelected[article.articleCategName].value
          ) {
            if (
              article.articleCategName ==
              categorySelected[article.articleCategName].label
            ) {
              return article;
            }
          }
        });
        setTabArticles(values);
      } else {
        setTabArticles(articles);
      }
    }
  }, [JSON.stringify(categorySelected)]);

  const handleChangeCategory = (value) => {    
    history.push({ pathname: `/actualites`, search: `?categorie=${value}`,})
    if (!(categorySelected && categorySelected[value])) {
      setCategorySelected({
        [value]: {
          label: value,
          value:
            categorySelected &&
            categorySelected[value] &&
            categorySelected[value].value
              ? categorySelected[value].value == false
                ? true
                : false
              : true,
        },
      });
    } else {
      setCategorySelected(null);
    }
  };

  const goToArticleDetails = (article) => {
    history.push({ pathname: `/actualites/${article.articleCategName}/${article.articleSubTitle}`, state: article });
  };

  return (
    <>
      <Helmet>
        <title>Make my work | le Blog</title>
      </Helmet>
      {user ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <section data-aos="fade-up" data-aos-delay="200">
        <div className="container mb-5 height-vh">
          <div className=" d-flex justify-content-center align-items-center my-5">
            <h1 className="text-futura-30">{t("minNews")}</h1>
          </div>
          <div className=" d-flex justify-content-center align-items-center mb-5 scroll">
            {tabarticlesCatergories &&
              tabarticlesCatergories.length>0 ?
              tabarticlesCatergories.map((categorie) => (
                <div className="pointer">
                  <div
                    to="/profil"
                    onClick={() =>
                      handleChangeCategory(categorie.articleCategName)
                    }
                    className={
                      categorySelected &&
                      categorySelected[categorie.articleCategName] &&
                      categorySelected[categorie.articleCategName].value
                        ? "m-3 selected-nav"
                        : "m-3 not-selected-nav"
                    }
                  >
                    {categorie.articleCategName}
                  </div>
                </div>
              )):<span className="no-items">{t("noItems")}</span>}
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center w-100 gap-4">
            {tabArticles &&
            tabArticles.length>0 ?
              tabArticles.map((article, index) => (
                <div className="card-news">
                  <div className="d-flex flex-row ">
                    <div className=" d-flex justify-content-start align-items-center">
                      <img
                        src={article.image}
                        className="image-width-100-icon-home-bottom"
                      />
                    </div>
                    <div className="w-100 d-flex-direction-col-jutify-center px-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <h2 className="text-futura-20 text-underline-gradiant">
                          {article.articleTitle}
                        </h2>
                        <p className="text-futura-11-grey">
                          {moment(article.dateAjoutArticle).format(
                            "YYYY-MM-DD"
                          )}
                        </p>
                      </div>
                      <p
                        className="text-roboto-regular-16-black text-align-left ellipsis-text-card-news"
                        dangerouslySetInnerHTML={{
                          __html: article.articleContent,
                        }}
                      ></p>
                      <a
                        className="text-futura-14 text-underline text-align-right pointer"
                        onClick={() => goToArticleDetails(article)}
                      >
                        {t("seeNews")}
                      </a>
                    </div>
                  </div>
                </div>
              )):<span className="no-items">{t("noItems")}</span>}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default News;
