import { useEffect, useState } from "react";
import "./conversation.css";
import moment from "moment";
import "moment/locale/fr";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import CircleMessageIcon from "../icons/CircleMessageIcon";

export default function Conversation({ conversation, currentUser }) {
  const { t } = useTranslation();

  moment.locale("fr");
  let timeAgo = moment
    .utc(conversation?.dateTime)
    .local()
    .startOf("seconds")
    .fromNow();

  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
  const random = Math.floor(Math.random() * ColorList.length);

  const [user, setUser] = useState(conversation.pseudo[0].toUpperCase());
  const [color, setColor] = useState(ColorList[random]);

  return (
    <div className="conversation">
      <div className="left">
        <div className="space-for-circle">
          {!conversation.status ? <CircleMessageIcon /> : null}
        </div>
        {conversation.profilePicture ? (
          <div className="position-relative">
            <img
              className="conversationImg"
              src={conversation.profilePicture}
              alt=""
            />
            {conversation.isActive ? <div className="green-dot"></div> : null}
          </div>
        ) : (
          <div className="position-relative">
            <Avatar
              style={{
                backgroundColor: color,
                verticalAlign: "middle",
                marginRight: "20px",
              }}
              size={48}
            >
              {user}
            </Avatar>
            {conversation.isActive ? <div className="green-dot"></div> : null}
          </div>
        )}

        <div>
          <span
            className={
              conversation.status
                ? "conversationName d-block"
                : "message-not-seen conversationName d-block"
            }
          >
            {conversation.pseudo}
          </span>
          <span
            className={
              conversation.status
                ? "conversationName d-block"
                : "message-not-seen conversationName d-block"
            }
          >
            {conversation.text
              ? conversation.text
              : conversation.candidatureId
              ? "Candidature"
              : t("attachmentHasBeenSent")}
          </span>
        </div>
      </div>
      <div className="right">{timeAgo.replace("il y a", "")}</div>
    </div>
  );
}
