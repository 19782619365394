import React, { useContext, useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import * as actionCreator from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Filter from "../../components/icons/Filter";
import HomeBottom from "./HomeBottom";
import StarRatings from "react-star-ratings";
import { Modal, notification } from "antd";
import SpinLoading from "../../components/SpinLoading";
import Url from "../../constants/Url";
import { useTranslation } from "react-i18next";
import { useContainerDimensions } from "../ContainerDimensions";
import { Helmet } from "react-helmet-async";
import CategoryCard from "../../components/cards/CategoryCard";
import AnnounceCard from "../../components/cards/AnnounceCard";


function HomeService() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [announcementType, setAnnouncementType] = useState("demande");
  const categoryType = "service";
  const [openModalStarRate, setOpenModalStarRate] = useState(false);
  const [openModalStarRateDone, setOpenModalStarRateDone] = useState(false);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState(null);
  const [loadingAvis, setLoadingAvis] = useState(false);
  const [avis, setaAvis] = useState(false);
  const [step, setStep] = useState(0);
  const [maxCards, setMaxCards] = useState(8);
  const [maxCardsCategory, setMaxCardsCategory] = useState(10);

  const workRef = useRef(null);
  const { user } = useContext(AuthContext);

  const allData = useSelector((state) => state.HomeReducer.allData);
  const success = useSelector(
    (state) => state.HomeReducer.successFormConnected
  );
  const skills = useSelector((state) => state.SkillsReducer.skills);

  useEffect(() => {
    if (user) {
      if (!success || announcementType) {
        const data = {
          announcementType: announcementType,
          categoryType: categoryType,
          userId: user.id,
        };

        dispatch(actionCreator.homePageConnected(data));
        dispatch(actionCreator.getAllSkills());
      }
    }
  }, [success, announcementType, user]);

  useEffect(() => {
    if (localStorage.getItem("avis")) {
      let data = JSON.parse(localStorage.getItem("avis"));
      if (data.length > 0) {
        setaAvis(data);
        setOpenModalStarRate(true);
        localStorage.removeItem("avis");
      }
    }
  }, [localStorage.getItem("avis")]);

  const goToAnnouncementDetails = (announce) => {
    history.push({
      pathname: `/annonce/${announce.slug}`,
      state: announce,
      connected: true,
    });
  };

  const goToSearch = (category) => {
    history.push({
      pathname: "/recherche",
      categoryId: category.id,
      categoryName: category.categoriefr,
    });
  };

  const avisDemandeur = async () => {
    setLoadingAvis(true);
    await Url.Url4.post("avisDemandeur", {
      candidatureId: avis[step].id,
      rating: rate,
      commentaire: comment,
    })
      .then(async (response) => {
        await Url.Url4.get("getAvisUserAndUpdateMoyenne/" + `${user.id}`).then(
          (response) => {}
        );

        notification.success({

          message: "Avis",
          description: "Avis envoyée",
        });
        setLoadingAvis(false);
        setRate(0);
        setComment("");

        if (step < avis.length - 1) {
          setStep(step + 1);
        } else {
          setOpenModalStarRate(false);
          setOpenModalStarRateDone(true);
        }
      })
      .catch((error) => {
        //console.log("error :", error);
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  };

  const gotoHowItWork = () =>
    window.scrollTo({
      top: workRef.current.offsetTop - 140,
      behavior: "smooth",
    });

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  useEffect(async () => {
    setMaxCards(parseInt(width / 200));
    setMaxCardsCategory(parseInt(width / 150));
  });

  return (
    <>

      <Helmet>
        <title>
          Service à la personne : Ménage, babysitting, bricolage, informatique, démenagement, cuisine - Make My Work
        </title>
        <meta name="description" content="Make My Work est une plateforme de mise en relation pour les services à la personne. Récupère le temps dont tu as besoin, nous trouverons l’assistance parfaite pour vos taches du quotidien ." />
      </Helmet>
      <NavBarConnected />
      <NavbarMobile />

      <main id="main">
        <section>
          <div
            className="container-fluid d-flex background-home-ser-img"
            ref={componentRef}
          >
            <div className="icon-box-ser">
              <h1 className="text-futura-30 text-align-left mt-2"  style={{ lineHeight: "3rem" }}>
                {t("welcome")} !
              </h1>
              <p
                className="text-justify text-roboto-regular-16-black text-align-left mt-5"
              >
                {t("welcomeDesc")}
              </p>
              <div className="getstarted-btns gap-3">
                <button
                  onClick={() =>
                    history.push({ pathname: "/selectionner-un-type-dannonce" })
                  }
                  className="btn-mmk"
                >
                  {t("btnCreateAnAnnoucement")}
                </button>

                <button
                  onClick={() => gotoHowItWork()}
                  className="btn-mmk-white"
                >
                  {t("btnHowItWork")} ?
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-ens" className="portfolio-ens">
          <div className="container-mmk w-100">
            <div className="portfolio-grid" style={{ marginTop: "2rem" }}>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="w-100">
                    <div
                      data-filter=".filter-ens-Demandes"
                      className={
                        announcementType === "demande"
                          ? "selected-nav-purple pointer"
                          : "not-selected-nav-purple pointer"
                      }
                      onClick={() => setAnnouncementType("demande")}
                    >
                      {t("requests")}
                    </div>
                  </div>
                  <div className="w-100">
                    <div
                      data-filter="filter-ens-Offres"
                      className={
                        announcementType === "offre"
                          ? "selected-nav-purple pointer"
                          : "not-selected-nav-purple pointer"
                      }
                      onClick={() => setAnnouncementType("offre")}
                    >
                      {t("offers")}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="portfolio-ens-flters"
                className=""
                style={{ textAlign: "end" }}
              >
                <Link to="/recherche">
                  <Filter />
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-4">
              <h2 className="text-futura-20 text-underline-gradiant">
                {t("popularCategories")}
              </h2>
              <NavLink to="/categorie-service">
                <p
                  className="text-futura-14 text-underline"
                  style={{ color: "#aa4dfc" }}
                >
                  {t("LinkSellAll")}
                </p>
              </NavLink>
            </div>

            <div className="row gap-4">
              {allData &&
              allData.categorieType.length>0 ?
                allData.categorieType?.map(
                  (category, key) =>
                    key < maxCardsCategory && (
                      <CategoryCard props={{category,key}} onClick={(category)=>goToSearch(category)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </section>

        <section id="Photographe" className="Photographe">
          <div className="container-mmk w-100">
            <div className="d-flex justify-content-between mt-3 mb-1">
              <h2 className=" text-align-left text-futura-20 text-underline-gradiant">
                {t("inYourSkills")}
              </h2>

              <NavLink to="/dans-tes-competences-service">
                <p
                  className="text-futura-14 text-underline"
                  style={{ color: "#aa4dfc" }}
                >
                  {t("LinkSellAll")}
                </p>
              </NavLink>
            </div>

            <div className="row gap-4">
              {allData &&
              allData.otherAnnouncementInYourSkills.length>0 ?
                allData.otherAnnouncementInYourSkills?.map(
                  (announce, key) =>
                    key < maxCards && (
                       <AnnounceCard props={{announce, key}} onClick={() => goToAnnouncementDetails(announce)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>

          <div className="container-mmk w-100">
            {announcementType === "demande" ? (
              <div className="d-flex  justify-content-between mt-4 mb-1">
                <h2 className="text-align-left text-futura-20 text-underline-gradiant">
                  {t("otherRequests")}
                </h2>
                <NavLink to="/autres-demandes-service">
                  <p
                    className="text-futura-14 text-underline"
                    style={{ color: "#aa4dfc" }}
                  >
                    {t("LinkSellAll")}
                  </p>
                </NavLink>
              </div>
            ) : (
              <div className="d-flex  justify-content-between  mt-4">
                <h2 className="text-align-left text-underline-gradiant">
                  {t("otherOffers")}
                </h2>
                <NavLink to="/autres-demandes-service">
                  <p
                    className="text-futura-14 text-underline"
                    style={{ color: "#aa4dfc" }}
                  >
                    {t("LinkSellAll")}
                  </p>
                </NavLink>
              </div>
            )}
            <div className="row gap-4">
              {allData &&
              allData.otherAnnouncementNotInYourSkills.length>0 ?
                allData.otherAnnouncementNotInYourSkills?.map(
                  (announce, key) =>
                    key < maxCards && (
                      <AnnounceCard props={{announce, key}} onClick={() => goToAnnouncementDetails(announce)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </section>
        <div ref={workRef}></div>
        <HomeBottom />
      </main>

      <Modal
        open={openModalStarRate}
        onCancel={() => setOpenModalStarRate(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="max-width-avis-container d-flex flex-column justify-content-center align-items-center w-100">
            <h1 className="text-futura-30 mt-3">{t("LeaveReview")}</h1>
            <p className="text-futura-20">
              {avis && avis.length > 0 &&
              avis[step].announcement.announcementType === "offre"
                ? avis[step].announcement.user?.name
                : null}
              {avis.length > 0 &&
              avis[step].announcement.announcementType === "demande"
                ? avis[step].user?.name
                : null}
            </p>
            <p className="text-futura-20 py-5">
              {t("HowDidYouFindThePerformance")}{" "}
              {avis.length > 0 &&
              avis[step].announcement.announcementType === "offre"
                ? avis[step].announcement.user?.name
                : null}
              {avis.length > 0 &&
              avis[step].announcement.announcementType === "demande"
                ? avis[step].user?.name
                : null}{" "}
              ?
            </p>
            <StarRatings
              rating={rate}
              starRatedColor="#F817AC"
              //starEmptyColor = ""
              starHoverColor="#F817AC"
              starDimension="30px"
              starSpacing="0px"
              changeRating={(rating) => setRate(rating)}
              numberOfStars={5}
              name="rating"
            />
            <div className="d-flex justify-content-start w-100 mt-3">
              <p className="text-futura-16 text-align-left">
                {t("lableComment")}
              </p>
            </div>

            <textarea
              className="input-textArea"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />

            <button
              className={
                rate !== 0
                  ? "btn-mmk mt-5 mb-2"
                  : "btn-mmk-grey-opacity mt-5 mb-2"
              }
              onClick={() => avisDemandeur()}
              disabled={loadingAvis}
            >
              {loadingAvis ? <SpinLoading /> : t("buttonValidate")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalStarRateDone}
        onCancel={() => setOpenModalStarRateDone(false)}
        footer={false}
        width={625}

      >
        <div className="d-flex justify-content-center align-items-center  w-100">
          <div className="d-flex flex-column container-cart-payment-succed">
            <img
              src={require('../../assets/img/payment/rateSent.png')}
              height={263}
              width={295}
            />

            <p className="text-futura-20 py-5">{t("yourReviewSent")} !</p>
            <p className="text-futura-14">{t("yourReviewSentDesc")}</p>
          </div>
        </div>
      </Modal>

      <Footer />
    </>
  );
}

export default HomeService;
