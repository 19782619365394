import React, { useEffect } from "react";
import Slider from "react-slick";
import FullStar from "../../components/icons/FullStar";
import CreditCard from "../../components/icons/CreditCard";
import Phone from "../../components/icons/Phone";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../redux/actions";
import { useTranslation } from "react-i18next";
import moment from "moment";

function HomeBottom() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const articles = useSelector((state) => state.NewsReducer.articles);
  const videoUrl = useSelector((state) => state.NewsReducer.videoUrl);
  const partners = useSelector((state) => state.NewsReducer.partners);

  var settings = {
    dots: false,
    infinite: true,
    speed: 750,

    slidesToShow: partners ? partners.length : 0,
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <Arrows icon="icon-chevron-left" className="slick" />,
    nextArrow: <Arrows icon="icon-chevron-right" className="slick" />,
    lazyload: true,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: partners ? partners.length : 0,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 1366,
        settings: {
          slidesToShow: partners ? partners.length : 0,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(
      actionCreator.getArticle(i18n.language.includes("fr") ? "fr" : "en")
    );
    dispatch(actionCreator.getVideo());
    dispatch(actionCreator.getPartners());
  }, []);

  const goToArticleDetails = (article) => {
    history.push({ pathname: `/actualites/${article.articleCategName}/${article.articleSubTitle}`, state: article });
  };

  return (
    <>
      <div
        className="view-march display-flex-col"
        style={{ marginTop: "7rem" }}
      >
        <div className="left-view">
          <div className="">
            <img
              src={require('../../assets/img/phonewithbackground.png')}
              height="100%"
              width="100%"
            />
          </div>
        </div>
        <div className="right-view col">
          <h1 className="text-futura-30 mt-4">{t("minHowItWork")} ?</h1>
          <div className="d-flex flex-column gap-3 mt-1 w-100 view-col">
            <div className="d-flex flex-row justify-content-start gap-5 max-width fix-algin">
              <span className="number">
                <img
                  src={require('../../assets/img/circle.png')}
                  style={{ width: "7.6rem", height: "7.6rem" }}
                ></img>
                <span className="text-futura-30">1</span>
              </span>
              <div className="d-flex flex-column width-90">
                <div>
                  <h2 className="text-futura-20 text-align-left text-underline-gradiant width-content">
                    {t("createAnnounce1")}
                  </h2>
                </div>
                <p className="text-roboto-regular-16-black text-align-left">
                  {t("descCreateAnnounce1")}
                </p>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-center gap-5 max-width fix-algin">
              <span className="number">
                <img
                  src={require('../../assets/img/circle.png')}
                  style={{ width: "7.6rem", height: "7.6rem" }}
                ></img>
                <span className="text-futura-30">2</span>
              </span>
              <div className="d-flex flex-column width-90">
                <h2 className="text-futura-20 text-align-left text-underline-gradiant width-content">
                  {t("createAnnounce2")}
                </h2>
                <p className="text-roboto-regular-16-black text-align-left">
                  {t("descCreateAnnounce2")}
                </p>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end gap-5 max-width fix-algin">
              <span className="number">
                <img
                  src={require('../../assets/img/circle.png')}
                  style={{ width: "7.6rem", height: "7.6rem" }}
                ></img>
                <span className="text-futura-30">3</span>
              </span>
              <div className="d-flex flex-column width-90">
                <h2 className="text-futura-20 text-align-left text-underline-gradiant width-content">
                  {t("createAnnounce3")}
                </h2>
                <p className="text-roboto-regular-16-black text-align-left ">
                  {t("descCreateAnnounce3")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-mmk w-100 my-5">
        <div className="row " style={{ marginTop: "9rem" }}>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center align-items-center">
            <img src={require('../../assets/img/Group 6856.png')} className="image-width-90" />
            </div>
          </div>

          <div className="col-lg-6">
            <h1 className="text-futura-30 text-align-left mt-1 mb-3">
            {t('differentServices')} 
            </h1>

            <div className="row " style={{ margin: "2rem 2rem 0 0" }}>
              <div className="col d-flex justify-content-start align-items-center">
                <div className="service-card">
                    <img
                      src={require('../../assets/img/garde-enfant.png')}
                    />
                  </div>
              </div>
              <div className="col-lg-11">
                <h2 className="text-futura-20 text-align-left title-section-home width-content">
                {t('childCare')} 
                </h2>
                <p className="text-roboto-regular-16-black text-align-left">
                {t('childCareDesc')} 
                </p>
              </div>
            </div>

            <div className="row " style={{ margin: "2rem 2rem 0 0" }}>
              <div className="col d-flex justify-content-start align-items-center">
                 <div className="service-card">
                  <img
                    src={require('../../assets/img/entretien.png')}
                  />
                </div>
              </div>
              <div className="col-lg-11">
                <h2 className="text-futura-20  title-section-home text-align-left width-content">
                {t('multipServices')}
                </h2>
                <p className="text-roboto-regular-16-black text-align-left">
                {t('multipServicesDesc')} 
                </p>
              </div>
            </div>

            <div className="row " style={{ margin: "2rem 2rem 0 0" }}>
              <div className="col d-flex justify-content-start align-items-center">
                <div className="service-card">
                  <img
                    src={require('../../assets/img/Histoire.png')}
                  />
                </div>
             
              </div>
              <div className="col-lg-11">
                <h2 className="text-futura-20  title-section-home text-align-left width-content">
                {t('teachingHelp')} 
                </h2>
                <p className="text-roboto-regular-16-black text-align-left">
                {t('teachingHelpDesc')} 
                </p>
              </div>
            </div>

            <div className="row " style={{ margin: "2rem 2rem 0 0" }}>
              <div className="col d-flex justify-content-start align-items-center">
              <div className="service-card">
                  <img
                    src={require('../../assets/img/demande.png')}
                  />
                </div>
              </div>
              <div className="col-lg-11">
                <h2 className="text-futura-20  title-section-home text-align-left  width-content">
                {t('createYourOwnService')} 
                </h2>
                <p className="text-roboto-regular-16-black text-align-left">
                {t('createYourOwnServiceDesc')} 
                </p>
              </div>
            </div>

          </div>
        </div>

        <div
          className="row justify-content-center mb-5"
          style={{ marginTop: "7rem" }}
        >
          <h1 className="text-futura-30"> {t("whyChooseMakeMyWork")} ? </h1>
        </div>

        <div className="row">
          <div className="col d-flex flex-column align-items-center">
            <div className="mask-mmk">
              <div className="view-item row">
                <div className="col ">
                  <div className="view-lg d-flex flex-column">
                    <i>
                      <FullStar width={30} height={30} />
                    </i>
                    <i>
                      <FullStar width={30} height={30} />
                    </i>
                    <i>
                      <FullStar width={30} height={30} />
                    </i>
                    <i>
                      <FullStar width={30} height={30} />
                    </i>
                    <i>
                      <FullStar width={30} height={30} />
                    </i>
                  </div>
                </div>
                <div className="col">
                  <div className="view">
                    <i>
                      <CreditCard width={40} height={40} />
                    </i>
                  </div>
                  <div className="view">
                    <i>
                      <Phone width={40} height={40} />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <div className="view-bottom w-100">
              <h1 className="text-futura-30 bold mb-3">{t("securePayment")}</h1>
              <p>{t("securePaymentDesc")}</p>
            </div>
          </div>
          <div className="col  d-flex flex-column align-items-center">
            <div className="mask-mmk">
              <div className="view-item row">
                <div className="col">
                  <div className="view">
                    <i>
                      <CreditCard width={40} height={40} />
                    </i>
                  </div>
                  <div className="view">
                    <i>
                      <CreditCard width={40} height={40} />
                    </i>
                  </div>
                </div>
                <div className="col">
                  <div className="view">
                    <i>
                      <Phone width={40} height={40} />
                    </i>
                  </div>
                  <div className="view">
                    <i>
                      <Phone width={40} height={40} />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <div className="view-bottom w-100">
              <h1 className="text-futura-30 bold mb-3">{t("simplicityAndTranquility")}</h1>
              <p>{t("simplicityAndTranquilityDesc")}</p>
            </div>
          </div>
          <div className="col d-flex flex-column align-items-center ">
            <div className="mask-mmk">
              <div className="view-item row">
                <div className="col d-flex justify-content-center align-items-center">
                  <div className="view">
                    <i>
                      <Phone width={40} height={40} />
                    </i>
                  </div>
                </div>
                <div className="col">
                  <div className="view">
                    <i>
                      <CreditCard width={40} height={40} />
                    </i>
                  </div>
                  <div className="view">
                    <i>
                      <Phone width={40} height={40} />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <div className="view-bottom w-100">
              <h1 className="text-futura-30 bold mb-3">{t("qualityProvider")}</h1>
              <p>{t("qualityProviderDesc")}</p>
            </div>
          </div>
        </div>

        <section id="Vedio" className="Vedio d-flex align-items-center">
          <div className="container-mmk w-100">
            <div className="d-flex justify-content-center ">
              <div className="d-flex flex-column align-items-center justify-content-center my-5">
                <h1 className="text-futura-30">{t("videoSection")}</h1>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="d-flex align-items-center justify-content-center w-100">
                <iframe
                  className="mbr-embedded-video"
                  src={videoUrl}
                  width="100%"
                  height="100%"
                  frameborder="0"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <div
          className="row justify-content-center mb-5"
          style={{ marginTop: "7rem" }}
        >
          {partners && partners.length > 0 ? (
            <h1 className="text-futura-30">{t("theyTalkAboutUs")}</h1>
          ) : null}
        </div>
        {partners && partners.length > 0 ? (
          <Slider {...settings}>
            {partners &&
              partners.map((logo, index) => (
                <div className="profil-card pointer">
                  <img
                    src={logo.urlS3}
                    height="200px"
                    className="radius-20 shadow-img"
                  />
                </div>
              ))}
          </Slider>
        ) : null}

        <div
          className="row justify-content-center "
          style={{ marginTop: "7rem" }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center my-5">
            {articles && articles.length > 0 ? (
              <h1 className="text-futura-30">{t("minNews")}</h1>
            ) : null}
          </div>
        </div>
        <div className="row ">
          <div className="col col-lg-5">
            {articles && articles[0] && (
              <div className="space-new-cards">
                <img src={articles[0].image} className="image-width-100-home" />
                <div>
                  <div className="d-flex justify-content-between align-items-center my-3">
                    <h2 className="text-futura-20 text-underline-gradiant text-align-left">
                      {articles[0].articleTitle}
                    </h2>
                    <p className="text-futura-11-grey">
                      {moment(articles[0].dateAjoutArticle).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                  <div className="hide-text">
                    <soan
                      className="content-desc"
                      dangerouslySetInnerHTML={{
                        __html: articles[0].articleContent,
                      }}
                    />
                  </div>
                  <p
                    className="text-futura-14 text-underline text-align-right pointer mt-2"
                    onClick={() => goToArticleDetails(articles[0])}
                  >
                    {t("seeNews")}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-1 col-lg-1">
            <div className="h-100 w-50 col-actualite"></div>
          </div>

          <div className="col-xl-6 col-lg-6">
            {articles &&articles.length>0 ?
              articles.map(
                (article, index) =>
                  index > 0 &&
                  index < 5 && (
                    <div
                      className="row space-new-cards"
                      style={{ height: "14.5rem" }}
                    >
                      <div className=" col col-lg-3  col-md-3 col-sm-2 d-flex justify-content-start align-items-center">
                        <img
                          src={article.image}
                          className="image-width-100-icon-home-bottom"
                        />
                      </div>

                      <div
                        className="col-lg-9 d-flex-direction-col-jutify-center"
                        style={{ padding: "0rem" }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="text-futura-20 text-underline-gradiant text-align-left">
                            {article.articleTitle}
                          </h2>
                          <p className="text-futura-11-grey">
                            {moment(article.dateAjoutArticle).format(
                              "YYYY-MM-DD"
                            )}
                          </p>
                        </div>
                        <div className="hide-text">
                          <span
                            className="text-roboto-regular-16-black text-align-left"
                            dangerouslySetInnerHTML={{
                              __html: article.articleContent,
                            }}
                          ></span>
                        </div>

                        <p
                          className="text-futura-14 text-underline text-align-right pointer mt-2"
                          onClick={() => goToArticleDetails(article)}
                        >
                          {t("seeNews")}
                        </p>
                      </div>
                    </div>
                  )
              ):<span className="no-items">{t("noItems")}</span>}
          </div>
          {articles && articles.length > 0 ? (
            <div
              className="d-flex justify-content-center align-items-center mt-5"
              style={{ marginBottom: "9rem" }}
            >
              <button className="btn-mmk" onClick={() => history.push("/actualites")}>
                {t("seeAllNews")}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
const Arrows = (props) => {
  const { className, onClick, icon } = props;
  return (
    <a className={`slick-arrow slick-next ${className}`} onClick={onClick}>
      {icon ? <i className={icon}></i> : <i className="icon-chevron-right"></i>}
    </a>
  );
};
export default HomeBottom;
