import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { getToken } from "../shared";

function Privacy() {

  let token = getToken();

  useEffect(() => {
    window.scrollTo(0, 0);
  })

  return (
    <>
      <Helmet>
        <title>Make My Work | Politique de confidentialité</title>
      </Helmet>
      {token ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <div className="container ">
        <div className="terms d-flex flex-column align-items-center mt-5">
          <div className="term-header">
            <h1>POLITIQUE DE CONFIDENTIALITÉ DE LA PLATEFORME MAKEMYWORK</h1>
          </div>
          <div className="term-content">
            <div className="term-paragh">
              <h2>1. Données personnelles</h2>
              <p>
                Le responsable de traitement des Données Personnelles mis en œuvre sur la Plateforme est la société MAKE MY WORK, société par actions
                simplifiée immatriculée au RCS de Versailles sous le numéro 900 672 882, dont le siège social est situé 8 avenue Manuel, 78600
                Maisons-Laffitte.
              </p>
              <h3 className="sub-title">1.1 Données Personnelles collectées</h3>
              <p>
                La société MAKE MY WORK est amenée à collecter via la Plateforme les Données Personnelles des Utilisateurs suivantes dans les
                hypothèses suivantes.
              </p>
              <h3 className="sub-middle-title">● Création du Compte Utilisateur et configuration du Profil</h3>
              <p>
                Dans le cadre de la création de son Compte Utilisateur et de la configuration de son Profil, celui-ci doit renseigner les éléments
                suivants le concernant :
              </p>
              <ul>
                <li>Nom, prénom de l’Utilisateur ;</li>
                <li>Pseudonyme de l’Utilisateur ;</li>
                <li>Adresse IP de l’Utilisateur ;</li>
                <li>Adresse mail de l’Utilisateur ;</li>
                <li>Photo de l’Utilisateur pour le Profil / Profil Certifié ;</li>
                <li>Données relatives aux compétences du Prestataire qui se propose de fournir des Services à la Personne ;</li>
              </ul>
              <p>La création du Compte Utilisateur peut également se faire à partir du compte Google ou Facebook de l’Utilisateur.</p>
              <p>
                <strong>Facebook Connect :</strong> lorsque l’Utilisateur souhaite s’inscrire à la Plateforme à partir de Facebook Connect, Facebook
                demandera à l’Utilisateur l’autorisation de communiquer à la Plateforme des Données Personnelles. Celles-ci peuvent inclure les nom,
                prénom, adresse mail, photo de profil de l’Utilisateur ainsi qu’une localisation générale. Ces informations sont recueillies sur
                Facebook et sont communiquées à la Plateforme conformément à la{" "}
                <a className="link" href="#">
                  Politique de confidentialité de Facebook
                </a>
                . L’Utilisateur peut contrôler l'information que la Plateforme reçoit de Facebook au moyen de la configuration de confidentialité de
                Facebook de son compte Facebook.
              </p>
              <p>
                <strong>Connexion avec Google :</strong> lorsque l’Utilisateur souhaite s’inscrire à la Plateforme à partir de son compte Google,
                Google demandera à l’Utilisateur l’autorisation de communiquer à la Plateforme des Données Personnelles. Celles-ci peuvent inclure les
                nom, prénom, adresse mail, photo de profil de l’Utilisateur ainsi qu’une localisation générale. Ces informations sont recueillies par
                Google et sont fournies à la Plateforme conformément à la politique de{" "}
                <a className="link" href="#">
                  Google en matière de confidentialité.
                </a>
              </p>
              <h3 className="sub-middle-title">● Création d’un Profil Certifié</h3>
              <p>
                Dans le cadre de la création d’un Profil Certifié, la Plateforme peut également collecter de l’Utilisateur un justificatif d’identité,
                et ce en sus des Données Personnelles collectées lors de la création du Compte Utilisateur et du Profil. Dans ce cas, la Plateforme
                collecte également ses lieu, date de naissance ainsi que le pays d’émission de son justificatif d’identité.
              </p>
              <h3 className="sub-middle-title">
                ● Communication d’informations pertinentes concernant l’activité de l’Utilisateur aux autres Utilisateurs
              </h3>
              <p>
                Afin de fournir aux Utilisateurs des informations pertinentes concernant un autre Utilisateur, la Plateforme collecte, utilise et rend
                public les informations suivantes :
              </p>
              <ul>
                <li>[ex. date de dernière connexion, nombre et nom de membre des abonnés, nombre et nom de membre des membres suivis, etc]</li>
              </ul>
              <h3 className="sub-middle-title">● Règlement via le Service de Paiement Intégré</h3>
              <p>L’exécution de l’Accord nécessite l’utilisation par l’Utilisateur du Service de Paiement Intégré.</p>
              <p>Les paiements sur la Plateforme sont effectués par l’intermédiaire du Prestataire de Service de Paiement Intégré.</p>
              <p>Le Prestataire de Service de Paiement Intégré collecte les données suivantes :</p>
              <ul>
                <li>
                  Données relatives aux moyens de paiement : numéro de carte bancaire, date de fin de validité de la carte bancaire, cryptogramme
                  visuel ;
                </li>
                <li>Données relatives à la transaction : numéro de la transaction ; détail de l’Accord ;</li>
                <li>Données relatives au paiement de l’Accord : modalités de règlement, factures.</li>
              </ul>
              <p>Les informations bancaires de l’Utilisateur ne sont jamais en la possession de la société MAKE MY WORK.</p>
              <h3 className="sub-middle-title">● Envoi de la newsletter</h3>
              <p>Afin de permettre l’envoi de la newsletter, la Plateforme collecte les Données Personnelles suivantes :</p>
              <ul>
                <li>Nom, prénom de l’Utilisateur ;</li>
                <li>Adresse mail de l’Utilisateur ;</li>
              </ul>
              <h3 className="sub-title">1.2 Cookies</h3>
              <p>
                La Plateforme utilise des cookies (témoins de connexion) ce dont l’Utilisateur est informé en arrivant sur le site internet qui
                permettent d’enregistrer des informations relatives à la navigation de l’ordinateur sur ledit site Internet, de mesure l’audience et
                la performance dudit site internet, de réaliser des publicités ciblées en dehors de l’utilisation de la Plateforme. Ces cookies ne
                sont installés qu’après acceptation par l’Utilisateur, la poursuite de la navigation sur le site internet valant acceptation.
              </p>
              <p>
                L’Utilisateur peut s’opposer à l’utilisation de ces cookies en paramétrant son navigateur, sachant que l’accès à certains services
                peut nécessiter l’acceptation préalable par l’Utilisateur des cookies.
              </p>

              <h3 className="sub-title">1.3 Finalités des traitements, durés de conservation et bases légales</h3>

              <h3 className="sub-middle-title">1.3.1 Finalités de la collecte</h3>
              <p>Les Données Personnelles sont collectées en vue de :</p>
              <ol className="s">
                <li>l'exploitation et la mise à disposition de la Plateforme et ses Services aux Utilisateurs ;</li>
                <li>la création d’un Compte Utilisateur permettant d’accéder aux Services de la Plateforme ;</li>
                <li>l’authentification d’un Utilisateur via son compte Google et/ou Facebook ;</li>
                <li>la vérification d’identité de l’Utilisateur afin de lui permettre d’obtenir un Profil Certifié ;</li>
                <li>le dépôt de cookies ;</li>
                <li>
                  la réalisation d’opérations relatives à la gestion des Utilisateurs concernant la publication d’Annonce, la conclusion d’Accord ;
                </li>
                <li>la gestion et l’exécution du paiement fait par un Client à un Prestataire dans le cadre de l’exécution d’un Accord ;</li>
                <li>la constitution d’un fichier clients et prestataires ;</li>
                <li>l’envoi de newsletters et messages promotionnels ;</li>
                <li>l’élaboration de statistiques commerciales et de fréquentation de la Plateforme et de ses Service;</li>
                <li> l’évaluation des Utilisateurs ;</li>
                <li>l’aide au règlement amiable des différends survenant entre Utilisateurs ;</li>
                <li>la défense des droits et intérêts de la société MAKE MY WORK et de la violation des présentes CGU par l’Utilisateur ;</li>
                <li>le respect des obligations légales et réglementaires.</li>
              </ol>
              <h3 className="sub-middle-title">1.3.2 Durée de conservation des Données Personnelles collectées</h3>
              <p>1.3.2 Durée de conservation des Données Personnelles collectées</p>
              <h3 className="sub-middle-title">● Données relatives aux Compte Utilisateur, Profil et Profil Certifié</h3>
              <p>
                Ces données sont conservées tant que le Compte Utilisateur reste actif. Au terme d’un délai d’inactivité de 2 (deux) ans,
                l’Utilisateur recevra un courrier électronique afin de l’informer de la fermeture de son Compte Utilisateur, sauf nouvelle connexion.
                Ces données sont ensuite conservées pendant une durée de 1 (un) à compter de la clôture du Compte Utilisateur.
              </p>
              <p>Les données relatives à un Utilisateur ayant commis un manquement aux présentes CGU seront conservés pendant 10 (dix) ans. </p>
              <h3 className="sub-middle-title">● Données relatives à l’utilisation de la Plateforme</h3>
              <p>Ces données sont conservées pendant une durée de 13 (treize) mois.</p>
              <h3 className="sub-middle-title">● Données relatives à la défense des droits et intérêts de la société MAKE MY WORK</h3>
              <p>
                Ces données sont conservées pendant une durée de 1 (un) an à compter de la survenance d’un différend entre l’Utilisateur et la société
                MAKE MY WORK ou d’une violation des présentes CGU par l’Utilisateur ou pour toute la durée d’un différend ou d’une procédure, de
                quelque nature qu’elle soit, selon la période la plus longue.
              </p>
              <h3 className="sub-middle-title">● Données relatives au respect des obligations légales et réglementaires</h3>
              <p>
                Ces données sont conservées aussi longtemps que le Compte Utilisateur reste actif ou pendant 5 à partir du moment où le Compte
                Utilisateur devient inactif
              </p>

              <h3 className="sub-middle-title">1.3.3 Bases légales de la collecte des Données Personnelles</h3>
              <p>Les Données Personnelles sont collectées par la Plateforme en vertu des fondements légaux suivants :</p>
              <ul>
                <li>le consentement de l’Utilisateur ;</li>
                <li>l’exécution de l’Accord passé entre les Utilisateurs par le biais de la Plateforme ;</li>
                <li>l’intérêt légitime de la société MAKE MY WORK.</li>
              </ul>

              <h3 className="sub-title">1.4 Partage des Données Personnelles</h3>
              <p>
                Les Données Personnelles ne sont pas louées ni vendeurs à des tiers sans le consentement de l’Utilisateur, sauf dispositions
                contraires énoncées ci-après. Les Données Personnelles peuvent être partagées dans les conditions suivantes :
              </p>
              <ol className="s">
                <li>
                  Les Données Personnelles, ainsi que des informations telles que des données d’emplacement, cookies, fichiers journaux et
                  identificateurs de périphériques, peuvent être partagés avec des fournisseurs de services afin de permettre la mise à disposition de
                  la Plateforme et de ses Services. Ces fournisseurs de services traitent les données uniquement après instruction explicite et sont
                  contractuellement tenus de garantir des mesures techniques et organisationnelles suffisantes pour la protection des données.
                </li>
                <li>
                  Les Données Personnelles incluant des informations de paiement sont transmises au Prestataire de Service de Paiement Intégré qui
                  collecte, traite et utilise ces informations à des fins de traitement des paiements ou de recouvrement de la créance sur votre
                  compte. La société MAKE MY WORK ne collecte ni ne conserve ces informations de paiement.
                </li>
                <li>
                  La société MAKE MY WORK pourra accéder aux Données Personnelles, les préserver et les partager en réponse à une requête judiciaire
                  (mandat de perquisition, ordonnance d’un tribunal ou autre). Cela peut inclure la réponse à des requêtes judiciaires provenant de
                  juridictions en dehors de la France, si la société MAKE MY WORK a toutes les raisons de penser que la réponse est requise par la loi
                  de cette juridiction, qu’elle s’applique aux utilisateurs dépendant de cette juridiction et qu’elle est conforme aux normes
                  internationalement reconnues.
                </li>
                <li>
                  La société MAKE MY WORK pourra également accéder à des informations personnelles, les préserver et les partager lorsqu’il est
                  nécessaire de : détecter, empêcher et traiter des fraudes ou toute autre activité illicite ; protéger la société MAKE MY WORK et
                  protéger des tiers, notamment dans le cas d’enquêtes ; et empêcher la mort ou tout risque imminent d’atteinte à l’intégrité physique
                  d’une personne.
                </li>
                <li>
                  La société MAKE MY WORK pourra accéder aux Données Personnelles, les traiter et les conserver pendant une période prolongée dans le
                  but de répondre à une requête judiciaire, à une enquête gouvernementale ou une enquête concernant la possible violation de nos
                  conditions ou politiques, ou en vue d’empêcher tout dommage.
                </li>
              </ol>
              <h3 className="sub-title">1.5 Droits de l’Utilisateur</h3>
              <p>
                L’Utilisateur dispose à tout moment de la faculté d’exercer auprès de la société MAKE MY WORK les droits prévus par la réglementation
                applicable en matière de données à caractère personnel, sous réserve d’en remplir les conditions :
              </p>
              <ol className="s">
                <li>
                  Droit d’accès : l’Utilisateur peut avoir communication de ses données personnelles faisant l’objet d’un traitement par la société
                  MAKE MY WORK ;
                </li>
                <li>
                  Droit de rectification : l’Utilisateur peut mettre à jour ses données personnelles ou faire rectifier ses données personnelles
                  traitées par la société MAKE MY WORK ;
                </li>
                <li>
                  Droit d’opposition : l’Utilisateur peut exprimer son souhait de ne plus recevoir de communication de la part de la société MAKE MY
                  WORK ou demander à ce que ses données personnelles ne fassent plus l’objet d’un traitement ;
                </li>
                <li>Droit à l’effacement : l’Utilisateur peut demander la suppression de ses données personnelles ;</li>
                <li>Droit à la limitation : l’Utilisateur peut demander la suspension du traitement de ses données personnelles ;</li>
                <li>
                  Droit à la portabilité : l’Utilisateur peut demander à la société MAKE MY WORK de récupérer ses données personnelles afin d’en
                  disposer.
                </li>
              </ol>
              <p>
                L’Utilisateur a également la possibilité de donner des instructions relatives à la conservation, à l'effacement et à la communication
                de ses données après son décès.
              </p>
              <p>
                L’Utilisateur peut exercer ses droits en donnant toute information qui permettrait de traiter le plus rapidement possible sa demande,
                en s'adressant :
              </p>
              <ul>
                <li>par courrier électronique à l'adresse suivante :</li>
                <li>par courrier postale à l’adresse suivante : MAKE MY WORK, 8 avenue Manuel, 78600 Maisons-Laffitte.</li>
              </ul>
              <p>par courrier postale à l’adresse suivante : MAKE MY WORK, 8 avenue Manuel, 78600 Maisons-Laffitte.</p>
              <p>
                Si l’Utilisateur l’a préalablement accepté, l’Utilisateur pourra recevoir des emails ou des SMS marketing de la société MAKE MY WORK
                dont il pourra se désinscrire, pour les emails, en cliquant sur le lien de désinscription figurant au bas de chaque e‐mail reçu et
                pour les SMS en cliquant sur STOP dans chaque SMS reçu.
              </p>
              <p>
                En cas de difficulté en lien avec la gestion de ses données à caractère personnel, l’Utilisateur a le droit d'introduire une
                réclamation auprès de la Commission Nationale de l'Informatique et des Libertés (CNIL).
              </p>
              <h3 className="sub-title">1.6 Modification des règles de confidentialité</h3>
              <p>
                La société MAKE MY WORK peut être amenée à modifier occasionnellement les règles de confidentialité mentionnées à l’article 9. Lorsque
                cela est nécessaire, la société MAKE MY WORK en informe l’Utilisateur dans les meilleurs délais.
              </p>
            </div>
            <div className="term-paragh">
              <h2>2. Cession</h2>
              <p>
                La société MAKE MY WORK peut, sous réserve des lois sur la protection des données applicables, transférer et/ou céder l’ensemble des
                droits et obligations qui sont attachés aux présentes CGU, à tous tiers, notamment en cas de cession de branche d’activité, de fusion
                par constitution d’une nouvelle société, de fusion absorption, de scission ou de tout changement de contrôle affectant la société MAKE
                MY WORK à condition que cela ne réduise pas la protection des droits de l’Utilisateur. Un tel transfert/une telle cession libère la
                société MAKE MY WORK de toute obligation en tant qu'opérateur de la Plateforme envers ses Utilisateurs pour l’avenir. En cas de
                transfert et/ou de cession de ces droits et obligations qui sont attachés aux présentes CGU à des tiers, tout Utilisateur pourra
                mettre fin immédiatement à ses relations avec la société MAKE MY WORK et clôturer son Compte Utilisateur. Les Utilisateurs ne peuvent
                transférer ou céder tout ou partie de leurs droits et obligations découlant des présentes Conditions Générales.
              </p>
            </div>
            <div className="term-paragh">
              <h2>3. Droit applicable</h2>
              <p>
                Les présentes CGU sont soumises au droit français, sauf en cas d’application d’une disposition d’ordre public. A défaut d’accord
                amiable, tout litige pouvant résulter de l’application des présentes CGU sera de la compétence des juridictions françaises.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Privacy;
