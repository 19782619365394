const destroyToken = function () {
  localStorage.removeItem("expiredIn");
  localStorage.removeItem("token");
  localStorage.removeItem("avis");

  window.location.replace("/connexion");
};
/**********************************************************************************************************************/
const getToken = function () {
  const token = localStorage.getItem("token");

   const expiration = localStorage.getItem("expiredIn");
  /*if (expiration && Date.now() > expiration) {
      destroyToken()
      return null;
  }*/
  return token;
};
/**********************************************************************************************************************/
const storeToken = function (expiredIn, token) {
  localStorage.setItem("expiredIn", expiredIn);
  localStorage.setItem("token", token);
};
/**********************************************************************************************************************/

export { destroyToken, getToken, storeToken };
