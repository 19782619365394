import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const CreateAnnouncement = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("createAnnoucement", {
        userId: data.userId,
        announcementType: data.announcementType,
        categoryName: data.categoryName,
        categoryType: data.categoryType,
        announcementTitle: data.announcementTitle,
        announcementDescription: data.announcementDescription,
        price: data.price,
        deadLine: data.deadLine,
        arrPic: data.arrPic,
        city: data.city,
        categoryToAdd: data.categoryToAdd,
      })
        .then((response) => {
          dispatch({ type: actionsTypes.SUCCESS_ADD_ANNONCE, payload: true });
          notification.success({

            message: "Annonce",
            description: "Annonce créée",
          });
        })
        .catch((error) => {
          //console.log("error :", error.message);
          notification.error({

            message: "error",
            description: "Tous les champs sont obligatoires",
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const GetAllAnnouncement = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getAllAnnouncementForUser", {
        userId: data.userId,
      })
        .then((response) => {
          dispatch(
            setAnnouncement({
              announcement: response.data.result,
            })
          );
        })
        .catch((error) => {
          //console.log("error :", error.message);
          //NotificationManager.warning(error.message);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllAnnouncementEnCoursForUser = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getAllAnnouncementEnCoursForUser", {
        userId: data.userId,
      })
        .then((response) => {
          if (data.me) {
            dispatch({
              type: actionsTypes.GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_USER,
              payload: response.data.result,
            });
          } else {
            dispatch({
              type: actionsTypes.GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_OTHER_USER,
              payload: response.data.result,
            });
          }
        })
        .catch((error) => {
          //console.log("error :", error.message);
          //NotificationManager.warning(error.message);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const announcementDetail = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.get("announcementDetail/" + `${data}`)
        .then((response) => {
          dispatch({
            type: actionsTypes.ANNOUNCEMENT_DETAIL,
            payload: response.data.result[0],
          });
        })
        .catch((error) => {
          //console.log("error :", error.message);
          //NotificationManager.warning(error.message);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const setAnnouncement = (data) => {
  return {
    type: actionsTypes.GET_ALL_ANNOUNCEMENT,
    payload: data,
  };
};
