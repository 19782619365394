import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import User from "../../components/icons/User";
import IconMail from "../../components/icons/IconMail";
import HiddenEye from "../../components/icons/HiddenEye";
import Eye from "../../components/icons/Eye";
import NavbarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { notification } from "antd";
import { AuthContext } from "../../context/AuthContext";
import { gapi } from "gapi-script";
import { Helmet } from "react-helmet-async";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: false,
      name: "",
      surname: "",
      //birthDate: "",
      //phoneNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptRols: false,
      //scroll: false,
      inputError: "",
      enabled: false,
      //addressLine1: "",
      //addressLine2: null,
      //city: "",
      //region: "",
      //postalCode: "",
      //country: "",
      maxDate: null,
      mailForm: null,
      error: {
        password: "",
        confirmPassword: "",
      },
      passwordType: "password",
      passwordConfrType: "password",
      //addressObj: null,
      //addressPlaces: null,
    };
  }

  componentDidMount() {
    gapi.load("client:auth2", () => {
      gapi.auth2.init({
        clientId:
          "771665287344-3sb26ap7bb2rvjgg7pbsgee5dc47rgsq.apps.googleusercontent.com",
      });
    });
  }

  clickFacebook = () => {
    this.myRef.current.click();
  };

  async authSignUP(e) {
    e.preventDefault();

    this.setState({ loading: true });

    const { name, surname, birthDate, email, password } = this.state;
    if (name == "") {
      this.setState({ inputError: true });
    } else if (surname == "") {
      this.setState({ inputError: true });
    }
    let testFormMail = this.emailValidation();

    if (
      password !== "" &&
      email !== "" &&
      birthDate !== "" &&
      surname !== "" &&
      name !== "" &&
      testFormMail
    ) {
      e.preventDefault();
      const { name, surname, email, password, acceptRols } = this.state;

      if (acceptRols) {
        const data = {
          name: name,
          surname: surname,
          email: email,
          //birthdate: birthDate,
          pwd: password,
          //phoneNumber: phoneNumber,
          //addressLine1: addressLine1,
          //addressLine2: addressLine2,
          //city: city,
          //region: region,
          //postalCode: postalCode,
          //country: country,
        };
        await this.props.Signup(data);
        this.setState({ loading: false });
      } else {
        //NotificationManager.warning("vous devez accepter le rôle","rôle",3000);
        notification.warning({
          message: "Rôles",
          description: "Vous devez accepter les rôles svp",
        });
        this.setState({ loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
    //}
  }
  emailValidation() {
    const regex =
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    if (this.state.email !== "" && regex.test(this.state.email) === false) {
      this.setState({ mailForm: "Form mail non validé!" });
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.validateInput(e);
  };

  responseFacebook = (response) => {
    if (!response.email) {
      notification.error({
        message: "Erreur de création",
        description:
          "cette compte facebook n'est pas autorisé, essayez avec un autre compte!",
      });
      return;
    }
    const data = {
      email: response.email,
      idFacebook: response.id,
      name: response.name,
      surname: "",
      from: "inscription",
    };
    this.props.LoginFacebook(data);
  };

  responseGoogle = (response) => {
    if (response.googleId) {
      const data = {
        email: response.profileObj.email,
        idGoogle: response.googleId,
        name: response.profileObj.name,
        surname: response.profileObj.familyName,
        from: "inscription",
      };
      this.props.LoginGoogle(data);
    }
  };

  handleChange = (e) => {
    this.setState({ acceptRols: e.target.checked });
  };

  validateInput = (e) => {
    let { name, value } = e.target;
    this.setState((prevState) => {
      const s = this.state.error;
      switch (name) {
        case "password":
          if (
            this.state.password &&
            value !== this.state.confirmPassword &&
            this.state.confirmPassword !== ""
          ) {
            this.setState({
              error: {
                confirmPassword:
                  "Le mot de passe et la confirmation du mot de passe ne correspondent pas.",
              },
            });
          } else {
            this.setState({ error: { [name]: "" } });
          }
          break;

        case "confirmPassword":
          if (this.state.confirmPassword && value !== this.state.password) {
            this.setState({
              error: {
                [name]:
                  "Le mot de passe et la confirmation du mot de passe ne correspondent pas.",
              },
            });
          } else {
            this.setState({ error: { [name]: "" } });
          }
          break;

        default:
          break;
      }
    });
  };

  showHidepwd() {
    this.setState({
      passwordType:
        this.state.passwordType === "password" ? "text" : "password",
    });
  }

  showHideconfpwd() {
    this.setState({
      passwordConfrType:
        this.state.passwordConfrType === "password" ? "text" : "password",
    });
  }

  render() {
    const { t } = this.context.t;
    const customStyle = {
      backgroundColor: "white",
      cursor: "pointer",
      width: "45px",
      height: "45px",
      borderRadius: "50px ",
      color: "red",
      fontSize: "40px",
    };
    const {
      name,
      surname,
      //birthDate,
      //phoneNumber,
      email,
      password,
      acceptRols,
      //addressLine1,
      //addressLine2,
      //city,
      //region,
      //postalCode,
      //country,
    } = this.state;

    const i18n = this.context.i18n;
    return (
      <>
        <Helmet>
          <title>Make my work | Inscription</title>
        </Helmet>
        <NavBarNotConnected />
        <NavbarMobile />
        <main id="main">
          <section
            className="padding-top-signup"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="d-flex align-content-center  justify-content-center  mb-1 ">
              {/* <div className="col-6 d-flex justify-content-end  align-items-center px-5">
                <FacebookLogin
                  buttonStyle={{
                    border: "none",
                    background: "#184EAF",
                    borderRadius: "50%",
                    position: "relative",
                    color: "white",
                    fontSize: "5rem",
                    textAlign: "center",
                    width: "57px",
                    height: "57px",
                    textAlign: "center",
                  }}
                  appId="1352722058743100" //APP ID NOT CREATED YET
                  fields="name,email,picture"
                  callback={this.responseFacebook}
                  cssClass="my-facebook-button-class"
                  icon="fa-facebook"
                  textButton={false}
                />
              </div> */}
              <div className="col-6 d-flex justify-content-center align-items-center px-5">
              <GoogleLogin
                //className="rounded-circle"
                //icon="FaGoogle"
                clientId="771665287344-3sb26ap7bb2rvjgg7pbsgee5dc47rgsq.apps.googleusercontent.com"
                buttonText={false}
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogle}
                render={(renderProps) => (
                  <div onClick={renderProps.onClick} style={customStyle}>
                    <span className="ic-google">
                      <strong>G</strong>
                    </span>
                  </div>
                )}
              />
              </div>
            </div>

            <div className="col col-12">
              <div className="d-flex justify-content-center align-items-center ">
                <form
                  onSubmit={(e) => this.authSignUP(e)}
                  id="contact-form"
                  className="w-100 d-flex justify-content-center align-items-center flex-column"
                >
                  <div className="input-cnx w-100">
                    <p className="text-futura-16 text-align-left">
                      {t("lableFirstName")}
                    </p>

                    <div className="all-input">
                      <input
                        type="text"
                        className="w-100 fix-input"
                        name="name"
                        id="user_name"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <i>
                        <User />
                      </i>
                    </div>

                    {this.state.inputError && name === "" ? (
                      <p className="input-msg-error">
                        {t("errRequiredFirstName")}
                      </p>
                    ) : null}
                  </div>

                  <div className="input-cnx w-100">
                    <p className="text-futura-16 text-align-left">
                      {t("lableLastName")}
                    </p>

                    <div className="all-input">
                      <input
                        type="text"
                        className="w-100 fix-input"
                        name="surname"
                        id="user_pname"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <i>
                        <User />
                      </i>
                    </div>

                    {this.state.inputError && surname === "" ? (
                      <p className="input-msg-error">
                        {t("errRequiredLastName")}
                      </p>
                    ) : null}
                  </div>

                  <div className="input-cnx w-100">
                    <p className="text-futura-16 text-align-left">
                      {t("lableEmail")}
                    </p>
                    <div className="all-input">
                      <input
                        style={{ background: "transparent" }}
                        type="email"
                        className="form-mmk w-100 fix-input"
                        name="email"
                        id="user_email"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <i>
                        <IconMail />
                      </i>
                    </div>

                    {this.props.msg !== "" ? (
                      <p className="input-msg-error">{this.props.msg}</p>
                    ) : null}
                    {this.state.mailForm ? (
                      <p className="input-msg-error">{this.state.mailForm}</p>
                    ) : null}

                    {this.state.inputError && this.state.email === "" ? (
                      <p className="input-msg-error">{t("errRequiredEmail")}</p>
                    ) : null}
                  </div>

                  <div className="input-cnx w-100">
                    <p className="text-futura-16 text-align-left">
                      {t("lablePassword")}
                    </p>
                    <div className="all-input">
                      <input
                        type={this.state.passwordType}
                        className="w-100 fix-input"
                        name="password"
                        id="user_pwd"
                        onChange={(e) => this.handleInputChange(e)}
                        onBlur={(e) => this.validateInput(e)}
                      />
                      <i className="pointer" onClick={() => this.showHidepwd()}>
                        {this.state.passwordType === "text" ? (
                          <Eye />
                        ) : (
                          <HiddenEye />
                        )}
                      </i>
                    </div>

                    {this.state.inputError && password === "" ? (
                      <p className="input-msg-error">
                        {t("errRequiredPassword")}
                      </p>
                    ) : null}
                    {this.state.error.password && (
                      <span className="input-msg-error">
                        {this.state.error.password}
                      </span>
                    )}
                  </div>

                  <div className="input-cnx w-100">
                    <p className="text-futura-16 text-align-left">
                      {t("lableConfirmPassword")}
                    </p>
                    <div className="all-input">
                      <input
                        type={this.state.passwordConfrType}
                        className="w-100 fix-input"
                        name="confirmPassword"
                        id="user_pwd_confirm"
                        onChange={(e) => this.handleInputChange(e)}
                        onBlur={(e) => this.validateInput(e)}
                      />
                      <i
                        className="pointer"
                        onClick={() => this.showHideconfpwd()}
                      >
                        {this.state.passwordConfrType === "text" ? (
                          <Eye />
                        ) : (
                          <HiddenEye />
                        )}
                      </i>
                    </div>

                    {this.state.inputError && password === "" ? (
                      <p className="input-msg-error">
                        {t("errRequiredConfirmPassword")}
                      </p>
                    ) : null}
                    {this.state.error.confirmPassword && (
                      <span className="input-msg-error">
                        {this.state.error.confirmPassword}
                      </span>
                    )}
                  </div>
                  <div className="input-cnx">
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        type="checkbox"
                        className="checkbox-mmk"
                        checked={this.state.acceptRols}
                        onChange={this.handleChange}
                      />
                      <span
                        className="condition-black"
                        style={{ marginLeft: "12px" }}
                      >
                        {t("conditionText")}{" "}
                        <Link to="/condition-generale-utilisation">
                          <span className="condition-blue">
                            {t("conditionLink")}
                          </span>
                        </Link>
                      </span>
                    </div>
                  </div>

                  <div className="col-12 text-center mb-5">
                    <button
                      type="submit"
                      id="submit"
                      className={
                        name && surname && email && password && acceptRols
                          ? "btn-mmk"
                          : "btn-mmk-grey-opacity"
                      }
                      disabled={this.state.loading}
                    >
                      {this.state.loading ? (
                        <SpinLoading />
                      ) : (
                        t("buttonRegister")
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    msg: state.AuthReducer.msg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Signup: (data) => dispatch(actionCreator.Signup(data)),
    LoginFacebook: (data) => dispatch(actionCreator.LoginFacebook(data)),
    LoginGoogle: (data) => dispatch(actionCreator.LoginGoogle(data)),
  };
};

SignUp.contextType = AuthContext;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUp));
