import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import Store from "../../components/icons/Store";
import DemandeIcon from "../../components/icons/DemandeIcon";
import { AuthContext } from "../../context/AuthContext";
import { Helmet } from "react-helmet-async";

class TypeAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      type: null,
    };
  }

  AnnouncementType(e) {
    this.props.history.push({
      pathname: "/creer-une-annonce",
      state: this.state.type,
    });
  }

  render() {
    const { type } = this.state;
    const { t } = this.context.t;
    return (
      <>

      <Helmet>
        <title>
        Création d'annonce sur makemywork
        </title>
      </Helmet>
        <NavBarConnected />
        <NavbarMobile />

        <main id="main">
          <section className="height-without-navbar">
            <div className="container w-100 d-flex justify-content-center align-items-center mt-5">
              <div className="d-flex justify-content-center mt-5">
                <div className="col-12">
                  <div className="row  justify-content-center align-items-center mb-5">
                    <p className="text-futura-20">{t("announcementAbout")} :</p>
                  </div>

                  <div className="d-flex flex-row justify-content-center  align-items-center mb-5 bio-down">
                    <div
                      className="d-flex justify-content-center align-items-center px-5"
                      onClick={() => this.setState({ type: "offre" })}
                    >
                      <div className="crt-wrap">
                        <div
                          className={
                            type === "offre"
                              ? "announce-type-selected"
                              : "annouce-type-not-selected"
                          }
                        >
                          <i className="store">
                            <Store />
                          </i>
                        </div>
                        <p className="text-futura-16 mt-2">{t("lableOffer")}</p>
                      </div>
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => this.setState({ type: "demande" })}
                    >
                      <div className="crt-wrap">
                        <div
                          className={
                            type === "demande"
                              ? "announce-type-selected"
                              : "annouce-type-not-selected"
                          }
                        >
                          <i className="demande">
                            <DemandeIcon />
                          </i>
                        </div>
                        <p className="text-futura-16 mt-2">
                          {t("lableRequest")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row gy-4  justify-content-center align-items-center">
                    <div className="col-12 text-center">
                      <button
                        onClick={(e) => this.AnnouncementType(e)}
                        className={type ? "btn-mmk" : "btn-mmk-grey-opacity"}
                        disabled={type ? false : true}
                      >
                        {t("buttonValidate")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

TypeAnnouncement.contextType = AuthContext;

export default withRouter(TypeAnnouncement);
