import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import FullStar from "../../components/icons/FullStar";
import EmptyStar from "../../components/icons/EmptyStar";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/AuthContext";
import * as actionCreator from "../../redux/actions";
import { DatePicker, Modal, Space } from "antd";
import NavbarMobile from "../../components/NavbarMobile";
import { useTranslation } from "react-i18next";

function Activity() {
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  const [activityData, setActivityData] = useState(null);
  const [chiffreDaffaireNet, setChiffreDaffaireNet] = useState(0);
  const [revenuTotale, setRevenuTotale] = useState(0);
  const [serviceRendu, setServiceRendu] = useState(0);
  const [openModalForAvis, setOpenModalForAvis] = useState(false);

  const userActivity = useSelector(
    (state) => state.ProfileReducer.userActivity
  );
  const avgStarRating = useSelector(
    (state) => state.ProfileReducer.avgStarRating
  );
  const userAvis = useSelector((state) => state.ProfileReducer.userAvis);

  useEffect(() => {
    if (user) {
      const data = {
        userId: user.id,
        me: true,
      };
      dispatch(actionCreator.getAvisUser(data));
    }
  }, [user]);

  useEffect(() => {
    let data = [];
    let sumChiffreDaffaireNet = 0;
    let sumRevenuTotale = 0;
    let sumServiceRendu = 0;
    if (userActivity) {
      userActivity.map((value) => {
        data.push(value.chiffreDaffaireNetMensuelle);
        sumChiffreDaffaireNet += parseFloat(value.chiffreDaffaireNetMensuelle);
        sumRevenuTotale += parseFloat(value.revenuTotaleNetMensuelle);
        sumServiceRendu += parseInt(value.totaleServiceRenduMensuelle);
      });
      setChiffreDaffaireNet(sumChiffreDaffaireNet);
      setRevenuTotale(sumRevenuTotale);
      setServiceRendu(sumServiceRendu);
      setActivityData(data);
    }
  }, [userActivity]);

  const labels = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const getData = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient = ctx.createLinearGradient(1300, 0, 300, 0);
    gradient.addColorStop(0, "rgba(50, 211, 136,0.2)");
    gradient.addColorStop(0.5, "rgba(203, 144, 255,0.5)");
    gradient.addColorStop(1, "rgba(250, 60, 186,0.2)");

    return {
      labels: labels,
      datasets: [
        {
          fill: true,
          label: "Revenus",
          data: activityData ? activityData : null,
          backgroundColor: gradient,
          borderColor: gradient,
        },
      ],
    };
  };
  const canvas = document.createElement("canvas");
  const chartData = getData(canvas);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const options = {
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: false,
      },
      title: {
        display: false,
      },
    },
  };
  const getActivity = (date, dateString) => {
    if (user && dateString) {
      const data = {
        year: dateString,
        userId: user.id,
      };
      dispatch(actionCreator.getUserActivity(data));
    }
  };

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <>
      <NavBarConnected />
      <NavbarMobile />
      <section data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div className=" d-flex justify-content-center align-items-center">
            <NavLink
              to="/profil"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyProfile")}
            </NavLink>

            <NavLink
              to="/mon-activite"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyActivity")}
            </NavLink>

            <NavLink
              to="/mes-annonces"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyAnnouncements")}
            </NavLink>

            <NavLink
              to="/token-mmw"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navToken")}
            </NavLink>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center w-100">
            {avgStarRating ? (
              <div className="activity-card mb-3">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="d-flex flex-row justify-content-center align-items-center pointer gap-2"
                    onClick={() => setOpenModalForAvis(true)}
                  >
                    <p className="text-futura-16 mt-2">
                      {avgStarRating
                        ? parseFloat(avgStarRating).toFixed(2)
                        : null}
                    </p>
                    <FullStar />
                  </div>
                  <p className="text-futura-14">{t("seeRateOnservice")}</p>
                </div>
              </div>
            ) : null}

            <div className="d-flex justify-content-between align-items-center w-100 mb-5">
              <p className="text-futura-20">{t("incomeThisYear")}</p>
              <div className="d-flex flex-column justify-content-start align-items-start">
                <p className="text-futura-16">{t("chooseYear")}</p>

                <Space direction="vertical">
                  <DatePicker
                    onChange={(date, dateString) =>
                      getActivity(date, dateString)
                    }
                    picker="year"
                    className="select-year"
                    placeholder=""
                  />
                </Space>
              </div>
            </div>
            <div style={{ width: "100%", height: 300 }}>
              <Line options={options} data={chartData} />
            </div>

            <div className="d-flex flex-column w-100 mt-5">
              <p className="text-futura-14 text-align-left">
                {t("numbersYear")} :{" "}
                {chiffreDaffaireNet
                  ? chiffreDaffaireNet.toFixed(2)
                  : chiffreDaffaireNet.toFixed(2)}{" "}
                €
              </p>
              <p className="text-futura-14 text-align-left my-2">
                {t("totalRevenueGenerated")} :{" "}
                {revenuTotale
                  ? revenuTotale.toFixed(2)
                  : revenuTotale.toFixed(2)}{" "}
                €
              </p>
              <p className="text-futura-14 text-align-left mb-5">
                {t("totalServicesRendered")} :{" "}
                {serviceRendu ? serviceRendu : serviceRendu} {t("services")}
              </p>
            </div>
          </div>
        </div>
        <Modal
          open={openModalForAvis}
          width={"62rem"}
          onCancel={() => setOpenModalForAvis(false)}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("Notice")}</p>

            <div className="content w-100">
              <div className="blue-scroll d-flex flex-column align-items-center height-modal-avis">
                {userAvis && userAvis.length > 0 ? (
                  userAvis.map((announce, key) => (
                    <div className="card-avis mb-4" key={key}>
                      <div className="d-flex w-100">
                        {announce.user
                          ?.profilePicture ? (
                          <img
                            src={
                              announce.user
                                .profilePicture
                            }
                            className="img-card-avis"
                          />
                        ) : null}
                        <div className="d-flex flex-column justify-content-start align-items-start px-3 width-78">
                          <p className="text-futura-11-grey">
                            {announce.user?.surname}
                          </p>
                          <div className="d-flex justify-content-end algin-items-center w-100">
                            {generateTempArray(
                              announce.rating
                            ).map((item) => (
                              <FullStar width={22} height={21} />
                            ))}
                            {generateTempArray(
                              5 - announce.rating
                            ).map((item) => (
                              <EmptyStar width={20} height={20} />
                            ))}
                          </div>
                          <p className="text-futura-14 overflow-hidden ellipsis-text-card-avis">
                            {announce.commentaire}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="no-items">{t("noItems")}</span>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Activity;
