import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Url from "../../constants/Url";
import { AuthContext } from "../../context/AuthContext";
import NavBarConnected from "../../components/NavBarConnected";
import SpinLoading from "../../components/SpinLoading";
import { notification } from "antd";
import { Helmet } from "react-helmet-async";

class ChoicePseudo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pseudo: "",
      //scroll:false,
      msgError: "",
    };
  }

  async addPseudo(e) {
    this.setState({ loading: true });
    e.preventDefault();
    await Url.Url1.post("addPseudo", {
      userId: this.context.user.id,
      pseudo: this.state.pseudo,
    })
      .then((response) => {
        //const data = response.data
        if (this.context.user.hasSkills === false) {
          this.props.history.push({
            pathname: "/dis-nous-en-plus",

          });
        } else if (this.context.user.isCertified === "0") {
          this.props.history.push({
            pathname: "/verifie-ton-profil",

          });
        } else
          this.props.history.push({
            pathname: "/accueil",

          });
        this.setState({ loading: false });
        //NotificationManager.success("Pseudo ajouté avec succes", "Pseudo");
        notification.success({

          message: "Pseudonyme",
          description: "Pseudonyme ajouté avec succès",
        });
      })
      .catch((error) => {
        if (error.message === "Request failed with status code 404") {
          this.setState({ msgError: "Pseudo déjà utilisé" });
          this.setState({ loading: false });
        } else {
          //NotificationManager.error("error :", error.message)
          notification.error({

            message: "error",
            description: error.message,
          });
          this.setState({ loading: false });
        }
      });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { t } = this.context.t;
    return (
      <>
       <Helmet>
        <title>Make My Work | Choisir pseudo</title>
      </Helmet>
        <NavBarConnected />

        <main id="main">
          <section
            className="d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-10 justify-content-center align-items-center">
                  <div className="row ">
                    <div className="col-12 img-choice-pseudo d-flex justify-content-center align-items-center">
                      <img
                        className="mb-5 image-pseudo"
                        src={require('../../assets/img/pseudo/pseudo.png')}
                        alt="..."
                      />
                    </div>

                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <p className="text-futura-16 text-align-left m-0">
                        {t("lablePseudo")}
                      </p>
                      <div className="all-input w-100 input-max-width">
                        <input
                          type="text"
                          name="pseudo"
                          className="fix-input"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-3">
                        {this.state.msgError == "Pseudo déjà utilisé" ? (
                          <p className="input-msg-error">
                            {this.state.msgError}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="row gy-4  justify-content-center align-items-center">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className={
                            this.state.pseudo
                              ? "btn-mmk mb-5"
                              : "btn-mmk-grey-opacity mb-5"
                          }
                          onClick={(e) => this.addPseudo(e)}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <SpinLoading />
                          ) : (
                            t("buttonValidate")
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

/*************************************************************************************************************************************/
const mapStateToProps = (state) => {
  return {
    userInfo: state.AuthReducer.userInfo,
    email: state.AuthReducer.email,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {};
};
/*************************************************************************************************************************************/
ChoicePseudo.contextType = AuthContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChoicePseudo));
