import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function MobileApp() {
    const { t } = useTranslation();
  return (
    <>
        <div className='mobileApp-background'>
            <div className='mobileApp-container'>
                <img src={`${require("../../img/logo-mobile.png")}`} alt="logo" className='mobile-app-logo' />
                <h2 className='mt-5'>Installer notre application :</h2>
                <div className="d-flex flex-column gap-3">
                    <button onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.trimakus.mmw", '_blank', 'noopener,noreferrer')}>
                        Play Store
                    </button>

                    <button onClick={()=>window.open("https://apps.apple.com/tn/app/makemywork/id1628763862", '_blank', 'noopener,noreferrer')}>
                        Apple Store
                    </button>
                </div>

                <div className="mt-5">
                  <ul>
                    <li>
                      <Link to="/politique-de-confidentialite">
                        <span className="text-futura-14 text-align-left">{t("privacyPolicy")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/condition-generale-utilisation">
                        <span className="text-futura-14 text-align-left">
                          {t("generalConditions")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </>
  )
}

export default MobileApp