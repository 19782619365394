import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  Announcements: [],
  inputSearch: "",
  profilRecommended: [],
  categoryType: "service",
};

const SearchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SEARCH_ANNOUNCEMENT:
      return {
        ...state,
        Announcements: action.payload,
      };

    case actionType.ADVANCED_SEARCH:
      return {
        ...state,
        Announcements: action.payload,
      };
    case actionType.GET_ANNOUNCE_BY_CATEGORY:
      return {
        ...state,
        Announcements: action.payload,
      };

    case actionType.PROFIL_RECOMMENDED:
      return {
        ...state,
        profilRecommended: action.payload,
      };

    case actionType.HANDLE_INPUT_SEARCH:
      return {
        ...state,
        inputSearch: action.payload,
      };

    case actionType.HANDLE_CATEGORY_TYPE:
      return {
        ...state,
        categoryType: action.payload,
      };

    default:
      return { ...state };
  }
};
export default SearchReducer;
