import React from 'react'

function MangeAnnounceCard(props) {
  return (
    <div
    key={props.props.key}
    className="d-flex justify-content-center align-items-center"
  >
    <div
      onClick={() => 
      props.onClick(props.props.order)}
      className="crt-wrap annonce pointer"
    >
      <div className="d-flex justify-content-between align-items-center">
        <span className="text-futura-14 announce-title-over-flow">
          {props.props.order.announcement.announcementTitle}
        </span>
        <div className="d-flex align-items-center">
          <span className="text-futura-11-grey pe-5 ps-2 address-over-flow">
            {props.props.order.announcement.city}
          </span>
          <span className="text-futura-14">
            {!props.props.order.Propositions[0].prixPropose
              ? props.props.order.announcement.price
              : props.props.order.Propositions[0].prixPropose}
            $
          </span>
        </div>
      </div>

      <p className="text-futura-14 text-align-left overflow-hidden mt-2 ellipsis-text">
        {props.props.order.announcement.announcementDescription}
      </p>
      <p className="text-futura-14 text-align-right">
        {props.props.order.statusPaiement === "1"
          ? "Prépayé"
          : props.props.order.statusPaiement === "2"
          ? "Payé"
          : "Non payé"}
      </p>
    </div>
  </div>
  )
}

export default MangeAnnounceCard