/**
 * App Reducers
 */
import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import SkillsReducer from "./SkillsReducer";
import AnnouncementReducer from "./AnnouncementReducer";
import ProfileReducer from "./ProfileReducer";
import HomeReducer from "./HomeReducer";
import SearchReducer from "./SearchReducer";
import CandidacyReducer from "./CandidacyReducer";
import NewsReducer from "./NewsReducer";
import NotificationReducer from "./NotificationReducer";

const reducers = combineReducers({
  AuthReducer: AuthReducer,
  SkillsReducer: SkillsReducer,
  AnnouncementReducer: AnnouncementReducer,
  ProfileReducer: ProfileReducer,
  HomeReducer: HomeReducer,
  SearchReducer: SearchReducer,
  CandidacyReducer: CandidacyReducer,
  NewsReducer: NewsReducer,
  NotificationReducer: NotificationReducer,
});

export default reducers;
