import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import Url from "../../constants/Url";
import * as actionCreator from "../../redux/actions/index";
import { AuthContext } from "../../context/AuthContext";
import NavBarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formatDate";

function Dissertation() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [step, setStep] = useState(1);
  const [dateDebService, setDateDebService] = useState(null);
  const [advancementPercentage, setAdvancementPercentage] = useState(null);
  const [loadingDateDebService, setLoadingDateDebService] = useState(false);
  const [loadingAdvancement, setLoadingAdvancement] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);

  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalReportDone, setOpenModalReportDone] = useState(false);
  const [filesNames, setFilesNames] = useState(null);
  const [files, setFiles] = useState(null);
  const [comment, setComment] = useState(null);
  const [motif, setMotif] = useState(null);
  const [inputRequired, setInputRequired] = useState(null);

  const myRef = useRef(null);

  const percentage = useSelector((state) => state.CandidacyReducer.percentage);
  const condidature = useSelector(
    (state) => state.CandidacyReducer.condidature
  );
  const motifs = useSelector((state) => state.CandidacyReducer.motifs);

  useEffect(() => {
    if (percentage === 0) {
      dispatch(actionCreator.getPourcentageMakeMyWork());
    }
    if (user) {dispatch(actionCreator.getDetailsCandidature(location.state.id));}

    dispatch(actionCreator.getMotif(location.state.id));
  }, [location.state, user]);

  useEffect(() => {
    if (condidature) {
      if (parseInt(condidature.statusPaiement) === 0) {
        setStep(1);
      }
      if (parseInt(condidature.statusPaiement) !== 0) {
        setStep(2);
      }
      if (condidature.dateDebService) {
        setStep(3);
      }
      if (parseInt(condidature.avancementService) === 100) {
        setStep(4);
      }
      if (condidature.validationClient) setStep(5);
    }
  }, [condidature]);

  const mentionServiceStartDate = async () => {
    setLoadingDateDebService(true);
    await Url.Url4.post("mentionServiceStartDate", {
      candidatureId: condidature.id,
      dateDebService: dateDebService,
      playerId: "",
    })
      .then((response) => {
        setLoadingDateDebService(false);
        dispatch(actionCreator.getDetailsCandidature(location.state.id));
        setStep(3);
        //NotificationManager.success("date fixeé!");
        notification.success({

          message: "Date de début",
          description: "Date de début fixeé",
        });
      })
      .catch((error) => {
        setLoadingDateDebService(false);
        //NotificationManager.error(error.message);
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  };

  const mentionServiceAdvancement = async (passed) => {
    setLoadingAdvancement(true);

    let url = "mentionServiceAdvancement";
    if (condidature.announcement.announcementType === "offre") {
      url = "mentionServiceAdvancementToOffre";
    }

    let avanPrece = advancementPercentage;
    if (passed) avanPrece = "100";

    await Url.Url4.post(url, {
      candidatureId: condidature.id,
      avancementService: avanPrece,
      playerId: "",
    })
      .then((response) => {
        setLoadingAdvancement(false);
        dispatch(actionCreator.getDetailsCandidature(location.state.id));
        if (parseInt(advancementPercentage) === 100) {
          setStep(4);
        }

        notification.success({

          message: "Avancement de service",
          description: "Avancement de service envoyée",
        });
      })
      .catch((error) => {
        setLoadingAdvancement(false);
        //NotificationManager.error(error.message);
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  };

  const handleFileInputChange = (e) => {
    setFilesNames([]);
    let arrPic = [];
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      getBase64(e.target.files[0])
        .then((result) => {
          file[i] = result;
          arrPic.push(result);
          setFilesNames((filesNames) => [...filesNames, file.name]);
          setFiles(arrPic);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  const createConflict = async () => {
    if (!motif && !comment) {
      setInputRequired("Tous les champs sont obligatoires *");
      setTimeout(() => {
        setInputRequired(null);
      }, 5000);
    } else {
      setLoadingReport(true);
      await Url.Url5.post("createConflict", {
        candidatureId: condidature.id, //condidatireId
        conflictReasonId: motif,
        problemExplication: comment,
        arrDoc: files,
      })
        .then((response) => {
          setLoadingReport(false);
          if (response.status === 201) {
            setOpenModalReport(false);
            setOpenModalReportDone(true);
            setComment(null);
            setFiles(null);
            setFilesNames(null);
            setMotif(null);
            //NotificationManager.success("Litige envoyé");
            notification.success({

              message: "Litige",
              description: "Litige envoyée",
            });
            dispatch(actionCreator.getDetailsCandidature(location.state.id));
          } else {
            //NotificationManager.error(response.data.message);
            notification.error({

              message: "error",
              description: response.data.message,
            });
          }
        })
        .catch((error) => {
          setLoadingReport(false);
          //NotificationManager.error(error.message);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    }
  };

  const goToMessages = () => {
    let otherUserId = condidature.userId;

    if (user.id === condidature.userId)
      otherUserId = condidature.announcement.userId;

    history.push({
      pathname: "/messagerie",
      state: otherUserId,
    });
  };

  const clickButton = (e) => {
    myRef.current.click();
  };
  return (
    <>
      <NavBarConnected />
      <NavBarMobile />

      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center mt-5 p-5 ">
          <p className="text-futura-20">
            {condidature && condidature
              ? condidature.announcement.announcementTitle
              : null}
          </p>
          <p className="text-futura-14">{user && user.name}</p>
        </div>
        {condidature && condidature ? (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className={
                step !== 1 && condidature.statusPaiement === "0"
                  ? "dissertation-cart-opacity step-max-width"
                  : "dissertation-cart step-max-width"
              }
            >
              {condidature.statusPaiement === "0" ? (
                <div className="step">1</div>
              ) : (
                <div className="step-finshed">
                  <i class="bi bi-check"></i>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  {condidature.statusPaiement === "0" ? (
                    <p>{t("waitingPayment")}</p>
                  ) : (
                    <p>{t("paymentDone")}</p>
                  )}

                  <p>
                    {!condidature.proposition
                      ? condidature.announcement.price
                      : condidature.proposition.prixPropose}
                    €
                  </p>
                </div>
                <div className=" d-flex justify-content-center align-items-center">
                  <p>
                    {t("lableTotal")} : &nbsp;
                    {!condidature.proposition
                      ? parseFloat(condidature.announcement.price) +
                        parseFloat(
                          condidature.announcement.price * (percentage / 100)
                        )
                      : parseFloat(condidature.proposition.prixPropose) +
                        parseFloat(
                          condidature.proposition.prixPropose *
                            (percentage / 100)
                        )}
                    €
                  </p>
                </div>
              </div>
            </div>

            {!condidature.statusPaiement === "0" &&
            step === 1 &&
            ((condidature.announcement.announcementType === "offre" &&
              condidature.announcement.userId === user.id) ||
              (condidature.announcement.announcementType === "demande" &&
                condidature.announcement.userId !== user.id)) ? (
              <div className="d-flex flex-column justify-content-center dissertation w-50">
                <p className="text-futura-20 text-align-left">
                  {t("providerWatingPayment")}
                </p>
                <p className="text-futura-16 text-align-left mt-3">
                  {t("lableNoWorries")}
                </p>
                <div className="d-flex"></div>
                <button className="btn-mmk" onClick={() => goToMessages()}>
                  {t("btnGoToConversation")}
                </button>
                <div className="d-flex justify-content-center my-5">
                  <button
                    className={
                      dateDebService ? "btn-mmk" : "btn-mmk-grey-opacity"
                    }
                    onClick={() => mentionServiceStartDate()}
                    disabled={loadingDateDebService}
                  >
                    {loadingDateDebService ? <SpinLoading /> : t("btnSave")}
                  </button>
                </div>
              </div>
            ) : null}

            <div
              className={
                step !== 2 && !condidature.dateDebService
                  ? "dissertation-cart-opacity step-max-width"
                  : "dissertation-cart step-max-width"
              }
            >
              {!condidature.dateDebService ? (
                <div className="step">2</div>
              ) : (
                <div className="step-finshed">
                  <i class="bi bi-check"></i>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  {condidature.dateDebService === null ? (
                    <p>{t("waitingServiceSupport")}</p>
                  ) : (
                    <p>{t("serviceSupport")}</p>
                  )}

                  <p>
                    {condidature.dateDebService === null
                      ? "Date de début de service"
                      : "Date de début le " + formatDate(condidature.dateDebService) }
                  </p>
                </div>
              </div>
            </div>

            {!condidature.dateDebService &&
            step === 2 &&
            ((condidature.announcement.announcementType === "offre" &&
              condidature.announcement.userId === user.id) ||
              (condidature.announcement.announcementType === "demande" &&
                condidature.announcement.userId !== user.id)) ? (
              <div className="d-flex flex-column justify-content-center dissertation w-50">
                <p className="text-futura-20 text-align-left">
                  {t("whenStartService")} :
                </p>
                <p className="text-futura-16 text-align-left mt-3">
                  {t("startingDate")}
                </p>
                <input
                  type="date"
                  min={new Date()}
                  name="birthDate"
                  className="form-date"
                  id="user_date"
                  onChange={(e) => setDateDebService(e.target.value)}
                />
                <div className="d-flex justify-content-center my-5">
                  <button
                    className={
                      dateDebService ? "btn-mmk" : "btn-mmk-grey-opacity"
                    }
                    onClick={() => mentionServiceStartDate()}
                    disabled={loadingDateDebService}
                  >
                    {loadingDateDebService ? <SpinLoading /> : t("btnSave")}
                  </button>
                </div>
              </div>
            ) : null}

            <div
              className={
                step !== 3 &&
                (parseInt(condidature.avancementService) < 100 ||
                  !parseInt(condidature.avancementService))
                  ? "dissertation-cart-opacity step-max-width"
                  : "dissertation-cart step-max-width"
              }
            >
              {parseInt(condidature.avancementService) < 100 ||
              !parseInt(condidature.avancementService) ? (
                <div className="step">3</div>
              ) : (
                <div className="step-finshed">
                  <i class="bi bi-check"></i>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  {parseInt(condidature.avancementService) < 100 ||
                  !parseInt(condidature.avancementService) ? (
                    <p>{t("serviceProgress")}</p>
                  ) : (
                    <p>{t("deadlineApproaching")} !</p>
                  )}

                  <p>
                    {condidature.avancementService === null
                      ? "Estimation en poucentage"
                      : "Avancement du service estimé à " +
                        condidature.avancementService +
                        "%"}
                  </p>
                </div>
              </div>
            </div>

            {(parseInt(condidature.avancementService) < 100 ||
              !parseInt(condidature.avancementService)) &&
            step === 3 &&
            ((condidature.announcement.announcementType === "offre" &&
              condidature.announcement.userId === user.id) ||
              (condidature.announcement.announcementType === "demande" &&
                condidature.announcement.userId !== user.id)) ? (
              <div className="d-flex flex-column w-50 dissertation">
                <p className="text-futura-14 text-align-left">
                  {t("reassureApplicant")}
                </p>

                <p className="text-futura-16 text-align-left mt-3">
                  {t("serviceProgress")}
                </p>
                <input
                  type="number"
                  className="form-date"
                  onChange={(e) => setAdvancementPercentage(e.target.value)}
                  min={1}
                  max={100}
                />
                <div className="d-flex justify-content-center my-5 px-5 gap-5 bio-down">
                  <button
                    className={
                      advancementPercentage <= 100 && advancementPercentage >= 1
                        ? "btn-mmk"
                        : "btn-mmk-grey-opacity"
                    }
                    onClick={() => mentionServiceAdvancement(false)}
                    disabled={loadingAdvancement}
                  >
                    {loadingAdvancement ? <SpinLoading /> : t("btnSave")}
                  </button>

                  <button
                    className="btn-mmk-white"
                    onClick={() => mentionServiceAdvancement(true)}
                  >
                    {loadingAdvancement ? <SpinLoading /> : t("btnSkipStep")}
                  </button>
                </div>
              </div>
            ) : null}

            <div
              className={
                step !== 4 && parseInt(condidature.avancementService) !== 100
                  ? "dissertation-cart-opacity step-max-width"
                  : "dissertation-cart step-max-width"
              }
            >
              {parseInt(condidature.avancementService) !== 100 ? (
                <div className="step">4</div>
              ) : (
                <div className="step-finshed">
                  <i class="bi bi-check"></i>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  {step !== 4 ? (
                    <p>{t("serviceDelivery")}</p>
                  ) : (
                    <p>{t("serviceDelivered")} !</p>
                  )}

                  <p>
                    {condidature.dateFinService
                      ? "Livré le " + formatDate(condidature.dateFinService)
                      : "Date de fin"}
                  </p>
                </div>
              </div>
            </div>

            {parseInt(condidature.avancementService) === 100 &&
            step === 4 &&
            ((condidature.announcement.announcementType === "offre" &&
              condidature.announcement.userId === user.id) ||
              (condidature.announcement.announcementType === "demande" &&
                condidature.announcement.userId !== user.id)) ? (
              <div className="d-flex flex-column justify-content-center dissertation w-50">
                <p className="text-futura-20 text-align-left">
                  {t("areYouDone")} ?
                </p>
                <p className="text-futura-14 text-align-left">
                  {t("whenYouDone")}
                </p>
                <div className="d-flex justify-content-center my-5 px-5 gap-5 bio-down">
                  <button
                    className="btn-mmk"
                    onClick={() => {
                      setStep(5);
                      goToMessages();
                    }}
                  >
                    {t("btnGoToConversation")}
                  </button>
                </div>
              </div>
            ) : null}

            <div
              className={
                step !== 5 && !condidature.validationClient
                  ? "dissertation-cart-opacity step-max-width"
                  : "dissertation-cart step-max-width"
              }
            >
              {!condidature.validationClient ? (
                <div className="step">5</div>
              ) : (
                <div className="step-finshed">
                  <i class="bi bi-check"></i>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  <p>{t("clientValidation")}</p>
                  <p>
                    {condidature.validationClient ? "Terminé" : "En attente"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {condidature &&
        condidature.statusPaiement !== "0" &&
        condidature.statusPaiement &&
        !condidature.statusConflict ? (
          <div className="d-flex justify-content-center align-items-center p-5">
            <span
              className="text-futura-14 text-underline pointer"
              on
              onClick={() => setOpenModalReport(true)}
            >
              {t("btnProblemWithClient")}
            </span>
          </div>
        ) : null}

        <Modal
          open={openModalReport}
          onCancel={() => setOpenModalReport(false)}
          footer={false}
          width={"63rem"}
        >
          <div className="row gy-4 justify-content-center align-items-star">
            <div className="d-flex align-items-center justify-content-center">
              <p className="text-futura-30">{t("Dispute")}</p>
            </div>
            <p className="text-futura-16 my-4">
              {condidature ? condidature.name : null}
            </p>
            <p className="text-futura-14" style={{ maxWidth: "350px" }}>
              {t("disputeRequest")}
            </p>

            <div className=" d-flex flex-column justify-content-center align-items-center w-100 input-max-width">
              {inputRequired ? (
                <p className="input-msg-error mb-3">{inputRequired}</p>
              ) : null}

              <p className="text-futura-16 text-align-left w-100">
                {t("disputeReasons")}
              </p>
              <select
                className="form-creation mb-4"
                aria-label="Default select example"
                onChange={(e) => setMotif(e.target.value)}
              >
                <option selected disabled={true}>
                  {t("ChooseRepportType")}
                </option>
                {motifs &&
                  motifs.map((motif, key) => (
                    <option key={key} value={motif.id}>
                      {motif.reason}
                    </option>
                  ))}
                <option
                  value={"Le demandeur ne valide pas la fin de ma prestation"}
                >
                  {t("disputeDefaultOption")}
                </option>
              </select>
              <div className="col-12">
                <p className="text-futura-16 text-align-left">
                  {t("lableAddMultiplFiles")}
                </p>
                <span
                  className="form-input-file width-100"
                  onClick={(e) => clickButton(e)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <span className="w-100 pl-2">
                      {filesNames &&
                        filesNames.map((name, key) => (
                          <p className="text-futura-14 text-align-left m-0">
                            {name}
                          </p>
                        ))}
                    </span>
                    {filesNames && filesNames.length > 0 ? (
                      <span
                        className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                        style={{ paddingRight: "15px" }}
                      >
                        | {t("btnEDIT")}
                      </span>
                    ) : (
                      <span
                        className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                        style={{ paddingRight: "15px" }}
                      >
                        | {t("btnBROWSE")}
                      </span>
                    )}
                  </div>
                </span>
                <input
                  ref={myRef}
                  className="form-creation fix-input text-futura-16 text-align-left"
                  type="file"
                  id="formFile"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileInputChange(e)}
                  multiple
                />
              </div>

              <div className="d-flex justify-content-start w-100 mt-4">
                <p className="text-futura-16 text-align-left">
                  {t("lableComment")}
                </p>
              </div>

              <textarea
                className="input-textArea"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>

            <div className="d-flex align-items-center justify-content-center  mt-3 px-3">
              <button
                onClick={() => createConflict()}
                className="btn-mmk my-5"
                disabled={loadingReport}
              >
                {loadingReport ? <SpinLoading /> : t("btnSent")}
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalReportDone}
          onCancel={() => setOpenModalReportDone(false)}
          footer={false}
          width={625}
        >
          <div className="d-flex justify-content-center align-items-center  w-100">
            <div className="d-flex flex-column container-cart-payment-succed">
              <p className="text-futura-30 mt-2 mb-4">{t("DisputeSent")}</p>

              <div className="d-flex justify-content-center align-items-center w-100">
                <img src={require('../../assets/img/litige.png')} />
              </div>

              <div className="d-flex flex-column  justify-content-center align-items-center w-100">
                <p className="text-futura-20 py-5">{t("requestDisputeSent")}</p>
              </div>

              <p className="text-futura-14 mb-5">
                {t("requestDisputeSentDesc")}
              </p>
              <div className="d-flex justify-content-center align-items-center"></div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Dissertation;
