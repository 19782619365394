import React, { Component } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import { AuthContext } from "../../context/AuthContext";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import { Modal, notification } from "antd";
import { withRouter } from "react-router-dom";
import Localisation from "../../components/icons/Localisation";
import SpinLoading from "../../components/SpinLoading";
import axios from "axios";
import Url from "../../constants/Url";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Helmet } from "react-helmet-async";

import Compressor from "compressorjs";

class CreateAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addCatergorie: false,
      skills: [],
      categoryName: "",
      categoryType: "service",
      announcementTitle: "",
      announcementDescription: "",
      price: 0,
      deadLine: null,
      arrPic: [],
      categoryToAdd: null,
      city: null,
      file: [],
      openModal: false,
      filesNames: [],
      detectNudity: [],
      openModalBankAccount: false,
      openModalWaitingForVerification: false,
      openModalFileNotSend: false,
      user: null,
      addressObj: null,
      addressPlaces: null,
    };
    this.myRef = React.createRef();
  }

  clickButton = (e) => {
    this.myRef.current.click();
  };

  async componentDidMount() {
    this.getCategories();
  }

  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.long_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });


    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  async componentDidUpdate(prevState, prevProps) {
    if (prevState.addressObj != this.state.addressObj) {
      const { addressObj } = this.state;
      const geocodeObj =
        addressObj &&
        addressObj.value &&
        (await geocodeByPlaceId(addressObj.value.place_id));
      const addressObject =
        geocodeObj && this.getAddressObject(geocodeObj[0].address_components);
      this.setState({
        city:
          addressObject.address +
          " " +
          addressObject.city +
          " " +
          addressObject.province +
          ", " +
          addressObject.postal_code +
          " " +
          addressObject.country,
      });
    }
    if (prevState.skills.length != this.state.skills.length) {
      this.getCategories();
      this.setState({ skills: this.props.skills });
    }
    if (!this.state.user) {
      this.setState({ user: this.context.user }, async () => {
        if (this.state.user.id && this.props.location.state === "offre") {
          await this.props.testIfUserHaveBankAccount(this.state.user.id);
          if (this.props.userBankAccountInfo) {
            if (
              this.props.userBankAccountInfo.isCertified !== "2" &&
              this.props.userBankAccountInfo.isCertified !== "4"
            ) {
              this.setState({ openModalFileNotSend: true });
            } else if (
              this.props.userBankAccountInfo.userHaveBankAccount !== true
            ) {
              this.setState({ openModalBankAccount: true });
            } else if (
              this.props.userBankAccountInfo.fileMangoPayUploaded !== true
            ) {
              this.setState({ openModalWaitingForVerification: true });
            }
          }
        }
      });
    }
  }

  getCategories() {
    this.props.getAllSkills();
  }

  async CreateAnnouncement(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const {
      categoryName,
      categoryType,
      announcementTitle,
      announcementDescription,
      price,
      deadLine,
      arrPic,
      categoryToAdd,
      city,
    } = this.state;

    let detecter = await this.FnDetectNudity();
    let detecterText = await this.FnDetectText();

    if (!detecter && !detecterText) {
      const data = {
        userId: this.context.user.id,
        announcementType: this.props.location.state,
        categoryName: categoryName,
        categoryType: categoryType,
        announcementTitle: announcementTitle,
        announcementDescription: announcementDescription,
        price: price,
        deadLine: deadLine,
        arrPic: arrPic,
        city: city,
        categoryToAdd: categoryToAdd,
      };
      await this.props.CreateAnnouncement(data);
      this.setState({ loading: false });

      if (this.props.success) {
        //this.props.history.push({pathname:"/annonce-publier"})
        this.setState({ openModal: true, loading: false });
      }
    } else {
      this.setState({ loading: false });
      notification.error({
        
        message: "Immoralité",
        description: "Attention !",
      });
    }
  }

  FnDetectText = async () => {
    let isNudity = false;
    await Url.Url2.post("moedarationTextImage", {
      announcementTitle: this.state.announcementTitle,
      announcementDescription: this.state.announcementDescription,
    })
      .then((response) => {
        if (response.data.message == "failed!") {
          isNudity = true;
        }
      })
      .catch((error) => {
        notification.error({
          
          message: "error",
          description: error.message,
        });
      });
    return isNudity;
  };

  FnDetectNudity = async () => {
    const { detectNudity } = this.state;

    let isNudes = false;
    let i = 0;
    while (!isNudes && i < detectNudity.length) {
      let data = new FormData();
      data.append("media", detectNudity[i]);
      data.append("models", "nudity,gore");
      data.append("api_user", "27749884");
      data.append("api_secret", "zXkiuCNjDkpEQDyJBH89");

      await axios({
        method: "post",
        url: "https://api.sightengine.com/1.0/check.json",
        data: data,
      })
        .then(function (response) {
          if (
            response.data.nudity.safe <= 0.3 ||
            response.data.gore.prob >= 0.5
          ) {
            isNudes = true;
          }
        })
        .catch(function (error) {
          if (error.response) console.log(error.response.data);
          else console.log(error.message);
        });
      i++;
    }
    return isNudes;
  };

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFileInputChange = (e) => {
    this.setState({ filesNames: [] });
    let arrPic = [];
    this.setState({ detectNudity: e.target.files });
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];
      new Compressor(file, {
        quality: 0.6,
        success: (compressedResult) => {
          this.getBase64(compressedResult)
            .then((result) => {
              file[i] = result;
              arrPic.push(result);
              this.state.filesNames.push(file.name);
              this.setState({
                arrPic: arrPic,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {

        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  closeModalAndGoToCreateBankAccount = () => {
    this.props.history.push({
      pathname: "/profil",
      state: "needBankAccount",
    });
  };

  closeModalAndGoToVerifyAccount = () => {
    this.props.history.push({ pathname: "/verifie-ton-profil" });
  };

  closeModalAndGoToProfile = () => {
    this.props.history.push({ pathname: "/profil" });
  };

  render() {
    const { skills } = this.props;
    const { addCatergorie } = this.state;
    const { t } = this.context.t;
    const i18n = this.context.i18n;

    return (
      <>
        <Helmet>
          <title>Création d'annonce sur makemywork</title>
        </Helmet>

        <NavBarConnected />
        <NavbarMobile />
        <main id="main">
          <section>
            <div className="container w-100 mt-5">
              <div className="d-flex justify-content-center p-2">
                <div className="mt-5 container-max-width">
                  <form
                    onSubmit={(e) => this.CreateAnnouncement(e)}
                    id="contact-form"
                    className="contact-form"
                  >
                    <div className="row gy-4 justify-content-center align-items-star">
                      <div className="d-flex ">
                        <input
                          className="custom-radio"
                          type="radio"
                          name="categoryType"
                          id="flexRadioDefault1"
                          value="service"
                          onChange={(e) => this.handleInputChange(e)}
                          required
                          checked={
                            this.state.categoryType === "service" ? true : false
                          }
                        />

                        <p
                          className="text-futura-16 mx-4"
                          for="flexRadioDefault1"
                        >
                          {t("lableService")}
                        </p>

                        <input
                          className="custom-radio"
                          type="radio"
                          name="categoryType"
                          id="flexRadioDefault2"
                          value="enseignement"
                          onChange={(e) => this.handleInputChange(e)}
                          required
                          checked={
                            this.state.categoryType === "enseignement"
                              ? true
                              : false
                          }
                        />

                        <p
                          className="text-futura-16 mx-4"
                          for="flexRadioDefault2"
                        >
                          {t("lableEducation")}
                        </p>
                      </div>

                      <div className="col-4"></div>

                      <div className="col-sm-12">
                        <p className="text-futura-16 text-align-left">
                          {t("ChooseCategory")}
                        </p>
                        <select
                          className="form-creation"
                          aria-label="Default select example"
                          onChange={(e) =>
                            this.setState({ categoryName: e.target.value })
                          }
                          required={addCatergorie ? false : true}
                          disabled={addCatergorie ? true : false}
                          value={this.state.categoryName}
                        >
                          <option
                            selected
                            disabled={this.state.categoryName == ""}
                            value=""
                          >
                            {t("ChooseType")}
                          </option>
                          {skills.map((skill, key) =>
                            skill.categoryType === this.state.categoryType ? (
                              <option key={key} value={skill.categoryName}>
                                {i18n.language === "fr"
                                  ? skill.categoryName
                                  : skill.categoryNameEn}
                              </option>
                            ) : null
                          )}
                        </select>

                        <div
                          className="text-futura-16 text-underline text-align-right pointer mt-2 mb-3"
                          onClick={() =>
                            this.setState({
                              addCatergorie:
                                addCatergorie === true ? false : true,
                              categoryName: "",
                            })
                          }
                          required={addCatergorie ? true : false}
                        >
                          {t("proposeNewCategory")}
                        </div>

                        {addCatergorie === true ? (
                          <input
                            type="text"
                            name="categoryToAdd"
                            className="form-creation fix-input text-futura-16 text-align-left"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                          />
                        ) : null}
                      </div>

                      <div className="col-12">
                        {this.props.location.state === "demande" ? (
                          <p className="text-futura-20">
                            {t("InformationRequest")}
                          </p>
                        ) : (
                          <p className="text-futura-20">
                            {t("InformationOffer")}
                          </p>
                        )}
                      </div>

                      <div className="col-12 ">
                        {this.props.location.state === "demande" ? (
                          <p className="text-futura-16 text-align-left">
                            {t("requestTitle")}
                          </p>
                        ) : (
                          <p className="text-futura-16 text-align-left">
                            {t("offerTitle")}
                          </p>
                        )}
                        <input
                          type="text"
                          className="form-creation fix-input text-futura-16 text-align-left"
                          id="user_pname"
                          name="announcementTitle"
                          onChange={(e) => this.handleInputChange(e)}
                          required
                        />
                      </div>

                      <div className="col-12 ">
                        {this.props.location.state === "demande" ? (
                          <p className="text-futura-16 text-align-left">
                            {t("placeRequest")}
                          </p>
                        ) : (
                          <p className="text-futura-16 text-align-left">
                            {t("placeOffer")}
                          </p>
                        )}
                        <div className="form-c-sign">
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBRg0WgLuj6W49GfqwVITzEApOmt903zf0"
                            selectProps={{
                              noOptionsMessage: () =>
                                i18n.language === "fr"
                                  ? "Indiquez votre ville"
                                  : "Indicate your city",
                              placeholder: "",
                              isClearable: true,
                              value: this.state.addressObj,
                              onChange: (val) => {
                                this.setState({ addressObj: val });
                              },
                            }}
                          />
                          <i>
                            <Localisation />
                          </i>
                        </div>
                      </div>

                      <div className="col-12 ">
                        {this.props.location.state === "demande" ? (
                          <p className="text-futura-16 text-align-left">
                            {t("descRequest")}
                          </p>
                        ) : (
                          <p className="text-futura-16 text-align-left">
                            {t("descOffer")}
                          </p>
                        )}
                        <div className="col-sm-12">
                          <textarea
                            className="form-creation fix-input height text-futura-16 text-align-left "
                            name="announcementDescription"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                          ></textarea>
                        </div>
                      </div>

                      {this.props.location.state === "demande" ? (
                        <div className="d-flex flex-column align-items-center justify-content-center w-100">
                          <p className="text-futura-16">{t("limitDate")}</p>

                          <input
                            className="form-creation w-100 text-futura-16 text-align-left "
                            type="date"
                            name="deadLine"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </div>
                      ) : null}

                      <div className="col-12">
                        {this.props.location.state === "demande" ? (
                          <p className="text-futura-20">
                            {t("detailsRequest")}
                          </p>
                        ) : (
                          <p className="text-futura-20">{t("detailsOffer")}</p>
                        )}
                        <p className="text-futura-16">{t("proposePrice")}</p>
                      </div>

                      <div className=" d-flex justify-content-center align-items-star mr-6">
                        <input
                          type="number"
                          name="price"
                          className="form-price fix-input text-futura-16 container-4rem"
                          id="user_pname"
                          placeholder="0"
                          min={2}
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </div>

                      <div className="col-12">
                        <p className="text-futura-16 text-align-left">
                          {t("addMultipleImages")}
                        </p>
                        <span
                          className="form-input-file width-100"
                          onClick={(e) => this.clickButton(e)}
                        >
                          <div className="d-flex justify-content-between w-100">
                            <span className="w-100 pl-2">
                              {this.state.filesNames &&
                                this.state.filesNames.map((name, key) => (
                                  <p className="text-futura-14 text-align-left m-0">
                                    {name}
                                  </p>
                                ))}
                            </span>
                            {this.state.filesNames.length > 0 ? (
                              <span
                                className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                                style={{ paddingRight: "15px" }}
                              >
                                | {t("btnEDIT")}
                              </span>
                            ) : (
                              <span
                                className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                                style={{ paddingRight: "15px" }}
                              >
                                | {t("btnBROWSE")}
                              </span>
                            )}
                          </div>
                        </span>
                        <input
                          ref={this.myRef}
                          className="form-creation fix-input text-futura-16 text-align-left"
                          type="file"
                          id="formFile"
                          style={{ display: "none" }}
                          onChange={(e) => this.handleFileInputChange(e)}
                          multiple
                          accept="image/*"
                        />
                      </div>

                      <div className="col-12 text-center my-5">
                        <button
                          type="submit"
                          id="submit"
                          className={
                            (this.state.categoryName ||
                              this.state.categoryToAdd) &&
                            this.state.announcementDescription &&
                            //this.state.arrPic.length > 0&&
                            this.state.announcementTitle &&
                            this.state.announcementDescription
                              ? "btn-mmk"
                              : "btn-mmk-grey-opacity"
                          }
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <SpinLoading />
                          ) : (
                            t("btnCreateAnnouncement")
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Modal
          open={this.state.openModal}
          width={"62rem"}
          onCancel={() =>
            this.props.history.push({ pathname: "/mes-annonces" })
          }
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("AnnouncementCreated")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="col-12 justify-content-center align-items-center">
                <p className="text-futura-20 mb-4">
                  {t("confirmationAnnouncementCreation")}
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">
                    {t("descAnnouncementCreated")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.openModalBankAccount}
          width={"62rem"}
          onCancel={() =>
            this.props.history.push({
              pathname: "/profil",
              state: "needBankAccount",
            })
          }
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("needBankAccount")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">{t("registerData")}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.openModalWaitingForVerification}
          width={"62rem"}
          onCancel={() => this.props.history.push({ pathname: "/profil" })}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("waitingVerification")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">
                    {t("weDidntValidateYourRequest")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={this.state.openModalFileNotSend}
          width={"62rem"}
          onCancel={() =>
            this.props.history.push({ pathname: "/verifie-ton-profil" })
          }
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("verifyProfile")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src="assets/img/Annonce/popup.png"
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">{t("descVerifyProfile")}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    skills: state.SkillsReducer.skills,
    success: state.AnnouncementReducer.success,
    userBankAccountInfo: state.CandidacyReducer.userBankAccountInfo,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {
    getAllSkills: () => dispatch(actionCreator.getAllSkills()),
    CreateAnnouncement: (data) =>
      dispatch(actionCreator.CreateAnnouncement(data)),
    testIfUserHaveBankAccount: (userId) =>
      dispatch(actionCreator.testIfUserHaveBankAccount(userId)),
  };
};
/*************************************************************************************************************************************/
CreateAnnouncement.contextType = AuthContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateAnnouncement));
