import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";

class MailSent extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      loading: false,
    };
  }

  render() {
    const { t } = this.context.t;
    return (
      <>
        <NavBarNotConnected />
        <NavbarMobile />

        <main id="main">
          <section
            className="d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="container-mailsent">
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="image-mail-sent"
                    src={require('../../assets/img/confirm/conf.png')}
                    alt="..."
                  />
                </div>

                <div className="d-flex  justify-content-center align-items-center">
                  <p className="text-futura-30">{t("mailSent")}</p>
                </div>

                <div className="row  justify-content-center align-items-center">
                  <div className="">
                    <p className="text-futura-16">{t("mailSentDesc")}</p>
                  </div>
                </div>
                <div className=" d-flex justify-content-center my-5">
                  <button
                    id="submit"
                    className="btn-mmk"
                  >
                    <Link to="/connexion">{t("buttonRefresh")}</Link>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

MailSent.contextType = AuthContext;
export default withRouter(MailSent);
