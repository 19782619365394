import "./messenger.css";
import Conversation from "../../components/conversations/Conversation";
import Message from "../../components/message/Message";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { io } from "socket.io-client";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import Url from "../../constants/Url";
import { Dropdown, Menu, notification, Skeleton, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import * as actionCreator from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import FullStar from "../../components/icons/FullStar";
import EmptyStar from "../../components/icons/EmptyStar";
import SendIcon from "../../components/icons/SendIcon";
import UploadFileIcon from "../../components/icons/UploadFileIcon";
import Modal from "antd/lib/modal/Modal";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import CircleMessageIcon from "../../components/icons/CircleMessageIcon";
import moment from "moment";
import { useMemo } from "react";
import { formatDate } from "../../utils/formatDate";
import axios from "axios";
import SpinLoading from "../../components/SpinLoading";
import { Helmet } from "react-helmet-async";

export default function Messenger() {
  const socket = useRef();
  const { user } = useContext(AuthContext);
  const scrollRef = useRef();
  const myRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const percentage = useSelector((state) => state.CandidacyReducer.percentage);
  const notifications = useSelector(
    (state) => state.NotificationReducer.notifications
  );

  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [allmsg, setAllmsg] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [reasonOfReport, setReasonOfReport] = useState(null);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [messageSent, setMessageSent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [messageHeader, setMessageHeader] = useState(null);
  const [offer, setOffer] = useState(0);
  const [msgNotifSwitcher, setMsgNotifSwitcher] = useState("msg");

  const [openModalForAvis, setOpenModalForAvis] = useState(false);
  const [openModalMakeOffre, setOpenModalMakeOffre] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalBloc, setOpenModalBloc] = useState(false);
  const [statusBlocked, setStatusBlocked] = useState(false);

  const [messageNotSeen, setMessageNotSeen] = useState(0);

  const { reCallApi, setReCallApi } = useContext(AuthContext);
  const userAvis = useSelector((state) => state.ProfileReducer.otherUserAvis);
  const otherUserAvgStarRating = useSelector(
    (state) => state.ProfileReducer.otherUserAvgStarRating
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    if (searchParams.get("discussionId") && user && conversations) {
      conversations.map((conv) => {
        if (conv._id == searchParams.get("discussionId")) {
          setCurrentChat(conv);
        }
      });
      getDiscssion(searchParams.get("discussionId"));
      const data = {
        userId: searchParams.get("discussionId"),
        me: false,
      };
      dispatch(actionCreator.getAvisUser(data));
    }
  }, [location, user, conversations]);

  useEffect(() => {
    if (location.state) {
      if (location.state && conversations) {
        conversations.map((conv) => {
          if (conv._id == location.state) {
            setCurrentChat(conv);
          }
        });
        getDiscssion(location.state);
        const data = {
          userId: location.state,
          me: false,
        };
        dispatch(actionCreator.getAvisUser(data));
      }
      if (percentage === 0) {
        dispatch(actionCreator.getPourcentageMakeMyWork());
      }
    }
  }, [location.state, conversations]);

  const clickButton = (e) => {
    myRef.current.click();
  };

  useEffect(() => {
    if (user) {
      if (percentage === 0) {
        dispatch(actionCreator.getPourcentageMakeMyWork());
      }
      socket.current = io("https://apiv1.makemywork.fr/"); //http://145.239.197.226:3001/

      socket.current.on("send-new-message2", (data) => {
        getDiscussions(user.id);

        setArrivalMessage({
          receiver: data.receiver,
          text: data[0].text,
          user: data[0].user,
          value: data[0].value,
          fileType: data[0].fileType,
          createdAt: data[0].createdAt,
        });
      });

      socket.current.emit("user-is-connected", user.id);
      getDiscussions(user.id);

      socket.current.on("send-new-message", (data) => {
        setMessageSent({
          receiver: data.receiver,
          text: data[0].text,
          user: data[0].user,
          createdAt: data[0].createdAt,
          value: data[0].value,
          fileType: data[0].fileType,
        });
      });
    }
  }, [user]);

  useEffect(() => {
    if (arrivalMessage && currentChat)
      if (
        parseInt(arrivalMessage.receiver) == user.id &&
        parseInt(arrivalMessage.user._id) == parseInt(currentChat._id)
      ) {
        setMessages(() => [...messages, arrivalMessage]);
      }
  }, [arrivalMessage]);

  useEffect(() => {
    if (location && allmsg.length > 0) {
      let keyword = location.search.substring(7, location.search.length);

      let newMessages = allmsg.filter(
        (msg) =>
          msg.pseudo.toUpperCase().includes(keyword.toUpperCase()) ||
          msg.fileType.toUpperCase().includes(keyword.toUpperCase()) ||
          msg.fullName.toUpperCase().includes(keyword.toUpperCase())
      );
      setConversations(newMessages);
    }
  }, [location.search]);

  const getDiscussions = async (data) => {
    try {
      await Url.Url3.get(`getDiscussions/${data}`)
        .then((response) => {
          let notseen = 0;
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i];
            if (!element.status) {
              notseen++;
            }
          }

          setMessageNotSeen(notseen);
          setConversations(response.data);
          setAllmsg(response.data);
        })
        .catch((error) => {
          //console.log("error :", error);
          //NotificationManager.error(error.message);
          notification.error({
            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      //data.loading(false)
      console.error(error);
    }
  };

  useEffect(() => {
    if (reCallApi) {
      const data = {
        userId: currentChat._id,
        me: false,
      };
      getDiscssion(currentChat._id);
      dispatch(actionCreator.getAvisUser(data));
    }
  }, [reCallApi]);

  useEffect(() => {
    if (currentChat) {
      const data = {
        userId: currentChat._id,
        me: false,
      };
      getDiscssion(currentChat._id);
      dispatch(actionCreator.getAvisUser(data));
    }
  }, [currentChat]);

  const getDiscssion = async (discussionId) => {
    const data = {
      senderId: user.id,
      receiverId: discussionId,
    };
    try {
      await Url.Url3.post("getMessageCandidature", {
        senderId: data.senderId,
        receiverId: data.receiverId,
      })
        .then((response) => {
          //
          let offre = response.data.find(
            (message) =>
              message.fileType === "candidature" &&
              (message.announcementType === "offre" ||
                message.announcementType === "demande")
          );
          if (offre && !offre.validationClient) {
            setMessageHeader(offre);
          }
          setMessages(response.data.reverse());
          setReCallApi(false);
        })
        .catch((error) => {
          //console.log("error :", error);
          notification.error({
            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  const FnDetectNudity = async (detectNudity) => {
    let isNudes = false;
    let i = 0;
    while (!isNudes && i < detectNudity.length) {
      let data = new FormData();
      data.append("media", detectNudity[i]);
      data.append("models", "nudity,gore");
      data.append("api_user", "27749884");
      data.append("api_secret", "zXkiuCNjDkpEQDyJBH89");

      await axios({
        method: "post",
        url: "https://api.sightengine.com/1.0/check.json",
        data: data,
      })
        .then(function (response) {
          if (
            response.data.nudity.safe <= 0.3 ||
            response.data.gore.prob >= 0.5
          ) {
            isNudes = true;
          }
        })
        .catch(function (error) {
          if (error.response) console.log(error.response.data);
          else console.log(error.message);
        });
      i++;
    }
    return isNudes;
  };

  const handleSubmit = async (e, file) => {
    e.preventDefault();
    if (newMessage) {
      const receiverId = currentChat._id;
      let data;
      socket.current.emit("send-new-message", {
        0: {
          _id: Math.round(Math.random() * 1000000),
          createdAt: Date.now(),
          text: newMessage,
          user: {
            _id: user.id,
            avatar: user.profilePicture,
            name: user.pseudo,
          },
          value: null,
          fileType: null,
        },
        receiver: receiverId,
      });
      data = {
        senderId: user.id,
        receiverId: receiverId,
        message: newMessage,
      };

      sendMessage(data);
      setMessages([...messages]);
      setNewMessage("");
    }
  };

  const sendMessage = async (data) => {
    await Url.Url3.post("sendMessage", {
      senderId: data.senderId,
      receiverId: data.receiverId,
      message: data.message,
      fileType: data.fileType || null,
      value: data.value || null,
    })
      .then((response) => {
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error :", error);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  useEffect(() => {
    if (messageSent) setMessages(() => [...messages, messageSent]);
  }, [messageSent]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (msgNotifSwitcher === "notif") {
      if (user) {
        dispatch(
          actionCreator.getMessagesNotificationsWithSpecificUser(user.id)
        );
      }
    }
  }, [msgNotifSwitcher]);

  const handleFileInputChange = async (e) => {
    let DetectNudity = false;
    if (e.target.files[0].type.includes("image")) {
      DetectNudity = await FnDetectNudity(e.target.files);
    }
    if (!DetectNudity) {
      getBase64(e.target.files[0])
        .then((result) => {
          let data;
          let type = e.target.files[0].type;
          if (type.includes("image")) {
            data = {
              file: result,
              fileType: "image",
            };
          } else if (type.includes("pdf")) {
            data = {
              file: result,
              fileType: "pdf",
            };
          }

          urlFiles(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      notification.error({
        message: "Immoralité",
        description: "Attention !",
      });
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  const urlFiles = async (data) => {
    setLoading(true);
    await Url.Url3.post("urlFiles", {
      file: data.file,
      fileType: data.fileType,
    })
      .then((response) => {
        const receiverId = currentChat._id;
        let fileUrl = response.data.url;

        socket.current.emit("send-new-message", {
          0: {
            _id: Math.round(Math.random() * 1000000),
            createdAt: Date.now(),
            text: null,
            value: fileUrl,
            fileType: data.fileType,
            user: {
              _id: user.id,
              avatar: user.profilePicture,
              name: user.pseudo,
            },
          },
          receiver: receiverId,
        });
        data = {
          senderId: user.id,
          receiverId: receiverId,
          message: null,
          fileType: data.fileType,
          value: fileUrl,
        };
        sendMessage(data);
      })
      .catch((error) => {
        //console.log("error :", error);
        //NotificationManager.error(error.message);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  const proposerUnPrix = async () => {
    await Url.Url4.post("proposerUnPrix", {
      candidatureId: messageHeader.candidatureId,
      prixPropose: offer,
      playerId: "",
    })
      .then((response) => {
        notification.success({
          message: "Proposition de prix",
          description: "Proposition de prix envoyée",
        });
        getDiscssion(currentChat._id);
        setOpenModalMakeOffre(false);
      })
      .catch((error) => {
        //console.log("error :", error);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  const redirectionFromNotifiction = async (notif) => {
    setMsgNotifSwitcher("msg");
    if (!notif.vu) updateMessagesNotificationsWithSpecificUser(notif.id);

    if (notif.url.includes("messagerie")) {
      history.push({
        pathname: notif.url.replace(Url.serverUrl, ""),
        state: notif.senderId,
      });
    } else if (notif.url.includes("gerer-vos-services")) {
      history.push({
        pathname: notif.url.replace(Url.serverUrl, ""),
        state: { id: notif.candidatureId },
      });
    } else if (notif.url.includes("annonce")) {
      await Url.Url2.get("announcementDetail/" + `${notif.announcementId}`)
        .then((response) => {
          history.push({
            pathname: notif.url.replace(Url.serverUrl, ""),
            state: response.data.result[0],
            connected: true,
          });
        })
        .catch((error) => {
          //console.log("error :", error.message);
          notification.error({
            message: "error",
            description: error.message,
          });
        });
    } else if (notif.commentaireRefus) {
      history.push({
        pathname: "/verifie-ton-profil",
      });
    } else {
      history.push({
        pathname: notif.url.replace(Url.serverUrl, ""),
      });
    }
  };

  const updateMessagesNotificationsWithSpecificUser = async (
    notificationId
  ) => {
    await Url.Url3.post("updateMessagesNotificationsWithSpecificUser ", {
      notificationId: notificationId,
    })
      .then((response) => {})
      .catch((error) => {
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  const updateLastMessagesVu = async (conv) => {
    await Url.Url3.post("updateLastMessagesVu ", {
      userId: user.id,
      senderId: user.id,
      receiverId: conv._id,
    })
      .then((response) => {
        getDiscussions(user.id);
      })
      .catch((error) => {
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  const deleteMessages = async () => {
    await Url.Url3.delete("deleteMessages", {
      data: {
        senderId: user.id,
        receiverId: currentChat._id,
      },
    })
      .then((response) => {
        notification.success({
          message: "Messages",
          description: "Messages supprimés",
        });
        getDiscussions(user.id);
      })
      .catch((error) => {
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  const blockUserByOtherUSer = () => {
    setLoadingReport(true);
    const data = {
      senderId: user.id,
      recieverId: currentChat._id,
    };
    dispatch(actionCreator.blockUserByOtherUSer(data));
    setLoadingReport(false);
    setOpenModalBloc(false);
    setStatusBlocked(!statusBlocked);
  };

  const reportAbusive = async () => {
    setLoadingReport(true);
    const data = {
      senderId: user.id,
      receiverId: currentChat._id,
      reasonOfReport: reasonOfReport,
      annonceId: null,
      type: "message",
    };
    await dispatch(actionCreator.reportAbusive(data));
    setLoadingReport(false);
    setOpenModalReport(false);
    setReasonOfReport("");
  };

  return (
    <>
      <Helmet>
        <title>Make my work | Messagerie</title>
      </Helmet>
      <NavBarConnected />
      <NavbarMobile />
      <div className="row chat-wrapper">
        <div className={isMobile ? "col col-lg-4 mobile-conv" : "col col-lg-4"}>
          <div className="chatMenuWrapper scroll">
            <div className="d-flex flex-colmun justify-content-between py-3 px-1">
              <p className="text-futura-20 ">
                {messageNotSeen} {t("unread")}
              </p>
            </div>
            {conversations && conversations.length > 0 ? (
              conversations.map((c, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setCurrentChat(c);
                    setIsMobile(true);
                    updateLastMessagesVu(c);
                    setStatusBlocked(c.blocked);
                  }}
                >
                  <Conversation conversation={c} currentUser={user} />
                </div>
              ))
            ) : (
              <span className="no-items">{t("noItems")}</span>
            )}
          </div>
        </div>
        <div className="col col-lg-8">
          <div className="d-flex justify-content-center algin-items-center">
            <div
              onClick={() => setMsgNotifSwitcher("msg")}
              className={
                msgNotifSwitcher == "msg"
                  ? "m-3 selected-nav pointer"
                  : "m-3 not-selected-nav pointer"
              }
            >
              {t("messages")}
            </div>
            <div
              onClick={() => setMsgNotifSwitcher("notif")}
              className={
                msgNotifSwitcher == "notif"
                  ? "m-3 selected-nav pointer"
                  : "m-3 not-selected-nav pointer"
              }
            >
              {t("notifications")}
            </div>
          </div>
          <div
            className={
              !isMobile ? "chatBoxWrapper mobile-desc" : "chatBoxWrapper"
            }
          >
            {currentChat && msgNotifSwitcher === "msg" ? (
              <>
                <div className="chatBoxTop scroll">
                  <div className="user-info">
                    <div className="d-flex w-100 px-5">
                      <div className="d-flex justify-content-start align-items-center  w-100">
                        <div className="d-flex justify-content-center align-items-center gap-3">
                          <div
                            className="return"
                            onClick={(e) => setIsMobile(false)}
                          >
                            <i className="icon-chevron-left"></i>
                          </div>

                          <img
                            src={require("../../assets/img/fullTrash.png")}
                            className="pointer delete"
                            onClick={() => deleteMessages()}
                          />
                        </div>

                        {user &&
                        messageHeader &&
                        messageHeader.user._id != user.id ? (
                          <div className="d-flex justify-content-start align-items-center">
                            {messageHeader &&
                            messageHeader.statusProposition !== true
                              ? messageHeader.announcementType === "demande"
                                ? !statusBlocked && (
                                    <button
                                      className="btn-mmk mx-5"
                                      onClick={() => {
                                        setOpenModalMakeOffre(true);
                                        setOffer(messageHeader.price);
                                      }}
                                    >
                                      {t("btnMakeOffer")}
                                    </button>
                                  )
                                : !statusBlocked && (
                                    <button
                                      className="btn-mmk mx-5"
                                      onClick={() => {
                                        setOpenModalMakeOffre(true);
                                        setOffer(messageHeader.price);
                                      }}
                                    >
                                      {t("btnMakeRequest")}
                                    </button>
                                  )
                              : null}
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex flex-column justify-content-center align-items-center w-100">
                        <div className="d-flex justify-content-center align-items-center">
                          <div
                            className="text-futura-20 px-3 pointer"
                            onClick={() =>
                              history.push(`/profil/${currentChat._id}`)
                            }
                          >
                            {currentChat && currentChat.pseudo}
                          </div>
                          <div
                            className="d-flex pointer"
                            onClick={() => setOpenModalForAvis(true)}
                          >
                            <p className="text-futura-16">
                              {otherUserAvgStarRating
                                ? parseInt(otherUserAvgStarRating)
                                : null}
                            </p>
                            <FullStar width={24} height={24} />
                          </div>
                        </div>

                        <p className="text-futura-16">
                          {t("onlineThereIs")} 10 min
                        </p>
                      </div>

                      {messageHeader && messageHeader ? (
                        <div className="d-flex justify-content-end align-items-center w-100">
                          {messageHeader && messageHeader.picture.length > 0 ? (
                            <img
                              src={messageHeader.picture[0].picture}
                              height="36px"
                              width="42px"
                              className="mx-3 rounded"
                            />
                          ) : (
                            <img
                              height="36px"
                              width="42px"
                              src={require("../../assets/img/No-image.jpeg")}
                              className="mx-3 rounded"
                            />
                          )}

                          <div className="d-flex flex-column hide-detaile-announce-messenger">
                            <p className="text-futura-16 text-align-left">
                              {messageHeader && messageHeader.announcementTitle}
                            </p>
                            {}
                            <p className="text-futura-14 text-align-left">
                              {t("proposedRate")} :
                              {messageHeader && messageHeader.price}€
                            </p>
                            {messageHeader && messageHeader.deadLine && (
                              <p className="text-futura-11-grey text-align-left">
                                {t("before")}{" "}
                                {formatDate(messageHeader.deadLine)}
                              </p>
                            )}

                            <p className="text-futura-11-grey text-align-left">
                              {messageHeader && messageHeader.city}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end align-items-center w-100"></div>
                      )}
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <div
                                  className="text-futura-14 d-flex align-items-center justify-content-center"
                                  onClick={() => setOpenModalBloc(true)}
                                >
                                  {statusBlocked
                                    ? t("dBlocTitle")
                                    : t("blocTitle")}
                                </div>
                              </Menu.Item>
                              <Menu.Item key="2">
                                <div
                                  className="text-futura-14 d-flex align-items-center justify-content-center"
                                  onClick={() => setOpenModalReport(true)}
                                >
                                  {t("lableReport")}
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <i className="bi bi-three-dots-vertical"></i>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  {messages && messages.length > 0 ? (
                    messages.map((m) => (
                      <div ref={scrollRef}>
                        <Message
                          message={m}
                          own={m.user._id === user.id}
                          me={user}
                          percentage={percentage}
                          otherUserpseudo={currentChat.pseudo}
                          discussionId={currentChat._id}
                        />
                      </div>
                    ))
                  ) : (
                    <span className="no-items">{t("noItems")}</span>
                  )}
                </div>
                {!statusBlocked ? (
                  <div className="chatBoxBottom">
                    <span
                      className="upload-piece"
                      onClick={() => clickButton()}
                    >
                      <UploadFileIcon />
                    </span>

                    <div className="send-message">
                      <TextareaAutosize
                        placeholder={t("writeHere")}
                        onChange={(e) => {
                          setNewMessage(e.target.value);
                        }}
                        value={newMessage}
                        style={{ width: "100%" }}
                        minRows={1}
                        maxRows={5}
                      ></TextareaAutosize>
                      {loading ? (
                        <span className="send">
                          <Spin />
                        </span>
                      ) : (
                        <span
                          className="send"
                          style={{ cursor: "pointer" }}
                          onClick={handleSubmit}
                        >
                          <SendIcon />
                        </span>
                      )}
                    </div>

                    <input
                      ref={myRef}
                      type="file"
                      onChange={(e) => handleFileInputChange(e)}
                    />
                  </div>
                ) : (
                  <span className="text-futura-14">{t("unavailableUser")}</span>
                )}
              </>
            ) : msgNotifSwitcher === "notif" ? (
              <div className="chatBoxTop scroll">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="notifications-wrapper">
                    {notifications && notifications.length > 0 ? (
                      notifications.map((notif) => (
                        <div
                          className="d-flex justify-content-center align-items-center gap-2 mb-4 pointer"
                          onClick={() => redirectionFromNotifiction(notif)}
                        >
                          {!notif.vu ? (
                            <CircleMessageIcon />
                          ) : (
                            <div className="empty-circle"></div>
                          )}
                          <div className="notification-card">
                            <div className="d-flex justify-content-between">
                              <p className="text-futura-14 text-align-left overflow-hidden ellipsis-text">
                                {notif.title}
                              </p>
                              <p className="text-futura-11-grey">
                                {moment
                                  .utc(notif.dateTime)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()
                                  .replace("il y a", "")}
                              </p>
                            </div>
                            <p className="text-futura-14 text-align-left overflow-hidden ellipsis-text">
                              {notif.contenu}
                            </p>
                            {notif.commentaireRefus ? (
                              <p className="text-futura-14 text-align-left overflow-hidden ellipsis-text">
                                {notif.commentaireRefus}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      ))
                    ) : (
                      <span className="no-items">{t("noItems")}</span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <span className="noConversationText">
                <Skeleton />
              </span>
            )}
          </div>
        </div>
        <div className="chatOnline">
          <div className="chatOnlineWrapper"></div>
        </div>
      </div>
      <Modal
        //title="Annonce créé avec succès"

        open={openModalMakeOffre}
        onCancel={() => setOpenModalMakeOffre(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="text-futura-30 mt-2 mb-5">{t("titleMakeRequest")}</p>

          <div className="d-flex justify-content-center align-items-center w-100 mb-3">
            {messageHeader && messageHeader.picture.length > 0 ? (
              <img
                src={messageHeader.picture[0].picture}
                height="36px"
                width="42px"
                className="mx-3 rounded"
              />
            ) : (
              <img
                height="36px"
                width="42px"
                src={require("../../assets/img/No-image.jpeg")}
                className="mx-3 rounded"
              />
            )}

            <div className="d-flex flex-column">
              <p className="text-futura-16 text-align-left">
                {messageHeader && messageHeader.announcementTitle}
              </p>
              <p className="text-futura-14 text-align-left">
                {t("proposedRate")} :{messageHeader && messageHeader.price}€
              </p>

              {messageHeader && messageHeader.deadLine && (
                <p className="text-futura-11-grey text-align-left">
                  {t("before")} {formatDate(messageHeader.deadLine)}
                </p>
              )}
              <p className="text-futura-11-grey text-align-left">
                {messageHeader && messageHeader.city}
              </p>
            </div>
          </div>
          <p className="text-futura-20">{t("chooseRate")}</p>

          <input
            type="number"
            className="input-payment-half text-futura-16 container-4rem my-5"
            value={offer}
            min={2}
            onChange={(e) => setOffer(e.target.value)}
          />

          <button
            className="btn-mmk mt-5 mb-3"
            onClick={() => proposerUnPrix()}
          >
            {t("btnSendQuotation")}
          </button>
        </div>
      </Modal>

      <Modal
        open={openModalForAvis}
        width={"62rem"}
        onCancel={() => setOpenModalForAvis(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("Notice")}</p>

          <div className="content w-100">
            <div className="blue-scroll d-flex flex-column align-items-center height-modal-avis">
              {userAvis && userAvis.length > 0 ? (
                userAvis.map((announce, key) => (
                  <div className="card-avis mb-4" key={key}>
                    <div className="d-flex w-100">
                      {announce.user?.profilePicture ? (
                        <img
                          src={announce.user.profilePicture}
                          className="img-card-avis"
                        />
                      ) : null}
                      <div className="d-flex flex-column justify-content-start align-items-start px-3 width-78">
                        <p className="text-futura-11-grey">
                          {announce.user?.surname}
                        </p>
                        <div className="d-flex justify-content-end algin-items-center w-100">
                          {generateTempArray(announce.rating).map((item) => (
                            <FullStar width={22} height={21} />
                          ))}
                          {generateTempArray(5 - announce.rating).map(
                            (item) => (
                              <EmptyStar width={22} height={22} />
                            )
                          )}
                        </div>
                        <p className="text-futura-14 overflow-hidden ellipsis-text-card-avis">
                          {announce.commentaire}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="no-items">{t("noItems")}</span>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalReport}
        width={"60rem"}
        onCancel={() => setOpenModalReport(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">{t("lableReport")}</p>
          <div className="d-flex justify-content-start w-100 mt-3">
            <p className="text-futura-16 text-align-left">
              {t("lableComment")}
            </p>
          </div>

          <div className="content w-100">
            <div className="blue-scroll d-flex flex-column align-items-center">
              <textarea
                className="input-textArea"
                value={reasonOfReport}
                onChange={(e) => setReasonOfReport(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={
                reasonOfReport
                  ? "btn-mmk mt-5 mb-2"
                  : "btn-mmk-grey-opacity mt-5 mb-2"
              }
              onClick={() => reportAbusive()}
              disabled={loadingReport}
            >
              {loadingReport ? <SpinLoading /> : t("btnSent")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalBloc}
        width={"50rem"}
        onCancel={() => setOpenModalBloc(false)}
        footer={false}
      >
        <div className="justify-content-center align-items-center">
          <p className="text-futura-30 mb-4">
            {statusBlocked ? t("dBlocTitle") : t("blocTitle")}
          </p>
          <div className="d-flex justify-content-center w-100 mt-3">
            <p className="text-futura-16 text-align-left">
              {statusBlocked ? t("dBlocText") : t("blocText")}
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center gap-3">
            <button
              className="btn-mmk mt-5 mb-2"
              onClick={() => blockUserByOtherUSer()}
              disabled={loadingReport}
            >
              {loadingReport ? <SpinLoading /> : t("btnYes")}
            </button>

            <button
              className="btn-mmk-grey-opacity mt-5 mb-2"
              onClick={() => setOpenModalBloc(false)}
              disabled={loadingReport}
            >
              {loadingReport ? <SpinLoading /> : t("btnNo")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
