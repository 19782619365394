import React, { Component } from "react";
import ReactCountryFlag from "react-country-flag";
import { connect } from "react-redux";
import * as actionCreator from "../redux/actions";
import { Link, NavLink, withRouter } from "react-router-dom";
import {Drawer, Space } from "antd";
import ArrowDown from "./icons/FlechDown";
import Accueil from "./icons/Accueil";
import Gere from "./icons/Gere";
import Plus from "./icons/Plus";
import IconMessage from "./icons/IconMessage";
import User from "./icons/User";
import { Menu, Dropdown } from "antd";
import { getToken } from "../routes/shared";
import { AuthContext } from "../context/AuthContext";

class NavbarMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      path: props.location.pathname,
      search: props.inputSearch,
      connected:false,
      visible: false,
      showPartie: false,
      placement: "right",
      categoryType:(props.location.pathname=== "/accueil-non-connecte"|| props.location.pathname=== "/accueil" ) || props.reducerCategoryType=='service'?"service":"enseignement",
    };
  }

  componentDidMount() {
    let token = getToken()
    if(token){
      this.setState({connected:true})
    }
  }

  handleSearch(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: `/recherche`,
      search: `?value=${this.state.search}`,
      state: true,
    });
  }

  showDrawer = () => {
    this.setState({ visible: true });
  };

  showDrawerPartie = () => {
    this.setState({ showPartie: true });
  };
  onClose = () => {
    this.setState({ visible: false });
  };

  handleInputChange(e) {
    this.setState({ search: e.target.value });
    this.props.handleInputSearch(e.target.value);
  }

  handleCategoryTypeChange(type){
      this.props.handleCategoryType(type);
      this.setState({categoryType:type})
    
      if(this.state.connected){
        if(type=="enseignement" && (this.state.path != "/categorie-service" && this.state.path != "/categorie-enseignement"))
        {this.props.history.push("/accueil/enseignement")}
        else if(type=="service" && (this.state.path != "/categorie-service" && this.state.path != "/categorie-enseignement"))
        {this.props.history.push("/accueil")}
      }
      else{
        if(type=="enseignement" && (this.state.path != "/categorie-service" && this.state.path != "/categorie-enseignement"))
      {this.props.history.push("/accueil-non-connecte/enseignement")}
      else if(type=="service" && (this.state.path != "/categorie-service" && this.state.path != "/categorie-enseignement"))
      {this.props.history.push("/accueil-non-connecte")}
      }

  }

  handleChangeLanguage(lang){
    const i18n = this.context.i18n
    i18n.changeLanguage(lang)
  }

  render() {
    const { path } = this.state;
    const {t} = this.context.t
    const i18n = this.context.i18n
    return (
      <>
        <div className="fixed-top navbar-mobile w-100">
          <div
            className="navbar-mobile"
            style={{
              backgroundImage: ` url(${require('../img/Banner2.png')})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor : "#fff",
              //backgroundSize: "100%",
              height: "100px",
            }}
          >
            <div
              className="d-flex justify-content-between  w-100"
              style={{ padding: "15px 15px" }}
            >
              <div className="d-flex justify-content-start w-50">
                <Link to={"/accueil-non-connecte"}>
                <img src={`${require('../img/mylogoM.png')}`} width="50px" hegiht="50px"/>
                </Link>
              </div>
              <Drawer
                title="Menu"
                placement={this.state.placement}
                closable={false}
                onClose={this.onClose}
                open={this.state.visible}
                key={this.state.placement}
                extra={
                  <Space>
                    <i onClick={this.onClose} className="bi bi-x"></i>
                  </Space>
                }
              >
              

               {this.state.connected ?
                  <div className="drop-mobile d-flex flex-column gap-5">
                  <div className="d-flex align-items-center justify-content-start">
                    <form
                      className="has-search-mobile "
                      onSubmit={(e) => this.handleSearch(e)}
                    >
                      <span className="bi bi-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('search')}
                        style={{ height: "45px" }}
                        value={this.state.search}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </form>
                  </div>

                  <div className="d-flex justify-content-between px-3">
                    {path == "/accueil" || path === "/categorie-service" ? (
                      this.state.categoryType ==="service"?
                      <Dropdown
                    
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                             
                                <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                                  {t('teachingPart')}
                                </span>
                             
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-purple " >
                          {t('servicesPart')}
                          </span>

                         <ArrowDown/>
                        </a>
                      </Dropdown>
                      :
                      <Dropdown

                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                            
                              <span className="text-parte-purple " onClick={()=>this.handleCategoryTypeChange("service")}>
                              {t('servicesPart')}
                              </span>
                           
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="text-parte-pink ">
                        {t('teachingPart')}
                        </span>

                        <ArrowDown/>
                      </a>
                    </Dropdown>
                    ) : path == "/accueil/enseignement" || path === "/categorie-enseignement" ? (
                      this.state.categoryType === "enseignement"?
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                              
                                <span className="text-parte-purple " onClick={()=>this.handleCategoryTypeChange("service")}>
                                {t('servicesPart')}
                                </span>
                             
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-pink ">
                          {t('teachingPart')}
                          </span>

                          <ArrowDown/>
                        </a>
                      </Dropdown>:
                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                           
                              <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                              {t('teachingPart')}
                              </span>
                           
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="text-parte-purple " >
                        {t('servicesPart')}
                        </span>

                        <ArrowDown/>
                      </a>
                    </Dropdown>
                    ) : 
                    <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                              
                                <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                                {t('teachingPart')}
                                </span>
                           
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-purple ">
                          {t('servicesPart')}
                          </span>

                          <ArrowDown/>
                        </a>
                      </Dropdown>}

                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("en")}
                              >
                                <ReactCountryFlag countryCode="US" svg />
                                &nbsp; English
                              </div>
                          </Menu.Item>

                          <Menu.Item key="2">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("fr")}
                              >
                                <ReactCountryFlag countryCode="FR" svg />
                                &nbsp; Français
                              </div>
                          </Menu.Item>

                          <Menu.Item key="3">
                              <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={() => this.handleChangeLanguage("es")}
                              >
                                <ReactCountryFlag countryCode="ES" svg />
                                &nbsp; Español
                              </div>
                          </Menu.Item>
                   
                        </Menu>
                      }
                    >
                      {i18n.language === "fr" ? (
                        <span>
                          <ReactCountryFlag countryCode="FR" svg />
                          <ArrowDown />
                        </span>
                      ) :i18n.language === "en" ?(
                        <span>
                          <ReactCountryFlag countryCode="US" svg />
                          <ArrowDown />
                        </span>
                      ):
                      <span>
                        <ReactCountryFlag countryCode="ES" svg />
                        <ArrowDown />
                      </span>}
                    </Dropdown>
                    
                  </div>

                      
                  <div className="d-flex flex-column ">
                    <div className="d-flex algin-items-center">
                      <div className="navbar-icon"><Accueil/></div>
                      <NavLink
                        to="/accueil"
                        className="text-futura-20-grey pointer text-align-left py-3"
                        activeClassName="text-futura-20-pink"
                      >
                        {t('home')}
                      </NavLink>
                    </div>

                    <div className="d-flex algin-items-center">
                      <div className="navbar-icon"><Gere/></div>
                      <NavLink
                        to="/gerer"
                        className="text-futura-20-grey pointer text-align-left py-3"
                        activeClassName="text-futura-20-pink"
                      >
                        {t('manage')}
                      </NavLink>
                    </div>


                    <div className="d-flex algin-items-center">
                      <div className="navbar-icon"><Plus/></div>
                      <NavLink
                        to="/selectionner-un-type-dannonce"
                        className="text-futura-20-grey pointer text-align-left py-3"
                        activeClassName="text-futura-20-pink"
                      >
                        {t('create')}
                      </NavLink>
                    </div>


                    <div className="d-flex algin-items-center">
                      <div className="navbar-icon"><IconMessage/></div>
                      <NavLink
                        to="/messagerie"
                        className="text-futura-20-grey pointer text-align-left py-3"
                        activeClassName="text-futura-20-pink"
                      >
                        {t('message')}
                      </NavLink>
                    </div>


                    <div className="d-flex algin-items-center">
                      <div className="navbar-icon"><User/></div>
                      <NavLink
                        to="/profil"
                        className="text-futura-20-grey pointer text-align-left py-3"
                        activeClassName="text-futura-20-pink"
                      >
                        {t('profile')}
                      </NavLink>
                      </div>
                    </div>
                    

                  
                </div>
               :
                <div className="drop-mobile d-flex flex-column gap-5">
                  <div className="d-flex align-items-center justify-content-start">
                    <form
                      className="has-search-mobile "
                      onSubmit={(e) => this.handleSearch(e)}
                    >
                      <span className="bi bi-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('search')}
                        style={{ height: "45px" }}
                        value={this.state.search}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </form>
                  </div>

                  <div className="d-flex justify-content-between">
                    {path == "/accueil-non-connecte" || path === "/categorie-service" ? (
                      this.state.categoryType ==="service"?
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                              
                                <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                                {t('teachingPart')}
                                </span>
                             
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-purple ">
                          {t('servicesPart')}
                          </span>

                          <ArrowDown/>
                        </a>
                      </Dropdown>:
                      <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key="1">
                          
                              <span className="text-parte-purple " onClick={()=>this.handleCategoryTypeChange("service")}>
                              {t('servicesPart')}
                              </span>
                            
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        <span className="text-parte-pink ">
                        {t('teachingPart')}
                        </span>

                        <ArrowDown/>
                      </a>
                    </Dropdown>

                    ) : path == "/accueil-non-connecte/enseignement" || path === "/categorie-enseignement" ? (
                      this.state.categoryType === "enseignement"?
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                            
                                <span className="text-parte-purple " onClick={()=>this.handleCategoryTypeChange("service")}>
                                {t('servicesPart')}
                                </span>
                              
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-pink ">
                          {t('teachingPart')}
                          </span>

                          <ArrowDown/>
                        </a>
                      </Dropdown>:
                        <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1">
                              
                                <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                                {t('teachingPart')}
                                </span>
                             
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="text-parte-purple ">
                          {t('servicesPart')}
                          </span>

                          <ArrowDown/>
                        </a>
                      </Dropdown>

                    ) :  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          
                            <span className="text-parte-pink " onClick={()=>this.handleCategoryTypeChange("enseignement")}>
                            {t('teachingPart')}
                            </span>
                         
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="text-parte-purple ">
                      {t('servicesPart')}
                      </span>

                      <ArrowDown/>
                    </a>
                  </Dropdown>}

                  <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                {i18n.language === "fr" ?
                                <div
                                className="text-futura-14 d-flex align-items-start justify-content-start"
                                onClick={()=>this.handleChangeLanguage("en")}
                              >
                              <ReactCountryFlag countryCode="US" svg />&nbsp;
                                English
                              </div>:
                              <div
                              className="text-futura-14 d-flex align-items-start justify-content-start"
                              onClick={()=>this.handleChangeLanguage("fr")}
                            >
                             <ReactCountryFlag countryCode="FR" svg />&nbsp;
                              Français
                            </div>
                              }
                              </Menu.Item>
                            </Menu>
                          }
                        >
                           {i18n.language === "fr" ?
                            <span >
                              <ReactCountryFlag countryCode="FR" svg />&nbsp;
                              <ArrowDown />
                            </span>:
                            <span>
                            <ReactCountryFlag countryCode="US" svg />&nbsp;
                            <ArrowDown />
                          </span>
                            }
                        </Dropdown>
                  </div>

                  <Link to="/inscription">
                    <a
                      className="btn-mmk-white"
                      style={{ padding: "10px 15px" }}
                    >
                      {t('buttonRegister')}
                    </a>
                  </Link>

                  <Link to="/connexion">
                    <a className="btn-mmk" style={{ padding: "10px 15px" }}>
                    {t('buttonLogin')}
                    </a>
                  </Link>
                </div>
                  }
              </Drawer>

              <div className="d-flex w-50 justify-content-end ">
                <i
                  className="icon-menu menu-mobile-icon"
                  onClick={() => this.showDrawer()}
                ></i>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleInputSearch: (data) =>
      dispatch(actionCreator.handleInputSearch(data)),
    handleCategoryType: (data) =>
      dispatch(actionCreator.handleCategoryType(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    inputSearch: state.SearchReducer.inputSearch,
    reducerCategoryType: state.SearchReducer.categoryType,
  };
};


NavbarMobile.contextType = AuthContext

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavbarMobile));
