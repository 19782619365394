import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Url from "../../constants/Url";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import IconMail from "../../components/icons/IconMail";
import NavbarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { notification } from "antd";
import { Helmet } from "react-helmet-async";

class ResetPwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      confirmation: false,
      email: "",
      error: "",
    };
  }

  async resetPassword(e) {
    e.preventDefault();
    this.setState({ loading: true });
    if (this.state.email) {
      this.setState({ error: "" });
      await Url.Url1.get("sendUrl/" + this.state.email)
        .then((response) => {
          notification.success({

            message: "Restauration de mot de passe",
            description: "Un mail a été envoyé sur votre email",
          });
          this.setState({ confirmation: true, loading: false });
        })
        .catch((error) => {
          this.setState({ error: true, loading: false });
        });
    } else {
      this.setState({ error: true });
      setTimeout(() => {
        this.setState({ error: "", loading: false });
      }, 1000);
    }
  }
  render() {
    const { t } = this.context.t;

    return (
      <>
        <Helmet>
          <title>Make my work | Réinitialiser le mot de passe</title>
        </Helmet>
        <NavBarNotConnected />
        <NavbarMobile />

        <main id="main">
          <section data-aos="fade-up" data-aos-delay="200">
            <div className="container mt-5">
              <div className="row justify-content-center align-items-center">
                <div className="row  justify-content-center align-items-center">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img
                      className="image-signin mb-5"
                      src={require('../../assets/img/connx/connect.png')}
                      alt="..."
                    />
                  </div>

                  {this.state.confirmation ? (
                    <div className="d-flex flex-column container-max-width py-5">
                      <p className=" d-flex justify-content-center align-items-center text-futura-20 my-5">
                        {t("resetPasswordConfirmed")}
                      </p>
                      <div className="d-flex justify-content-center align-items-center">
                        <Link to="/connexion">
                          <p className="text-futura-14 text-underline padding-20-40">
                            {t("connect")}
                          </p>
                        </Link>
                        <button
                          type="submit"
                          id="submit"
                          className={
                            this.state.email
                              ? "btn-mmk"
                              : "btn-mmk-grey-opacity"
                          }
                          onClick={(e) => this.resetPassword(e)}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <SpinLoading />
                          ) : (
                            t("buttonSendLink")
                          )}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-12 container-max-width">
                        <p className="d-flex justify-content-center m-5 text-futura-20"></p>

                        <div className="row justify-content-center">
                          <p className="d-flex justify-content-start m-0 p-0 text-futura-16">
                            {t("lableEmail")}
                          </p>
                          <div className="form-c-sign ">
                            <input
                              type="email"
                              name="email"
                              id="user_email"
                              className="fix-input"
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                              required
                            />
                            <i>
                              <IconMail />
                            </i>
                          </div>
                        </div>
                        {this.state.error && this.state.email ? (
                          <span className="text-futura-14-red">
                            {t("errEmailNotExists")}
                          </span>
                        ) : null}
                        {this.state.error && !this.state.email ? (
                          <span className="text-futura-14-red">
                            {t("errRequiredEmail")}
                          </span>
                        ) : null}
                      </div>

                      <div className="d-flex justify-content-center align-items-center my-5">
                        <Link to="/connexion">
                          <p className="text-futura-14 text-underline padding-20-40">
                            {t("linkIknowmypassword")}
                          </p>
                        </Link>
                        <button
                          type="submit"
                          id="submit"
                          className={
                            this.state.email
                              ? "btn-mmk"
                              : "btn-mmk-grey-opacity"
                          }
                          onClick={(e) => this.resetPassword(e)}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <SpinLoading />
                          ) : (
                            t("buttonSendLink")
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

ResetPwd.contextType = AuthContext;

export default withRouter(ResetPwd);
