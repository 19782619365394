import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions/index";
import { AuthContext } from "../../context/AuthContext";
import Url from "../../constants/Url";
import NavBarConnected from "../../components/NavBarConnected";
import SpinLoading from "../../components/SpinLoading";
import { notification } from "antd";
import { Helmet } from "react-helmet-async";

class TellUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      search: "",
      test: "",
      skills: [],
      selectedSkills: [],
      foundSkills: [],
      skillsNames: [],
      update: props.location.search || "",
    };
  }

  componentDidMount() {
    this.getAllSkills();
    let search = this.props.location.search;
    let index = search.indexOf("=,");
    let str = search.substring(index + 2);
    let arraySkills = str.split(",");
    if (index !== -1) {
      for (let i = 0; i < arraySkills.length; i++) {
        this.state.selectedSkills.push(parseInt(arraySkills[i]));
      }
    }
    this.setState({ skills: this.props.skills });
  }

  getAllSkills() {
    this.props.getAllSkills();
  }

  addDeleteSkill = (e, skill) => {
    e.preventDefault();
    const { selectedSkills } = this.state;
    if (!selectedSkills.includes(skill.id)) {
      this.state.selectedSkills.push(skill.id);
    } else {
      for (let i = 0; i < selectedSkills.length; i++) {
        if (skill.id === selectedSkills[i]) selectedSkills.splice(i, 1);
      }
    }
    this.setState({ test: "" });
  };

  searchForSkills = (e) => {
    e.preventDefault();
    this.setState({ search: e.target.value });
    const { skills, search } = this.state;
    if (search.length > 2) {
      const newSkills = skills.filter((item) => {
        const skillName = item.categoryName.toUpperCase();
        return (
          skillName.indexOf(search.toUpperCase()) > -1 &&
          !item.populaire &&
          this.state.selectedSkills.find((skill) => skill === item.id) ===
            undefined
        );
      });
      this.setState({ skillsNames: newSkills });
    } else this.setState({ skillsNames: [] });
  };

  componentDidUpdate(prevState, prevProps) {
    if (prevState.skills.length !== this.props.skills.length) {
      this.getAllSkills();
      this.setState({ skills: this.props.skills });
    }
  }

  async addSkills(e) {
    e.preventDefault();
    this.setState({ loading: true });
    const { selectedSkills } = this.state;
    let newArray = [];
    for (let i = 0; i < selectedSkills.length; i++) {
      if (selectedSkills[i] != null) {
        newArray.push(selectedSkills[i]);
      }
    }

    await Url.Url1.post("addSkills", {
      userId: this.context.user.id,
      arraycategorieId: newArray,
    })
      .then(async (response) => {
        notification.success({

          message: "Compétences",
          description: "Compétences ajoutées avec succès",
        });
        this.setState({ loading: false });
        if (!this.state.update) {
          if (this.context.user.isCertified === "0") {
            this.props.history.push({
              pathname: "/verifie-ton-profil",
            });
          } else {
            this.props.history.push({
              pathname: "/accueil",
            });
          }
        } else {
          if (this.context.user.id) {
            await this.props.getUserDetail({ userId: this.context.user.id });
            this.props.history.push({
              pathname: "/profil",
              search: "?updated=true",
            });
            this.setState({ loading: false });
          }
        }
      })
      .catch((error) => {
        //NotificationManager.error("error :", error);
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  }

  handleSelect(name) {
    this.setState({ search: name, skillsNames: [] });
    const { skills, search } = this.state;
    if (search.length > 2) {
      const newSkills = skills.filter((item) => {
        const skillName = item.categoryName.toUpperCase();
        return (
          skillName.indexOf(search.toUpperCase()) > -1 &&
          !item.populaire &&
          this.state.selectedSkills.find((skill) => skill === item.id) ===
            undefined
        );
      });
      this.setState({ foundSkills: newSkills });
    } else this.setState({ foundSkills: [] });
  }

  render() {
    const { search, foundSkills, skillsNames } = this.state;

    const { t } = this.context.t;
    const i18n = this.context.i18n;

    return (
      <>

        <Helmet>
          <title>Make My Work | Dites-nous</title>
        </Helmet>
        <NavBarConnected />

        <main id="main">
          <section
            className=" d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-7 justify-content-center align-items-center">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-12 d-flex justify-content-center align-items-center mt-5">
                      <p className="text-futura-20">{t("whatAreYourSkills")}</p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center  align-items-center">
                    <div className="col col-xl-7 col-lg-7 col-12 has-search align-items-center justify-content-start">
                      <span className="bi bi-search form-control-feedback"></span>

                      <input
                        type="text"
                        className="form-control  input-max-width"
                        placeholder={
                          i18n.language === "fr" ? "Rechercher" : "Search"
                        }
                        value={search}
                        onChange={(e) => this.searchForSkills(e)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center  align-items-center">
                    <div className="col col-xl-7 col-lg-7 col-12 has-search align-items-center justify-content-start">
                      <ul className="list-group mb-5 input-max-width">
                        {skillsNames.length>0?skillsNames.map((skill, key) => (
                          <li
                            className="list-group-item"
                            onClick={() =>
                              this.handleSelect(skill.categoryName)
                            }
                          >
                            {skill.categoryName}
                          </li>
                        )):null}
                      </ul>
                    </div>
                  </div>

                  <div className="skilss-grid justify-content-center  align-items-center gap-3">
                    {this.props.skills.length>0?
                    this.props.skills.map((skill, key) =>
                      skill.populaire === true ||
                      (this.state.selectedSkills.includes(skill.id) &&
                        this.props.location.search) ? (
                        <div
                          key={key}
                          onClick={(e) => this.addDeleteSkill(e, skill)}
                          className=" d-flex justify-content-center align-items-center t"
                        >
                          <div>
                            <div
                              className={
                                this.state.selectedSkills.includes(skill.id)
                                  ? " iconStyle crt-wrap border d-flex justify-content-center align-items-center onSelectitem"
                                  : " iconStyle crt-wrap border d-flex justify-content-center align-items-center"
                              }
                            >
                              <img
                                className="img-size"
                                height="50px"
                                width="50px"
                                src={skill.picture}
                                alt="image skills picture"
                              />
                            </div>
                            <p
                              className={
                                this.state.selectedSkills.includes(skill.id)
                                  ? "selectedP text-futura-16 mb-4"
                                  : "text-futura-16 mb-4"
                              }
                            >
                              {i18n.language === "fr"
                                ? skill.categoryName
                                : skill.categoryNameEn}
                            </p>
                          </div>
                        </div>
                      ) : null
                    ):null}

                    {foundSkills.length>0?
                     foundSkills.map(
                      (skill, key) =>
                        !this.state.selectedSkills.includes(skill.id) && (
                          <div
                            key={key}
                            onClick={(e) => this.addDeleteSkill(e, skill)}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <div>
                              <div
                                className={
                                  this.state.selectedSkills.includes(skill.id)
                                    ? "iconStyle crt-wrap border  d-flex justify-content-center align-items-center onSelectitem"
                                    : "iconStyle crt-wrap border  d-flex justify-content-center align-items-center"
                                }
                              >
                                <img
                                  className="img-size w-50 h-50"
                                  src={skill.picture}
                                  alt="..."
                                />
                              </div>
                              <p
                                className={
                                  this.state.selectedSkills.includes(skill.id)
                                    ? "selectedP text-futura-16 mb-4"
                                    : "text-futura-16 mb-4"
                                }
                              >
                                {i18n.language === "fr"
                                  ? skill.categoryName
                                  : skill.categoryNameEn}
                              </p>
                            </div>
                          </div>
                        )
                    ):null}
                  </div>

                  <div className="row gy-4  justify-content-center align-items-center">
                    <div className="col-12 text-center">
                      <button
                        onClick={(e) => this.addSkills(e)}
                        id="submit"
                        className={
                          this.state.selectedSkills.length === 0
                            ? "btn-mmk-grey-opacity"
                            : "btn-mmk"
                        }
                        disabled={this.state.loading}
                      >
                        {this.state.loading ? (
                          <SpinLoading />
                        ) : (
                          t("buttonValidate")
                        )}
                      </button>
                    </div>
                    <div className="row justify-content-end align-items-end">
                      <div className="d-flex justify-content-center align-items-end my-5">
                        {this.props.location.search ? (
                          <Link to="/profil">
                            <p className="text-roboto-regular-16 m-0">
                              {t("linkLater")}
                            </p>
                          </Link>
                        ) : (
                          <Link to="/verifie-ton-profil">
                            <p className="text-roboto-regular-16 m-0">
                              {t("linkLater")}
                            </p>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

/*************************************************************************************************************************************/
const mapStateToProps = (state) => {
  return {
    skills: state.SkillsReducer.skills,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {
    getAllSkills: () => dispatch(actionCreator.getAllSkills()),
    getUserDetail: () => dispatch(actionCreator.getUserDetail()),
  };
};
/*************************************************************************************************************************************/
TellUs.contextType = AuthContext;
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TellUs));
