import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import { AuthContext } from "../../context/AuthContext";
import Url from "../../constants/Url";
import NavBarConnected from "../../components/NavBarConnected";
import SpinLoading from "../../components/SpinLoading";
import { notification, Button, Popover } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import Phone from "../../components/icons/Phone";
import IconMail from "../../components/icons/IconMail";
import CreditCard from "../../components/icons/CreditCard";
import Localisation from "../../components/icons/Localisation";
import Compressor from "compressorjs";
import { Helmet } from "react-helmet-async";

import PhoneInput from "react-phone-number-input";

const info = (t) => {
  return (
    <>
      <p>
        {t("certificationFileOptimisation")}
        <br />
        {t("certificationDont")}
        <br />
        {t("certificationOp1")}
        <br />
        {t("certificationOp2")}
        <br />
        {t("certificationOp3")}
        <br />
        {t("certificationOp4")}
        <br />
        {t("certificationOp5")}
        <br />
        {t("certificationOp6")}
        <br />
        {t("certificationOp7")}
        <br />
        {t("certificationOp8")}
      </p>
    </>
  );
};

class VerifyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      base64URL: "",
      setSelectedFile: null,
      file: null,
      arrPic: [],
      filesNames: "",

      birthDate: "",
      phoneNumber: "+33",
      addressLine1: "",
      addressLine2: null,
      city: "",
      region: "",
      postalCode: "",
      country: "",

      maxDate: null,
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var age = currentYear - 18;
    this.setState({ maxDate: age + "-12-31" });
  }

  clickButton = (e) => {
    this.myRef.current.click();
  };

  handleFileInputChange = (e) => {
    let { file, arrPic, filesNames } = this.state;
    this.setState({
      filesNames: "",
    });

    file = e.target.files[0];

    let arrPicTwo = [];
    if (file.name.includes(".pdf")) {
      this.getBase64(file)
        .then((result) => {
          file["base64"] = result;
          arrPicTwo.push(result);
          this.setState({
            filesNames: file.name,
          });
          this.setState({
            base64URL: result,
            file,
            arrPic: arrPicTwo,
          });
        })
        .catch((err) => {
          console.log(err);
        });
      this.setState({
        file: e.target.files[0],
      });
    } else {
      new Compressor(file, {
        quality: 0.6,
        success: (compressedResult) => {
          this.getBase64(compressedResult)
            .then((result) => {
              file["base64"] = result;
              arrPicTwo.push(result);
              this.setState({
                filesNames: file.name,
              });
              this.setState({
                base64URL: result,
                file,
                arrPic: arrPicTwo,
              });
            })
            .catch((err) => {
              console.log(err);
            });
          this.setState({
            file: e.target.files[0],
          });
        },
      });
    }

    setTimeout(() => {
      if (this.state.base64URL.includes("data:application/pdf;base64,")) {
        this.setState({
          base64URL: this.state.base64URL.slice(28),
        });
      } else {
        this.setState({
          base64URL: this.state.base64URL,
        });
      }
    }, 2000);
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };

  async addfile(e) {
    this.setState({ loading: true });

    const { base64URL } = this.state;
    await Url.Url1.post("demandeCertification", {
      userId: this.context.user.id,
      certification: base64URL,
      image: false,
    })
      .then((response) => {
        this.updateAdressInformation();
      })
      .catch((error) => {
        //NotificationManager.error("error :", error);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
    this.setState({ loading: false });
  }

  updateAdressInformation = async () => {
    const {
      phoneNumber,
      birthDate,
      country,
      addressLine1,
      addressLine2,
      city,
      region,
      postalCode,
      file,
    } = this.state;
    if (
      file &&
      phoneNumber &&
      birthDate &&
      country &&
      addressLine1 &&
      city &&
      region &&
      postalCode
    ) {
      let addressL2 = addressLine2;
      if (addressLine2 === "") addressL2 = null;

      const data = {
        userId: this.context.user.id,
        addressLine1: this.state.addressLine1,
        addressLine2: addressL2,
        city: this.state.city,
        region: this.state.region,
        postalCode: this.state.postalCode,
        country: this.state.country,
        phoneNumber: this.state.phoneNumber,
        birthDate: this.state.birthDate,
      };
      await this.props.updateAdressInformation(data);
      this.goToprofileConfirmations();
    } else {
      notification.error({
        message: "Obligatoire",
        description: "Tous les champs sont obligatoire!",
      });
    }
  };

  goToprofileConfirmations() {
    this.props.history.push({
      pathname: "/profil-verifier",
    });
  }

  getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
      countryISO: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.long_name;
            address["countryISO"] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;

    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }

    return address;
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.addressObj != this.state.addressObj) {
      const { addressObj } = this.state;
      const geocodeObj =
        addressObj &&
        addressObj.value &&
        (await geocodeByPlaceId(addressObj.value.place_id));
      const addressObject =
        geocodeObj && this.getAddressObject(geocodeObj[0].address_components);

      this.setState({
        addressPlaces: addressObject,
        city: addressObject.city,
        region: addressObject.province,
        postalCode: addressObject.postal_code,
        addressLine1: addressObj.label, //addressObject.address,
        country: addressObject.countryISO,
      });
    }
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { t } = this.context.t;
    const {
      birthDate,
      phoneNumber,
      addressLine1,
      addressLine2,
      city,
      region,
      postalCode,
      country,
      file,
    } = this.state;
    const i18n = this.context.i18n;
    return (
      <>
        <Helmet>
          <title>Make My Work | Vérifie ton profil</title>
        </Helmet>
        <NavBarConnected />

        <main id="main" style={{ margin: "3rem 0" }}>
          <section
            id={this.state.file ? "sec7" : "sec7-3"}
            className="sec7 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container ">
              <div className="container-mailsent">
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="image-verify-profile"
                    src={require("../../assets/img/certifie/certif.png")}
                    alt="..."
                  />
                </div>

                <div
                  className="row  justify-content-center align-items-center mb-5"
                  style={{ marginTop: "3rem" }}
                >
                  <p className="text-futura-16">{t("descProfileCertified")}</p>
                </div>

                <div className="d-flex w-100 justify-content-center">
                  <div className="width-90 ">
                    <form
                      id="contact-form"
                      className="w-100 d-flex justify-content-center align-items-center flex-column"
                    >
                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableBirthDay")}
                        </p>
                        <div className="all-input">
                          <input
                            type="date"
                            max={this.state.maxDate}
                            name="birthDate"
                            className="w-100 fix-input"
                            id="user_date"
                            onChange={(e) => this.handleInputChange(e)}
                            value={this.state.birthDate}
                            disabled={this.state.loading}
                            required
                          />
                        </div>
                        {this.state.inputError && birthDate === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredBirthDay")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lablePhoneNumber")}
                        </p>
                        <div className="all-input">
                          {/* <input
                            type="number"
                            className="w-100 fix-input"
                            name="phoneNumber"
                            id="user_Tel"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={this.state.loading}
                          /> */}
                          <PhoneInput
                            defaultCountry="FR"
                            placeholder="Enter phone number"
                            value={this.state.phoneNumber}
                            onChange={(e) =>
                              e && this.setState({ phoneNumber: e })
                            }
                            required
                            disabled={this.state.loading}
                            className="w-100 fix-input"
                            name="phoneNumber"
                            id="user_Tel"
                          />
                          <i>
                            <Phone width={24} height={24} />
                          </i>
                        </div>

                        {this.state.inputError && phoneNumber === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredPhoneNumber")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableAddressLine1")}
                        </p>
                        <div className="all-input">
                          <GooglePlacesAutocomplete
                            apiKey="AIzaSyBRg0WgLuj6W49GfqwVITzEApOmt903zf0"
                            selectProps={{
                              noOptionsMessage: () =>
                                i18n.language === "fr"
                                  ? "Indiquez votre ville"
                                  : "Indicate your city",
                              placeholder: "",
                              isClearable: true,
                              value: this.state.addressObj,
                              isDisabled: this.state.loading,
                              onChange: (val) => {
                                this.setState({ addressObj: val });
                              },
                            }}
                          />
                          <i>
                            <CreditCard width={24} height={24} />
                          </i>
                        </div>
                        {this.state.inputError && addressLine1 === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredAddressLine1")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableAddressLine2")}
                        </p>
                        <div className="all-input">
                          <input
                            type="text"
                            className="w-100 fix-input"
                            value={this.state.addressLine2}
                            name="addressLine2"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={this.state.loading}
                          />
                          <i>
                            <CreditCard width={24} height={24} />
                          </i>
                        </div>
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableTown")}
                        </p>
                        <div className="all-input">
                          <input
                            type="text"
                            className="w-100 fix-input"
                            value={this.state.city}
                            name="city"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={this.state.loading}
                          />
                          <i>
                            <Localisation />
                          </i>
                        </div>

                        {this.state.inputError && city === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredTown")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableRegion")}
                        </p>
                        <div className="all-input">
                          <input
                            type="text"
                            className="w-100 fix-input"
                            value={this.state.region}
                            name="region"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={this.state.loading}
                          />
                          <i>
                            <Localisation />
                          </i>
                        </div>

                        {this.state.inputError && region === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredRegion")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lablePostalCode")}
                        </p>
                        <div className="all-input">
                          <input
                            type="number"
                            className="w-100 fix-input"
                            value={this.state.postalCode}
                            name="postalCode"
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={this.state.loading}
                          />
                          <i>
                            <IconMail />
                          </i>
                        </div>

                        {this.state.inputError && postalCode === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredPostalCode")}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-cnx w-100">
                        <p className="text-futura-16 text-align-left">
                          {t("lableCountry")}
                        </p>
                        <div className="all-input">
                          <input
                            type="text"
                            className="w-100 fix-input"
                            name="country"
                            value={this.state.country}
                            onChange={(e) => this.handleInputChange(e)}
                            required
                            disabled={
                              this.state.loading ||
                              (this.state.country && this.state.country)
                            }
                          />
                          <i>
                            <Localisation />
                          </i>
                        </div>

                        {this.state.inputError && country === "" ? (
                          <p className="input-msg-error">
                            {t("errRequiredCountry")}
                          </p>
                        ) : null}
                      </div>

                      <input
                        ref={this.myRef}
                        className="form-creation"
                        type="file"
                        id="formFile"
                        style={{ display: "none" }}
                        onChange={(e) => this.handleFileInputChange(e)}
                        required
                        disabled={this.state.loading}
                      />
                      <p className="text-futura-16 text-align-left m-0 w-100">
                        {t("lableAddFiles")}

                        <Popover
                          content={info(t)}
                          title={t("certificationFileRecommandation")}
                          trigger="hover"
                          overlayStyle={{ width: "25vw" }}
                        >
                          <Button id="btn-get-info">
                            <i className="bi bi-info-circle"></i>
                          </Button>
                        </Popover>
                      </p>

                      <span
                        className="form-input-file w-100"
                        onClick={(e) => this.clickButton(e)}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <span className="w-100 pl-2">
                            {this.state.filesNames && (
                              <p className="text-futura-14 text-align-left m-0">
                                {this.state.filesNames}
                              </p>
                            )}
                          </span>

                          {this.state.filesNames.length > 0 ? (
                            <span
                              className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left text-futura-14 text-align-left"
                              style={{ paddingRight: "15px" }}
                            >
                              | {t("btnEDIT")}
                            </span>
                          ) : (
                            <span
                              className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left text-futura-14 text-align-left"
                              style={{ paddingRight: "15px" }}
                            >
                              | {t("btnBROWSE")}
                            </span>
                          )}
                        </div>
                      </span>

                      <div className=" d-flex justify-content-center mt-5">
                        <button
                          type="submit"
                          id="submit"
                          className={
                            file &&
                            phoneNumber &&
                            birthDate &&
                            country &&
                            addressLine1 &&
                            city &&
                            region &&
                            postalCode
                              ? "btn-mmk"
                              : "btn-mmk-grey-opacity"
                          }
                          onClick={(e) => this.addfile(e)}
                          disabled={this.state.loading}
                        >
                          {this.state.loading ? (
                            <SpinLoading />
                          ) : (
                            t("buttonValidate")
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-end align-items-end">
                  <div className="d-flex justify-content-center align-items-end mt-5">
                    <Link to="/accueil">
                      <p className="text-roboto-regular-16 m-0">
                        {t("linkLater")}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

/*************************************************************************************************************************************/
const mapStateToProps = (state) => {
  return {
    skills: state.SkillsReducer.skills,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {
    getAllSkills: () => dispatch(actionCreator.getAllSkills()),
    updateAdressInformation: (data) =>
      dispatch(actionCreator.updateAdressInformation(data)),
  };
};
/*************************************************************************************************************************************/
VerifyProfile.contextType = AuthContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyProfile));
