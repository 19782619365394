import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

export default class Footer extends Component {
  render() {
    const { t } = this.context.t;
    return (
      <React.Fragment>
        <footer id="footer" className="footer">
          <div className="footer-top">
            <div className="container-mmk w-100">
              <div className="row ">
                <div className="col-md-3 footer-links mt-5">
                  <p className="text-futura-30-bold text-align-left">
                    {t("mmk")}
                  </p>

                  <ul>
                    <li>
                      {" "}
                      <NavLink to="/contact">
                        <p className="text-futura-16 text-align-left">{t("contact")}</p>
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/actualites">
                        <p href className="text-futura-16 text-align-left">
                          {t("blog")}
                        </p>
                      </NavLink>
                    </li>
                    {/* <li>
                      {" "}
                      <p className="text-futura-16 text-align-left">{t("whoAreWe")}?</p>
                    </li> */}
                  </ul>
                </div>

                <div className="col-md-4 footer-links mt-5 ">
                  <ul>
                    <li>
                      <p className="text-futura-30-bold text-align-left">
                        {t("legalInfo")}
                      </p>
                    </li>

                    <li>
                      <Link to="/mentions-legales">
                        <p className="text-futura-16 text-align-left">{t("legalMention")}</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/politique-de-confidentialite">
                        <p className="text-futura-16 text-align-left">{t("privacyPolicy")}</p>
                      </Link>
                    </li>
                    {/* <li>
                      <p className="text-futura-16 text-align-left">{t("sitePlan")}</p>
                    </li> */}
                    <li>
                      <Link to="/condition-generale-utilisation">
                        <p className="text-futura-16 text-align-left">
                          {t("generalConditions")}
                        </p>
                      </Link>
                    </li>
                    <li>
                      <p className="text-futura-16 text-align-left">{t("cookies")}</p>
                    </li>
                    {/* <li>
                      <p className="text-futura-16 text-align-left">{t("FAQ")}</p>
                    </li> */}
                  </ul>
                </div>

                <div className="col-md-1 social-links"></div>

                <div className="col-md-4 social-links mt-5">
                  <div className="grid-social-links">
                    <a
                      className="text-center text-md-start"
                      href="https://twitter.com/makemywork_"
                    >
                      <img
                        src={require('../assets/img/twitter.png')}
                        alt=""
                        height="56px"
                        width="56px"
                        className="pointer"
                      />
                    </a>

                    <a
                      className="text-center text-md-start"
                      href="https://www.linkedin.com/company/make-my-work/"
                    >
                      <img
                      src={require('../assets/img/linkedin.png')}
                        alt=""
                        height="56px"
                        width="56px"
                        className="pointer"
                      />
                    </a>

                    <a
                      className="text-center text-md-start"
                      href="https://www.facebook.com/MakeMyWork1"
                    >
                      <img
                        src={require('../assets/img/facebook.png')}
                        alt=""
                        height="56px"
                        width="56px"
                        className="pointer"
                      />
                    </a>

                    <a
                      className="text-center text-md-start"
                      href="https://www.instagram.com/makemywork/"
                    >
                      <img
                       src={require('../assets/img/instagram.png')}
                        alt=""
                        height="56px"
                        width="56px"
                        className="pointer"
                      />
                    </a>
                  </div>

                  <div className=" footer-contact-grid ">
                    <div className="footer-contact pointer" onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.trimakus.mmw", '_blank', 'noopener,noreferrer')}>
                      <img src={require('../assets/img/badges-googl-play2.png')} alt=""/>
                    </div>

                    <div className="footer-contact pointer" onClick={()=>window.open("https://apps.apple.com/tn/app/makemywork/id1628763862", '_blank', 'noopener,noreferrer')}>
                      <img src={require('../assets/img/badges-appstore2.png')} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-5">
                <p className="text-futura-14 text-align-left">
                  {t("copyRight")}
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

Footer.contextType = AuthContext;
