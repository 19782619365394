import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  percentage: 0,
  orderList: null,
  serviceList: null,
  condidature: null,
  motifs: null,
  userBankAccountInfo: null,
};

const CandidacyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.PERCENTAGE_MMK:
      return {
        ...state,
        percentage: action.payload,
      };
    case actionType.GET_ORDER_LIST: {
      return {
        ...state,
        orderList: action.payload,
      };
    }
    case actionType.GET_SERVICE_LIST: {
      return {
        ...state,
        serviceList: action.payload,
      };
    }
    case actionType.GET_DETAIL_CANDIDATURE: {
      return {
        ...state,
        condidature: action.payload,
      };
    }

    case actionType.GET_MOTIF: {
      return {
        ...state,
        motifs: action.payload,
      };
    }

    case actionType.VERFIY_USER_BANK_ACCOUNT: {
      return {
        ...state,
        userBankAccountInfo: action.payload,
      };
    }

    default:
      return { ...state };
  }
};
export default CandidacyReducer;
