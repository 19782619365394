import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const homePageNotConnected = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("homePageListingNotConnected", {
        announcementType: data.announcementType,
        categoryType: data.categoryType,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.HOME_PAGE_NOT_CONNECTED,
            payload: response.data,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const homePageConnected = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("homePageListingConnected", {
        announcementType: data.announcementType,
        categoryType: data.categoryType,
        userId: data.userId,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.HOME_PAGE_CONNECTED,
            payload: response.data,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};
