import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import NavBarConnected from "../../components/NavBarConnected";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Url from "../../constants/Url";
import { AuthContext } from "../../context/AuthContext";
import FullStar from "../../components/icons/FullStar";
import EmptyStar from "../../components/icons/EmptyStar";
import NavbarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreator from "../../redux/actions/index";
import { Dropdown, Menu, Modal, notification } from "antd";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

function AnnouncementDetails() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [announce, setAnnounce] = useState(null);
  const { user } = useContext(AuthContext);
  const [loadingService, setLoadingService] = useState(false);
  const [openModalWaitingForVerification, setOpenModalWaitingForVerification] =
    useState(false);
  const [openModalBankAccount, setOpenModalBankAccount] = useState(false);
  const [openModalFileNotSend, setOpenModalFileNotSend] = useState(false);
  const [openModalForAvis, setOpenModalForAvis] = useState(false);
  const [openModalReport, setOpenModalReport] = useState(false);
  const [openModalBloc, setOpenModalBloc] = useState(false);


  const [reasonOfReport, setReasonOfReport] = useState(null);

  const [loadingReport, setLoadingReport] = useState(false);

 

  const userBankAccountInfo = useSelector(
    (state) => state.CandidacyReducer.userBankAccountInfo
  );
  const userAvis = useSelector((state) => state.ProfileReducer.otherUserAvis);
  const otherUserAvgStarRating = useSelector(
    (state) => state.ProfileReducer.otherUserAvgStarRating
  );

  useEffect(() => {
    if (location.state) {
      setAnnounce(location.state);

      const data = {
        userId: location.state.user[0].id,
        me: false,
      };

      dispatch(actionCreator.getAvisUser(data));
    }
  }, [location]);

  useEffect(() => {
    if (userBankAccountInfo) {
      if (
        userBankAccountInfo.userHaveBankAccount &&
        userBankAccountInfo.fileMangoPayUploaded &&
        userBankAccountInfo.isCertified === "2"
      ) {
        DemandeService();
      } else {
        if (
          userBankAccountInfo.isCertified !== "2" ||
          userBankAccountInfo.isCertified !== "4"
        ) {
          setOpenModalFileNotSend(true);
        }else
        if (userBankAccountInfo.userHaveBankAccount !== true) {
          setOpenModalBankAccount(true);
        }
        else if (userBankAccountInfo.fileMangoPayUploaded !== true) {
          setOpenModalWaitingForVerification(true);
        }
      }
    }
  }, [JSON.stringify(userBankAccountInfo)]);

  const VerifyUserBankAccount = async () => {
    if (user && announce.announcementType === "demande") {
      dispatch(actionCreator.testIfUserHaveBankAccount(user.id));
    } else {
      DemandeService();
    }
  };

  const DemandeService = async () => {
    setLoadingService(true);
    if (user) {
      await Url.Url4.post("demanderService", {
        userId: user.id,
        announcementId: announce.id,
        playerId: "",
      })
        .then((response) => {
          setLoadingService(false);
          history.push({
            pathname: "/messagerie",
            state: response.data.senderId,
          });
        })
        .catch((error) => {
          setLoadingService(false);
          //NotificationManager.error(error.message);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } else {
      setLoadingService(false);
      history.push({ pathname: "/connexion" });
    }
  };

  const closeModalAndGoToCreateBankAccount = () => {
    if (user) history.push({ pathname: "/profil", state: "needBankAccount" });
    else history.push({ pathname: "/connexion" });
  };

  const closeModalAndGoToVerifyAccount = () => {
    if (user) history.push({ pathname: "/verifie-ton-profil" });
    else history.push({ pathname: "/connexion" });
  };

  const closeModalAndGoToProfile = () => {
    if (user) history.push({ pathname: "/profil" });
    else history.push({ pathname: "/connexion" });
  };

  const NextArrow = (props) => {
    const { className, onClick, icon } = props;
    return (
      <a className={`nav default-nav  ${className}`} onClick={onClick}>
        {icon ? (
          <i className={icon} style={{ color: "#000" }}></i>
        ) : (
          <i className="icon-chevron-right" style={{ color: "#000" }}></i>
        )}
      </a>
    );
  };

  const PrevArrow = (props) => {
    const { className, onClick, icon } = props;
    return (
      <a className={`nav default-nav ${className}`} onClick={onClick}>
        {icon ? (
          <i className={icon} style={{ color: "#000" }}></i>
        ) : (
          <i className="icon-chevron-left " style={{ color: "#000" }}></i>
        )}
      </a>
    );
  };

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    arrows: true,
    prevArrow: <PrevArrow icon="icon-chevron-left" className="slick" />,
    nextArrow: <NextArrow icon="icon-chevron-right" className="slick" />,
  };

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  const blockUserByOtherUSer = () => {
    setLoadingReport(true);
    const data = {
      "senderId": user.id,
      "recieverId": announce.user[0].id
    };
    dispatch(actionCreator.blockUserByOtherUSer(data));
    setLoadingReport(false);
    setOpenModalBloc(false);
    history.goBack();
  };

  const reportAbusive = async () => {
    setLoadingReport(true);
    const data = {
      "senderId": user ? user.id : null,
      "receiverId": null,
      "reasonOfReport": reasonOfReport,
      "annonceId": announce.id,
      "type": "annonce",
    };
    await dispatch(actionCreator.reportAbusive(data));
    setLoadingReport(false);
    setOpenModalReport(false);
    setReasonOfReport(null);

  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {(announce && announce.announcementTitle) || "Annonce | loading..."}
        </title>
        <meta name="description" content="Make my work" />
      </Helmet>
      {user ? (
        <NavBarConnected props={announce} />
      ) : (
        <NavBarNotConnected props={announce} />
      )}
      <NavbarMobile />
      <main id="main">
        <section data-aos="fade-up" data-aos-delay="200">
          <div className="container mt-3">
          
              <div className="d-flex align-items-center justify-content-end">
                <Dropdown
                  overlay={
                    <Menu>
                      {user ?
                        <Menu.Item key="1">
                          <div
                            className="text-futura-14 d-flex align-items-center justify-content-center"
                            onClick={() => setOpenModalBloc(true)}
                          >
                            {t("blocTitle")}
                          </div>
                        </Menu.Item>
                      :null }
                      
                      <Menu.Item key="2">
                        <div
                          className="text-futura-14 d-flex align-items-center justify-content-center"
                          onClick={() => setOpenModalReport(true)}
                        >
                          {t("lableReport")}
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <i className="bi bi-three-dots-vertical"></i>
                </Dropdown>
              </div>

              
        

            {announce ? (
              <div className="row justify-content-center align-items-center ">
                <div className="col container-max-width">
                  {announce.picture.length > 0 ? (
                    <div className="slide-container">
                      <Slide {...properties}>
                        {announce.picture.map((image, key) => (
                          <div className="each-slide" key={key}>
                            <img
                              src={
                                image ||
                                require("../../assets/img/No-image.jpeg")
                              }
                              height="330px"
                              width="100%"
                              style={{
                                borderRadius: "10px",
                                backgroundSize: "cover",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        ))}
                      </Slide>
                    </div>
                  ) : null}

                  <div className="d-flex justify-content-between align-items-star mt-5">
                    <p className="text-futura-20">{t("description")}</p>

                    <div className="d-flex">
                      <p className="text-futura-14 px-5">{announce.price}€</p>
                      <div
                        className="pointer d-flex"
                        onClick={() => setOpenModalForAvis(true)}
                      >
                        <p className="text-futura-14 px-2">
                          {otherUserAvgStarRating
                            ? parseFloat(otherUserAvgStarRating).toFixed(2)
                            : null}
                        </p>
                        <FullStar width={22} height={21} />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end align-items-star mb-3">
                    <p className="text-roboto-regular-12-grey">
                      {announce.city}{" "}
                    </p>
                  </div>

                  <div className="details-card mt-2 mb-5">
                    <p className="text-futura-14 text-align-left">
                      {announce.announcementDescription}
                    </p>
                  </div>

                  <div className="d-flex justify-content-center align-items-star my-5">
                    {announce && announce.announcementType === "offre" ? (
                      <button
                        className="btn-mmk"
                        onClick={() => VerifyUserBankAccount()}
                        disabled={loadingService}
                      >
                        {loadingService ? (
                          <SpinLoading />
                        ) : (
                          t("btnRequestService")
                        )}
                      </button>
                    ) : (
                      <button
                        className="btn-mmk"
                        onClick={() => VerifyUserBankAccount()}
                        disabled={loadingService}
                      >
                        {loadingService ? (
                          <SpinLoading />
                        ) : (
                          t("btnProposeService")
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </section>

        <Modal
          open={openModalBankAccount}
          width={"62rem"}
          onCancel={() => closeModalAndGoToCreateBankAccount()}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("needBankAccount")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">{t("registerData")}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalWaitingForVerification}
          width={"62rem"}
          onCancel={() => closeModalAndGoToProfile()}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("waitingVerification")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">
                    {t("weDidntValidateYourRequest")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalFileNotSend}
          width={"62rem"}
          onCancel={() => closeModalAndGoToVerifyAccount()}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("verifyProfile")} !</p>

            <div className="content">
              <div className="col-12 d-flex justify-content-center align-items-center mb-5">
                <img
                  src={require("../../assets/img/Annonce/popup.png")}
                  className="image-announce-created"
                  alt="..."
                />
              </div>

              <div className="d-flex justify-content-center align-items-center mb-5">
                <div className="col col-8 ">
                  <p className="text-futura-14">{t("descVerifyProfile")}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalForAvis}
          width={"62rem"}
          onCancel={() => setOpenModalForAvis(false)}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">Avis</p>

            <div className="content w-100">
              <div className="blue-scroll d-flex flex-column align-items-center height-modal-avis">
                {userAvis &&
                  userAvis.length > 0 ?
                  userAvis.map((announce, key) => (
                    <div className="card-avis mb-4" key={key}>
                      <div className="d-flex w-100">
                        {announce.user &&
                        announce.user
                          .profilePicture ? (
                          <img
                            src={
                              announce.user
                                .profilePicture
                            }
                            className="img-card-avis"
                          />
                        ) : null}
                        <div className="d-flex flex-column justify-content-start align-items-start px-3 width-78">
                          <p className="text-futura-11-grey">
                            {announce.user &&
                              announce.user.surname}
                          </p>
                          <div className="d-flex justify-content-end algin-items-center w-100">
                            {generateTempArray(
                              announce.rating
                            ).map((item) => (
                              <FullStar width={22} height={21} />
                            ))}
                            {generateTempArray(
                              5 - announce.rating
                            ).map((item) => (
                              <EmptyStar width={20} height={20} />
                            ))}
                          </div>
                          <p className="text-futura-14 overflow-hidden ellipsis-text-card-avis">
                            {announce.commentaire}
                          </p>
                        </div>
                      </div>
                    </div>
                  )):<span className="no-items">{t("noItems")}</span>}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModalReport}
          width={"60rem"}
          onCancel={() => setOpenModalReport(false)}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("lableReport")}</p>
            <div className="d-flex justify-content-start w-100 mt-3">
              <p className="text-futura-16 text-align-left">
                {t("lableComment")}
              </p>
            </div>

            <div className="content w-100">
              <div className="blue-scroll d-flex flex-column align-items-center">
                <textarea
                  className="input-textArea"
                  value={reasonOfReport}
                  onChange={(e) => setReasonOfReport(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <button
                className={
                  reasonOfReport
                    ? "btn-mmk mt-5 mb-2"
                    : "btn-mmk-grey-opacity mt-5 mb-2"
                }
                onClick={() => reportAbusive()}
                disabled={loadingReport}
              >
                {loadingReport ? <SpinLoading /> : t("btnSent")}
              </button>
            </div>
          </div>
        </Modal>

          <Modal
          open={openModalBloc}
          width={"50rem"}
          onCancel={() => setOpenModalBloc(false)}
          footer={false}
        >
          <div className="justify-content-center align-items-center">
            <p className="text-futura-30 mb-4">{t("blocTitle")}</p>
            <div className="d-flex justify-content-center w-100 mt-3">
              <p className="text-futura-16 text-align-left">
                {t("blocText")}
              </p>
            </div>

            <div className="d-flex align-items-center justify-content-center gap-3">
              <button
                className="btn-mmk mt-5 mb-2"
                onClick={() => blockUserByOtherUSer()}
                disabled={loadingReport}
              >
                {loadingReport ? <SpinLoading /> : t("btnYes")}
              </button>

              <button
                className="btn-mmk-grey-opacity mt-5 mb-2"

                onClick={() => setOpenModalBloc(false)}
                disabled={loadingReport}
              >
                {loadingReport ? <SpinLoading /> : t("btnNo")}
              </button>
            </div>
          </div>
        </Modal>

      </main>
    </React.Fragment>
  );
}

export default AnnouncementDetails;
