import React from "react";
import Logo from "../img/mylogoM.png";

function Preloader() {
  return (
    <div className="preloader">
      <div className="prelaoder__content">
        <img className="logo__loader" src={Logo}></img>
      </div>
    </div>
  );
}

export default Preloader;
