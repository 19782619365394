import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Url from "../../constants/Url";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import NavbarMobile from "../../components/NavbarMobile";
import { notification } from "antd";

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: "",
      newPassword: "",
      confirmPassword: "",
      token: "",
    };
  }

  componentDidMount() {
    let search = this.props.location.search;
    if (search) {
      let index = search.indexOf("=");
      this.setState({ token: search.substring(index + 1) });
    }
  }

  async resetPassword(e) {
    const { newPassword, confirmPassword } = this.state;
    e.preventDefault();
    if (newPassword && confirmPassword && newPassword == confirmPassword) {
      await Url.Url1.post("resetPwdWeb", {
        newPassword: this.state.newPassword,
        token: this.state.token,
      })
        .then((response) => {
          notification.success({

            message: "Mettre à jour du mot de passe",
            description: "La mise à jour du mot de passe a réussi",
          });
          this.props.history.push({ pathname: "/connexion" });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    }
  }
  render() {
    return (
      <>
        <NavBarNotConnected />
        <NavbarMobile />

        <main id="main">
          <section
            id="sec6"
            className="sec6 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container mt-5">
              <div className="row justify-content-center">
                <div className="col-6 justify-content-center align-items-center">
                  <div className="row  justify-content-center align-items-center">
                    <div className="col-12 d-flex justify-content-center align-items-center">
                      <img
                        className="img-size"
                        src={require('../../assets/img/connx/connect.png')}
                        alt="..."
                      />
                    </div>

                    <div className="row justify-content-center align-items-center">
                      <div className="col-2 "></div>
                      <div className="col-8 ">
                        <p>
                        Ta demande de réinitialisation de mot de passe a bien été prise en compte. Tu peux dés maintenant changer ton mot de passe.
                        </p>
                      </div>
                      <div className="col-2 "></div>
                    </div>

                    <div className="col-8 ">
                      <label className="label-pass">Nouveau mot de passe</label>
                      <input
                        type="password"
                        name="password"
                        className="form-creation"
                        id="user_password"
                        onChange={(e) =>
                          this.setState({ newPassword: e.target.value })
                        }
                      />
                    </div>
                    <div className="col-8 ">
                      <label className="label-pass">
                        Confirmer mot de passe
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-creation"
                        id="user_password"
                        onChange={(e) =>
                          this.setState({ confirmPassword: e.target.value })
                        }
                      />
                      {this.state.confirmPassword != "" &&
                      this.state.confirmPassword != this.state.newPassword ? (
                        <span style={{ color: "red" }}>
                          Les mots de passe ne sont pas identiques.
                        </span>
                      ) : null}
                    </div>

                    <div className="row gy-4  justify-content-center align-items-center mb-5">
                      <div className="col-12 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className={
                            this.state.newPassword &&
                            this.state.confirmPassword &&
                            this.state.confirmPassword == this.state.newPassword
                              ? "btn-mmk"
                              : "btn-gray"
                          }
                          onClick={(e) => this.resetPassword(e)}
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(NewPassword);
