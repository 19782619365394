import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const getAllSkills = () => {
  return async (dispatch) => {
    try {
      await Url.Url2.get("getCategories")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            const element = response.data[i];
            Object.assign(element, { isSelected: false });
          }
          dispatch(setSkills(response.data));
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const setSkills = (data) => {
  return {
    type: actionsTypes.GET_ALL_SKILLS,
    payload: data,
  };
};
