/*****************DEV*************** */

// export const MangoclientId = "makemyworktest3";
// export const Mangopassword =
//   "2mDGKkdg2SfC3vsTrecD7tAeksiLHqMjVK83fBeAicwdGwajrg";
// export const ID_USER_WALLET_MANGO_PAY = "wlt_m_01HQ5D6E3K2YPKCN30KAVQ6QS1";
// export const urlTokenMangoPay = "https://api.sandbox.mangopay.com/v2.01/oauth/token";

/****************PROD************** */

export const MangoclientId = "makemywork";
export const Mangopassword =
  "2mDGKkdg2SfC3vsTrecD7tAeksiLHqMjVK83fBeAicwdGwajrg";
export const ID_USER_WALLET_MANGO_PAY = "2774396113";
export const urlTokenMangoPay = "https://api.mangopay.com/v2.01/oauth/token";

export const ENDPOINT = "https://api.mangopay.com"; // prod
//export const ENDPOINT = "https://api.sandbox.mangopay.com"; // dev
