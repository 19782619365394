import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Url from "../constants/Url";
import Pusher from "pusher-js";
import { getToken , destroyToken } from "../routes/shared";
import { notification } from "antd";

export const AuthContext = createContext();

export function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [reCallApi, setReCallApi] = useState(false);
  const t = useTranslation();
  const { i18n } = useTranslation();

  useEffect(async () => {
    let token = getToken();
    if (token) {
      await Url.Url1.post("getUserInformations", {
        token: token,
      })
        .then((res) => {
          if(res.data.isBlocked){
            notification.error({
              message: "Bloqué",
              description: "Vous êtes bloqué !",
            });
            setTimeout(() => {
              logOut(res.data.id)
            }, 2000);
            
          }else{
            setUser(res.data);
          }
        })
        .catch(() => {
          destroyToken()
          window.location.reload(true)
        });
    }
  }, []);

  const logOut = (userId) => {
    const pusher = new Pusher("d69d1373d3c67363441e", {
      cluster: "eu",
    });
    pusher.unsubscribe(userId.toString());
    pusher.disconnect();

    destroyToken();
  };

  return (
    <AuthContext.Provider
      value={{ user, setUser, reCallApi, setReCallApi, t, i18n }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
