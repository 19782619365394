import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import { withRouter } from "react-router-dom";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import NavbarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import { AuthContext } from "../../context/AuthContext";

class Confirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      codeVerification: "",
      userInfo: props.userInfo,
    };
  }

  componentDidMount() {
    const currentURL = window.location.href;
    const codeVerification = currentURL.split("#");
    this.setState({
      codeVerification: codeVerification[codeVerification.length - 1],
    });
  }

  async verify() {
    this.setState({ loading: true });
    const { codeVerification } = this.state;
    const data = {
      codeVerification: codeVerification,
    };
    await this.props.verify(data);
    this.setState({ loading: false });

    if (this.props.connectedSucceed) {
      this.props.history.push({ pathname: "/pseudo" });
    }
  }

  render() {
    const { t } = this.context.t;
    return (
      <>
        <NavBarNotConnected />
        <NavbarMobile />

        <main id="main">
          <section
            id="sec7"
            className="sec7 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container">
              <div className="container-mailsent">
                <div className=" d-flex justify-content-center align-items-center">
                  <img
                    className="image-mail-sent"
                    src={require('../../assets/img/confirm/conf.png')}
                    alt="..."
                  />
                </div>

                <div className="d-flex  justify-content-center align-items-center">
                  <p className="text-futura-30">{t("confirmedMail")}</p>
                </div>

                <div className="row  justify-content-center align-items-center">
                  <p className="text-futura-16">{t("confirmedMailDesc")}</p>
                </div>
                <div className=" d-flex justify-content-center my-5">
                  <button
                    onClick={() => this.verify()}
                    id="submit"
                    className="btn-mmk"
                    disabled={this.state.loading}
                  >
                    {this.state.loading ? <SpinLoading /> : t("buttonNext")}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

/*************************************************************************************************************************************/
const mapStateToProps = (state) => {
  return {
    connectedSucceed: state.AuthReducer.connectedSucceed,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {
    verify: (data) => dispatch(actionCreator.Verify(data)),
  };
};
/*************************************************************************************************************************************/

Confirmation.contextType = AuthContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Confirmation));
