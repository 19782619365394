//make My Work
export const NATIV_SIGNUP = 'NATIV_SIGNUP';
export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';
export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';
export const NATIV_SIGNIN = 'NATIV_SIGNIN';
export const MAIL_IN_USED = 'MAIL_IN_USED';
export const HAS_AVIS = "HAS_AVIS";



//skill actions

export const GET_ALL_SKILLS = "GET_ALL_SKILLS";

// home actions
export const GET_DATA = "GET_DATA";
export const HOME_PAGE_NOT_CONNECTED = "HOME_PAGE_NOT_CONNECTED";
export const HOME_PAGE_CONNECTED = "HOME_PAGE_CONNECTED";


export const SUCCESS_ADD_ANNONCE = "SUCCESS_ADD_ANNONCE"
// announcement actions

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ALL_ANNOUNCEMENT = "GET_ALL_ANNOUNCEMENT";
export const ANNOUNCEMENT_DETAIL = "ANNOUNCEMENT_DETAIL";
export const GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_USER = "GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_USER";
export const GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_OTHER_USER = "GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_OTHER_USER";


// profil actions

export const GET_USER_DETAIL = "GET_USER_DETAIL";
export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const ADD_PROFILE_PICTURE = "ADD_PROFILE_PICTURE"
export const CREATE_BACK_ACCOUNT = "CREATE_BACK_ACCOUNT"
export const GET_USER_AVIS = "GET_USER_AVIS"
export const GET_OTHER_USER_DETAIL = "GET_OTHER_USER_DETAIL";
export const GET_OTHER_USER_AVIS = "GET_OTHER_USER_AVIS";
export const GET_SERVICE_NUMBER= "GET_SERVICE_NUMBER";
export const GET_USER_ACTIVITY= "GET_USER_ACTIVITY";
export const GET_SERVICE_NUMBER_FOR_OTHER_USER= "GET_SERVICE_NUMBER_FOR_OTHER_USER";






// search actions
export const TITLE_ANNOUNCEMENT_SEARCH = "TITLE_ANNOUNCEMENT_SEARCH"
export const ADVANCED_SEARCH = "ADVANCED_SEARCH"
export const SEARCH_ANNOUNCEMENT = "SEARCH_ANNOUNCEMENT"
export const GET_ANNOUNCE_BY_CATEGORY = "GET_ANNOUNCE_BY_CATEGORY"
export const HANDLE_INPUT_SEARCH = "HANDLE_INPUT_SEARCH"
export const PROFIL_RECOMMENDED = "PROFIL_RECOMMENDED"
export const HANDLE_CATEGORY_TYPE = "HANDLE_CATEGORY_TYPE"



//candidacy actions
export const PERCENTAGE_MMK = "PERCENTAGE_MMK"
export const GET_ORDER_LIST = "GET_ORDER_LIST"
export const GET_SERVICE_LIST = "GET_SERVICE_LIST"
export const GET_DETAIL_CANDIDATURE = "GET_DETAIL_CANDIDATURE"
export const GET_MOTIF = "GET_MOTIF"
export const VERFIY_USER_BANK_ACCOUNT = "VERFIY_USER_BANK_ACCOUNT"


//News actions
export const GET_ARTICLE_CATEGORIES = "GET_ARTICLE_CATEGORIES"
export const GET_ARTICLE = "GET_ARTICLE"
export const GET_VIEDO = "GET_VIEDO"
export const GET_PARTNERS = "GET_PARTNERS"

//Notification actions
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const ALLOW_NOTIFICATIONS = "ALLOW_NOTIFICATIONS"


