import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';

function AnnounceCard(props) {
    const { t } = useTranslation();
  return (
   <>
        <div
        className="col-auto Photographe-item filter-Demandes pointer"
        onClick={() => props.onClick(props.props.announce)}
        key={props.props.key}
        >
            <div className="Photographe-wrap">
                <img
                className="img-size"
                width="150px"
                height="150px"
                src={
                    props.props.announce.picture[0] || require('../../assets/img/No-image.jpeg')
                }
                //style={{ height: "8rem", width: "8rem" }}
                />
                <h4 className="text-futura-14 text-align-left">
                {props.props.announce.announcementTitle}
                </h4>
                {props.props.announce.price ? (
                <p className="text-futura-14 text-align-left">
                    {t("proposedRate")} : {props.props.announce.price}€{" "}
                </p>
                ) : null}
                {props.props.announce.deadLine ? (
                <p className="text-futura-11-grey text-align-left">
                    {t("before")} {formatDate(props.props.announce.deadLine)}{" "}
                    <br />
                    {props.props.announce.city}
                </p>
                ) : (
                <p className="text-futura-11-grey text-align-left">
                    {props.props.announce.city}
                </p>
                )}
        </div>
        </div>
   </>
  )
}

export default AnnounceCard