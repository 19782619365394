import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";
import { notification } from "antd";

export const searchAnnouncement = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("searchAnnouncement", {
        announcementType: data.announcementType,
        categoryName: null,
        categoryType: null,
        announcementTitle: data.announcementTitle,
        dateDebut: null,
        dateFin: null,
        city: null,
        page: 1,
        pageSize: 100,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.SEARCH_ANNOUNCEMENT,
            payload: response.data.result,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const advancedSearch = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("advancedSearch", {
        announcementTitle: data.announcementTitle,
        announcementType: data.announcementType,
        categoryName: data.categoryName,
        categoryType: data.categoryType,
        mySkills: data.mySkills,
        dateDebut: data.dateDebut,
        userId: data.userId,
        city: data.city,
        page: 1,
        pageSize: 100,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.ADVANCED_SEARCH,
            payload: response.data.result,
          });
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAnnounceByCategorie = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getAnnounceByCategorie", {
        categorieId: data.categoryId,
        userId: data.userId,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_ANNOUNCE_BY_CATEGORY,
            payload: response.data.result,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllUsersWithHighRating = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url2.post("getAllUsersWithHighRating", {
        rating: data.rating,
        categoryName: data.categoryName,
        userId: data.userId,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.PROFIL_RECOMMENDED,
            payload: response.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
};

export const handleInputSearch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionsTypes.HANDLE_INPUT_SEARCH,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const handleCategoryType = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionsTypes.HANDLE_CATEGORY_TYPE,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    }
  };
};
