import "./message.css";
import { Document, Page, pdfjs } from "react-pdf";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Url from "../../constants/Url";
import { DatePicker, Dropdown, Menu, Modal, notification, Space } from "antd";
import StarRatings from "react-star-ratings";
import CheckCircle from "../icons/CheckCircle";
import Close from "../icons/Close";
import SpinLoading from "../SpinLoading";
import { AuthContext } from "../../context/AuthContext";
import { ethers } from "ethers";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import React from "react";
import {
  MangoclientId,
  Mangopassword,
  ENDPOINT,
  urlTokenMangoPay,
} from "../../constants/mangopay";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Message({
  message,
  own,
  me,
  percentage,
  otherUserpseudo,
  discussionId,
}) {
  const location = useLocation();

  const { reCallApi, setReCallApi } = useContext(AuthContext);

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const apikey = "pk_test_G5euPArK9e2zLcrNP8QoKDtw";

  const [openModalpayment, setOpenModalpayment] = useState(false);
  const [openModalpaymentSucces, setOpenModalpaymentSucces] = useState(false);
  const [openModalStarRate, setOpenModalStarRate] = useState(false);
  const [openModalStarRateDone, setOpenModalStarRateDone] = useState(false);
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState(null);
  const [offer, setOffer] = useState(null);

  const [nameOnCart, setNameOnCart] = useState(null);
  const [lastNameOnCart, setLastNameOnCart] = useState(null);
  const [numCarte, setNumCarte] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [cryptoGrame, setCryptoGrame] = useState(null);

  const [cartInfo, setCartInfo] = useState(false);
  const [paymentWithMMKT, setPaymentWithMMKT] = useState(false);

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingAvis, setLoadingAvis] = useState(false);
  const [loadingVirement, setLoadingVirement] = useState(false);
  const [loadingAccp, setLoadingAccp] = useState(false);
  const [loadingRef, setLoadingRef] = useState(false);
  const [loadingPaymentMetamask, setLoadingPaymentMetamask] = useState(false);

  const { t } = useTranslation();

  const [error, setError] = useState();
  const [txs, setTxs] = useState([]);

  const startPaymentWithMetamask = async () => {
    try {
      let amount;
      let cryptos;
      let mmkAddr = "0x82254b7Bef5a610281494cd0c97f4f0E386f988B";

      await axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemulti?fsyms=EUR,IOT&tsyms=ETH"
        )
        .then((res) => {
          cryptos = res.data.EUR.ETH;
        });

      if (message.devisPayer) {
        amount = message.devisPayer;
      } else {
        amount = message.price;
      }

      let amountInEth = amount * cryptos;

      if (!window.ethereum) {
        notification.error({
          message: "error",
          description: "Merci d'installer l'extension metamask.",
        });
        throw new Error("Merci d'installer l'extension metamask.");
      }

      await window.ethereum.send("eth_requestAccounts");

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      ethers.utils.getAddress(mmkAddr);
      const tx = await signer.sendTransaction({
        to: mmkAddr,
        value: ethers.utils.parseEther(amountInEth.toString()),
      });

      setTxs([tx]);
      if (tx) {
        saveTransaction(tx, "ETHER", amountInEth);
        updatePaiementType("ETHER");
        setOpenModalpaymentSucces(true);
        setLoadingPaymentMetamask(false);
        setOpenModalpayment(false);
      }
    } catch (err) {
      setError(err.message);
      console.log(err.message);
      notification.error({
        message: "Metamask",
        description: "transaction échouée, montant insuffisant!",
      });
    }
  };

  const transferMMKToken = async () => {
    let amount;
    let cryptos;
    let mmkAddr = "0x82254b7Bef5a610281494cd0c97f4f0E386f988B";

    await axios
      .get(
        "https://min-api.cryptocompare.com/data/pricemulti?fsyms=EUR,IOT&tsyms=ETH"
      )
      .then((res) => {
        cryptos = res.data.EUR.ETH;
      });

    if (message.devisPayer) {
      amount = message.devisPayer;
    } else {
      amount = message.price;
    }

    let amountInEth = amount * cryptos;

    const CONTRACT_ADDRESS_TOKEN = "0xF878dFad54A6C1cd6751b2d9899dC5999797e6A1";

    const ABI_TOKEN = [
      "function transferFrom(address from, address to, uint256 amount) public returns (bool)",
    ];

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      if (!window.ethereum) {
        notification.error({
          message: "error",
          description: "Merci d'installer l'extension metamask.",
        });
        throw new Error("Merci d'installer l'extension metamask.");
      }

      await window.ethereum.send("eth_requestAccounts");

      const signer = provider.getSigner();

      const tokenContract = new ethers.Contract(
        CONTRACT_ADDRESS_TOKEN,
        ABI_TOKEN,
        signer
      );

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (walletAddress) => {
          const tx = await tokenContract.transferFrom(
            walletAddress[0],
            mmkAddr,
            ethers.utils.parseEther(amountInEth.toString())
          );
          if (tx) {
            saveTransaction(tx, "MMKT", amountInEth);
            updatePaiementType("MMKT");
            setOpenModalpayment(false);
          }
        });
    } catch (error) {
      setError(error.message);
      console.log(error.message);
      notification.error({
        message: "Metamask",
        description: "transaction échouée, montant insuffisant!",
      });
    }
  };

  async function saveTransaction(tx, type, amountInEth) {
    let otherUserId = message.user._id;

    let dateNow = moment(new Date()).format("YYYY-MM-DD");

    if (message.announcementType === "offre") {
      otherUserId = message.annoncuerId;
    }

    await Url.Url4.post("saveTransaction", {
      hash: tx.hash,
      from: tx.from,
      to: tx.to,
      value: amountInEth,
      toOtherUser: otherUserId,
      candidatureId: message.candidatureId,
      senderId: me.id,
      transactionDate: dateNow,
      type: type,
      playerId: "",
    }).then((response) => {});
  }

  async function updateTransactionId(transactionId) {
    await Url.Url4.post("updateTransactionId", {
      messagingId: message._id,
      transactionId,
    }).then((response) => {});
  }

  async function updateStatutPaiement() {
    await Url.Url4.post("updateStatutPaiement", {
      candidatureId: message.candidatureId,
    }).then((response) => {});
  }

  async function updatePaiementType(type) {
    await Url.Url4.post("updatePaiementType", {
      candidatureId: message.candidatureId,
      paiementType: type,
    }).then((response) => {});
  }

  const startPaymentWithCrypto = async (message) => {
    try {
      let cryptos;
      let amountInEuro;
      if (message.devisPayer) {
        amountInEuro = message.devisPayer;
      } else {
        amountInEuro = message.price;
      }

      await axios
        .get(
          "https://min-api.cryptocompare.com/data/pricemulti?fsyms=EUR,IOT&tsyms=BTC"
        )
        .then((res) => {
          cryptos = res.data.EUR.BTC;
        });

      let reciverId = message.user._id;

      if (message.announcementType === "offre") {
        reciverId = message.annoncuerId;
      }

      await axios
        .post(
          "https://pay.crypto.com/api/payments",
          {
            amount: parseInt(amountInEuro * cryptos * 100000000), //a change
            currency: "BTC",
            description: JSON.stringify({
              sender: {
                fullName: me.name + " " + me.surname,
                id: me.id,
              },
              type: "mission",
              description: "payment Crypto.com",
              announcementType: message.announcementType,
              announcementTitle: message.announcementTitle,
              reciver: {
                fullName: otherUserpseudo,
                id: reciverId,
              },
              candidatureId: message.candidatureId,
              paymentType: "payIn",
            }),
          },
          {
            auth: {
              username: apikey,
              password: "",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            window.open(response.data.payment_url, "_blank");
            updateTransactionId(response.data.id);
            updatePaiementType("CRYPTO");
            setOpenModalpayment(false);
          } else {
            console.log(response);
          }
        });
    } catch (error) {
      console.log(error.response.data.error.code);
      notification.error({
        message: "error",
        description: error.response.data.error.code,
      });
    }
  };

  async function getMangoPayToken(genratedToken) {
    try {
      let accessToken = "";
      await axios
        .post(
          urlTokenMangoPay,
          { grant_type: "client_credentials" },
          {
            headers: {
              Authorization: `Basic ${genratedToken}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          accessToken = response.data.access_token;
        })
        .catch((error) => console.log(error));
      return accessToken;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(async () => {
    console.log("first");
    if (
      location.search &&
      searchParams.get("candidatureId") &&
      searchParams.get("amount") &&
      searchParams.get("firstName") &&
      searchParams.get("lastName") &&
      searchParams.get("email") &&
      searchParams.get("transactionId") &&
      message._id == searchParams.get("messageId")
    ) {
      await getTransitionStatus(searchParams.get("transactionId"));
    }
  }, []);

  const getTransitionStatus = async (transactionId) => {
    const token = Buffer.from(
      `${MangoclientId}:${Mangopassword}`,
      "utf8"
    ).toString("base64");
    const authToken = await getMangoPayToken(token);
    if (!authToken) {
      return false;
    }

    await axios
      .get(`${ENDPOINT}/V2.01/${MangoclientId}/payins/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        if (response.data.Status === "SUCCEEDED") {
          finishPaymentWithMangoPay();
        } else {
          notification.error({
            message: "Paiement",
            description: "Le paiement a échoué, réessayez !",
          });
        }
      })
      .catch(() => {
        notification.error({
          message: "Paiement",
          description: "Le paiement a échoué, réessayez !",
        });
      });
  };

  useEffect(() => {
    setOffer(parseInt(message.price));
  }, [message]);

  useEffect(async () => {
    if (
      message.transactionId &&
      message.announcementType &&
      (!message.statusPaiement || message.statusPaiement === "0")
    ) {
      await axios
        .get(`https://pay.crypto.com/api/payments/${message.transactionId}`, {
          auth: {
            username: apikey,
            password: "",
          },
        })
        .then((response) => {
          if (response.data.status === "succeeded") {
            updateStatutPaiement();
          }
        });
    }
  }, [message]);

  const acceptOrRefusePrice = async (data) => {
    if (data) setLoadingAccp(true);
    else setLoadingRef(true);

    await Url.Url4.post("acceptOrRefusePrice", {
      propositionId: message.propositionId,
      stateProposition: data,
      messagingId: message._id,
      candidatureId: message.candidatureId,
      playerId: "",
    })
      .then((response) => {
        setReCallApi(true);

        if (data) {
          notification.success({
            message: "Proposition de prix",
            description: "Prix accepté",
          });
          setLoadingAccp(false);
        } else {
          notification.error({
            message: "Proposition de prix",
            description: "Prix refusé",
          });
          setLoadingRef(false);
        }
      })
      .catch((error) => {
        //console.log("error :", error);
        //NotificationManager.error(error.message);
        notification.error({
          message: "error",
          description: error.message,
        });

        if (data) setLoadingAccp(false);
        else setLoadingRef(false);
      });
  };

  const virementForAnnonceurByMangoPay = async (test) => {
    setLoadingVirement(true);

    let annonceurId = message.user._id;
    let candidatureId = message.candidatureId;
    let type = "demande";
    let userId = message.annoncuerId;

    if (test === "offre") {
      annonceurId = message.annoncuerId;
      candidatureId = message.candidatureId;
      type = "offre";
      userId = message.user._id;
    }

    await Url.Url4.post("virementForAnnonceurByMangoPay", {
      annonceurId: annonceurId,
      userId: userId,
      candidatureId: candidatureId,
      type: type,
      playerId: "",
    })
      .then((response) => {
        setLoadingVirement(false);
        setOpenModalStarRate(true);
        //NotificationManager.success("virement succed!");
        notification.success({
          message: "Virement",
          description: "Virement réussi",
        });
        setReCallApi(true);
      })
      .catch((error) => {
        //NotificationManager.error(error.message);
        if (error.response.status == 404) {
          notification.error({
            message: "Compte bancaire",
            description: "L'autre utilisateur n'a pas de compte bancaire!",
          });
        } else {
          notification.error({
            message: "error",
            description: error.message,
          });
        }

        setLoadingVirement(false);
      });
  };

  const apiMongoPayPayment = async () => {
    setLoadingPayment(true);
    if (
      nameOnCart &&
      lastNameOnCart &&
      numCarte &&
      expiredDate &&
      cryptoGrame
      //&&
      //message.devisPayer < 50
    ) {
      let dateMMYY = expiredDate.toString().split("-");

      var ip;

      await axios
        .get("https://api.ipify.org?format=json")
        .then((response) => (ip = response.data.ip));

      await Url.Url4.post("apiMongoPayPayment", {
        amount: parseInt(message.devisPayer) * 100,
        candidatureId: message.candidatureId,
        number: numCarte,
        cvc: cryptoGrame,
        FirstName: nameOnCart,
        LastName: lastNameOnCart,
        email: me.email,
        Birthday: me.birthdate,
        cardExpirationDate: dateMMYY[0] + dateMMYY[1],
        playerId: "",
        acceptHeader: "application/json,text/javascript,*/*;q=0.01<",
        javaEnabled: window.navigator.javaEnabled(),
        language: window.navigator.language,
        colorDepth: window.screen.colorDepth,
        screenHeight: window.screen.height,
        screenWidth: window.screen.width,
        timezoneOffset: 60,
        userAgent: window.navigator.userAgent,
        javascriptenabled: true,
        ipAddress: ip,
        messageId: message._id,
        discussionId: discussionId,
      })
        .then((response) => {
          if (response.status === 200) {
            setOpenModalpayment(false);
            updatePaiementType("MANGOPAY");
            setOpenModalpaymentSucces(true);
            setLoadingPayment(false);
            setReCallApi(true);
          } else if (response.status === 201) {
            setOpenModalpayment(false);
            setLoadingPayment(false);
            updatePaiementType("MANGOPAY");
            console.log(response.data.SecureModeRedirectURL);
            window.open(response.data.SecureModeRedirectURL, "_blank");
          } else {
            //NotificationManager.error(response.data.message);
            notification.warning({
              message: "warning",
              description: response.data.message,
            });
            setLoadingPayment(false);
          }
        })
        .catch((error) => {
          //console.log("error :", error);
          //NotificationManager.error(error.message);
          notification.error({
            message: "error",
            description: error.message,
          });
          setLoadingPayment(false);
        });
    }
    setLoadingPayment(false);
  };

  const startMangoPaymentWithUrl = async () => {
    setLoadingPayment(true);
    if (nameOnCart && lastNameOnCart) {
      await Url.Url4.post("startMangoPaymentWithUrl", {
        //await axios.post(" http://localhost:8083/api/candidature/startMangoPaymentWithUrl", {
        amount: parseInt(message.devisPayer) * 100,
        candidatureId: message.candidatureId,
        FirstName: nameOnCart,
        LastName: lastNameOnCart,
        Birthday: me.birthdate,
        email: me.email,
        discussionId: discussionId,
        messageId: message._id,
        playerId: "",
      })
        .then((response) => {
          //createPaymentMethodeToOffre(response.data.paymentId);
          if (response.status === 200) {
            window.open(response.data.redirectURL, "_blank");
            setOpenModalpayment(false);
            setLoadingPayment(false);
          } else {
            //NotificationManager.error(response.data.message);
            notification.error({
              message: "error",
              description: response.data.message,
            });
            setLoadingPayment(false);
          }
        })
        .catch((error) => {
          //console.log("error :", error);
          //NotificationManager.error(error.message);
          notification.error({
            message: "error",
            description: error.message,
          });
          setLoadingPayment(false);
        });
    }
    setLoadingPayment(false);
  };

  const finishPaymentWithMangoPay = async () => {
    await Url.Url4.post("finishPaymentWithMangoPay", {
      candidatureId: searchParams.get("candidatureId"),
      amount: searchParams.get("amount"),
      FirstName: searchParams.get("firstName"),
      LastName: searchParams.get("lastName"),
      email: searchParams.get("email"),
      playerId: "",
    })
      .then((response) => {
        if (response.status === 200) {
          setOpenModalpayment(false);
          updatePaiementType("MANGOPAY");
          setOpenModalpaymentSucces(true);
          setLoadingPayment(false);
          setReCallApi(true);
          setTimeout(() => {
            window.location.search =
              "discussionId=" + searchParams.get("discussionId");
          }, 5000);
        } else {
          //NotificationManager.error(response.data.message);
          notification.error({
            message: "error",
            description: response.data.message,
          });
          setLoadingPayment(false);
        }
      })
      .catch((error) => {
        //console.log("error :", error);
        //NotificationManager.error(error.message);
        notification.error({
          message: "error",
          description: error.message,
        });
        setLoadingPayment(false);
      });
  };

  const avisDemandeur = async () => {
    setLoadingAvis(true);
    await Url.Url4.post("avisDemandeur", {
      candidatureId: message.candidatureId,
      rating: rate,
      commentaire: comment,
    })
      .then(async (response) => {
        await Url.Url4.get("getAvisUserAndUpdateMoyenne/" + `${me.id}`).then(
          (response) => {}
        );
        setLoadingAvis(false);
        setOpenModalStarRate(false);
        setRate(0);
        setComment(null);
        setOpenModalStarRateDone(true);
      })
      .catch((error) => {
        //console.log("error :", error);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  let messageContent;
  if (message) {
    if (message.text) {
      messageContent = (
        <p className="messageText">
          {own ? (
            <>
              <span className="d-block">{t("you")}</span>
              {message.text}{" "}
            </>
          ) : (
            <>
              <span className="d-block">{message.user.name}</span>
              {message.text}
            </>
          )}
        </p>
      );
    } else if (message.fileType) {
      if (message.fileType === "pdf") {
        messageContent = (
          <div className={own ? "file-message ownf" : "file-message"}>
            {own ? (
              <span className="d-block">{t("you")}</span>
            ) : (
              <span className="d-block">{message.user.name}</span>
            )}
            <Document
              file={message.value}
              onClick={(e) => window.open(message.value, "_blank")}
            >
              <Page pageNumber={1} />
            </Document>
          </div>
        );
      } else if (message.fileType === "image") {
        messageContent = (
          <div className={own ? "file-message ownf" : "file-message"}>
            {own ? (
              <span className="d-block">{t("you")}</span>
            ) : (
              <span className="d-block">{message.user.name}</span>
            )}
            <img
              src={message.value}
              height="150px"
              width="150px"
              onClick={(e) => window.open(message.value, "_blank")}
            ></img>
          </div>
        );
      }
    }

    if (message.fileType === "propositionPrix") {
      if (message.announcementType === "demande") {
        messageContent = (
          <div className="message-Candidacy-container">
            <div className="message-Candidacy">
              {message.statusProposition === null ? (
                <>
                  {own ? (
                    <div>
                      <p className="text-futura-20 text-align-left py-2">
                        {t("yourAboutToSentRequestForRequest")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                      <p className="text-futura-14 text-align-left">
                        {t("amount")} : {message.devisPayer} €
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-futura-20 text-align-left py-2">
                        {t("youReceivedQuoteRequest")} « 
                        {message.announcementTitle} »
                      </p>
                      <p className="text-futura-14 text-align-left">
                        {t("amount")} : {message.devisPayer} €
                      </p>
                    </div>
                  )}

                  {message.user._id != me.id &&
                  message.statusProposition === null ? (
                    <div className="d-flex justify-content-end gap-5">
                      <button
                        className="btn-mmk"
                        onClick={() => acceptOrRefusePrice(true)}
                        disabled={loadingAccp}
                      >
                        {loadingAccp ? <SpinLoading /> : t("btnAccepte")}
                      </button>
                      <button
                        class="btn-mmk"
                        onClick={() => acceptOrRefusePrice(false)}
                        disabled={loadingRef}
                      >
                        {loadingRef ? <SpinLoading /> : t("btnRefuse")}
                      </button>
                    </div>
                  ) : (
                    <p className="text-futura-14 text-align-right">
                      {t("ifTheServiceProviderAccepts")}
                    </p>
                  )}
                </>
              ) : message.statusProposition ? (
                own ? (
                  <div>
                    {message.statusPaiement == "1" ? (
                      <p className="text-futura-20 text-align-left py-2">
                        {t("theInvoiceIsPrepay")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                    ) : (
                      <p className="text-futura-20 text-align-left py-2">
                        {t("theSupplierHasAcceptedTheQuote")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                    )}

                    <p className="text-futura-14 text-align-left">
                      {t("totalAmount")} : {message.devisPayer} €
                    </p>
                    {message.user._id != me.id &&
                    message.statusProposition === true ? (
                      message.statusPaiement === "0" ||
                      !message.statusPaiement ? (
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-mmk"
                            onClick={() => setOpenModalpayment(true)}
                          >
                            {t("btnPrepay")}
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p className="text-futura-20">{t("prepaid")}</p>
                        </div>
                      )
                    ) : null}
                  </div>
                ) : message.statusPaiement === "1" ? (
                  <p className="text-futura-20 text-align-left py-2">
                    {t("youHaveJustPrepaidTheInvoice")} « 
                    {message.announcementTitle} » <CheckCircle />
                  </p>
                ) : (
                  <div>
                    <p className="text-futura-20 text-align-left py-2">
                      {t("youHaveJustAcceptedTheQuote")} « 
                      {message.announcementTitle} » <CheckCircle />
                    </p>

                    <p className="text-futura-14 text-align-left">
                      {t("totalAmount")} : {message.devisPayer} €
                    </p>
                    {message.user._id != me.id &&
                    message.statusProposition === true ? (
                      message.statusPaiement === "0" ||
                      !message.statusPaiement ? (
                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-mmk"
                            onClick={() => setOpenModalpayment(true)}
                          >
                            {t("btnPrepay")}
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <p className="text-futura-20">{t("prepaid")}</p>
                        </div>
                      )
                    ) : null}
                  </div>
                )
              ) : message.statusProposition === false ? (
                own ? (
                  <div>
                    <p className="text-futura-20 text-align-left">
                      {t("theServiceProviderHasJustRefused")}
                      « 
                      {message.announcementTitle} » <Close />
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-futura-20 text-align-left">
                      {t("youHaveJustRefusedTheQuote")} « 
                      {message.announcementTitle} » <Close />{" "}
                    </p>
                  </div>
                )
              ) : null}
            </div>
            {message.avancementService === 100 &&
            !message.validationClient &&
            message.statusProposition ? (
              own ? (
                <div
                  className={
                    !message.validationClient
                      ? "d-flex justify-content-between align-items-center py-4"
                      : null
                  }
                >
                  {!message.validationClient ? (
                    <p className="text-futura-20 px-3">
                      {t("yourMoneyBlockedOnThePlatformUntil")}{" "}
                      {otherUserpseudo} {t("validateYourService")}
                    </p>
                  ) : null}
                  {message.user._id != me.id && !message.validationClient ? (
                    <button
                      class="btn-mmk"
                      onClick={() => virementForAnnonceurByMangoPay("demande")}
                      disabled={loadingVirement}
                    >
                      {loadingVirement ? <SpinLoading /> : t("btnServiceDone")}
                    </button>
                  ) : null}
                </div>
              ) : (
                <div
                  className={
                    !message.validationClient
                      ? "d-flex justify-content-between align-items-center py-4"
                      : null
                  }
                >
                  {!message.validationClient ? (
                    <p className="text-futura-20 text-align-left py-4 px-3">
                      {t("yourMoneyBlockedOnThePlatformUntil")}{" "}
                      {otherUserpseudo} {t("endTheService")}
                    </p>
                  ) : null}
                  {message.user._id != me.id && !message.validationClient ? (
                    <button
                      class="btn-mmk"
                      onClick={() => virementForAnnonceurByMangoPay("demande")}
                      disabled={loadingVirement}
                    >
                      {loadingVirement ? <SpinLoading /> : t("btnServiceDone")}
                    </button>
                  ) : null}
                </div>
              )
            ) : null}
          </div>
        );
      } else if (message.announcementType === "offre") {
        messageContent = (
          <div className="message-Candidacy-container">
            <div className="message-Candidacy">
              {message.statusProposition === null ? (
                <>
                  {own ? (
                    <div>
                      <p className="text-futura-20 text-align-left py-2">
                        {t("yourAboutToSentRequestForOffer")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                      <p className="text-futura-14 text-align-left">
                        {t("amount")} : {message.devisPayer} € -{percentage}%{" "}
                        {t("mmkFees")}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-futura-20 text-align-left py-2">
                        {t("youHaveJustReceivedQuoteForTheOffer")} « 
                        {message.announcementTitle} »
                      </p>
                      <p className="text-futura-14 text-align-left">
                        {t("amount")} : {message.devisPayer} €
                      </p>
                    </div>
                  )}

                  {message.user._id != me.id &&
                  message.statusProposition === null ? (
                    <div className="d-flex justify-content-end gap-5">
                      <button
                        className="btn-mmk"
                        onClick={() => acceptOrRefusePrice(true)}
                        disabled={loadingAccp}
                      >
                        {loadingAccp ? <SpinLoading /> : t("btnAccepte")}
                      </button>
                      <button
                        class="btn-mmk"
                        onClick={() => acceptOrRefusePrice(false)}
                        disabled={loadingRef}
                      >
                        {loadingRef ? <SpinLoading /> : t("btnRefuse")}
                      </button>
                    </div>
                  ) : (
                    <p className="text-futura-14 text-align-right">
                      {t("ifTheServiceProviderAcceptsYouCanPrepayMission")}
                    </p>
                  )}
                </>
              ) : message.statusProposition ? (
                own ? (
                  <div>
                    {message.statusPaiement == "1" ? (
                      <p className="text-futura-20 text-align-left py-2">
                        {t("youreAboutToPaytheFacture")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                    ) : (
                      <p className="text-futura-20 text-align-left py-2">
                        {t("theServiceProviderAcceptedQuoteTheOffer")} « 
                        {message.announcementTitle} » <CheckCircle />
                      </p>
                    )}

                    <p className="text-futura-14 text-align-left">
                      {t("totalAmount")} : {message.devisPayer} €
                    </p>
                    {message.statusPaiement === "0" ||
                    !message.statusPaiement ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn-mmk"
                          onClick={() => setOpenModalpayment(true)}
                        >
                          {t("btnPrepay")}
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <p className="text-futura-20">{t("prepaid")}</p>
                      </div>
                    )}
                  </div>
                ) : message.statusPaiement === "1" ? (
                  <div>
                    <p className="text-futura-20 text-align-left py-2">
                      {t("facturePaidForTheOffer")} « 
                      {message.announcementTitle} » <CheckCircle />
                    </p>
                  </div>
                ) : (
                  <>
                    <p className="text-futura-20 text-align-left py-2">
                      {t("yourAboutToAcceptedQuotationTheOffer")} « 
                      {message.announcementTitle} » <CheckCircle />
                    </p>
                    <p className="text-futura-14 text-align-left">
                      {t("totalAmount")} :{" "}
                      {message.devisPayer -
                        message.devisPayer * (percentage / 100)}{" "}
                      €
                    </p>
                    {message.statusPaiement === "0" ||
                    !message.statusPaiement ? (
                      <div className="d-flex justify-content-end">
                        <p className="text-futura-20">
                          {t("waitingForPrepayment")}
                        </p>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <p className="text-futura-20">{t("prepaid")}</p>
                      </div>
                    )}
                  </>
                )
              ) : message.statusProposition === false ? (
                own ? (
                  <div>
                    <p className="text-futura-20 text-align-left">
                      {t("theServiceProviderHasJustRefusedQuoteForOffer")} « 
                      {message.announcementTitle} » <Close />
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-futura-20 text-align-left">
                      {t("youHaveJustDeclinedTheQuoteTheOffer")} « 
                      {message.announcementTitle} » <CheckCircle />{" "}
                    </p>
                  </div>
                )
              ) : null}
            </div>
            {message.statusProposition &&
            (message.statusPaiement === "0" || !message.statusPaiement) &&
            !own ? (
              <p className="text-futura-20 text-align-left py-4">
                {t("alwaysWaitForThePrepaymentBeforeStartingTheService")}
              </p>
            ) : null}
            {message.avancementService === 100 && message.statusProposition ? (
              own ? (
                <div
                  className={
                    !message.validationClient
                      ? "d-flex justify-content-between align-items-center py-4"
                      : null
                  }
                >
                  {!message.validationClient ? (
                    <p className="text-futura-20 px-3">
                      {t("yourMoneyBlockedOnThePlatformUntil")}{" "}
                      {otherUserpseudo} {t("endTheService")}
                    </p>
                  ) : null}

                  {!message.validationClient ? (
                    <button
                      class="btn-mmk"
                      onClick={() => virementForAnnonceurByMangoPay("offre")}
                      disabled={loadingVirement}
                    >
                      {loadingVirement ? <SpinLoading /> : t("btnServiceDone")}
                    </button>
                  ) : null}
                </div>
              ) : !message.validationClient ? (
                <p className="text-futura-20 text-align-left py-4">
                  {t("yourMoneyBlockedOnThePlatformUntil")} {otherUserpseudo}{" "}
                  {t("validateYourService")}
                </p>
              ) : null
            ) : null}
          </div>
        );
      }
    }

    if (message.fileType === "facture") {
      messageContent = (
        <div className="message-Candidacy-container">
          <div className="message-Candidacy">
            <div className="d-flex flex-column justify-content-center align-items-start">
              <p className="text-futura-20 align-items-left">
                {t("invoice")}
                <CheckCircle />
              </p>
              <div className="d-flex gap-3">
                <p className="text-futura-14 align-items-left">
                  {t("lableTotal")} :{message.devisPayer / 100}€
                </p>
                <p className="text-futura-14 align-items-left">
                  {" "}
                  -{percentage} % {t("mmkFees")}
                </p>
              </div>
              <p className="text-futura-20 align-items-rigth">
                {t("quotePaid")} !
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  const hideMessage = async () => {
    await Url.Url3.post("hideMessage", {
      messageId: message._id,
    })
      .then(async (response) => {
        notification.success({
          message: "Supprimé",
          description: "Message supprimé !",
        });
        setReCallApi(true);
      })
      .catch((error) => {
        //console.log("error :", error);
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  return (
    <>
      {own ? (
        (message.announcementType !== null && message["announcementType"]) ||
        message.fileType === "facture" ? (
          <div className="message own w-100">
            <div className="message-links">
              {" "}
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <div
                        className="text-futura-14 d-flex align-items-center justify-content-center"
                        onClick={() => hideMessage()}
                      >
                        {t("hide")}
                      </div>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <i className="bi bi-three-dots-vertical"></i>
                  </Space>
                </a>
              </Dropdown>
            </div>
            <div className="messageTop w-100">{messageContent}</div>
          </div>
        ) : (
          <>
            <div className="message own">
              <div className="message-links">
                {" "}
                <Dropdown
                  placement="bottomRight"
                  overlay={
                    <Menu>
                      <Menu.Item key="1">
                        <div
                          className="text-futura-14 d-flex align-items-center justify-content-center"
                          onClick={() => hideMessage()}
                        >
                          {t("hide")}
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <i className="bi bi-three-dots-vertical"></i>
                    </Space>
                  </a>
                </Dropdown>
              </div>
              <div className="messageTop">{messageContent}</div>
            </div>
          </>
        )
      ) : (message.announcementType !== null && message["announcementType"]) ||
        message.fileType === "facture" ? (
        <div className="message w-100">
          <div className="messageTop w-100">{messageContent}</div>
        </div>
      ) : (
        <div className="message">
          <div className="messageTop">{messageContent}</div>
        </div>
      )}

      <Modal
        open={openModalpayment}
        onCancel={() => setOpenModalpayment(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex flex-column justify-content-center align-items-center g-5">
          <p className="text-futura-30 mt-2 mb-5">{t("payment")}</p>

          <div
            className="d-flex flex-column card-facture"
            //style={{ width: "300px" }}
          >
            <p className="text-futura-16 text-align-left">
              {t("invoiceForRequest")} « {message.announcementTitle} »
            </p>
            <div className="d-flex justify-content-center">
              <p className="text-futura-14">
                {message.devisPayer !== null
                  ? "Montant total :" +
                    (message.devisPayer + message.devisPayer * 0.018)
                  : "Montant total :" + (message.price + message.price * 0.018)}
                €
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-5 mb-3">
            <p className="text-futura-20">{t("choosePaymentMethod")}</p>
          </div>

          <div className="d-flex justify-content-center align-items-center gap-2 mb-5">
            <img
              className="rounded-circle pointer"
              src={require("../../assets/img/payment/visa.png")}
              style={{ height: "60px", width: "60px" }}
              onClick={() => {
                setCartInfo(!cartInfo);
                setPaymentWithMMKT(false);
              }}
            />
            {message.devisPayer >= 10 ? (
              <img
                className="pointer"
                src={require("../../assets/img/payment/metamask.png")}
                style={{ height: "60px", width: "60px" }}
                onClick={() => startPaymentWithMetamask(message)}
              />
            ) : null}
            {message.devisPayer >= 10 ? (
              <img
                className="rounded-circle pointer"
                src={require("../../assets/img/payment/crypto-com-coin-cro-logo.png")}
                style={{ height: "60px", width: "60px" }}
                onClick={() => {
                  startPaymentWithCrypto(message);
                }}
              />
            ) : null}
            {message.devisPayer >= 10 ? (
              <img
                className="pointer"
                src={require("../../assets/img/payment/crypto.png")}
                style={{ height: "60px", width: "60px" }}
                onClick={() => {
                  setPaymentWithMMKT(!paymentWithMMKT);
                  setCartInfo(false);
                }}
              />
            ) : null}
          </div>

          {paymentWithMMKT ? (
            <div className="d-flex flex-column justify-content-center align-items-center p-3">
              <button
                type="submit"
                className="btn-mmk"
                onClick={() => transferMMKToken()}
              >
                {t("btnPay")}
              </button>
            </div>
          ) : null}

          {cartInfo ? (
            <div className=" d-flex flex-column justify-content-center align-items-center w-100 max-width-payment-container">
              <p className="text-futura-16 text-align-left w-100">
                {t("labelNameOntheCart")}
              </p>
              <input
                className="input-payment mb-3"
                type="text"
                onChange={(e) => setNameOnCart(e.target.value)}
                required
              />

              <p className="text-futura-16 text-align-left w-100">
                {t("labelLastNameOnTheCart")}
              </p>
              <input
                className="input-payment mb-3"
                type="text"
                onChange={(e) => setLastNameOnCart(e.target.value)}
                required
              />
              <div className="w-100">
                <p className="text-futura-16 text-align-left w-100">
                  {t("labelNumOnTheCart")}
                </p>

                <input
                  className="input-payment mb-3"
                  type="number"
                  onChange={(e) => setNumCarte(e.target.value)}
                  required
                />

                <div className="d-flex w-100 justify-content-between flex-wrap">
                  <span className=" d-flex flex-column ">
                    <p className="text-futura-16 text-align-left">
                      {t("expirationDate")}
                    </p>

                    <DatePicker
                      className="input-payment-half"
                      placeholder=""
                      format="MM-YY"
                      onChange={(date, dateString) => {
                        setExpiredDate(dateString);
                      }}
                      picker="month"
                    />
                  </span>
                  <span className=" d-flex flex-column ">
                    <p className="text-futura-16 text-align-left">
                      {t("Cryptogram")}
                    </p>
                    <input
                      className="input-payment-half"
                      type="password"
                      onChange={(e) => setCryptoGrame(e.target.value)}
                      maxLength="3"
                      required
                    />
                  </span>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mt-5 mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <img
                    src="https://www.mangopay.com/terms/powered-by-mangopay.png"
                    width="50%"
                    height="50%"
                  />

                  <button
                    className={
                      nameOnCart &&
                      lastNameOnCart &&
                      numCarte &&
                      expiredDate &&
                      cryptoGrame
                        ? "btn-mmk"
                        : "btn-mmk-grey-opacity"
                    }
                    onClick={() => apiMongoPayPayment()}
                    disabled={loadingPayment}
                  >
                    {loadingPayment ? <SpinLoading /> : t("btnPay")}
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal>

      <Modal
        open={openModalpaymentSucces}
        onCancel={() => setOpenModalpaymentSucces(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex justify-content-center align-items-center  w-100">
          <div className="d-flex flex-column container-cart-payment-succed">
            <p className="text-futura-30 mt-2 mb-5">{t("btnPay")}</p>
            <img
              src={require("../../assets/img/payment/paymentSucces.png")}
              height={190}
              width={280}
            />

            <p className="text-futura-20 py-4">{t("confirmedPayment")} !</p>
            <p className="text-futura-14">{t("confirmedPaymentDesc")}</p>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalStarRate}
        onCancel={() => setOpenModalStarRate(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="max-width-avis-container d-flex flex-column justify-content-center align-items-center w-100">
            <p className="text-futura-30 mt-3">{t("LeaveReview")}</p>
            <p className="text-futura-20">{otherUserpseudo}</p>
            <p className="text-futura-20 py-5">
              {t("HowDidYouFindThePerformance")} {otherUserpseudo} ?
            </p>
            <StarRatings
              rating={rate}
              starRatedColor="#F817AC"
              starHoverColor="#F817AC"
              starDimension="30px"
              starSpacing="0px"
              changeRating={(rating) => setRate(rating)}
              numberOfStars={5}
              name="rating"
            />
            <div className="d-flex justify-content-start w-100 mt-3">
              <p className="text-futura-16 text-align-left">
                {t("lableComment")}
              </p>
            </div>

            <textarea
              className="input-textArea"
              onChange={(e) => setComment(e.target.value)}
            />

            <button
              className={
                rate !== 0
                  ? "btn-mmk mt-5 mb-2"
                  : "btn-mmk-grey-opacity mt-5 mb-2"
              }
              onClick={() => avisDemandeur()}
              disabled={loadingAvis}
            >
              {loadingAvis ? <SpinLoading /> : t("buttonValidate")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openModalStarRateDone}
        onCancel={() => setOpenModalStarRateDone(false)}
        footer={false}
        width={625}
      >
        <div className="d-flex justify-content-center align-items-center  w-100">
          <div className="d-flex flex-column container-cart-payment-succed">
            <img
              src={require("../../assets/img/payment/rateSent.png")}
              height={263}
              width={295}
            />

            <p className="text-futura-20 py-5">{t("yourReviewSent")} !</p>
            <p className="text-futura-14">{t("yourReviewSentDesc")} !</p>
          </div>
        </div>
      </Modal>
    </>
  );
}
