import React, { useEffect, useState, useRef } from "react";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import * as actionCreator from "../../redux/actions";
import { useHistory } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/Footer";
import HomeBottom from "./HomeBottom";
import Filter from "../../components/icons/Filter";
import NavbarMobile from "../../components/NavbarMobile";
import { useTranslation } from "react-i18next";
import { useContainerDimensions } from "../ContainerDimensions";
import { Helmet } from "react-helmet-async";
import CategoryCardEngs from "../../components/cards/CategoryCardEngs";
import AnnounceCard from "../../components/cards/AnnounceCard";


export default function HomeNoLoggedEnsg() {
  const [announcementType, setAnnouncementType] = useState("demande");
  const categoryType = "enseignement"

  const history = useHistory();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const allData = useSelector((state) => state.HomeReducer.allData);
  const success = useSelector((state) => state.HomeReducer.success);
  const data = {
    announcementType: announcementType,
    categoryType: categoryType,
  };

  const [maxCards, setMaxCards] = useState(8);
  const [maxCardsCategory, setMaxCardsCategory] = useState(10);

  useEffect(() => {
    if (!success || announcementType)
      dispatch(actionCreator.homePageNotConnected(data));
  }, [success, announcementType]);

  const goToAnnouncementDetails = (announce) => {
    history.push({
      pathname: `/annonce/${announce.slug}`,
      state: announce,
      connected: false,
    });
  };

  const goToSearch = (category) => {
    history.push({
      pathname: "/recherche",
      categoryId: category.id,
      categoryName: category.categoriefr,
    });
  };

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  useEffect(async () => {
    setMaxCards(parseInt(width / 200));
    setMaxCardsCategory(parseInt(width / 150));
  });

  return (
    <>
     <Helmet>
        <title>
        Soutien scolaire : Cours particuliers, science, mathématique, physique chimie, art, marketing, commerce, droit - Make My Work
        </title>
        <meta name="description" content="Make My Work est une plateforme de mise en relation pour du soutien scolaire. Des difficultés à travailler ou à apprendre ? Faites vous aidez par des étudiants ou des professeurs" />
      </Helmet>
      <NavBarNotConnected />
      <NavbarMobile />

      <main id="main">
        <section>
          <div
            className="container-fluid w-100 d-flex background-home-ens-img"
            ref={componentRef}
            style={{ justifyContent: "flex-end" }}
          >
            <div className="icon-box-ser ">
              <h1 className="text-futura-30 text-align-left">
                {t("welcome")} !
              </h1>
              <p
                className="text-justify text-roboto-regular-16-black text-align-left mt-5"
                style={{ lineHeight: "3rem" }}
              >
                {t("welcomeDesc")}
              </p>

              <div className="getstarted-btns gap-3">
                <button
                  onClick={() => history.push({ pathname: "/connexion" })}
                  className="btn-mmk"
                >
                  {t("btnCreateAnAnnoucement")}
                </button>

                <button
                  onClick={() => {
                    document.getElementById("howItWork").scrollIntoView();
                  }}
                  className="btn-mmk-white"
                >
                  {t("btnHowItWork")} ?
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="portfolio-ens" className="portfolio-ens">
          <div className="container-mmk w-100">
            <div className="portfolio-grid" style={{ marginTop: "2rem" }}>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <div className="d-flex align-items-center justify-content-between pb-2">
                  <div className="w-100">
                    <div
                      data-filter=".filter-ens-Demandes"
                      className={
                        announcementType === "demande"
                          ? "selected-nav pointer"
                          : "not-selected-nav pointer"
                      }
                      onClick={() => setAnnouncementType("demande")}
                    >
                      {t("requests")}
                    </div>
                  </div>
                  <div className="w-100">
                    <div
                      data-filter="filter-ens-Offres"
                      className={
                        announcementType === "offre"
                          ? "selected-nav pointer"
                          : "not-selected-nav pointer"
                      }
                      onClick={() => setAnnouncementType("offre")}
                    >
                      {t("offers")}
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="portfolio-ens-flters"
                className=""
                style={{ textAlign: "end" }}
              >
                <Link to="/recherche">
                  <Filter />
                </Link>
              </div>
            </div>

            <div className="d-flex justify-content-between mb-4">
              <h2 className="text-futura-20 text-underline-gradiant">
                {t("popularCategories")}
              </h2>
              <NavLink to="/categorie-enseignement">
                <p
                  className="text-futura-14 text-underline"
                  style={{ color: "#f817ac" }}
                >
                  {t("LinkSellAll")}
                </p>
              </NavLink>
            </div>

            <div className="row gap-4">
              {allData &&
              allData.categorieType.length>0 ?
                allData.categorieType?.map(
                  (category, key) =>
                    key < maxCardsCategory && (
                      <CategoryCardEngs props={{category,key}} onClick={(category)=>goToSearch(category)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </section>

        <section id="Photographe" className="Photographe">
          <div className="container-mmk w-100">
            {announcementType === "demande" ? (
              <div className="d-flex justify-content-between mt-4 mb-1">
                <h2 className="text-futura-20 text-align-left text-underline-gradiant">
                  {t("otherRequests")}
                </h2>
                <NavLink to="/autres-demandes-enseignement">
                  <p
                    className="text-futura-14 text-underline"
                    style={{ color: "#f817ac" }}
                  >
                    {t("LinkSellAll")}
                  </p>
                </NavLink>
              </div>
            ) : (
              <div className="d-flex  justify-content-between  mt-4">
                <h2 className="text-futura-20 text-align-left text-underline-gradiant">
                  {t("otherOffers")}
                </h2>
                <NavLink to="/autres-demandes-enseignement">
                  <p
                    className="text-futura-14 text-underline"
                    style={{ color: "#f817ac" }}
                  >
                    {t("LinkSellAll")}
                  </p>
                </NavLink>
              </div>
            )}

            <div className="row gap-4">
              {allData &&
              allData.otherAnnouncement.length>0?
                allData.otherAnnouncement?.map(
                  (announce, key) =>
                    key < maxCards && (
                      <AnnounceCard props={{announce, key}} onClick={() => goToAnnouncementDetails(announce)}/>
                    )
                ):<span className="no-items">{t("noItems")}</span>}
            </div>
          </div>
        </section>
        <div id="howItWork"></div>
        <HomeBottom />
      </main>

      <Footer />
    </>
  );
}
