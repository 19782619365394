import React, { useContext, useEffect, useRef, useState } from "react";
import NavBarNoLoggedIn from "../../components/NavBarNotConnected";
import NavBar from "../../components/NavBarConnected";
import * as actionCreator from "../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import Slider from "react-slick";
import { Modal } from "antd";
import Filter from "../../components/icons/Filter";
import Localisation from "../../components/icons/Localisation";
import EmptyStar from "../../components/icons/EmptyStar";
import FullStar from "../../components/icons/FullStar";
import ArrowDown from "../../components/icons/FlechDown";
import ArrowUp from "../../components/icons/ArrowUp";
import NavbarMobile from "../../components/NavbarMobile";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import { useContainerDimensions } from "../ContainerDimensions";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { formatDate } from "../../utils/formatDate";
import { Helmet } from "react-helmet-async";

function Search() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const [announcementTitle, setAnnouncementTitle] = useState(null);
  const [announcementType, setAnnouncementType] = useState("demande");
  const [categoryName, setCategoryName] = useState(null);
  const [mySkills, setMySkills] = useState(false);
  const [dateDebut, setDateDebut] = useState(null);
  const [city, setCity] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [rating, setRating] = useState([]);

  const [pageSize, setPageSize] = useState(18);
  const [totalPage, setTotalPage] = useState(0);
  const [current, setCurrent] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);

  const [addressObj, setAddressObj] = useState(null);

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const [search, setSearch] = useState({
    announcementTitle: "",
    announcementType: "demande",
    categoryName: "",
    categoryType: "",
    mySkills: false,
    dateDebut: "",
    userId: null,
    city: "",
  });
  const [active, setActive] = useState(false);
  const [activeCategories, setActiveCategories] = useState(true);

  const announcements = useSelector(
    (state) => state.SearchReducer.Announcements
  );
  const categorys = useSelector((state) => state.SkillsReducer.skills);

  const inputSearch = useSelector((state) => state.SearchReducer.inputSearch);
  const profilRecommended = useSelector(
    (state) => state.SearchReducer.profilRecommended
  );

  const reducerCategoryType = useSelector(
    (state) => state.SearchReducer.categoryType
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 750,

    slidesToShow: profilRecommended.length > 7 ? 8 : profilRecommended.length,
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <Arrows icon="icon-chevron-left" className="slick" />,
    nextArrow: <Arrows icon="icon-chevron-right" className="slick" />,
    lazyload: true,
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow:
            profilRecommended.length > 5 ? 6 : profilRecommended.length,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
        },
      },

      {
        breakpoint: 1366,
        settings: {
          slidesToShow:
            profilRecommended.length > 4 ? 5 : profilRecommended.length,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow:
            profilRecommended.length > 3 ? 4 : profilRecommended.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            profilRecommended.length > 3 ? 4 : profilRecommended.length,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:
            profilRecommended.length > 2 ? 3 : profilRecommended.length,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            profilRecommended.length > 1 ? 2 : profilRecommended.length,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.long_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  useEffect(async () => {
    if (addressObj) {
      const geocodeObj =
        addressObj &&
        addressObj.value &&
        (await geocodeByPlaceId(addressObj.value.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
      setSearch((prevState) => ({
        ...prevState,
        city: addressObject.city,
      }));
    } else {
      setSearch((prevState) => ({
        ...prevState,
        city: "",
      }));
    }
  }, [addressObj]);

  useEffect(async () => {
    setPageSize(parseInt(width / 175) * 3);
  });

  useEffect(async () => {
    setTotalPage(announcements.length / pageSize);
    setMinIndex(0);
    setMaxIndex(pageSize);
  }, [announcements, pageSize]);

  useEffect(async () => {
    const data = {
      announcementTitle: inputSearch,
      announcementType: search.announcementType,
      categoryName: search.categoryName || location.categoryName,
      categoryType: reducerCategoryType,
      mySkills: search.mySkills,
      dateDebut: search.dateDebut,
      userId: user ? user.id : null,
      city: search.city,
    };
    await dispatch(actionCreator.advancedSearch(data));

    if (search.categoryName || location.categoryName) {
      const data2 = {
        rating: rating,
        categoryName: search.categoryName,
        userId: user ? user.id : null,
      };
      await dispatch(actionCreator.getAllUsersWithHighRating(data2));
    }
  }, [search]);

  useEffect(() => {
    setAnnouncementTitle(location.search.substring(7));
    if (location.categoryName) {
      setCategoryName(location.categoryName);
      setActive(true);
      setSearch((prevState) => ({
        ...prevState,
        categoryName: location.categoryName,
        announcementType: "demande",
      }));
    }
    if (location.categoryId) {
      const data = {
        categoryId: location.categoryId,
        userId: user !== null ? user.id : null,
      };

      dispatch(actionCreator.getAllSkills());
    } else if (!location.categoryId)
      if (location.search.substring(7) || announcementType) {
        dispatch(actionCreator.getAllSkills());
        const data = {
          announcementTitle: location.search.substring(7),
          announcementType: announcementType,
          categoryName: categoryName,
          categoryType: reducerCategoryType,
          mySkills: mySkills,
          dateDebut: dateDebut,
          userId: user ? user.id : null,
          city: city,
        };
        dispatch(actionCreator.advancedSearch(data));
      }
  }, [
    location.search,
    location.categoryId,
    location.categoryName,
    announcementType,
  ]);

  useEffect(async () => {
    const data = {
      rating: rating,
      categoryName: null,
      userId: user ? user.id : null,
    };
    await dispatch(actionCreator.getAllUsersWithHighRating(data));
  }, []);

  useEffect(async () => {
    if (rating) {
      const data2 = {
        rating: rating,
        categoryName: search.categoryName ? search.categoryName : null,
        userId: user ? user.id : null,
      };
      await dispatch(actionCreator.getAllUsersWithHighRating(data2));
    }
  }, [rating]);

  const goToAnnouncementDetails = (announce) => {
    history.push({
      pathname: `/annonce/${announce.slug}`,
      state: announce,
      //connected: connected,
    });
  };

  const changeCategory = (e, category, name) => {
    if (name == "categoryName") {
      setSearch((prevState) => ({
        ...prevState,
        [name]: category.categoryName,
      }));

      setCategoryName(category.categoryName);
      setActive(true);
    } else if (e.target.name == "mySkills") {
      setSearch((prevState) => ({
        ...prevState,
        [e.target.name]: !mySkills,
      }));
    } else {
      setSearch((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const advancedSearch = (e) => {
    e.preventDefault();
    const data = {
      announcementTitle: inputSearch === "" ? null : inputSearch,
      announcementType: announcementType,
      categoryName: categoryName,
      categoryType: reducerCategoryType,
      mySkills: mySkills,
      dateDebut: dateDebut,
      userId: user ? user.id : null,
      city: city,
    };

    dispatch(actionCreator.advancedSearch(data));
    setOpenModal(false);
  };

  const handleTypeChange = (e, type) => {
    if (type === "demande") setAnnouncementType("demande");
    else setAnnouncementType("offre");

    if (user) {
      setSearch((prevState) => ({
        ...prevState,
        announcementType: type,
      }));
    }
  };

  const generateTempArray = (maxItems) => {
    let result = [];

    for (let i = 0; i < maxItems; i++) {
      result.push(i);
    }
    return result;
  };

  const handleRating = (value) => {
    let newRating = rating;
    if (rating.indexOf(parseInt(value)) === -1) {
      setRating((rating) => [...rating, parseInt(value)]);
    } else if (newRating.length > 0) {
      newRating = rating.filter((rate) => rate != parseInt(value));
      setRating(newRating);
    }
  };

  const handleChange = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  return (
    <>

      <Helmet>
        <title>Make my work | Moteur de recherche</title>
      </Helmet>
   
      {user ? <NavBar /> : <NavBarNoLoggedIn />}
      <NavbarMobile />

      <div className="container-mmk w-100 row mb-5">
        <div className="col col-2 hide-component mb-5">
          <div className=" sidebar-search mt-5">
            <div className="d-flex justify-content-start align-items-center">
              <span className="icon">
                <Filter />
              </span>
              <p className="text-futura-20 px-3">{t("filter")}</p>
            </div>

            <p className="text-futura-16 text-align-left mt-5">
              {t("ZIPcodeCity")}
            </p>

            <div className="form-pro">
              <GooglePlacesAutocomplete
                apiKey="AIzaSyBRg0WgLuj6W49GfqwVITzEApOmt903zf0"
                selectProps={{
                  noOptionsMessage: () =>
                    i18n.language === "fr"
                      ? "Indiquez votre ville"
                      : "Indicate your city",
                  placeholder: "",
                  isClearable: true,
                  value: addressObj,
                  onChange: (val) => {
                    setAddressObj(val);
                  },
                }}
              />
              <i>
                <Localisation />
              </i>
            </div>

            <p className="text-futura-16 text-align-left mt-4">
              {t("requestDate")}
            </p>
            <input
              className="form-pro"
              type="date"
              name="dateDebut"
              onChange={(e) => {
                changeCategory(e);
              }}
            />

            <div
              className="d-flex align-items-center mt-4"
              onClick={(e) => setActiveCategories(!activeCategories)}
            >
              <p className="text-futura-16 text-align-left">
                {t("categories")}
              </p>
              <i>{activeCategories ? <ArrowDown /> : <ArrowUp />}</i>
            </div>

            {activeCategories &&
              categorys.length>0 ?
              categorys.map(
                (category, key) =>
                  reducerCategoryType === category.categoryType && (
                    <div className="d-flex flex-column mt-3" value={key}>
                      <div
                        className={
                          active && categoryName == category.categoryName
                            ? " d-flex category active align-items-center"
                            : " d-flex category align-items-center"
                        }
                        name="categoryName"
                        onClick={(e) =>
                          changeCategory(e, category, "categoryName")
                        }
                      >
                        <img
                          className="img-category"
                          src={category.picture}
                          height="24px"
                          width="24px"
                        />
                        <p
                          className={
                            active && categoryName == category.categoryName
                              ? " text-futura-16 text-align-left active "
                              : " text-futura-16 text-align-left"
                          }
                        >
                          {i18n.language === "fr"
                            ? category.categoryName
                            : category.categoryNameEn}
                        </p>
                      </div>
                    </div>
                  )
              ):<span className="no-items">{t("noItems")}</span>}

            <div className="d-flex justify-content-start align-items-center mt-4">
              <p className="text-futura-16">{t("ratings")} </p>
              <i>
                <ArrowDown />
              </i>
            </div>
            <div className="d-flex flex-column">
              <div className="d-flex py-2 align-items-center">
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />

                <input
                  className="checkbox-mmk px-3"
                  style={{ marginLeft: "2.8rem" }}
                  type="checkbox"
                  value={5}
                  onClick={(e) => handleRating(e.target.value)}
                  set
                />
              </div>

              <div className="d-flex py-2 align-items-center">
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />

                <EmptyStar />
                <input
                  className="checkbox-mmk px-3"
                  style={{ marginLeft: "2.8rem" }}
                  type="checkbox"
                  value={4}
                  onClick={(e) => handleRating(e.target.value)}
                  set
                />
              </div>

              <div className="d-flex py-2 align-items-center">
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <EmptyStar />
                <EmptyStar />
                <input
                  className="checkbox-mmk px-3"
                  style={{ marginLeft: "2.8rem" }}
                  type="checkbox"
                  value={3}
                  onClick={(e) => handleRating(e.target.value)}
                  set
                />
              </div>

              <div className="d-flex py-2 align-items-center">
                <FullStar width={21} height={21} />
                <FullStar width={21} height={21} />
                <EmptyStar />
                <EmptyStar />
                <EmptyStar />
                <input
                  className="checkbox-mmk px-3"
                  style={{ marginLeft: "2.8rem" }}
                  type="checkbox"
                  value={2}
                  onClick={(e) => handleRating(e.target.value)}
                  set
                />
              </div>

              <div className="d-flex py-2 align-items-center">
                <FullStar width={21} height={21} />
                <EmptyStar />
                <EmptyStar />
                <EmptyStar />
                <EmptyStar />
                <input
                  className="checkbox-mmk px-3"
                  style={{ marginLeft: "2.8rem" }}
                  type="checkbox"
                  value={1}
                  onClick={(e) => handleRating(e.target.value)}
                  set
                />
              </div>
            </div>
            {user && user ? (
              announcementType === "demande" ? (
                <div className="d-flex flex-row mt-5">
                  <input
                    className="checkbox-mmk"
                    type="checkbox"
                    name="mySkills"
                    onChange={(e) => {
                      setMySkills(!mySkills);
                      changeCategory(e);
                    }}
                    style={{ maxHeight: "23px" }}
                  />
                  <p className="text-futura-16 text-align-left px-3">
                    {t("inMySkills")}
                  </p>
                </div>
              ) : null
            ) : null}
          </div>
        </div>

        <div className="col col-10 fix-search" style={{ paddingLeft: "5rem" }}>
          <div className="d-flex align-items-center justify-content-center pt-2">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-100">
                  <div
                    name="announcementType"
                    data-filter=".filter-ens-Demandes"
                    className={
                      announcementType === "demande"
                        ? "selected-nav pointer"
                        : "not-selected-nav p ointer"
                    }
                    onClick={(e) => handleTypeChange(e, "demande")}
                  >
                    {t("requests")}
                  </div>
                </div>
                <div className="w-100">
                  <div
                    name="announcementType"
                    data-filter="filter-ens-Offres"
                    className={
                      announcementType === "offre"
                        ? "selected-nav pointer"
                        : "not-selected-nav pointer"
                    }
                    onClick={(e) => handleTypeChange(e, "offre")}
                  >
                    {t("offers")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {categoryName ? (
            announcementType === "offre" ? (
              <p className="text-futura-20 text-align-left py-3">
                {profilRecommended && profilRecommended.length > 0
                  ? "Profils recommandés pour «" + `${categoryName}` + "»"
                  : null}
              </p>
            ) : null
          ) : announcementType === "offre" ? (
            <p className="text-futura-20 text-align-left py-3">
              {profilRecommended && profilRecommended.length > 0
                ? "Profils recommandés pour «" + `${announcementTitle}` + "»"
                : null}
            </p>
          ) : null}

          {announcementType === "offre" &&
            profilRecommended &&
            profilRecommended.length > 0 ?(
              <Slider {...settings}>
                {profilRecommended.map((profil, key) => (
                  <div
                    className="profil-card pointer"
                    key={key}
                    onClick={() => history.push(`/profil/${profil.userId}`)}
                  >
                    <img
                      src={
                        profil.profilePicture
                          ? profil.profilePicture
                          : require('../../img/avatar.png')
                      }
                      width="115px"
                      height="115px"
                      className="rounded-circle"
                    />
                    <p className="text-roboto-regular-16-black">
                      {profil.surname}
                    </p>
                    <p className="text-roboto-regular-16-black">
                      {profil.name}
                    </p>
                    <div className="d-flex py-2 align-items-center">
                      {generateTempArray(profil.rating).map((item) => (
                        <FullStar width={22} height={21} />
                      ))}
                      {generateTempArray(5 - profil.rating).map((item) => (
                        <EmptyStar width={20} height={20} />
                      ))}
                    </div>
                    <div className="d-flex align-items-center">
                      <span id="met-vert">
                        <i className="bi bi-circle-fill"></i>
                      </span>
                      <p className="text-roboto-regular-12-grey px-2">
                        {t("available")}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            ):null}

          <div className="d-flex justify-content-between align-items-center my-5">
            {announcementType === "demande" ? (
              categoryName ? (
                <p className="text-futura-20 mt-5 mb-2">
                  {t("requestsFor")} «{categoryName}»
                </p>
              ) : mySkills ? (
                <p className="text-futura-20 mt-5 mb-2">
                  {t("requestsInMySkills")}
                </p>
              ) : (
                <p className="text-futura-20 mt-5 mb-2">
                  {t("requestsFor")} «{announcementTitle}»
                </p>
              )
            ) : categoryName ? (
              <p className="text-futura-20 mt-5 mb-2">
                {t("offersFor")} «{categoryName}»
              </p>
            ) : (
              <p className="text-futura-20 mt-5 mb-2">
                {t("offersFor")} «{announcementTitle}»
              </p>
            )}
            <div className="filter " onClick={(e) => setOpenModal(true)}>
              <div className="icon">
                <i className="bi bi-funnel-fill mr-2"></i>
              </div>
              <p className="text-futura-20">{t("filter")}</p>
            </div>
          </div>
          <div className="cards gap-4" ref={componentRef}>
            {announcements&& announcements.length>0 ?
              announcements.map(
                (announce, key) =>
                  key >= minIndex &&
                  key < maxIndex && (
                    <div
                      className="card-search"
                      onClick={() => goToAnnouncementDetails(announce)}
                      key={key}
                    >
                      <div className="Photographe-wrap">
                        <img
                          //className="img-size"
                          src={
                            announce.picture[0] || require('../../assets/img/No-image.jpeg')
                          }
                          alt="Annonce Picture"
                        />
                        <h4 className="text-futura-16 text-align-left mt-2">
                          {announce.announcementTitle}
                        </h4>
                        {announce.price ? (
                          <p className="text-futura-14 text-align-left">
                            {t("proposedRate")} : {announce.price}€{" "}
                          </p>
                        ) : null}
                        {announce.deadLine ? (
                          <p className="text-futura-11-grey text-align-left">
                            {t("before")} {formatDate(announce.deadLine)} <br />
                            {announce.city}
                          </p>
                        ) : (
                          <p className="text-futura-11-grey text-align-left">
                            {announce.city}
                          </p>
                        )}
                      </div>
                    </div>
                  )
              ):<span className="no-items">{t("noItems")}</span>}
          </div>
          <div className="d-flex algin-items-center justify-content-center my-5">
            {announcements && announcements.length > pageSize ? (
              <div className="pagination">
                <Pagination
                  size="small"
                  showLessItems
                  showSizeChanger={false}
                  current={current}
                  total={announcements.length}
                  pageSize={pageSize}
                  onChange={handleChange}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={false}
      >
        <div className=" sidebar-search-responsive">
          <div className="d-flex justify-content-start align-items-center">
            <span className="icon">
              <Filter />
            </span>
            <p className="text-futura-20 px-3">{t("filter")}</p>
          </div>
          <p className="text-futura-16 text-align-left mt-5">
            {t("ZIPcodeCity")}
          </p>

          <div className="form-pro">
            <input
              className="fix-input"
              type="text"
              name="city"
              onChange={(e) => {
                setCity(e.target.value);
                changeCategory(e);
              }}
            />
            <i>
              <Localisation />
            </i>
          </div>

          <p className="text-futura-16 text-align-left mt-4">
            {t("requestDate")}
          </p>
          <input
            className="form-pro"
            type="date"
            name="deadline"
            onChange={(e) => {
              setDateDebut(e.target.value);
              changeCategory(e);
            }}
          />

          <div
            className="d-flex align-items-center mt-4"
            onClick={(e) => setActiveCategories(!activeCategories)}
          >
            <p className="text-futura-16 text-align-left">{t("categories")}</p>
            <i
              className={
                activeCategories
                  ? "icon-chevron-up px-3"
                  : "icon-chevron-down px-3"
              }
            ></i>
          </div>

          {activeCategories &&
            categorys&&
            categorys.length>0 ?
            categorys.map((category, key) => (
              <div className="d-flex flex-column mt-3" value={key}>
                <div
                  className={
                    active && categoryName == category.categoryName
                      ? " d-flex category active align-items-center"
                      : " d-flex category align-items-center"
                  }
                  name="categoryName"
                  onClick={(e) => changeCategory(e, category, "categoryName")}
                >
                  <img
                    className="img-category"
                    src={category.picture}
                    height="24px"
                    width="24px"
                  />
                  <p
                    className={
                      active && categoryName == category.categoryName
                        ? " text-futura-16 text-align-left active "
                        : " text-futura-16 text-align-left"
                    }
                  >
                    {i18n.language === "fr"
                      ? category.categoryName
                      : category.categoryNameEn}
                  </p>
                </div>
              </div>
            )):<span className="no-items">{t("noItems")}</span>}

          <div className="d-flex justify-content-start align-items-center mt-4">
            <p className="text-futura-16">{t("ratings")}</p>
            <i className="icon-chevron-down px-3"></i>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex py-2 align-items-center">
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <input
                className="checkbox-mmk px-3"
                type="checkbox"
                value={5}
                onClick={(e) => handleRating(e.target.value)}
                set
              />
            </div>

            <div className="d-flex py-2 align-items-center">
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <EmptyStar />
              <input
                className="checkbox-mmk px-3"
                type="checkbox"
                value={4}
                onClick={(e) => handleRating(e.target.value)}
                set
              />
            </div>

            <div className="d-flex py-2 align-items-center">
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <EmptyStar />
              <EmptyStar />
              <input
                className="checkbox-mmk px-3"
                type="checkbox"
                value={3}
                onClick={(e) => handleRating(e.target.value)}
                set
              />
            </div>

            <div className="d-flex py-2 align-items-center">
              <FullStar width={21} height={21} />
              <FullStar width={21} height={21} />
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
              <input
                className="checkbox-mmk px-3"
                type="checkbox"
                value={2}
                onClick={(e) => handleRating(e.target.value)}
                set
              />
            </div>

            <div className="d-flex py-2 align-items-center">
              <FullStar width={21} height={21} />
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
              <EmptyStar />
              <input
                className="checkbox-mmk px-3"
                type="checkbox"
                value={1}
                onClick={(e) => handleRating(e.target.value)}
                set
              />
            </div>
          </div>
          {user && user ? (
            announcementType === "demande" ? (
              <div className="d-flex flex-row my-5">
                <input
                  className="checkbox-mmk"
                  type="checkbox"
                  value="mySkills"
                  onChange={(e) => setMySkills(true)}
                />
                <p className="text-futura-16 text-align-left px-3">
                  {t("inMySkills")}
                </p>
              </div>
            ) : null
          ) : null}

          <input
            type="button"
            className="btn-mmk"
            onClick={(e) => advancedSearch(e)}
            value={t("search")}
          />
        </div>
      </Modal>
    </>
  );
}

const Arrows = (props) => {
  const { className, onClick, icon } = props;
  return (
    <a className={`slick-arrow slick-next ${className}`} onClick={onClick}>
      {icon ? <i className={icon}></i> : <i className="icon-chevron-right"></i>}
    </a>
  );
};

export default Search;
