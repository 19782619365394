import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";
import Footer from "../../components/Footer";
import moment from "moment";
import { Helmet } from "react-helmet-async";

function NewsDetails() {
  const user = useContext(AuthContext);
  const location = useLocation();

  const [article, setArticle] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setArticle(location.state);
  }, []);

  return (
    <>

      <Helmet>
        <title>{article&&article.articleTitle || 'Actualites | loading...'}</title>
      </Helmet>
      
      {user ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <section data-aos="fade-up" data-aos-delay="200">
        {article && (
          <div className="container mb-5">
            <div className=" d-flex justify-content-center align-items-center my-5">
              <h1 className="text-futura-30">{article.articleTitle}</h1>
            </div>

            <div className="d-flex justify-content-center align-items-center mb-5">
              <img
                className="radius-20"
                src={article.image}
                height="540px"
                width="100%"
              />
            </div>
            <div className="card-news-details">
              <div className="d-flex justify-content-between align-items-center mb-5">
                <p className="text-futura-20">{article.articleSubTitle}</p>

                <p className="text-futura-11-grey">
                  {moment(article.dateAjoutArticle).format("YYYY-MM-DD")}
                </p>
              </div>
              <div
                className="text-roboto-regular-16-black text-align-left text-justify mb-4"
                dangerouslySetInnerHTML={{
                  __html: article.articleContent,
                }}
              ></div>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default NewsDetails;
