import React from 'react'

function ProfileAnnounceCard(props) {
  return (
    <div
    onClick={() =>
      props.onClick(props.props.announcement)
    }
    className=" announce-card"
    key={props.props.key}
  >
    <div className="d-flex justify-content-between">
      <span className="text-futura-14 announce-title-over-flow">
        {props.props.announcement.announcementTitle}
      </span>
      <div className="d-flex justify-content-between">
        <span className="text-futura-11-grey pe-5 ps-2 address-over-flow">
          {" "}
          {props.props.announcement.city}{" "}
        </span>
        <span className="text-futura-14">
          {props.props.announcement.price}€
        </span>
      </div>
    </div>

    <p className="text-futura-14 text-align-left overflow-hidden mt-3 ellipsis-text">
      {props.props.announcement.announcementDescription}
    </p>
  </div>
  )
}

export default ProfileAnnounceCard