import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { getToken } from "../shared";


function Terms() {

  let token = getToken();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  
  return (
    <React.Fragment>

      <Helmet>
        <title>Make My Work | Conditions générales</title>
      </Helmet>

    {token ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <div className="container ">
        <div className="terms d-flex flex-column align-items-center mt-5">
          <div className="term-header">
            <h1>CONDITIONS GENERALES D’UTILISATION DE LA PLATEFORME MAKEMYWORK ET POLITIQUE DE CONFIDENTIALITE</h1>
          </div>
          <div className="term-content">
            <div className="term-paragh">
              <h2>Préambule</h2>
              <p>
                La plateforme en ligne dénommée MakeMyWork est une plateforme de mise en relation de particuliers pour l’exécution de prestations de
                services à la personne .
              </p>
              <p>
                La plateforme MakeMyWork est accessible sur le site internetet via l’application mobile MakeMyWork disponible sur, tous deux édités
                par la société MAKE MY WORK, société par actions simplifiée immatriculée au RCS de Versailles sous le numéro 900 672 882, dont le
                siège social est situé 8 avenue Manuel, 78600 Maisons-Laffitte.
              </p>
            </div>
            <div className="term-paragh">
              <h2 className="with-num">1. Définition</h2>
              <p>Chacun des termes mentionnés ci-dessous aura dans les présentes CGU la signification suivante :</p>
              <div className="table-terms">
                <div className="row-table">
                  <div className="col-table left">Accord</div>
                  <div className="col-table">
                    <p>
                      désigne la relation contractuelle formée entre le Client et le Prestataire suite à l’acceptation du Client et du Prestataire de
                      la Mission et du paiement de la Mission par le Client via le Service de Paiement Intégré.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Annonce</div>
                  <div className="col-table">
                    <p>
                      désigne l'ensemble des éléments et données (visuelles, textuelles, sonores, photographies, dessins), déposé par un Client sous
                      forme de Demande ou par un Prestataire sous forme d’Offre, sous sa responsabilité éditoriale, en vue de proposer ou de
                      rechercher un Service à la Personne sur la Plateforme.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Avis</div>
                  <div className="col-table">
                    <p>
                      désigne toute évaluation d’un Client ou d’un Prestataire représentée quantitativement par une note allant de 1 à 5 étoiles
                      affichée sur son Profil.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Client</div>
                  <div className="col-table">
                    <p>
                      désigne tout Utilisateur qui dépose une Demande sur la Plateforme ou répond directement à une Offre d’un Prestataire en vue
                      d’obtenir la fourniture d’un Service à la Personne.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Compte Utilisateur</div>
                  <div className="col-table">
                    <p>
                      désigne l’espace personnel de l’Utilisateur accessible sur la Plateforme, la connexion au Compte Utilisateur rendant possible
                      l’accès aux Services et leur utilisation
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Demande</div>
                  <div className="col-table">
                    <p>désigne l’annonce publiée par un Client à la recherche d’un Prestataire pour l’exécution d’un Service à la Personne.</p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Données Personnelles</div>
                  <div className="col-table">
                    <p>désignent toutes les informations relatives à une personne physique.</p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Messagerie Privée Électronique / Messages Privés</div>
                  <div className="col-table">
                    <p>
                      désignent la communication par échange de Messages Privés entre les Utilisateurs au moyen du système de messagerie de la
                      Plateforme, à laquelle seuls les Utilisateurs connectés à leur Compte Utilisateur peuvent avoir accès.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Mission</div>
                  <div className="col-table">
                    <p>
                      désigne la proposition de fourniture de Service à la Personne que le Prestataire s’engage à fournir au Client / que le Client
                      souhaite obtenir du Prestataire, selon un tarif et un délai déterminé, émise par le Client ou le Prestataire, via la Messagerie
                      Privée Électronique. L’acceptation de la Mission et son paiement via le Service de Paiement Intégré vaut accord. La Mission peut
                      être annulée par l’Utilisateur tant que son interlocuteur ne l’a pas acceptée.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Offre</div>
                  <div className="col-table">
                    <p>désigne l’annonce publiée par un Prestataire contenant une proposition de réalisation de Service à la Personne.</p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Plateforme</div>
                  <div className="col-table">
                    <p>désigne la plateforme MakeMyWork dans sa version web et sa version mobile.</p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Prestataire</div>
                  <div className="col-table">
                    <p>
                      désigne tout Utilisateur qui dépose une Offre sur la Plateforme ou répond directement à une Demande d’un Client en vue de la
                      fourniture d’un Service à la Personne.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Prestataire de Service de Paiement Intégré</div>
                  <div className="col-table">
                    <p>
                      désigne la société MANGOPAY SA, société à responsabilité limitée enregistrée au Luxembourg, sous le numéro d’immatriculation
                      B173459 et dont le siège social est situé au 2, avenue Amélie, L-1125 Luxembourg
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Profil</div>
                  <div className="col-table">
                    <p>
                      désigne la page de présentation d’un Utilisateur contenant les informations renseignées par l’Utilisateur (par exemple nom,
                      prénom, pseudonyme, photographie, compétences, description), ses Annonces et ses Avis.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Profil Certifié</div>
                  <div className="col-table">
                    <p>
                      désigne tout Profil ayant obtenue un badge « Certifié » suite à la vérification de l’identité de l’Utilisateur par la société
                      MAKE MY WORK au moyen d’un papier d’identité.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Services</div>
                  <div className="col-table">
                    <p>désignent l’ensemble des services proposés par la Plateforme à ses Utilisateurs et définis en Article 5.</p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Service(s) à la Personne</div>
                  <div className="col-table">
                    <p>
                      désignent le(s) service(s) à la personne recherché(s) par un Client / proposé(s) par un Prestataire sur la Plateforme. Les
                      services à la personne susceptibles d'être recherchés ou proposés sur la Plateforme recouvrent notamment, mais pas
                      exclusivement, les services de soutien scolaire, aide aux devoirs, bricolage, jardinage, garde à domicile, aide à domicile,
                      cuisine, etc.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Service de Paiement Intégré</div>
                  <div className="col-table">
                    <p>
                      Désigne le service de paiement en ligne, opéré par le Prestataire de Service de Paiement Intégré, accessible sur la Plateforme.
                    </p>
                  </div>
                </div>
                <div className="row-table">
                  <div className="col-table left">Utilisateur</div>
                  <div className="col-table">
                    <p>
                      désigne toute personne physique non professionnelle qui s’est inscrite sur la Plateforme et a accepté les CGU. Par souci de
                      clarté, un Utilisateur désigne aussi bien un Client qu’un Prestataire.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="term-paragh">
              <h2 className="with-num">
                2. Dispositions générales relatives aux présentes conditions générales d’utilisation de la Plateforme (ci-après les « CGU »
              </h2>
              <p>
                Les présentes CGU régissent toute utilisation des Services de la Plateforme par l’Utilisateur, dans sa version web et mobile. Ainsi,
                les CGU définissent les relations entre, d’une part, la Plateforme et les Utilisateurs et, d’autre part, les Clients et les
                Prestataires ayant conclu ou cherchant à conclure un Accord.
              </p>
              <p>Toutes questions relatives aux CGU peuvent être adressées à</p>
              <p>
                Toute utilisation des Services de la Plateforme est subordonnée à l'acceptation des CGU par l’Utilisateur lors de la création de son
                Compte Utilisateur.
              </p>
              <p>En cas de modification des CGU, les CGU applicables sont celles en vigueur lors de la création du compte utilisateur.</p>
              <p>Les CGU pourront être modifiées sans préavis.</p>
              <p>Les CGU sont soumises au droit français applicable en vigueur</p>
            </div>

            <div className="term-paragh">
              <h2 className="with-num">3. Accessibilité et disponibilité de la Plateforme et des Services</h2>
              <p>
                Afin d'accéder à la Plateforme et aux Services et de les utiliser, l’Utilisateur doit disposer d'une connexion à internet et disposer
                de tout le matériel nécessaire, notamment d'un ordinateur et d'un modem, d’un Smartphone ou de tout outil de connexion et d’un accès
                internet (les frais de connexion éventuels sont entièrement à la charge de l'utilisateur ou du visiteur).
              </p>
              <p>
                La société MAKE MY WORK s'efforce d'assurer la disponibilité de la Plateforme et des Services 24 heures sur 24 et 7 jours sur 7.
                Cependant, la société MAKE MY WORK ne pourra être tenue pour responsable en cas d'indisponibilité pour des raisons d'actualisation et
                de maintenance de la Plateforme ou d’indisponibilité des serveurs.
              </p>
              <p>
                De même, la société MAKE MY WORK se réserve le droit à tout moment, et sans préavis, d'interrompre ou de modifier la Plateforme ou les
                Services temporairement ou de façon permanente. La société MAKE MY WORK ne pourra être tenue responsable de toute modification,
                suspension ou interruption de la Plateforme ou du ou des Service(s).
              </p>
            </div>

            <div className="term-paragh">
              <h2 className="with-num">4. Conditions d’accès et d’utilisation de la Plateforme et des Services</h2>
              <h3 className="sub-title">4.1 Création d’un Compte Utilisateur</h3>
              <p>
                Afin de pouvoir accéder et utiliser les Services, l’Utilisateur doit s’inscrire sur la Plateforme en procédant à l’ouverture d’un
                Compte Utilisateur. L’ouverture du Compte Utilisateur permet la création d’un Profil et permet l’accès à un espace en ligne permettant
                d’accéder aux différents Services et de les utiliser.
              </p>
              <p>
                Pour bénéficier des Services proposés sur la Plateforme, l’Utilisateur doit être une personne physique ayant atteint l’âge de 18 ans,
                disposant de sa pleine capacité juridique et agissant en qualité de non professionnel.
              </p>
              <p>
                L’Utilisateur reconnait et accepte que l’accès et l’utilisation de certains Services de la Plateforme sont réservés aux Utilisateurs
                détenant un Profil Certifié
              </p>
              <h3 className="sub-title">4.2 Informations fournies par l’Utilisateur</h3>
              <p>
                L’Utilisateur certifie l’exactitude des informations transmises lors de la création du Compte Utilisateur, de la création de son
                Profil, de son Profil Certifié et du dépôt d’une Annonce.
              </p>
              <p>L’Utilisateur est seul responsable de toute erreur ou omission.</p>
              <p>L’Utilisateur s’interdit d’utiliser de fausses informations ou d’utiliser les informations de tiers sans autorisation.</p>
              <p>
                En cas d'utilisation frauduleuse du Compte Utilisateur découverte par l’Utilisateur, l’Utilisateur devra alerter la société MAKE MY
                WORK dans les plus brefs délais par email à l’adresse.
              </p>
              <p>
                En cas d’utilisation frauduleuse du Compte Utilisateur, la société MAKE MY WORK pourra immédiatement suspendre le Compte Utilisateur
                ou procéder à sa suppression.
              </p>
            </div>
            <div className="term-paragh">
              <h2 className="with-num">5. Services proposés par la Plateforme</h2>
              <h3 className="sub-title">5.1 Service de mise en relation et de publication d’Annonces</h3>
              <p>
                La Plateforme fonctionne comme un marché en ligne qui met en relation les Clients et les Utilisateurs en vue de la fourniture d’un
                Service à la Personne. Pour ce faire, la Plateforme permet à tout Utilisateur, qu’il soit Client ou Prestataire, de créer et publier
                une Annonce portant sur une Demande ou une Offre de fourniture d’un Service à la Personne.
              </p>
              <p>
                La rédaction de l’Annonce appartient à l’Utilisateur. L’Annonce contient la description claire et précise de la Demande / de l’Offre,
                le délai dans lequel le Service à la Personne doit être effectué, la localité où le Service à la Personne doit être fourni ainsi que
                le prix proposé pour la fourniture dudit Service à la Personne.
              </p>
              <p>L’Annonce ainsi créée par l’Utilisateur est immédiatement publiée sur la Plateforme pour une durée de XX semaines / mois.</p>
              <p>Tout Utilisateur peut répondre à l’Annonce via le service de Messagerie Privée Électronique proposée par la Plateforme.</p>
              <p>
                L’Utilisateur peut modifier ou supprimer son Annonce jusqu’à temps qu’elle donne lieu à l’acceptation d’une Mission par un autre
                Utilisateur via le service de Messagerie Privée Électronique proposée par la Plateforme.
              </p>
              <h3 className="sub-title">5.2 Messagerie Privée Électronique</h3>
              <h3 className="sub-middle-title">5.2.1 Fonctionnement de la Messagerie Privée Électronique</h3>
              <p>
                Les Utilisateurs peuvent communiquer entre eux par le biais d’une messagerie privée électronique disponible sur la Plateforme. Sous
                réserve de leur connexion à leur Compte Utilisateur, les Utilisateurs peuvent envoyer des Messages Privés afin de répondre à une
                Annonce en cliquant sur le bouton « Envoyer un message » situé à côté de l’Annonce.
              </p>
              <p>
                Les Utilisateurs sont informés de tout nouveau Message Privé reçu via l’envoi d’un courrier électronique à l’adresse mail renseignée
                lors de l’ouverture de leur Compte Utilisateur et/ou par une notification reçue sur l’application mobile MakeMyWork
              </p>
              <p>
                La réception de notification via l’application MakeMyWork n’est possible que pour les Utilisateurs connectés à leur Compte
                Utilisateur.
              </p>
              <p>
                Les Utilisateurs doivent être connectés à leur Compte Utilisateur pour répondre et envoyer des Messages Privés et accéder à leur
                historique de conversation.
              </p>
              <p>
                Les adresses emails des Utilisateurs utilisant la Messagerie Privée Électronique ne sont pas visibles par leur interlocuteur, seul le
                pseudonyme renseigné lors de la création du Compte Utilisateur par l’Utilisateur l’étant.
              </p>
              <p>
                La société MAKE MY WORK n’a pas accès au contenu des Messages Privés. Les Utilisateurs sont seuls responsables du contenu des Messages
                Privés échangés via la Messagerie Privée Électronique.
              </p>
              <h3 className="sub-middle-title">5.2.2 Proposition de Mission et conclusion d’un Accord</h3>
              <p>
                L’envoi de Message Privé via la Messagerie Privée Électronique a pour finalité principale l’échange d’informations relatives à des
                Annonces, la proposition de Mission et la conclusion d’un Accord.
              </p>
              <p>La Mission contient la description du Service à la Personne, son prix, le lieu et les délais d’exécution.</p>
              <p>
                L’Utilisateur destinataire de la Mission dispose d’un délai de 24h pour accepter ou refuser la Mission en sélection le bouton «
                Accepter » ou « Refuser ». L’acceptation d’une Mission par un Utilisateur vaut Accord.
              </p>
              <p>
                Dès acceptation d’une Mission par un Utilisateur, son interlocuteur en est informé par une notification dans sa Messagerie Privée
                Électronique.
              </p>
              <p>
                Les adresses emails des Utilisateurs utilisant la Messagerie Privée Électronique ne sont pas visibles par leur interlocuteur, seul le
                pseudonyme renseigné lors de la création du Compte Utilisateur par l’Utilisateur l’étant.
              </p>
              <p>
                L’Accord entre les Utilisateurs est réputé conclu dès acceptation d’une Mission par l’Utilisateur et paiement par le Client de la
                Mission par carte de crédit ou de débit via le Service de Paiement Intégré.
              </p>
              <h3 className="sub-title">5.3 Service de Paiement Intégré</h3>
              <p>
                Le paiement de toute Mission acceptée par un Client s’effectue au moyen du Service de Paiement Intégré par carte de crédit ou de
                débit, ou tout autre moyen de paiement qui pourrait être introduit à l’avenir au Service de Paiement Intégré. Le Client doit
                s’acquitter du paiement avant que l’Accord ne soit exécuté par le Prestataire.
              </p>
              <p>L’Accord est réputé exécuté :</p>
              <ul>
                <li>dès lors que le Client confirme par le biais de la Plateforme que la Mission a été exécutée conformément à l’Accord ;</li>
                <li>
                  automatiquement après l’expiration de la période de contestation de 2 jours décrites à l’article 5.4 si le médiateur n’est pas saisi
                  d’un litige relatif à l’exécution de l’Accord ;
                </li>
                <li>si un litige est ouvert au cours de la période de contestation, après la résolution du litige</li>
              </ul>
              <p>
                Une fois l’Accord réputé exécuté, le prix payé par le Client pour l’exécution de la Mission sera immédiatement transféré au
                Prestataire.
              </p>
              <p>
                La société MAKE MY WORK fait appel à MANGOPAY SA pour le traitement du paiement et la conservation des informations relatives à la
                carte de crédit ou de débit. Pour pouvoir utiliser le Service de Paiement Intégré, il peut être demandé à au Client de déclarer avoir
                accepté les conditions générales dudit prestataire. Le Client sera seul responsable de la transmission des informations liées à sa
                carte de crédit ou de débit et la société MAKE MY WORK décline par les présentes toute responsabilité, dans toute la mesure autorisée
                par la législation applicable.
              </p>
              <h3 className="sub-title">5.4 Service de médiation en cas de litige</h3>
              <p>
                En cas de différend entre un Client et un Prestataire survenant dans le cadre de l’exécution d’un Accord, la Plateforme met à la
                disposition des Utilisateurs un service de médiation. Il appartient à la partie la plus diligente de saisir le médiateur de la
                Plateforme d’une demande de résolution du différend dans un délai de 2 jours ouvrés suivant l’exécution du Service à la Personne objet
                de la Mission. Passé ce délai de contestation de 2 jours, l’Accord sera réputé avoir été correctement exécuté. En cas de contestation
                et de saisine du médiateur, le médiateur se chargera de déterminer si la Mission a été correctement exécutée ou non et si le prix
                convenu au titre de l’Accord doit être payé au Prestataire ou remboursé au Client.
              </p>

              <h3 className="sub-title">5.5 Service d’évaluation</h3>
              <p>
                Une fois un Accord exécuté, le Client a le droit de laisser un Avis sur le Profil du Prestataire et inversement. L’Avis d’un
                Utilisateur à l’égard d’un autre Utilisateur doit être juste et honnête, s’abstenir de tout propos mensongers ou injurieux et, plus
                largement, respecter les présentes CGU. En cas d’évaluation déplacée, abusive ou non conforme aux présentes CGU, l’Utilisateur pourra
                saisir le médiateur en vue d’obtenir la suppression de l’Avis contesté.
              </p>
            </div>
            <div className="term-paragh">
              <h2 className="with-num">6. Obligations, responsabilités et garanties</h2>
              <h3 className="sub-title">6.1 Engagements de l’Utilisateur</h3>
              <p>L’Utilisateur s’engage :</p>
              <ol type="i">
                <li>à respecter les présentes CGU ;</li>
                <li>
                  à accéder et utiliser personnellement les Services et à ne permettre à aucun tiers de les utiliser à sa place ou pour son compte et
                  à exécuter personnellement l’Accord ;
                </li>
                <li>à ne créer qu’un seul Compte Utilisateur ;</li>
                <li>
                  à n’utiliser les Services et la Plateforme que pour être mis en relation à titre non professionnel et non commercial avec des
                  Clients ou des Prestataires ;
                </li>
                <li>
                  à ce que son Profil, son Profil Certifié, le contenu de ses Annonces et de ses Missions soient conformes aux dispositions légales et
                  règlementaires en vigueur. A ce titre, l’Utilisateur s’interdit notamment de rechercher ou proposer toute Annonce portant sur la
                  fourniture d’une prestation illicite ou réglementée
                </li>
                <li>
                  à ne publier aucun contenu sur la Plateforme, ne tenir aucun propos et n’avoir aucun comportement sur la Plateforme, notamment au
                  moyen de la publication d’une Annonce ou de l’utilisation de la Messagerie Privée Électronique, ou en dehors de la Plateforme dans
                  le cadre de l’exécution de l’Accord à caractère diffamatoire, injurieux, obscène, pornographique, vulgaire, offensant, agressif,
                  déplacé, violent, menaçant, harcelant, raciste, xénophobe, à connotation sexuelle, incitant à la violence, à la discrimination ou à
                  la haine, encourageant les activités ou l’usage de substances illégales ou, plus généralement, contraires aux finalités de la
                  Plateforme, de nature à porter atteinte aux droits de la société MAKE MY WORK ou d’un tiers ou contraires aux bonnes mœurs ;
                </li>
                <li>
                  à ne pas porter atteinte aux droits et à l’image de la société MAKE MY WORK ou de tiers, notamment à leurs droits de propriété
                  intellectuelle ;
                </li>
                <li>
                  à s’abstenir de créer un nouveau Compte Utilisateur en cas de suppression du Compte Utilisateur par la société MAKE MY WORK du fait
                  du manquement de l’Utilisateur à l’une de ses obligations ;
                </li>
                <li>à ne pas accepter ou effectuer un paiement en dehors du Service de Paiement Intégré de la Plateforme ;</li>
              </ol>
              <p>L’Utilisateur reconnait :</p>
              <ul>
                <li>
                  qu’il est seul responsable du contenu des Annonces qu’il publie et rend accessibles aux Utilisateurs ainsi que de tout document ou
                  information qu’il transmet aux Utilisateurs. L’Utilisateur assume ainsi l’entière responsabilité éditoriale du contenu des Annonces
                  qu’il publie ;
                </li>
                <li>qu’il est seul responsable de l’exécution des obligations visées ci-dessus et de la bonne exécution de l’Accord.</li>
              </ul>
              <p>
                En conséquence, l'Utilisateur relève la société MAKE MY WORK, ses sous-traitants et fournisseurs, de toutes responsabilités, les
                garantit contre tout recours ou action en relation avec l'Annonce qui pourrait être intenté contre ces derniers par tout tiers, et
                prendra à sa charge tous les dommages-intérêts ainsi que les frais et dépens auxquels ils pourraient être condamnés ou qui seraient
                prévus à leur encontre par un accord transactionnel signé par ces derniers avec ce tiers, nonobstant tous dommages-intérêts dont la
                société MAKE MY WORK, ses sous-traitants et fournisseurs pourraient réclamer à raison des faits dommageables de l’Utilisateur.
              </p>
              <p>
                En cas de manquement de l’Utilisateur à l’une des obligations visées ci-dessus, la société MAKE MY WORK pourra immédiatement suspendre
                ou supprimer le Compte Utilisateur.
              </p>
              <h3 className="sub-title">6.2 Responsabilités et obligations de la société MAKE MY WORK</h3>
              <h3 className="sub-middle-title">6.2.1 Responsabilités et obligations en qualité d’hébergeur</h3>
              <p>
                En sa qualité d'hébergeur, la société MAKE MY WORK est soumise à un régime de responsabilité atténuée prévu aux articles 6.I.2. et
                suivants de la loi nº2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique
              </p>
              <p>
                La société MAKE MY WORK ne saurait donc en aucun cas être tenue responsable du contenu des Annonces publiées par les Utilisateurs
                ainsi que des messages et contenus échangés via la Messagerie et ne donne aucune garantie, expresse ou implicite, à cet égard.
              </p>
              <p>
                La société MAKE MY WORK est un tiers aux correspondances et relations entre les Annonceurs et les Utilisateurs, et exclut de ce fait
                toute responsabilité à cet égard.
              </p>
              <h3 className="sub-middle-title">6.2.2 Limitation de responsabilité</h3>
              <p>
                La société MAKE MY WORK s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux la fourniture des Services aux
                Utilisateurs.
              </p>

              <p>Toutefois, la société MAKE MY WORK décline toute responsabilité en cas de :</p>
              <ul>
                <li>
                  interruptions, de pannes, de modifications et de dysfonctionnement de la Plateforme ou du ou des Service(s), quel que soit le
                  support de communication utilisé et ce quelles qu'en soient l'origine et la provenance ;
                </li>
                <li>
                  la perte de données ou d'informations stockées par la société MAKE MY WORK . Il incombe aux Utilisateurs de prendre toutes
                  précautions nécessaires pour conserver les Annonces qu'ils publient sur la Plateforme ;
                </li>
                <li>
                  impossibilité momentanée d'accès à la Plateforme en raison de problèmes techniques et ce quelles qu'en soient l'origine et la
                  provenance ;
                </li>
                <li>
                  dommages directs ou indirects causés à l'Utilisateur, quelle qu'en soit la nature, résultant du contenu des Annonces et/ou de
                  l'accès, de la gestion, de l'utilisation, de l'exploitation, du dysfonctionnement et/ou de l'interruption de la Plateforme ou d’un
                  ou des Service(s) ;
                </li>
                <li>utilisation anormale ou d'une exploitation illicite de la Plateforme ou d’un ou des Service(s) par tout Utilisateur ;</li>
                <li>
                  attaque ou piratage informatique, privation, suppression ou interdiction, temporaire ou définitive, et pour quelque cause que ce
                  soit, de l’accès au réseau internet.
                </li>
              </ul>
              <p>
                La société MAKE MY WORK n’effectue aucune Mission et n’emploie personne à cet effet. La Plateforme MAKE MY WORK n’est pas un service
                ni une entreprise de placement. Aucun des Utilisateurs n’est employé par la société MAKE MY WORK.
              </p>
              <p>La société MAKE MY WORK ne contrôle ni ne dirige l’exécution par les Utilisateurs de leur Accord.</p>
              <p>
                Ainsi, la société MAKE MY WORK décline toute responsabilité en cas de mauvaise exécution ou inexécution des Accords par les
                Prestataires ou les Clients dans le cadre de l’exécution de l’Accord.
              </p>
              <p>
                Enfin, la responsabilité de la société MAKE MY WORK ne pourra être engagée que pour les dommages directs causés par la société MAKE MY
                WORK et subis par l’Utilisateur, résultant d’un manquement à ses obligations contractuelles telles que définies aux présentes.
              </p>
              <p>
                Tout Utilisateur est seul responsable des dommages causés à tout autre Utilisateur dans le cadre de l’exécution d’un Accord ou, plus
                généralement, à tout tiers du fait de l’utilisation de la Plateforme et des Services et des conséquences des réclamations ou actions
                qui pourraient en découler. L'Utilisateur renonce également à exercer tout recours contre la société MAKE MY WORK dans le cas de
                poursuites diligentées par un autre Utilisateur du fait de la mauvaise exécution ou de l’inexécution d’un Accord ou par un tiers à son
                encontre du fait de l'Utilisation et/ou de l'exploitation illicite de la Plateforme ou de l’un ou des Services, ainsi qu’en cas de
                perte par un Utilisateur de son mot de passe ou en cas d'usurpation de son identité.
              </p>
            </div>
            <div className="term-paragh">
              <h2 className="with-num">7. Codes promotionnels et parrainage</h2>
              <h3 className="sub-middle-title">7.1 Codes promotionnels et parrainage</h3>
              <p>
                La société MAKE MY WORK peut communiquer à ses Utilisateurs des codes promotionnels, utilisables une ou plusieurs fois selon les cas
                et dans un délai déterminé. Ces codes promotionnels ne sont pas cumulables entre eux.
              </p>
              <h3 className="sub-middle-title">7.2 Parrainage</h3>
              <p>
                La société MAKE MY WORK Livre permet à l’Utilisateur (ci-après le « Parrain ») de parrainer un nouvel utilisateur (ci-après le «
                Filleul »).
              </p>
              <p>
                Afin de bénéficier d’un parrainage, le Filleul doit renseigner lors son inscription le code de parrainage transmis par son Parrain ou
                l’adresse email du Parrain.
              </p>
              <p>Lorsqu’un parrainage est activé, le Parrain et le Filleul bénéficie d’une réduction de X euros sur le prix d’une Mission.</p>
              <p>Cette réduction ou ce crédit peuvent être cumulés avec un code promotionnel prévue à l’article 7.1 des présentes CGU.</p>
            </div>
            <div className="term-paragh">
              <h2 className="with-num">8. Droits de propriété intellectuelle</h2>
              <p>
                La société MAKE MY WORK détient tous les droits, titres et intérêts afférents à la Plateforme et notamment les droits de propriété
                intellectuelle (notamment sur la mise en page et la conception de la Plateforme, sur les logiciels utilisés par et pour la Plateforme,
                sur les marques et noms de domaine utilisé en relation avec la Plateforme, etc).
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Terms;
