import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import NavBarConnected from "../../components/NavBarConnected";
import { AuthContext } from "../../context/AuthContext";
import { connect } from "react-redux";
import * as actionCreator from "../../redux/actions";
import Url from "../../constants/Url";
import { Modal, notification } from "antd";
import { Link, NavLink } from "react-router-dom";
import Plus from "../../components/icons/Plus";
import NavBarMobile from "../../components/NavbarMobile";
import SpinLoading from "../../components/SpinLoading";
import ProfileAnnounceCard from "../../components/cards/ProfileAnnounceCard";

class MyAnnouncements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDeleteAnnounce: false,
      loadingUpdateAnnounce: false,

      addCatergorie: false,
      userId: null,
      announcement: { announcementType: "" },

      annToDelete: [],
      showBtnmmk: false,
      offerAction: 0,
      demandeAction: 0,
      arrPic: [],
      openModalForUpdate: false,
      deleteOffre: false,
      deleteDemande: false,
      filesNames: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (this.context.user) this.getAllAnnouncement(this.context.user.id);
  }

  componentDidUpdate(prevState, prevProps) {
    if (this.context.user.id && !this.props.announcements.length) {
      this.getAllAnnouncement(this.context.user.id);
    }
  }

  getAllAnnouncement(id) {
    const data = {
      userId: id,
    };
    this.props.GetAllAnnouncement(data);
  }

  handleDeleteAnnouncement = (annId) => {
    const { annToDelete } = this.state;
    if (!annToDelete.includes(annId)) {
      annToDelete.push(annId);
    } else
      for (var i = 0; i < annToDelete.length; i++) {
        if (annToDelete[i] === annId) {
          annToDelete.splice(i, 1);
        }
      }
    setTimeout(() => {
      if (this.state.annToDelete.length !== 0) {
        this.setState({ showBtnmmk: true });
      } else {
        this.setState({ showBtnmmk: false });
      }
    }, 500);
  };

  async deleteAnnouncement(e) {
    this.setState({ loadingDeleteAnnounce: true });

    await Url.Url2.delete("deleteAnnouncement", {
      data: {
        arrAnnouncementId: this.state.annToDelete,
      },
    })
      .then((response) => {
        //NotificationManager.success("Announce supprimé","Announce");
        notification.success({

          message: "Annonce",
          description: "Annonce supprimée",
        });
        this.setState({
          loadingDeleteAnnounce: false,
          offerAction: 0,
          demandeAction: 0,
          deleteOffre: false,
          deleteDemande: false,
        });
        this.getAllAnnouncement(this.context.user.id);
      })
      .catch((error) => {
        //NotificationManager.error("error :", error.message);
        notification.error({

          message: "error",
          description: error.message,
        });
      });
  }

  handleInputChange(e) {
    this.setState({
      announcement: {
        ...this.state.announcement,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleFileInputChange = (e) => {
    let arrPic = [];
    this.setState({
      filesNames: [],
    });
    for (let i = 0; i < e.target.files.length; i++) {
      let file = e.target.files[i];

      this.getBase64(file)
        .then((result) => {
          file[i] = result;
          arrPic.push(result);
          this.state.filesNames.push(file.name);
          this.setState({
            arrPic: arrPic,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {

        baseURL = reader.result;

        resolve(baseURL);
      };
    });
  };
  clickButton = (e) => {
    this.myRef.current.click();
  };
  openModalForUpdateAnn(announcement) {
    this.setState({
      openModalForUpdate: !this.state.openModalForUpdate,
      announcement: announcement,
      filesNames: [],
    });
  }

  async updateAnnouncement() {
    const { announcement, arrPic, categoryName } = this.state;

    this.setState({ loadingUpdateAnnounce: true });
    await Url.Url2.post("updateAnnouncement", {
      announcementId: announcement.id,
      announcementType: announcement.announcementType,
      announcementTitle: announcement.announcementTitle,
      announcementDescription: announcement.announcementDescription,
      price: announcement.price,
      deadLine: announcement.deadLine,
      arrPic: arrPic,
      categoId: announcement.categoryId,
    })
      .then((response) => {
        notification.success({

          message: "Annonce",
          description: "Annonce mise à jour avec succès",
        });
        this.getAllAnnouncement(this.context.user.id);
        this.setState({ openModalForUpdate: false });
        this.setState({ loadingUpdateAnnounce: false });
      })
      .catch((error) => {
        //NotificationManager.error("error :", error.message);
        notification.error({

          message: "error",
          description: error.message,
        });
        this.setState({ loadingUpdateAnnounce: false });
      });
  }

  onDeleteOffre() {
    this.setState({
      offerAction: this.state.offerAction === 0 ? 2 : 0,
      deleteOffre: !this.state.deleteOffre,
    });
  }

  onDeleteDemande() {
    this.setState({
      demandeAction: this.state.demandeAction === 0 ? 2 : 0,
      deleteDemande: !this.state.deleteDemande,
    });
  }


  render() {
    const {
      announcement,
      arrPic,
      annToDelete,
      showBtnmmk,
      deleteOffre,
      deleteDemande,
    } = this.state;

    const { announcements } = this.props;

    const { t } = this.context.t;

    return (
      <>
        <NavBarConnected />
        <NavBarMobile />

        <main id="main">
          <section
            id="sec6"
            className="sec6 d-flex align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="container w-100 ">
              <div className=" d-flex justify-content-center align-items-center">
                <NavLink
                  to="/profil"
                  activeClassName="m-3 selected-nav"
                  className="m-3 not-selected-nav"
                >
                  {t("navMyProfile")}
                </NavLink>

                <NavLink
                  to="/mon-activite"
                  activeClassName="m-3 selected-nav"
                  className="m-3 not-selected-nav"
                >
                  {t("navMyActivity")}
                </NavLink>

                <NavLink
                  to="/mes-annonces"
                  activeClassName="m-3 selected-nav"
                  className="m-3 not-selected-nav"
                >
                  {t("navMyAnnouncements")}
                </NavLink>

                <NavLink
                  to="/token-mmw"
                  activeClassName="m-3 selected-nav"
                  className="m-3 not-selected-nav"
                >
                  {t("navToken")}
                </NavLink>
              </div>

              <div
                className="row justify-content-center"
                style={{ marginTop: "5rem" }}
              >
                <div className="col-sm justify-content-center col-offre">
                  <div className="d-flex justify-content-between">
                    <div>
                      {this.state.offerAction === 2 ? (
                        <p className="text-futura-20 text-align-left">
                          {t("offersToDelete")}
                        </p>
                      ) : (
                        <div className="row">
                          <p className="text-futura-20 text-align-left">
                            {t("myOffers")}
                          </p>
                        </div>
                      )}
                    </div>
                    <div>
                      <Link to="/selectionner-un-type-dannonce">
                        <i className="pointer">
                          <Plus />
                        </i>
                      </Link>

                      {deleteDemande === false ? (
                        <img
                          src={require('../../assets/img/fullTrash.png')}
                          onClick={() => this.onDeleteOffre()}
                          className="pointer"
                        />
                      ) : null}
                    </div>
                  </div>

                  {(announcements.length>0 && announcements.find(an => an.announcementType ==='offre'))?
                  announcements.map((announcement, key) =>
                    announcement.announcementType === "offre" ? (
                      <div
                        key={key}
                        className="d-flex flex-row justify-content-center align-items-center my-5"
                      >
                        {this.state.offerAction === 2 ? (
                          <input
                            type="checkbox"
                            style={{ marginRight: "15px" }}
                            className="checkbox-mmk"
                            onChange={() =>
                              this.handleDeleteAnnouncement(announcement.id)
                            }
                          />
                        ) : null}

                        <ProfileAnnounceCard props={{announcement,key}} onClick={()=>this.openModalForUpdateAnn(announcement)}/>
                      </div>
                    ) : null
                  ):<span className="no-items">{t("noItems")}</span>}
                  <div style={{ textAlignLast: "center" }}>
                    {this.state.offerAction === 2 ? (
                      <button
                        onClick={(e) => this.deleteAnnouncement(e)}
                        className={
                          showBtnmmk === true
                            ? "btn-mmk"
                            : "btn-mmk-grey-opacity"
                        }
                        disabled={this.state.loadingDeleteAnnounce}
                      >
                        {this.state.loadingDeleteAnnounce ? (
                          <SpinLoading />
                        ) : (
                          t("btnDeleteSelected")
                        )}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm justify-content-center col-demande">
                  <div className="d-flex justify-content-between ">
                    <div>
                      {this.state.demandeAction === 2 ? (
                        <p className="text-futura-20 text-align-left">
                          {t("requestToDelete")}
                        </p>
                      ) : (
                        <p className="text-futura-20 text-align-left">
                          {t("myRequests")}
                        </p>
                      )}
                    </div>
                    <div>
                      <Link to="/selectionner-un-type-dannonce">
                        <i className="pointer">
                          <Plus />
                        </i>
                      </Link>

                      {deleteOffre === false ? (
                        <img
                          src={require('../../assets/img/fullTrash.png')}
                          onClick={() => this.onDeleteDemande()}
                          className="pointer"
                        />
                      ) : null}
                    </div>
                  </div>
                  {(announcements.length>0 && announcements.find(an => an.announcementType ==='demande'))?
                  announcements.map((announcement, key) =>
                    announcement.announcementType === "demande" ? (
                      <div
                        key={key}
                        className="d-flex flex-row justify-content-center align-items-center my-5"
                      >
                        {this.state.demandeAction === 2 ? (
                          <input
                            type="checkbox"
                            style={{ marginRight: "15px" }}
                            className="checkbox-mmk"
                            onChange={() =>
                              this.handleDeleteAnnouncement(announcement.id)
                            }
                          />
                        ) : null}

                        <ProfileAnnounceCard props={{announcement,key}} onClick={()=>this.openModalForUpdateAnn(announcement)}/>
                      </div>
                    ) : null
                  ):<span className="no-items">{t("noItems")}</span>}
                  <div style={{ textAlignLast: "center" }}>
                    {this.state.demandeAction === 2 ? (
                      <button
                        onClick={(e) => this.deleteAnnouncement(e)}
                        className={
                          showBtnmmk === true
                            ? "btn-mmk"
                            : "btn-mmk-grey-opacity"
                        }
                        disabled={this.state.loadingDeleteAnnounce}
                      >
                        {this.state.loadingDeleteAnnounce ? (
                          <SpinLoading />
                        ) : (
                          t("btnDeleteSelected")
                        )}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Modal
          open={this.state.openModalForUpdate}
          footer={false}
          onCancel={() =>
            this.setState({ openModalForUpdate: false, filesNames: [] })
          }
        >
          <form id="contact-form" className="contact-form">
            <div className="row gy-4 justify-content-center align-items-star">
              <div className="d-flex align-items-center justify-content-center">
                <p className="text-futura-30">{t("announcementInfo")}</p>
              </div>

              <div className="col-12 ">
                <p className="text-futura-14 text-align-left">
                  {t("announcementTitle")}
                </p>
                <input
                  type="text"
                  className="form-control form-input-ann"
                  name="announcementTitle"
                  onChange={(e) => this.handleInputChange(e)}
                  value={announcement.announcementTitle}
                />
              </div>

              <div className="col-12 ">
                <p className="text-futura-14 text-align-left">
                  {t("lableTown")}
                </p>
                <input
                  type="text"
                  className="form-control form-input-ann"
                  name="city"
                  onChange={(e) => this.handleInputChange(e)}
                  value={announcement.city}
                />
              </div>

              <div className="col-12 ">
                <p className="text-futura-14 text-align-left">
                  {t("announcementDesc")}
                </p>
                <div className="col-sm-12">
                  <textarea
                    className="form-control form-textarea-ann"
                    style={{ height: "100px" }}
                    name="announcementDescription"
                    onChange={(e) => this.handleInputChange(e)}
                    value={announcement.announcementDescription}
                  ></textarea>
                </div>
              </div>

              <div className="col-12">
                <p className="text-futura-20 text-align-left">
                  {t("announcementDetails")}
                </p>
                <p className="text-futura-14 text-align-left">
                  {t("propostionPrice")}
                </p>
              </div>
              <div className="col-12">
                <div className="d-flex flex-row justify-content-between">
                  <div className=" d-flex justify-content-center align-items-star mr-6">
                    <input
                      type="text"
                      name="price"
                      className="form-control form-input-ann"
                      style={{ width: "65px" }}
                      min={2}
                      placeholder="0"
                      onChange={(e) => this.handleInputChange(e)}
                      value={announcement.price}
                    />

                    <div className="d-flex align-items-center justify-content-center ">
                      <span className="text-futura-11">&nbsp;€</span>
                    </div>
                  </div>

                  <div>
                    {announcement.announcementType === "demande" ? (
                      <div className="d-flex flex-row align-items-center justify-content-center">
                        <p className="text-futura-14 text-align-left">
                          {t("limitDateReq")}
                        </p>
                        <input
                          className="form-control form-input-ann"
                          type="date"
                          name="deadLine"
                          onChange={(e) => this.handleInputChange(e)}
                          value={announcement.deadLine}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <p className="text-futura-14 text-align-left">
                  {t("addMultipleImages")}
                </p>
                <span
                  className="form-input-file width-100"
                  onClick={(e) => this.clickButton(e)}
                >
                  <div className="d-flex justify-content-between w-100">
                    <span className="w-100 pl-2">
                      {this.state.filesNames && this.state.filesNames.length > 0
                        ? this.state.filesNames.map((name, key) => (
                            <p className="text-futura-14 text-align-left m-0">
                              {name}
                            </p>
                          ))
                        : announcement.picture &&
                          announcement.picture.map((fileName, key) => (
                            <p className="text-futura-14 text-align-left m-0">
                              {
                                fileName.split("/")[
                                  fileName.split("/").length - 1
                                ]
                              }
                            </p>
                          ))}
                    </span>
                    {this.state.filesNames.length > 0 ? (
                      <span
                        className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                        style={{ paddingRight: "15px" }}
                      >
                        | {t("btnEDIT")}
                      </span>
                    ) : (
                      <span
                        className="d-flex justify-content-end w-100 align-items-center text-futura-14 text-align-left"
                        style={{ paddingRight: "15px" }}
                      >
                        | {t("btnBROWSE")}
                      </span>
                    )}
                  </div>
                </span>
                <input
                  ref={this.myRef}
                  className="form-creation"
                  type="file"
                  id="formFile"
                  style={{ display: "none" }}
                  onChange={(e) => this.handleFileInputChange(e)}
                  multiple
                  required
                />
              </div>
            </div>
          </form>
          <div className="d-flex align-items-center justify-content-center my-5">
            <button
              onClick={() => this.updateAnnouncement()}
              className="btn-mmk"
              disabled={this.state.loadingUpdateAnnounce}
            >
              {this.state.loadingUpdateAnnounce ? (
                <SpinLoading />
              ) : (
                t("btnEDIT")
              )}
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    skills: state.SkillsReducer.skills,
    announcements: state.AnnouncementReducer.announcements,
  };
};
/*************************************************************************************************************************************/
const mapDispatchToProps = (dispatch) => {
  return {
    GetAllAnnouncement: (data) =>
      dispatch(actionCreator.GetAllAnnouncement(data)),
    getAllSkills: () => dispatch(actionCreator.getAllSkills()),
  };
};
/*************************************************************************************************************************************/
MyAnnouncements.contextType = AuthContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyAnnouncements));
