import React from 'react'

function SpinLoading() {
  return (
    <div class="spinner-border" role="status">
    <span class="sr-only"></span>
</div>
  )
}

export default SpinLoading