import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  announcementInProgress: [],
  announcements: [],
  success: null,
  announce: null,
  otherUserannouncements: null,
};

const AnnouncementReducer = (state = INITIAL_STATE, action) => {
  let data = null;
  switch (action.type) {
    case actionType.GET_ALL_ANNOUNCEMENT:
      return {
        ...state,
        announcements: action.payload.announcement,
      };

    case actionType.SUCCESS_ADD_ANNONCE:
      return {
        ...state,
        success: action.payload,
      };
    case actionType.ANNOUNCEMENT_DETAIL:
      return {
        ...state,
        announce: action.payload,
      };
    case actionType.GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_USER:
      return {
        ...state,
        announcementInProgress: action.payload,
      };

    case actionType.GET_ALL_ANNOUNCEMENT_ENCOURS_FOR_OTHER_USER:
      return {
        ...state,
        otherUserannouncements: action.payload,
      };

    default:
      return { ...state };
  }
};
export default AnnouncementReducer;
