import React, { useEffect, useState } from "react";
import NavBarConnected from "../../components/NavBarConnected";
import { notification } from "antd";
import NavbarMobile from "../../components/NavbarMobile";
import { ethers } from "ethers";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MMKToken() {
  const [amoutOfMMKTToBuy, setAmountOfMMKTToBuy] = useState(0);
  const [amoutOfMMKTToSell, setAmountOfMMKTToSell] = useState(0);
  const [balance, setBalance] = useState(0);
  const { t } = useTranslation();

  const CONTRACT_ADDRESS_SALE = "0xb814D6eD3fA4887B87dE80a82fe9D9FFAD6C6c81";
  const CONTRACT_ADDRESS_TOKEN = "0xF878dFad54A6C1cd6751b2d9899dC5999797e6A1";

  const ABI_SALE = [
    "function buyTokens() public payable returns (uint256 tokenAmount)",
    "function sellTokens(uint256 tokenAmountToSell) public",
    "function withdraw() public onlyOwner",
    "function tokensPerEth() returns(uint256)",
  ];

  const ABI_TOKEN = [
    "function approve(address spender, uint256 amount) public returns (bool)",
    "function decreaseAllowance(address spender, uint256 subtractedValue) public returns (bool)",
    "function increaseAllowance(address spender, uint256 addedValue) public returns (bool)",
    "function transfer(address to, uint256 amount) public returns (bool)",
    "function transferFrom(address from, address to, uint256 amount) public returns (bool)",
    "function allowance(address owner, address spender) public view returns (uint256)",
    "function balanceOf(address account) public view returns (uint256)",
    "function decimals() public view returns (uint8)",
    "function name() public view returns (string memory)",
    "function symbol() public view returns (string memory)",
    "function totalSupply() public view returns (uint256)",
  ];

  useEffect(() => {
    if (!window.ethereum) {
      notification.error({

        message: "MetaMask",
        description: "Merci d'installer l'extension metamask.",
      });
      //throw new Error("Merci d'installer l'extension metamask.");
    } else {
      try {
        
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tokenContract = new ethers.Contract(
        CONTRACT_ADDRESS_TOKEN,
        ABI_TOKEN,
        provider
      );
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (walletAddress) => {
          const balance = await tokenContract.balanceOf(walletAddress[0]);

          setBalance(ethers.utils.formatEther(balance));

          const allowance = await tokenContract.allowance(
            walletAddress[0],
            CONTRACT_ADDRESS_SALE
          );

          if (allowance.toString() === "0" && balance.toString() > "0") {
            await window.ethereum.send("eth_requestAccounts");

            const signer = provider.getSigner();

            const tokenContract = new ethers.Contract(
              CONTRACT_ADDRESS_TOKEN,
              ABI_TOKEN,
              signer
            );

            const tx = await tokenContract.approve(
              CONTRACT_ADDRESS_SALE,
              ethers.utils.parseEther("100000000")
            );
            if (tx) {
              notification.success({

                message: "Transaction",
                description: "Transaction de approuver le vente envoyée",
              });
            }
          }

          const allowance2 = await tokenContract.allowance(
            walletAddress[0],
            walletAddress[0]
          );

          if (allowance2.toString() === "0" && balance.toString() > "0") {
            await window.ethereum.send("eth_requestAccounts");

            const signer = provider.getSigner();

            const tokenContract = new ethers.Contract(
              CONTRACT_ADDRESS_TOKEN,
              ABI_TOKEN,
              signer
            );

            const tx2 = await tokenContract.approve(
              walletAddress[0],
              ethers.utils.parseEther("100000000")
            );
            if (tx2) {
              notification.success({
            
                message: "Transaction",
                description: "Transaction de approuver le transfert envoyée",
              });
            }
          }
        });

      } catch (error) {
        notification.error({
          
          message: "Metamask",
          description: "transaction échouée, montant insuffisant!",
        });
      }
    }
  }, []);

  const buyMMKToken = async () => {
    try {
      
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    if (!window.ethereum) {
      notification.error({

        message: "error",
        description: "Merci d'installer l'extension metamask.",
      });
      throw new Error("Merci d'installer l'extension metamask.");
    }

    await window.ethereum.send("eth_requestAccounts");

    const signer = provider.getSigner();

    const saleContract = new ethers.Contract(
      CONTRACT_ADDRESS_SALE,
      ABI_SALE,
      signer
    );

    const tx = await saleContract.buyTokens({
      value: ethers.utils.parseEther(amoutOfMMKTToBuy.toString()),
    });
    if (tx) {
      notification.success({

        message: "Transaction",
        description: "Transaction d'achat envoyée.",
      });
    }
  } catch (error) {
    notification.error({
      
      message: "Metamask",
      description: "transaction échouée, montant insuffisant!",
    });
  }
  };

  const sellMMKToken = async () => {
    try {
      
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    if (!window.ethereum) {
      notification.error({

        message: "error",
        description: "Merci d'installer l'extension metamask.",
      });
      throw new Error("Merci d'installer l'extension metamask.");
    }

    await window.ethereum.send("eth_requestAccounts");

    const signer = provider.getSigner();

    const saleContract = new ethers.Contract(
      CONTRACT_ADDRESS_SALE,
      ABI_SALE,
      signer
    );

    const tx = await saleContract.sellTokens(
      ethers.utils.parseEther((amoutOfMMKTToSell * 100).toString())
    );
    if (tx) {
      notification.success({

        message: "Transaction",
        description: "Transaction de vente envoyée",
      });
    }
  } catch (error) {
    notification.error({
      
      message: "Metamask",
      description: "transaction échouée, montant insuffisant!",
    });
  }
  };

  return (
    <>
      <NavBarConnected />
      <NavbarMobile />
      <section
        data-aos="fade-up"
        data-aos-delay="200"
        className="d-flex align-items-center"
      >
        <div className="container">
          <div className=" d-flex justify-content-center align-items-center">
            <NavLink
              to="/profil"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyProfile")}
            </NavLink>

            <NavLink
              to="/mon-activite"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyActivity")}
            </NavLink>

            <NavLink
              to="/mes-annonces"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navMyAnnouncements")}
            </NavLink>

            <NavLink
              to="/token-mmw"
              activeClassName="m-3 selected-nav"
              className="m-3 not-selected-nav"
            >
              {t("navToken")}
            </NavLink>
          </div>
          <div className="my-5">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={require('../../assets/img/payment/crypto.png')}
                style={{ height: "60px", width: "60px" }}
              />
              <div className="d-flex justify-content-center align-items-center gap-5 mt-5">
                <p className="text-futura-20 ">
                  {t("balance")} : {balance ? balance : 0} MMW
                </p>
              </div>
              <div className="d-flex flex-row gap-5 mt-5">
                <div className="d-flex flex-column justify-content-center align-items-center gap-5 card-token p-5">
                  <p className="text-futura-16 ">{t("amountToBuy")} (ETH)</p>
                  <input
                    type="text"
                    className="input-payment"
                    onChange={(e) => setAmountOfMMKTToBuy(e.target.value)}
                  />
                  <div>
                    <p className="text-futura-16">=</p>
                    <p className="text-futura-16">
                      {(amoutOfMMKTToBuy * 100).toFixed(3)} MMW
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="btn-mmk"
                    onClick={() => buyMMKToken()}
                  >
                    {t("btnBuy")}
                  </button>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center gap-5 card-token p-5">
                  <p className="text-futura-16 ">{t("amountToSell")} (ETH)</p>
                  <input
                    type="text"
                    className="input-payment"
                    onChange={(e) => setAmountOfMMKTToSell(e.target.value)}
                  />
                  <div>
                    <p className="text-futura-16">=</p>
                    <p className="text-futura-16">
                      {(amoutOfMMKTToBuy * 100).toFixed(3)} MMW
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="btn-mmk-grey"
                    onClick={() => sellMMKToken()}
                  >
                    {t("btnSell")}
                  </button>
                </div>
              </div>
              <div className="my-5"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MMKToken;
