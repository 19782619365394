import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { getToken } from "./routes/shared";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "antd/dist/antd.min.css";
import { HelmetProvider } from "react-helmet-async";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from "pusher-js";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { notification } from "antd";
import Url from "./constants/Url";
import { getOS } from "./utils/detectOS";
import { PureComponent } from "react";
import { Suspense } from "react";
import Preloader from "./common/Preloader";

import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes";
import "react-phone-number-input/style.css";

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      connected: getToken(),
    };
  }

  render() {
    let view;
    if (this.state.connected) {
      view = (
        <React.Fragment>
          <PrivateRoutes />
        </React.Fragment>
      );
    } else {
      view = (
        <React.Fragment>
          <PublicRoutes />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <NotificationContainer />
        <I18nextProvider i18n={i18n}>
          <Provider store={configureStore()}>
            <Suspense fallback={<Preloader />}>{view}</Suspense>
          </Provider>
        </I18nextProvider>
      </React.Fragment>
    );
  }
}

function AppWithPusher({ history }) {
  const { user } = useContext(AuthContext);
  const [channel, setChannel] = useState(null);

  useEffect(async () => {
    let os = getOS();
    if (os == "iOS" || os == "Android") {
      history.push({
        pathname: "/application-mobile",
      });
    }
  }, []);

  useEffect(async () => {
    if (user && user.id) {
      const pusher = new Pusher("d69d1373d3c67363441e", {
        cluster: "eu",
      });

      if (!pusher.channel(user.id.toString())) {
        setChannel(pusher.subscribe(user.id.toString()));
      } else {
        setChannel(pusher.channel(user.id.toString()));
      }
    }
  }, [user]);

  useEffect(async () => {
    if (user && user.id && channel) {
      const beamsClient = new PusherPushNotifications.Client({
        instanceId: "2e2b52e0-d2f9-4787-b348-931bb2dc0254",
      });

      await beamsClient
        .start()
        .then(async () => beamsClient.addDeviceInterest(user.id.toString()));
      await channel.bind("notification", function (data) {
        updateMessagesNotificationsWithSpecificUser(data.params.notificationId);
        notification.info({
          message: data.title,
          description:
            data.message + "" + data.commentaireRefus
              ? data.commentaireRefus
              : "",
          onClick: async () => {
            if (data.url.includes("messagerie")) {
              history.push({
                pathname: data.url.replace(Url.serverUrl, ""),
                state: data.params.senderId,
              });
            } else if (data.url.includes("gerer")) {
              history.push({
                pathname: data.url.replace(Url.serverUrl, ""),
                state: { id: data.params.candidatureId },
              });
            } else if (data.url.includes("annonce")) {
              await Url.Url2.get(
                "announcementDetails/" + `${data.params.announcementId}`
              )
                .then((response) => {
                  history.push({
                    pathname: data.url.replace(Url.serverUrl, ""),
                    state: response.data.result[0],
                    connected: true,
                  });
                })
                .catch((error) => {});
            } else {
              history.push({
                pathname: data.url.replace(Url.serverUrl, ""),
              });
            }
          },
        });
      });
    }
  }, [channel]);

  const updateMessagesNotificationsWithSpecificUser = async (
    notificationId
  ) => {
    await Url.Url3.post("updateMessagesNotificationsWithSpecificUser ", {
      notificationId: notificationId,
    })
      .then((response) => {})
      .catch((error) => {
        notification.error({
          message: "error",
          description: error.message,
        });
      });
  };

  return <App />;
}

function AppWithStore(props) {
  return (
    <HelmetProvider>
      <AuthProvider>
        <AppWithPusher history={props.history} />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default withRouter(AppWithStore);
