import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationFr from "./locales/fr.json";
import translationEn from "./locales/en.json";
import translationEs from "./locales/es.json";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    lng: 'fr',
    resources: {
      en: {
        translation: translationEn,
      },
      fr: {
        translation: translationFr,
      },
      es: {
        translation: translationEs,
      },
    },
  });

export default i18next;