import React from 'react';
import './index.css';
import './assets/css/index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from "react-dom";


const  myApp = (
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>
)
const rootElement = document.getElementById("root");

hydrate(myApp, rootElement);