import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  notifications: null,
};

const NotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    default:
      return { ...state };
  }
};
export default NotificationReducer;
