import * as actionType from "../actions/actionsTypes";
const INITIAL_STATE = {
  allData: null,
  success: null,
  successFormConnected: null,
};

const HomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.HOME_PAGE_NOT_CONNECTED:
      return {
        ...state,
        allData: action.payload,
        success: true,
      };
    case actionType.HOME_PAGE_CONNECTED:
      return {
        ...state,
        allData: action.payload,
        successFormConnected: true,
      };
    default:
      return { ...state };
  }
};
export default HomeReducer;
