import { notification } from "antd";
import Url from "../../constants/Url";
import * as actionsTypes from "./actionsTypes";

export const getPourcentageMakeMyWork = () => {
  return async (dispatch) => {
    try {
      await Url.Url4.get("getPourcentageMakeMyWork")
        .then((response) => {
          dispatch({
            type: actionsTypes.PERCENTAGE_MMK,
            payload: response.data.response.value,
          });
        })
        .catch((error) => {
          //console.log("error :", error);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCommandeList = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url4.post("getCommandeList", {
        userId: data,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_ORDER_LIST,
            payload: response.data,
          });
        })
        .catch((error) => {
          //console.log(err);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {}
  };
};

export const getServicesList = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url4.post("getServicesList", {
        userId: data,
      })
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_SERVICE_LIST,
            payload: response.data,
          });
        })
        .catch((error) => {
          //console.log(err);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {}
  };
};

export const getDetailsCandidature = (data) => {
  return async (dispatch) => {
    try {
      await Url.Url4.get("getDetailsCandidature/" + `${data}`)
        .then((response) => {
          dispatch({
            type: actionsTypes.GET_DETAIL_CANDIDATURE,
            payload: response.data,
          });
        })
        .catch((error) => {
          //console.log(err);
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {}
  };
};

export const getMotif = (candidatureId) => {
  return async (dispatch) => {
    try {
      await Url.Url5.post("getMotif", {
        candidatureId: candidatureId,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: actionsTypes.GET_MOTIF,
              payload: response.data,
            });
          }
          //
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {}
  };
};

export const testIfUserHaveBankAccount = (userId) => {
  return async (dispatch) => {
    try {
      await Url.Url4.post("testIfUserHaveBankAccount", {
        userId: userId,
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: actionsTypes.VERFIY_USER_BANK_ACCOUNT,
              payload: response.data,
            });
            setTimeout(() => {
              dispatch({
                type: actionsTypes.VERFIY_USER_BANK_ACCOUNT,
                payload: null,
              });
            }, 3000);
          }
        })
        .catch((error) => {
          notification.error({

            message: "error",
            description: error.message,
          });
        });
    } catch (error) {}
  };
};
