import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MangeAnnounceCard from "../../components/cards/MangeAnnounceCard";
import NavBarConnected from "../../components/NavBarConnected";
import NavBarMobile from "../../components/NavbarMobile";
import { AuthContext } from "../../context/AuthContext";
import * as actionCreator from "../../redux/actions/index";

function MangeOrderService() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const orderList = useSelector((state) => state.CandidacyReducer.orderList);
  const serviceList = useSelector(
    (state) => state.CandidacyReducer.serviceList
  );

  useEffect(() => {
    if (user) {
      dispatch(actionCreator.getCommandeList(user.id));
      dispatch(actionCreator.getServicesList(user.id));
    }
  }, [user]);

  const goToDissertation = (announce) => {
    history.push({
      pathname: "/gerer-vos-services",
      state: announce,
    });
  };

  return (
    <>
      <Helmet>
        <title>Make my work | Gérer les commandes et le services</title>
      </Helmet>
      <NavBarConnected />
      <NavBarMobile />
      <main id="main">
        <section data-aos="fade-up" data-aos-delay="200">
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-sm justify-content-center ">
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "5rem" }}
                >
                  <p className="text-futura-20 text-align-left">
                    {t("currentOrders")}
                  </p>
                  <div className="col-sm justify-content-center col-offre">
                    {orderList && orderList.length>0 && orderList.find(order=>order.statusCandidature === false)
                      ? orderList.map((order, key) =>
                          order.statusCandidature === false ? (
                            <MangeAnnounceCard props={{order,key}} onClick={() => goToDissertation(order)} />
                          ) : null
                        )
                      :<p className="no-items">{t("noItems")}</p>}
                    <p className="text-futura-20 text-align-left">
                      {t("completedOrders")}
                    </p>
                    {orderList && orderList.length>0 && orderList.find(order=>order.statusCandidature === true)
                      ? orderList.map((order, key) =>
                          order.statusCandidature === true ? (
                            <MangeAnnounceCard props={{order,key}} onClick={() => goToDissertation(order)} />
                          ) : null
                        )
                      :<p className="no-items">{t("noItems")}</p>}
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div
                  className="row justify-content-center"
                  style={{ marginTop: "5rem" }}
                >
                  <p
                    className="text-futura-20 text-align-left"
                    style={{ paddingLeft: "4rem" }}
                  >
                    {t("currentServices")}
                  </p>
                  <div
                    className="col-sm justify-content-center col-offre"
                    style={{
                      borderRight: "none",
                      paddingRight: "2.1rem",
                      paddingLeft: "4rem",
                    }}
                  >
                    {serviceList && serviceList.length>0 && serviceList.find(service=>service.statusCandidature === false)
                      ? serviceList.map((order, key) =>
                        order.statusCandidature === false ? (
                            <MangeAnnounceCard props={{order,key}} onClick={() => goToDissertation(order)} />
                          ) : null
                        )
                      :<p className="no-items">{t("noItems")}</p>}
                    <p className="text-futura-20 text-align-left">
                      {t("completedServices")}
                    </p>
                    { serviceList && serviceList.length>0 && serviceList.find(service=>service.statusCandidature === true)
                      ? serviceList.map((order, key) =>
                      order.statusCandidature === true ? (
                            <MangeAnnounceCard props={{order,key}} onClick={() => goToDissertation(order)} />
                          ) : null
                        )
                      :<p className="no-items">{t("noItems")}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default MangeOrderService;
