import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../components/Footer";
import NavBarConnected from "../../components/NavBarConnected";
import NavbarMobile from "../../components/NavbarMobile";
import NavBarNotConnected from "../../components/NavBarNotConnected";
import { AuthContext } from "../../context/AuthContext";



function MentionsLegales() {

    const { user } = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    })


  return (
    <>
           <Helmet>
        <title>Mentions legales | Make My Work</title>
      </Helmet>
      {user ? <NavBarConnected /> : <NavBarNotConnected />}
      <NavbarMobile />

      <div className="container ">
        <div className="terms d-flex flex-column align-items-center mt-5">
          <div className="term-header">
            <h1>MENTIONS LÉGALES DE LA PLATEFORME MAKEMYWORK</h1>
          </div>
          <div className="term-content">
            <div className="term-paragh">
              <p>
              La Plateforme est éditée et diffusée par la société MAKE MY WORK, société par actions simplifiée au capital de 5 000 euros inscrite au Registre du Commerce et des Sociétés de Versailles sous le numéro 900 672 882, ayant son siège social sis 8 avenue Manuel, 78600 Maisons-Laffitte, ayant pour numéro de TVA intra-communautaire FR44900672882.
              </p>
              <p>
              Les directeur de la publication est Lilian Hermandesse.
              </p>
            
              <p>
              La société MAKE MY WORK peut être contactée de la manière suivante :
              </p>
              <ul>
                <li>Par mail : contact@makemyworf.fr</li>
                <li>Par téléphone : +33 7 70 16 39 53</li>
                <li>Par courrier postal : MAKE MY WORK, 8 avenue Manuel, 78600 Maisons-Laffitte</li>
                
              </ul>
              <p>
La Plateforme est hébergée par Amazon Web Services EMEA SARL, dont l’adresse est 38 avenue John F. Kennedy, L-1855 Luxembourg.
</p>
              </div>
            </div>
            </div>
            </div>
            <Footer />
    </>
  )
}

export default MentionsLegales