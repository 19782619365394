import React, {useContext, useEffect } from "react";
import {useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import NavBarConnected from "../../components/NavBarConnected";
import { AuthContext } from "../../context/AuthContext";

export default function AnnouncementCreated({ history }) {
  const { user } = useContext(AuthContext);
  const data = useSelector((state) => state.AnnouncementReducer.data);
  useEffect(() => {}, [user]);

  return (
    <>
      <NavBarConnected />
      <main id="main">
        <section
          id="sec7-2-2"
          className="sec7-2-2 d-flex align-items-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="container ">
            <div className="row justify-content-center">
              <div className="col-6 justify-content-center align-items-center">
                <div className="row ">
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <img
                      className="img-size"
                      src={require('../../assets/img/recherche/recherche gat.png')}
                      alt="..."
                    />
                  </div>

                  <div className="col-10 d-flex justify-content-start align-items-star">
                    <div className="col-6 ">
                      {" "}
                      <h6>Description</h6>
                    </div>
                    <div className="col-2 "></div>
                    <div className="col-2 ">
                      <h6>
                        {data.price}€ / Heure <br />
                        <span id="met-gris">Paris 12</span>
                      </h6>
                    </div>
                    <div className="col-2 ">
                      <h6>
                        {" "}
                        4,5
                        <span id="met-violet">
                          <i className="bi bi-star-fill"></i>
                        </span>
                        <br />
                        <span id="met-vert">
                          <i className="bi bi-circle-fill"></i>
                        </span>
                        <span id="met-gris"> Disponible</span>
                      </h6>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1"></div>
                    <div
                      id="bgtext"
                      className="col-8 justify-content-start align-items-start"
                      style={{ width: "530px" }}
                    >
                      <p>{data.announcementDescription}</p>
                    </div>
                    <div className="col-1"></div>
                  </div>

                  <div className="row gy-4  justify-content-center align-items-center">
                    <div className="col-12 text-center">
                      <button classe="btn-get-started">
                        <a href="#popup1">Demander un service</a>
                      </button>
                    </div>
                  </div>

                  <div id="popup1" className="overlay">
                    <div className="popup">
                      <h1>Annonce créée !</h1>
                      <a className="close" href="#">
                        &times;
                      </a>

                      <div className="content">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <img
                            className="img-size"
                            src={require('../../assets/img/Annonce/popup.png')}
                            alt="..."
                          />
                        </div>

                        <div className="col-12 justify-content-center align-items-center">
                          <h2>Confirmation de création d’annonce</h2>
                          <p>
                            Ton annonce à bien été créee, tu peux la modifier ou
                            supprimer dans ton profil. Maintenant tu n’as plus
                            qu’à attendre que quelqu’un soit interressé !
                          </p>
                        </div>

                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <button classe="btn-get-started">
                            <a href="#">
                              <Link to="/selectionner-un-type-dannonce">
                                NOUVELLE CREATION
                              </Link>
                            </a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
