import React from 'react'

function CategoryCard(props) {
  return (
    <>
        <div className="col-auto portfolio-ser-item filter-Demandes pointer card-category-overflow"
        key={props.props.key}
        onClick={() => props.onClick(props.props.category)}>
        <div className="portfolio-ser-wrap">
            <div className="categorie-ser">
            <img
                //style={{ height: "8rem", width: "8rem" }}
                src={props.props.category.categoriePicture}
            />
            </div>
            <p className="text-futura-14 mt-3">
            {props.props.category.categoriefr}
            </p>
        </div>
        </div>
    </>
  )
}

export default CategoryCard